@import "./Variables.scss";

.side-button {
  display: inline-block !important;
  width: 5em !important;
  height: 2.5em !important;
  line-height: 2.5em !important;
  cursor: pointer !important;
  background: $side-button-bg !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
  font-size: $side-button-font-size !important;
  font-weight: $side-button-font-weight !important;
}

.side-button.left {
  border-top-left-radius: $border-radius-10px !important;
  border-bottom-left-radius: $border-radius-10px !important;
}

.side-button.right {
  border-top-right-radius: $border-radius-10px !important;
  border-bottom-right-radius: $border-radius-10px !important;
}

.side-button.active {
  background: $background-color-active !important;
  color: $side-button-bg !important;
}

@media screen and (max-width: 40em) {
  main {
    font-size: 80% !important;
  }

  h1 {
    padding-top: 1em !important;
    font-size: 2.5em !important;
  }

  .demo-buttons {
    max-width: 900px !important;
    padding: 0 2em !important;
  }
}

.bm-burger-button {
  position: fixed !important;
  width: 23px !important;
  height: 23px !important;
  left: 36px !important;
  top: 21px !important;
  z-index: 2 !important;
}

.right .bm-burger-button {
  left: 12px !important;
}

.bm-burger-bars {
  background: #373a47 !important;
}

.bm-morph-shape {
  fill: $bm-burger-background !important;
}

.bm-menu-wrap {
  width: 100% !important;
  margin: 0px !important;
  margin-top: -3px !important;
  right: 0% !important;
  height: 100vh !important;
}

.bm-menu {
  background: $bm-menu-bg !important;
  overflow: $none !important;
}

.bm-cross-button {
  border-radius: $border-radius-50pc !important;
  color: $black-color !important;
  font-weight: bold !important;
  margin-top: 7px !important;
  margin-right: 15px !important;
  z-index: $z-index-1000 !important;
}

.main-div {
  height: $main-div-height !important;
  width: $full-width !important;
  margin: 0px !important;
  background-color: $main-div-bg-color !important;
  border-bottom-right-radius: $border-radius-100pc !important;
  border-bottom-left-radius: $border-radius-100pc !important;
  h6 {
    color: #ffffff !important;
    font-weight: bold !important;
    padding-left: 10px !important;
    padding-top: 20px !important;
  }
}

.company-logo {
  height: $company-logo-height !important;
  width: $company-logo-width-340px !important;
  background-color: $company-logo-bg-color !important;
  margin-top: $company-logo-margin-top-1 !important;
  border-radius: $border-radius-6px !important;
  box-shadow: $company-logo-box-shadow !important;
}

@media screen and (min-width: 425px) {
  .company-logo {
    height: $company-logo-height !important;
    width: $company-logo-width-304px !important;
    background-color: $company-logo-bg-color !important;
    margin-top: $company-logo-margin-top-2 !important;
    border-radius: $border-radius-6px !important;
    box-shadow: $company-logo-box-shadow !important;
  }

  .item-analytics {
    margin-top: 25px !important;
  }
}

@media screen and (max-width: 425px) {
  .visitor-profile {
    overflow-x: scroll !important;
    overflow-y: hidden !important;
  }

  .item-analytics {
    margin-top: 20px !important;
  }
}

@media screen and (min-width: 768px) {
  .company-logo {
    height: $company-logo-height !important;
    width: $company-logo-width-400px !important;
    background-color: $company-logo-bg-color !important;
    margin-top: $company-logo-margin-top-3 !important;
    border-radius: $border-radius-6px !important;
    box-shadow: $company-logo-box-shadow !important;
  }

  .visiter-email {
    height: $visiter-email-height !important;
    width: $visiter-email-width-2 !important;
    background-color: $visiter-email-bg-color !important;
    border-radius: $border-radius-4px !important;
    border: 0.2px $visiter-email-border-color solid !important;
    margin-top: 20px !important;
    margin: $margin-auto !important;
  }

  .item-analytics {
    margin-top: 18px !important;
  }
}
@media screen and (min-width: 769px) {
  .bm-item {
    margin-top: -10px !important;
  }
}
.item-analytics {
  margin-top: 12px !important;
}

.visiter-email {
  height: $visiter-email-height !important;
  width: $visiter-email-width-1 !important;
  background-color: $visiter-email-bg-color !important;
  border-radius: $border-radius-4px !important;
  border: 0.2px $visiter-email-border-color solid !important;
  margin-top: $visiter-email-margin-top !important;
  margin: $margin-auto !important;
}

.count-box {
  width: $count-box-width !important;
  height: $count-box-height !important;
  border-radius: $border-radius-5px !important;
  display: flex !important;
  justify-content: space-between !important;
}

.view-button {
  width: $view-button-width !important;
  height: $view-button-height !important;
  background: $view-button-bg-color !important;
  border-radius: $border-radius-2.5px !important;
  font-size: $view-button-font-size !important;
  font-weight: $view-button-font-weight !important;
  border: 0px !important;
  margin-top: 3px !important;
  margin-left: 3px !important;
}

.bm-cross {
  background: $bm-cross-bg-color !important;
  width: $bm-cross-width !important;
  height: $bm-cross-height !important;
}

.bm-cross-button span {
  top: 2px !important;
  right: 5px !important;
  border-radius: $border-radius-8px !important;
}

.visitor-profile {
  margin-top: 3px !important;
  margin-left: 8px !important;
  font-size: $visitor-profile-font-size !important;
}

.form-check-input:checked {
  background-color: $form-check-input-checked-bg !important;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  --bs-gutter-x: $bs-gutter-x !important;
  --bs-gutter-y: $bs-gutter-y !important;
}
.rate-box {
  width: 330px !important;
  height: 80px !important;
  background: $visiter-email-bg-color !important;
  border-radius: 4px !important;
  border: 0.2px #989898 solid !important;
  text-align: $center !important;
  margin: $auto !important;
  margin-top: 12px !important;
}
.sign-out {
  width: 330px !important;
  line-height: 2.5px !important;
  background: #fff2f4 !important;
  border-radius: 4px !important;
  border: 0.2px #e82646 solid !important;
  text-align: $center !important;
  margin: $auto !important;
}
