/*------------------------------------------------------------------
[Master Stylesheet]

Project                            :   sash - HTML5 Bootstrap Admin Template
Create Date                        :   
Author & Copyright Ownership       :   Spruko Technologies Private Limited 
Author URL                         :   https://themeforest.net/user/spruko
Support	                           :   https://support.spruko.com/
License Details                    :   https://spruko.com/licenses-details
------------------------------------------------------------------*/
/* Table Of Content 
variables
switcher
accordion
alerts
badge
buttons
cards
dropdown
forms
input_group
list_group
modals
navbar
navs_tabs
pagination
popovers
progress
tables
toast
tooltips
authentication
custom
dashboard_styles
error
header
image
plugins
widgets
sign-up
closed_menu
detached_menu
double_menu
horizontal
icon_click
icon_hover
icon_overlay
icontext
menu_click
menu_hover
vertical
chat
ecommerce
file-manager
landing
mail
task
avatars
background
border
opacity
typography
*/
/* FONT */
/* inter */
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600&display=swap");

:root {
  --body-bg-rgb: 240, 240, 245;
  --primary-rgb: 13, 79, 247;
  --secondary-rgb: 5, 195, 251;
  --warning-rgb: 247, 183, 49;
  --info-rgb: 17, 112, 228;
  --success-rgb: 9, 173, 149;
  --danger-rgb: 232, 38, 70;
  --light-rgb: 246, 246, 251;
  --dark-rgb: 52, 58, 64;
  --orange-rgb: 252, 115, 3;
  --pink-rgb: 236, 130, 239;
  --teal-rgb: 28, 175, 159;
  --purple-rgb: 137, 39, 236;
  --default-body-bg-color: rgb(var(--body-bg-rgb));
  --primary-color: rgb(var(--primary-rgb));
  --primary-border: rgb(var(--primary-rgb));
  --primary01: rgba(var(--primary-rgb), 0.1);
  --primary02: rgba(var(--primary-rgb), 0.2);
  --primary03: rgba(var(--primary-rgb), 0.3);
  --primary04: rgba(var(--primary-rgb), 0.4);
  --primary05: rgba(var(--primary-rgb), 0.5);
  --primary06: rgba(var(--primary-rgb), 0.6);
  --primary07: rgba(var(--primary-rgb), 0.7);
  --primary08: rgba(var(--primary-rgb), 0.8);
  --primary09: rgba(var(--primary-rgb), 0.9);
  --primary005: rgba(var(--primary-rgb), 0.05);
  --default-font-family: "IBM Plex Sans", sans-serif;
  --default-font-weight: 400;
  --default-text-color: #282f53;
  --default-border: #e9edf4;
  --default-background: #f7f8f9;
  --menu-bg: #fff;
  --menu-prime-color: #212529;
  --menu-border-color: #e9edf4;
  --header-bg: #fff;
  --header-prime-color: rgb(var(--primary-rgb));
  --header-border-color: #e9edf4;
  --custom-white: #ffffff;
  --custom-black: #000;
  --bootstrap-card-border: #e9edf4;
  --list-hover-focus-bg: #f5f6f7;
  --text-muted: #74829c;
  --input-border: #e9edf6;
  --form-control-bg: #ffffff;
  --shadow-color: rgba(168, 180, 208, 0.1);
  /* Gray set */
  --gray-1: #f9fafb;
  --gray-2: #f2f4f5;
  --gray-3: #e6eaeb;
  --gray-4: #dbdfe1;
  --gray-5: #949eb7;
  --gray-6: #7987a1;
  --gray-7: #4d5875;
  --gray-8: #383853;
  --gray-9: #323251;
  /* White set */
  --white-1: rgba(255, 255, 255, 0.1);
  --white-2: rgba(255, 255, 255, 0.2);
  --white-3: rgba(255, 255, 255, 0.3);
  --white-4: rgba(255, 255, 255, 0.4);
  --white-5: rgba(255, 255, 255, 0.5);
  --white-6: rgba(255, 255, 255, 0.6);
  --white-7: rgba(255, 255, 255, 0.7);
  --white-8: rgba(255, 255, 255, 0.8);
  --white-9: rgba(255, 255, 255, 0.9);
  /* Black set */
  --black-1: rgba(0, 0, 0, 0.1);
  --black-2: rgba(0, 0, 0, 0.2);
  --black-3: rgba(0, 0, 0, 0.3);
  --black-4: rgba(0, 0, 0, 0.4);
  --black-5: rgba(0, 0, 0, 0.5);
  --black-6: rgba(0, 0, 0, 0.6);
  --black-7: rgba(0, 0, 0, 0.7);
  --black-8: rgba(0, 0, 0, 0.8);
  --black-9: rgba(0, 0, 0, 0.9);
}

/*Font Family*/
/*Font Size*/
/*Font Weight*/
/*Body & Text colors*/
/*Default Background Color*/
/*Border Color*/
/*Border Radius*/
/*Box Shadow*/
/* Header Variables */
/*Menu Variables*/
/*Color Variables*/
/* social icons colors */
/*gradient variables*/
/*gray set*/
/*white set*/
/*black set*/
/* dark mode */
[data-theme-mode=dark] {
  --body-bg-rgb: 26, 26, 60;
  --body-bg-rgb2: 42, 42, 74;
  --menu-bg: rgb(var(--body-bg-rgb2));
  --menu-border-color: rgba(255, 255, 255, 0.1);
  --menu-prime-color: rgba(255, 255, 255);
  --header-bg: rgb(var(--body-bg-rgb2));
  --header-prime-color: rgba(255, 255, 255);
  --header-border-color: rgba(255, 255, 255, 0.1);
  --custom-white: rgb(var(--body-bg-rgb2));
  --custom-black: #fff;
  --default-border: rgba(255, 255, 255, 0.1);
  --default-text-color: #dedefd;
  --light-rgb: 31, 31, 65;
  --dark-rgb: 240, 245, 248;
  --bootstrap-card-border: rgba(255, 255, 255, 0.1);
  --list-hover-focus-bg: rgba(255, 255, 255, 0.1);
  --default-background: rgba(255, 255, 255, 0.1);
  --default-body-bg-color: rgb(var(--body-bg-rgb));
  --text-muted: #9595b5;
  --input-border: rgba(255, 255, 255, 0.1);
  --form-control-bg: rgb(var(--body-bg-rgb2));
  --shadow-color: rgba(28, 28, 51, 0.15);
  /* Gray Set */
  --gray-100: #110f0f;
  --gray-200: #17171c;
  --gray-300: #393946;
  --gray-400: #505062;
  --gray-500: #73738c;
  --gray-600: #8f8fa3;
  --gray-700: #ababba;
  --gray-800: #c7c7d1;
  --gray-900: #e3e3e8;
  /* white set */
  --white-1: rgba(0, 0, 0, 0.1);
  --white-2: rgba(0, 0, 0, 0.2);
  --white-3: rgba(0, 0, 0, 0.3);
  --white-4: rgba(0, 0, 0, 0.4);
  --white-5: rgba(0, 0, 0, 0.5);
  --white-6: rgba(0, 0, 0, 0.6);
  --white-7: rgba(0, 0, 0, 0.7);
  --white-8: rgba(0, 0, 0, 0.8);
  --white-9: rgba(0, 0, 0, 0.9);
  /* black set */
  --black-1: rgba(255, 255, 255, 0.05);
  --black-2: rgba(255, 255, 255, 0.2);
  --black-3: rgba(255, 255, 255, 0.3);
  --black-4: rgba(255, 255, 255, 0.4);
  --black-5: rgba(255, 255, 255, 0.5);
  --black-6: rgba(255, 255, 255, 0.6);
  --black-7: rgba(255, 255, 255, 0.7);
  --black-8: rgba(255, 255, 255, 0.8);
  --black-9: rgba(255, 255, 255, 0.9);
}

/* Sidemenu Layout Syles */
@media (max-width: 991.98px) {
  .sidemenu-layout-styles {
    display: none;
  }
}

/* Sidemenu Layout Syles */
/* Respoonsive Logo Styles */
@media (max-width: 991.98px) {
  .app-header .horizontal-logo .header-logo img {
    height: 2rem;
    line-height: 2rem;
  }
}

/* Respoonsive Logo Styles */
[data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-dark {
  display: block;
}

[data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
  display: none;
}

[data-theme-mode=dark] .btn-close {
  filter: invert(1);
}

[data-theme-mode=dark] .alert .btn-close {
  filter: unset;
}

[data-theme-mode=dark] .dropdown-menu {
  box-shadow: 0 1rem 1.125rem rgba(40, 40, 40, 0.15);
}

[data-theme-mode=dark] button.gridjs-sort,
[data-theme-mode=dark] button.gridjs-sort-desc,
[data-theme-mode=dark] button.gridjs-sort-asc {
  filter: invert(1);
}

[data-theme-mode=dark] .choices__list--dropdown,
[data-theme-mode=dark] .choices__list[aria-expanded] {
  box-shadow: rgba(10, 10, 10, 0.2) 0 0.5rem 1.5rem !important;
}

[data-theme-mode=dark] .table {
  --bs-table-bg: rgb(var(--body-bg-rgb2));
  --bs-table-color-state: #fff;
}

@media (max-width: 991.98px) {

  [data-header-styles=dark] .app-header .horizontal-logo .header-logo .desktop-logo,
  [data-header-styles=dark] .app-header .horizontal-logo .header-logo .desktop-dark,
  [data-header-styles=dark] .app-header .horizontal-logo .header-logo .toggle-logo,
  [data-header-styles=dark] .app-header .horizontal-logo .header-logo .desktop-white,
  [data-header-styles=dark] .app-header .horizontal-logo .header-logo .toggle-white,
  [data-header-styles=transparent][data-theme-mode=dark] .app-header .horizontal-logo .header-logo .desktop-logo,
  [data-header-styles=transparent][data-theme-mode=dark] .app-header .horizontal-logo .header-logo .desktop-dark,
  [data-header-styles=transparent][data-theme-mode=dark] .app-header .horizontal-logo .header-logo .toggle-logo,
  [data-header-styles=transparent][data-theme-mode=dark] .app-header .horizontal-logo .header-logo .desktop-white,
  [data-header-styles=transparent][data-theme-mode=dark] .app-header .horizontal-logo .header-logo .toggle-white {
    display: none;
  }

  [data-header-styles=dark] .app-header .horizontal-logo .header-logo .toggle-dark,
  [data-header-styles=transparent][data-theme-mode=dark] .app-header .horizontal-logo .header-logo .toggle-dark {
    display: block;
  }
}

@media (max-width: 991.98px) {

  [data-header-styles=color] .app-header .horizontal-logo .header-logo .desktop-logo,
  [data-header-styles=color] .app-header .horizontal-logo .header-logo .desktop-dark,
  [data-header-styles=color] .app-header .horizontal-logo .header-logo .toggle-logo,
  [data-header-styles=color] .app-header .horizontal-logo .header-logo .desktop-white,
  [data-header-styles=color] .app-header .horizontal-logo .header-logo .toggle-dark,
  [data-header-styles=gradient] .app-header .horizontal-logo .header-logo .desktop-logo,
  [data-header-styles=gradient] .app-header .horizontal-logo .header-logo .desktop-dark,
  [data-header-styles=gradient] .app-header .horizontal-logo .header-logo .toggle-logo,
  [data-header-styles=gradient] .app-header .horizontal-logo .header-logo .desktop-white,
  [data-header-styles=gradient] .app-header .horizontal-logo .header-logo .toggle-dark {
    display: none;
  }

  [data-header-styles=color] .app-header .horizontal-logo .header-logo .toggle-white,
  [data-header-styles=gradient] .app-header .horizontal-logo .header-logo .toggle-white {
    display: block;
  }
}

@media (max-width: 991.98px) {

  [data-header-styles=light] .app-header .horizontal-logo .header-logo .toggle-logo,
  [data-header-styles=transparent][data-theme-mode=light] .app-header .horizontal-logo .header-logo .toggle-logo {
    display: block;
  }

  [data-header-styles=light] .app-header .horizontal-logo .header-logo .desktop-logo,
  [data-header-styles=light] .app-header .horizontal-logo .header-logo .toggle-dark,
  [data-header-styles=light] .app-header .horizontal-logo .header-logo .desktop-dark,
  [data-header-styles=light] .app-header .horizontal-logo .header-logo .desktop-white,
  [data-header-styles=light] .app-header .horizontal-logo .header-logo .toggle-white,
  [data-header-styles=transparent][data-theme-mode=light] .app-header .horizontal-logo .header-logo .desktop-logo,
  [data-header-styles=transparent][data-theme-mode=light] .app-header .horizontal-logo .header-logo .toggle-dark,
  [data-header-styles=transparent][data-theme-mode=light] .app-header .horizontal-logo .header-logo .desktop-dark,
  [data-header-styles=transparent][data-theme-mode=light] .app-header .horizontal-logo .header-logo .desktop-white,
  [data-header-styles=transparent][data-theme-mode=light] .app-header .horizontal-logo .header-logo .toggle-white {
    display: none;
  }
}

@media (max-width: 991.98px) {
  [data-toggled=close] .app-sidebar {
    transition: all 300ms ease;
    transform: translateX(-17rem);
  }

  [data-toggled=close] .app-header {
    padding-inline-start: 0rem;
  }

  [data-toggled=close] .app-header .horizontal-logo {
    display: block;
  }

  [data-toggled=close][dir=rtl] .app-sidebar {
    transition: all 300ms ease;
    transform: translateX(17rem);
  }
}

@media (max-width: 991.98px) {
  [data-toggled=open] .app-sidebar {
    transition: all 300ms ease;
    transform: translateX(0rem);
  }

  [data-toggled=open] .app-header {
    padding-inline-start: 0rem;
  }

  [data-toggled=open] .app-header .horizontal-logo {
    display: block;
  }
}

[data-page-style=classic] {
  --default-border: #e1e9e8;
  --header-border-color: #e1e9e8;
  --menu-border-color: #e1e9e8;
}

[data-page-style=classic][data-theme-mode=dark] {
  --default-border: rgba(255, 255, 255, 0.1);
  --header-border-color: rgba(255, 255, 255, 0.1);
  --menu-border-color: rgba(255, 255, 255, 0.1);
}

[data-page-style=classic] body {
  background-color: var(--custom-white);
}

[data-page-style=classic] .card.custom-card {
  box-shadow: none;
  border: 1px solid var(--default-border);
}

[data-page-style=classic] .card {
  box-shadow: none;
  border: 1px solid var(--default-border);
}

[data-page-style=classic] .app-sidebar,
[data-page-style=classic] .footer,
[data-page-style=classic] .app-header,
[data-page-style=classic] .app-sidebar .main-sidebar {
  box-shadow: none;
}

[data-page-style=classic][data-nav-layout=horizontal] .app-sidebar {
  border-block-end: 1px solid var(--default-border);
}

@media (min-width: 1400px) {
  [data-width=boxed] body {
    background-color: #cfd1e2;
  }

  [data-width=boxed] .page {
    width: 1400px;
    margin: 0 auto;
    background-color: var(--default-body-bg-color);
    position: relative;
    box-shadow: 0 0 1rem var(--black-1);
  }

  [data-width=boxed] .page .app-header {
    width: 1400px;
    margin: 0 auto;
  }

  [data-width=boxed] .page .app-sidebar {
    inset-inline-start: auto;
  }

  [data-width=boxed][data-nav-layout=horizontal] .app-sidebar {
    width: 1400px !important;
  }

  [data-width=boxed][data-page-style=classic] .page {
    background-color: var(--custom-white);
  }

  [data-width=boxed][data-page-style=classic] .card {
    box-shadow: none;
  }

  [data-width=boxed][data-theme-mode=dark] body {
    background-color: rgba(var(--body-bg-rgb), 0.8);
  }

  [data-width=boxed][data-vertical-style=detached] .page {
    width: 1400px;
    box-shadow: none;
  }

  [data-width=boxed][data-vertical-style=detached] .page .app-header {
    width: 100%;
  }

  [data-width=boxed][data-vertical-style=detached][data-toggled=detached-close] .app-content {
    margin-inline-start: 5rem;
  }

  [data-width=boxed][data-vertical-style=detached] .app-header .main-header-container {
    width: 1400px;
  }

  [data-width=boxed][data-vertical-style=detached] .app-content {
    margin-inline-start: 17rem;
  }

  [data-width=boxed][data-vertical-style=doublemenu] .app-sidebar .main-sidebar {
    height: 100%;
  }

  [data-width=boxed][data-vertical-style=doublemenu] .app-sidebar .simplebar-content-wrapper {
    position: initial;
  }

  [data-width=boxed][data-vertical-style=doublemenu] .app-sidebar .simplebar-mask {
    position: inherit;
  }

  [data-width=boxed] .card-text {
    font-size: 0.613rem !important;
  }

  [data-width=boxed] .followers-list .list-group-item>div>div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 4rem;
  }
}

[data-menu-position=scrollable][data-nav-layout=vertical] .app-sidebar {
  position: absolute;
}

[data-menu-position=scrollable][data-nav-layout=vertical] .app-sidebar .main-sidebar-header {
  position: absolute;
}

[data-menu-position=scrollable][data-nav-layout=horizontal] .app-sidebar {
  position: relative;
  z-index: 102;
}

[data-menu-position=scrollable][data-nav-layout=horizontal] .app-content {
  margin-block-start: 4rem;
}

[data-menu-position=scrollable][data-nav-layout=horizontal][data-header-position=scrollable] .app-sidebar {
  position: relative;
}

[data-menu-position=scrollable][data-nav-layout=horizontal][data-header-position=scrollable] .app-header {
  position: relative;
}

@media (max-width: 991.98px) {
  [data-menu-position=scrollable][data-nav-layout=horizontal] .app-sidebar {
    position: absolute;
  }

  [data-menu-position=scrollable][data-nav-layout=horizontal] .main-content {
    margin-block-start: 0;
  }

  [data-menu-position=scrollable][data-nav-layout=horizontal] .main-sidebar {
    height: 100%;
  }
}

@media (min-width: 992px) {
  [data-menu-position=scrollable] .app-content {
    min-height: calc(100vh - 2.5rem);
  }
}

[data-header-position=scrollable][data-nav-layout=vertical] .app-header {
  position: absolute;
}

[data-header-position=scrollable][data-nav-layout=horizontal] .app-sidebar {
  position: relative;
  top: 0;
}

[data-header-position=scrollable][data-nav-layout=horizontal] .app-sidebar.sticky {
  position: sticky;
  top: 0;
}

[data-header-position=scrollable][data-nav-layout=horizontal] .app-content {
  margin-block-start: 0;
}

[data-header-position=scrollable][data-nav-layout=horizontal] .app-header {
  position: relative;
}

[data-header-position=scrollable][data-nav-layout=horizontal][data-menu-position=scrollable] .app-sidebar {
  position: relative;
}

[data-header-position=scrollable][data-nav-layout=horizontal][data-menu-position=scrollable] .app-header {
  position: relative;
}

[data-header-position=scrollable][data-nav-layout=horizontal][data-width=boxed] .app-header {
  margin: 0;
}

@media (max-width: 991.98px) {
  [data-header-position=scrollable][data-nav-layout=horizontal] .app-sidebar {
    position: absolute !important;
    z-index: 102;
  }

  [data-header-position=scrollable][data-nav-layout=horizontal] .main-content {
    padding-block-start: 0;
  }

  [data-header-position=scrollable][data-nav-layout=horizontal][data-menu-position=fixed] .app-sidebar {
    position: fixed !important;
  }

  [data-header-position=scrollable][data-nav-layout=horizontal][data-menu-position=fixed] .app-sidebar .main-sidebar {
    margin-block-start: 3.72rem;
  }

  [data-header-position=scrollable][data-nav-layout=horizontal][data-menu-position=fixed] .app-sidebar.sticky .main-sidebar {
    margin-block-start: 0;
  }

  [data-header-position=scrollable][data-nav-layout=vertical] .app-sidebar.sticky {
    inset-block-start: 0;
  }
}

[data-menu-styles=light] {
  --menu-bg: #fff;
  --menu-prime-color: #3c4858;
  --menu-border-color: #e9edf4;
}

[data-menu-styles=light][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-logo {
  display: block;
}

[data-menu-styles=light][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=light][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=light][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=light][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=light][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
  display: none;
}

[data-menu-styles=light] .side-menu__label1 a {
  color: #222528;
  font-weight: 600;
}

[data-menu-styles=light] .side-menu__label1 {
  border-block-end: 1px solid #e9edf4 !important;
}

@media (min-width: 992px) {
  [data-menu-styles=light][data-vertical-style=doublemenu] .slide.has-sub .slide-menu {
    border-inline-end: 1px solid #e9edf4;
  }
}

[data-menu-styles=dark] {
  --menu-bg: var(--custom-white);
  --menu-prime-color: #dedefd;
  --menu-border-color: rgba(255, 255, 255, 0.1);
}

[data-menu-styles=dark][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-dark {
  display: block;
}

[data-menu-styles=dark][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=dark][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=dark][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=dark][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=dark][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
  display: none;
}

[data-menu-styles=dark][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
  display: block;
}

[data-menu-styles=dark][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=dark][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=dark][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=dark][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=dark][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
  display: none;
}

[data-menu-styles=dark][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-dark {
  display: block;
}

[data-menu-styles=dark][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=dark][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=dark][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=dark][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=dark][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
  display: none;
}

[data-menu-styles=dark] .side-menu__icon {
  color: var(--menu-prime-color) !important;
  fill: var(--menu-prime-color) !important;
}

[data-menu-styles=dark] .app-sidebar .side-menu__item.active .side-menu__icon,
[data-menu-styles=dark] .app-sidebar .side-menu__item:hover .side-menu__icon {
  color: var(--primary-color) !important;
  fill: var(--primary-color) !important;
}

[data-menu-styles=dark] .side-menu__label1 a {
  color: #fff;
  font-weight: 600;
}

[data-menu-styles=dark] .side-menu__label1 {
  border-block-end: 1px solid rgba(255, 255, 255, 0.1) !important;
}

[data-menu-styles=dark][data-theme-mode=light] {
  --menu-bg: rgb(42, 42, 74);
}

@media (min-width: 992px) {

  [data-menu-styles=dark][data-nav-layout=horizontal] .main-menu-container .slide-left,
  [data-menu-styles=dark][data-nav-layout=horizontal] .main-menu-container .slide-right {
    background-color: rgba(255, 255, 255, 0.3);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    border: 0;
  }

  [data-menu-styles=dark][data-nav-layout=horizontal] .main-menu-container .slide-left svg,
  [data-menu-styles=dark][data-nav-layout=horizontal] .main-menu-container .slide-right svg {
    fill: #000;
  }

  [data-menu-styles=dark][data-vertical-style=doublemenu] .slide.has-sub .slide-menu {
    border-inline-end: 1px solid rgba(255, 255, 255, 0.1);
  }
}

[data-menu-styles=dark][data-theme-mode=light] {
  --menu-bg: rgb(42, 42, 74);
  --menu-prime-color: #dedefd;
}

[data-menu-styles=color] {
  --menu-bg: var(--primary-color);
  --menu-prime-color: rgba(255, 255, 255, 0.6);
  --menu-border-color: rgba(255, 255, 255, 0.1);
}

[data-menu-styles=color][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-white {
  display: block;
}

[data-menu-styles=color][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=color][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=color][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=color][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=color][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
  display: none;
}

[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
  display: block;
}

[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
  display: none;
}

[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-white {
  display: block;
}

[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-white,
[data-menu-styles=color][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
  display: none;
}

[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-white,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
  display: block !important;
}

[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-white,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-white,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
  display: none;
}

[data-menu-styles=color] .side-menu__item.active {
  color: #fff !important;
}

[data-menu-styles=color] .side-menu__item.active:before {
  color: #fff !important;
}

[data-menu-styles=color] .side-menu__item.active .side-menu__icon,
[data-menu-styles=color] .side-menu__item.active .side-menu__label,
[data-menu-styles=color] .side-menu__item.active .side-menu__angle {
  color: #fff !important;
  fill: #fff !important;
}

[data-menu-styles=color] .side-menu__item:hover {
  color: #fff !important;
}

[data-menu-styles=color] .side-menu__item:hover:before {
  color: #fff !important;
}

[data-menu-styles=color] .side-menu__item:hover .side-menu__icon,
[data-menu-styles=color] .side-menu__item:hover .side-menu__label,
[data-menu-styles=color] .side-menu__item:hover .side-menu__angle {
  color: #fff !important;
  fill: #fff !important;
}

[data-menu-styles=color] .slide-menu {
  --menu-bg: var(--primary-color);
}

[data-menu-styles=color] .side-menu__label1 a {
  color: rgb(255, 255, 255);
  font-weight: 600;
}

[data-menu-styles=color] .side-menu__label1 {
  border-block-end: 1px solid rgba(255, 255, 255, 0.1) !important;
}

[data-menu-styles=color] .side-menu__icon {
  color: var(--menu-prime-color) !important;
  fill: var(--menu-prime-color) !important;
}

[data-menu-styles=color] .app-sidebar .side-menu__item.active .side-menu__icon,
[data-menu-styles=color] .app-sidebar .side-menu__item:hover .side-menu__icon {
  color: #fff !important;
  fill: #fff !important;
}

@media (min-width: 992px) {

  [data-menu-styles=color][data-nav-layout=horizontal] .main-menu-container .slide-left,
  [data-menu-styles=color][data-nav-layout=horizontal] .main-menu-container .slide-right {
    background-color: rgba(255, 255, 255, 0.3);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    border: 0;
  }

  [data-menu-styles=color][data-nav-layout=horizontal] .main-menu-container .slide-left svg,
  [data-menu-styles=color][data-nav-layout=horizontal] .main-menu-container .slide-right svg {
    fill: var(--primary-color);
  }

  [data-menu-styles=color][data-vertical-style=doublemenu] .slide.has-sub .slide-menu {
    border-inline-end: 1px solid rgba(255, 255, 255, 0.1);
  }

  [data-menu-styles=color][data-vertical-style=doublemenu][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
    display: block !important;
  }

  [data-menu-styles=color][data-vertical-style=doublemenu][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  [data-menu-styles=color][data-vertical-style=doublemenu][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-menu-styles=color][data-vertical-style=doublemenu][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
  [data-menu-styles=color][data-vertical-style=doublemenu][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
  [data-menu-styles=color][data-vertical-style=doublemenu][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
    display: none !important;
  }
}

[data-menu-styles=gradient] {
  --menu-bg: linear-gradient(to bottom, var(--primary-color) 0%, #00b9da 100%);
  --menu-prime-color: rgba(255, 255, 255, 0.7);
  --menu-border-color: rgba(255, 255, 255, 0.1);
}

[data-menu-styles=gradient][data-nav-layout=horizontal] {
  --menu-bg: linear-gradient(to right, var(--primary-color) 0%, #00b9da 100%);
}

[data-menu-styles=gradient] .app-sidebar .main-sidebar-header {
  background: var(--primary-color);
}

[data-menu-styles=gradient][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-white {
  display: block;
}

[data-menu-styles=gradient][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=gradient][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=gradient][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=gradient][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .toggle-white,
[data-menu-styles=gradient][data-nav-layout=vertical] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
  display: none;
}

[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
  display: block;
}

[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
  display: none;
}

[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-white {
  display: block;
}

[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-white,
[data-menu-styles=gradient][data-nav-layout=vertical][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
  display: none;
}

[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-white,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
  display: block !important;
}

[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-white,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-white,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
  display: none;
}

[data-menu-styles=gradient] .side-menu__item.active {
  color: #fff !important;
}

[data-menu-styles=gradient] .side-menu__item.active:before {
  color: #fff !important;
}

[data-menu-styles=gradient] .side-menu__item.active .side-menu__icon,
[data-menu-styles=gradient] .side-menu__item.active .side-menu__label,
[data-menu-styles=gradient] .side-menu__item.active .side-menu__angle {
  color: #fff !important;
  fill: #fff !important;
}

[data-menu-styles=gradient] .side-menu__item:hover {
  color: #fff !important;
}

[data-menu-styles=gradient] .side-menu__item:hover:before {
  color: #fff !important;
}

[data-menu-styles=gradient] .side-menu__item:hover .side-menu__icon,
[data-menu-styles=gradient] .side-menu__item:hover .side-menu__label,
[data-menu-styles=gradient] .side-menu__item:hover .side-menu__angle {
  color: #fff !important;
  fill: #fff !important;
}

[data-menu-styles=gradient] .slide-menu {
  --menu-bg: var(--primary-color);
}

[data-menu-styles=gradient] .side-menu__label1 a {
  color: rgb(255, 255, 255);
  font-weight: 600;
}

[data-menu-styles=gradient] .side-menu__label1 {
  border-block-end: 1px solid rgba(255, 255, 255, 0.1) !important;
}

[data-menu-styles=gradient] .side-menu__icon {
  color: var(--menu-prime-color) !important;
  fill: var(--menu-prime-color) !important;
}

[data-menu-styles=gradient] .app-sidebar .side-menu__item.active .side-menu__icon,
[data-menu-styles=gradient] .app-sidebar .side-menu__item:hover .side-menu__icon {
  color: #fff !important;
  fill: #fff !important;
}

@media (min-width: 992px) {

  [data-menu-styles=gradient][data-nav-layout=horizontal] .main-menu-container .slide-left,
  [data-menu-styles=gradient][data-nav-layout=horizontal] .main-menu-container .slide-right {
    background-color: rgba(255, 255, 255, 0.3);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    border: 0;
  }

  [data-menu-styles=gradient][data-nav-layout=horizontal] .main-menu-container .slide-left svg,
  [data-menu-styles=gradient][data-nav-layout=horizontal] .main-menu-container .slide-right svg {
    fill: var(--primary-color);
  }

  [data-menu-styles=gradient][data-vertical-style=doublemenu] .slide.has-sub .slide-menu {
    border-inline-end: 1px solid rgba(255, 255, 255, 0.1);
  }

  [data-menu-styles=gradient][data-vertical-style=doublemenu][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
    display: block !important;
  }

  [data-menu-styles=gradient][data-vertical-style=doublemenu][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  [data-menu-styles=gradient][data-vertical-style=doublemenu][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-menu-styles=gradient][data-vertical-style=doublemenu][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
  [data-menu-styles=gradient][data-vertical-style=doublemenu][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
  [data-menu-styles=gradient][data-vertical-style=doublemenu][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
    display: none !important;
  }
}

[data-menu-styles=transparent] {
  --menu-bg: var(--default-body-bg-color);
}

[data-menu-styles=transparent][data-theme-mode=light] {
  --menu-border-color: rgba(0, 0, 0, 0.07);
}

[data-menu-styles=transparent][data-theme-mode=dark] {
  --menu-prime-color: rgba(255, 255, 255, 0.6);
  --menu-border-color: rgba(255, 255, 255, 0.1);
  --menu-bg: var(--default-body-bg-color);
}

[data-menu-styles=transparent][data-theme-mode=dark][icon-overlay=open] {
  --menu-bg: rgb(var(--body-bg-rgb));
}

[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-dark {
  display: block;
}

[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
  display: none;
}

[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
  display: block;
}

[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
  display: none;
}

[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-dark {
  display: block;
}

[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
  display: none;
}

[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-white,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
  display: block !important;
}

[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=dark][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
  display: none;
}

[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light] .app-sidebar .main-sidebar-header .header-logo .desktop-logo {
  display: block;
}

[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
  display: none;
}

[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
  display: block;
}

[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
  display: none;
}

[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-logo {
  display: block;
}

[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
  display: none;
}

[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
  display: block !important;
}

[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-white,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-white,
[data-menu-styles=transparent][data-nav-layout=vertical][data-theme-mode=light][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
  display: none;
}

[data-menu-styles=transparent][data-theme-mode=dark][data-icon-overlay=open] .app-sidebar {
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
}

[data-menu-styles=transparent][data-theme-mode=dark][data-nav-layout=horizontal] .app-sidebar.sticky.sticky-pin {
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
}

[data-menu-styles=transparent][data-theme-mode=dark][data-nav-layout=horizontal][data-nav-style=menu-click] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-theme-mode=dark][data-nav-layout=horizontal][data-nav-style=menu-hover] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-theme-mode=dark][data-nav-layout=horizontal][data-nav-style=icon-click] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-theme-mode=dark][data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .slide.has-sub .slide-menu {
  background-color: var(--custom-white);
}

[data-menu-styles=transparent][data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-theme-mode=dark][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu {
  background-color: var(--custom-white);
}

@media (min-width: 992px) {

  [data-menu-styles=transparent][data-nav-layout=horizontal] .main-menu-container .slide-left,
  [data-menu-styles=transparent][data-nav-layout=horizontal] .main-menu-container .slide-right {
    background-color: rgba(255, 255, 255, 0.3);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    border: 0;
  }

  [data-menu-styles=transparent][data-nav-layout=horizontal] .main-menu-container .slide-left svg,
  [data-menu-styles=transparent][data-nav-layout=horizontal] .main-menu-container .slide-right svg {
    fill: var(--primary-color);
  }

  [data-menu-styles=transparent][data-vertical-style=doublemenu][data-theme-mode=light] .side-menu__label1 {
    border-block-end: 1px solid rgba(0, 0, 0, 0.07);
  }

  [data-menu-styles=transparent][data-vertical-style=doublemenu][data-theme-mode=light] .slide.has-sub .slide-menu {
    border-inline-end: 1px solid rgba(0, 0, 0, 0.07);
  }
}

[data-header-styles=light] {
  --header-bg: #fff;
  --header-border-color: #e9edf4;
  --header-prime-color: var(--primary-color);
}

[data-header-styles=light] .main-header-center input,
[data-header-styles=light] .main-header-center .btn {
  --default-text-color: #3c4858;
}

[data-header-styles=light] #mainHeaderProfile h6 {
  color: #3c4858;
}

[data-header-styles=dark] {
  --header-bg: var(--custom-white);
  --header-border-color: rgba(255, 255, 255, 0.1);
  --header-prime-color: #fff;
}

[data-header-styles=dark] .app-header #mainHeaderProfile {
  --dark-rgb: 255, 255, 255;
  --text-muted: rgba(255, 255, 255, 0.5);
}

@media (min-width: 992px) {
  [data-header-styles=dark][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .desktop-dark {
    display: block;
  }

  [data-header-styles=dark][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .desktop-logo,
  [data-header-styles=dark][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .toggle-dark,
  [data-header-styles=dark][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .toggle-logo,
  [data-header-styles=dark][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .desktop-white,
  [data-header-styles=dark][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .toggle-white {
    display: none;
  }
}

[data-header-styles=dark] .main-header-container .header-link:hover .header-link-icon,
[data-header-styles=dark] .main-header-container .header-link.show .header-link-icon {
  fill: #fff;
}

[data-header-styles=dark] .main-header-container .header-link-icon:hover {
  background-color: transparent;
}

[data-header-styles=dark][data-theme-mode=light] {
  --header-bg: rgb(42, 42, 74);
}

[data-header-styles=dark] .main-header-center input,
[data-header-styles=dark] .main-header-center .btn {
  --default-text-color: #fff;
}

[data-header-styles=dark] #mainHeaderProfile h6 {
  color: #fff;
}

[data-header-styles=color] {
  --header-bg: var(--primary-color);
  --header-border-color: rgba(255, 255, 255, 0.1);
  --header-prime-color: rgba(255, 255, 255, 0.6);
}

[data-header-styles=color] .app-header #mainHeaderProfile {
  --dark-rgb: 255, 255, 255;
  --text-muted: rgba(255, 255, 255, 0.5);
}

@media (min-width: 992px) {
  [data-header-styles=color][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .desktop-white {
    display: block;
  }

  [data-header-styles=color][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .desktop-logo,
  [data-header-styles=color][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .desktop-dark,
  [data-header-styles=color][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .toggle-dark,
  [data-header-styles=color][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .toggle-white,
  [data-header-styles=color][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .toggle-logo {
    display: none;
  }
}

[data-header-styles=color] .main-header-container .header-link:hover .header-link-icon,
[data-header-styles=color] .main-header-container .header-link.show .header-link-icon {
  fill: #fff;
}

[data-header-styles=color] .main-header-container .header-link-icon:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

[data-header-styles=color] .main-header-center input,
[data-header-styles=color] .main-header-center .btn {
  --default-text-color: #fff;
}

[data-header-styles=color] #mainHeaderProfile h6 {
  color: #fff;
}

[data-header-styles=gradient] {
  --header-bg: linear-gradient(to right, var(--primary-color) 0%, #00b9da 100%);
  --header-border-color: rgba(255, 255, 255, 0.1);
  --header-prime-color: rgba(255, 255, 255, 0.6);
}

[data-header-styles=gradient] .app-header #mainHeaderProfile {
  --dark-rgb: 255, 255, 255;
  --text-muted: rgba(255, 255, 255, 0.5);
}

@media (min-width: 992px) {
  [data-header-styles=gradient][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .desktop-white {
    display: block;
  }

  [data-header-styles=gradient][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .desktop-logo,
  [data-header-styles=gradient][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .desktop-dark,
  [data-header-styles=gradient][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .toggle-dark,
  [data-header-styles=gradient][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .toggle-white,
  [data-header-styles=gradient][data-nav-layout=horizontal] .header-element .horizontal-logo .header-logo .toggle-logo {
    display: none;
  }
}

[data-header-styles=gradient] .main-header-container .header-link:hover .header-link-icon,
[data-header-styles=gradient] .main-header-container .header-link.show .header-link-icon {
  fill: #fff;
}

[data-header-styles=gradient] .main-header-container .header-link-icon:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

[data-header-styles=gradient] .main-header-center input,
[data-header-styles=gradient] .main-header-center .btn {
  --default-text-color: #fff;
}

[data-header-styles=gradient] #mainHeaderProfile h6 {
  color: #fff;
}

[data-header-styles=transparent] {
  --header-bg: transparent;
}

[data-header-styles=transparent] v .app-header {
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: none;
}

[data-header-styles=transparent] .main-header-container .header-link-icon:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

[data-header-styles=transparent][data-theme-mode=light] {
  --header-border-color: rgba(0, 0, 0, 0.07);
}

[data-header-styles=transparent][data-theme-mode=light] .main-header-center input,
[data-header-styles=transparent][data-theme-mode=light] .main-header-center .btn {
  --default-text-color: #3c4858;
}

[data-header-styles=transparent][data-theme-mode=light] #mainHeaderProfile h6 {
  color: #3c4858;
}

[data-header-styles=transparent][data-theme-mode=dark] {
  --header-prime-color: rgba(255, 255, 255, 0.6);
  --header-border-color: rgba(255, 255, 255, 0.1);
}

[data-header-styles=transparent][data-theme-mode=dark] .main-header-container .header-link-icon:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

[data-header-styles=transparent][data-theme-mode=dark] .main-header-center input,
[data-header-styles=transparent][data-theme-mode=dark] .main-header-center .btn {
  --default-text-color: #fff;
}

[data-header-styles=transparent][data-theme-mode=dark] #mainHeaderProfile h6 {
  color: #fff;
}

@media (min-width: 992px) {

  [data-header-styles=transparent][data-nav-layout=horizontal][data-theme-mode=light] .header-element .horizontal-logo .header-logo .desktop-dark,
  [data-header-styles=transparent][data-nav-layout=horizontal][data-theme-mode=light] .header-element .horizontal-logo .header-logo .toggle-logo,
  [data-header-styles=transparent][data-nav-layout=horizontal][data-theme-mode=light] .header-element .horizontal-logo .header-logo .toggle-dark,
  [data-header-styles=transparent][data-nav-layout=horizontal][data-theme-mode=light] .header-element .horizontal-logo .header-logo .desktop-white,
  [data-header-styles=transparent][data-nav-layout=horizontal][data-theme-mode=light] .header-element .horizontal-logo .header-logo .toggle-white {
    display: none;
  }

  [data-header-styles=transparent][data-nav-layout=horizontal][data-theme-mode=light] .header-element .horizontal-logo .header-logo .desktop-logo {
    display: block;
  }

  [data-header-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark] .header-element .horizontal-logo .header-logo .desktop-dark {
    display: block;
  }

  [data-header-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark] .header-element .horizontal-logo .header-logo .desktop-logo,
  [data-header-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark] .header-element .horizontal-logo .header-logo .toggle-logo,
  [data-header-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark] .header-element .horizontal-logo .header-logo .toggle-dark,
  [data-header-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark] .header-element .horizontal-logo .header-logo .desktop-white,
  [data-header-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark] .header-element .horizontal-logo .header-logo .toggle-white {
    display: none;
  }
}

/* Header Colors Styles*/
/* Menu Background Image Styles */
.bg-img .app-sidebar .main-sidebar-header,
[data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-bg-img=bgimg1] .app-sidebar .main-sidebar-header {
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
}

.bg-img .app-sidebar:before,
[data-bg-img=bgimg5] .app-sidebar:before,
[data-bg-img=bgimg4] .app-sidebar:before,
[data-bg-img=bgimg3] .app-sidebar:before,
[data-bg-img=bgimg2] .app-sidebar:before,
[data-bg-img=bgimg1] .app-sidebar:before {
  background-color: rgba(var(--body-bg-rgb), 0.8);
}

@media (min-width: 992px) {

  .bg-img[data-nav-layout=horizontal] ul.slide-menu,
  [data-nav-layout=horizontal][data-bg-img=bgimg5] ul.slide-menu,
  [data-nav-layout=horizontal][data-bg-img=bgimg4] ul.slide-menu,
  [data-nav-layout=horizontal][data-bg-img=bgimg3] ul.slide-menu,
  [data-nav-layout=horizontal][data-bg-img=bgimg2] ul.slide-menu,
  [data-nav-layout=horizontal][data-bg-img=bgimg1] ul.slide-menu {
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
  }

  .bg-img[data-nav-layout=horizontal] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-bg-img=bgimg5] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-bg-img=bgimg4] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-bg-img=bgimg3] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-bg-img=bgimg2] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-bg-img=bgimg1] ul.slide-menu:before {
    content: "";
    position: absolute;
    inset-inline-start: 0;
    top: 0;
    inset-inline-end: 0;
    bottom: 0;
    opacity: 0.9;
    background: rgb(var(--body-bg-rgb));
  }

  .bg-img[data-nav-layout=horizontal][data-menu-styles=color] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-menu-styles=color][data-bg-img=bgimg5] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-menu-styles=color][data-bg-img=bgimg4] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-menu-styles=color][data-bg-img=bgimg3] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-menu-styles=color][data-bg-img=bgimg2] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-menu-styles=color][data-bg-img=bgimg1] ul.slide-menu:before,
  .bg-img[data-nav-layout=horizontal][data-menu-styles=dark] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg5] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg4] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg3] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg2] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg1] ul.slide-menu:before,
  .bg-img[data-nav-layout=horizontal][data-menu-styles=gradient] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-menu-styles=gradient][data-bg-img=bgimg5] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-menu-styles=gradient][data-bg-img=bgimg4] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-menu-styles=gradient][data-bg-img=bgimg3] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-menu-styles=gradient][data-bg-img=bgimg2] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-menu-styles=gradient][data-bg-img=bgimg1] ul.slide-menu:before {
    display: none;
  }

  .bg-img[data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg5] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg4] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg3] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg2] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg1] ul.slide-menu:before {
    background: rgb(245, 246, 247);
  }

  .bg-img[data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg5] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg4] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg3] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg2] ul.slide-menu:before,
  [data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg1] ul.slide-menu:before {
    display: block;
  }

  .bg-img[data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light] .app-sidebar .slide.has-sub .slide-menu,
  [data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
  [data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
  [data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
  [data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
  [data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu {
    background: #011626;
  }
}

.bg-img[data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
.bg-img[data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header {
  background-color: rgb(255, 255, 255);
}

.bg-img[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
.bg-img[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header {
  background-color: var(--primary-color);
}

.bg-img[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
.bg-img[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header {
  background: linear-gradient(to right, var(--primary-color) 0, rgb(var(--secondary-rgb)) 100%);
}

.bg-img[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
.bg-img[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header {
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
}

.bg-img[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
.bg-img[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header {
  background-color: rgb(var(--body-bg-rgb));
}

.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3 {
  background-color: var(--custom-white) !important;
}

.bg-img[data-theme-mode=light][data-menu-styles=dark] .app-sidebar:before,
[data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg5] .app-sidebar:before,
[data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg4] .app-sidebar:before,
[data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg3] .app-sidebar:before,
[data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg2] .app-sidebar:before,
[data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg1] .app-sidebar:before {
  background-color: rgba(0, 0, 0, 0.8);
}

.bg-img[data-vertical-style=doublemenu] .slide.has-sub .slide-menu,
[data-vertical-style=doublemenu][data-bg-img=bgimg5] .slide.has-sub .slide-menu,
[data-vertical-style=doublemenu][data-bg-img=bgimg4] .slide.has-sub .slide-menu,
[data-vertical-style=doublemenu][data-bg-img=bgimg3] .slide.has-sub .slide-menu,
[data-vertical-style=doublemenu][data-bg-img=bgimg2] .slide.has-sub .slide-menu,
[data-vertical-style=doublemenu][data-bg-img=bgimg1] .slide.has-sub .slide-menu {
  background-color: #fff;
  background-image: none;
}

.bg-img[data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu {
  background-color: #fff;
  background-image: none;
}

.bg-img[data-menu-styles=light] .app-sidebar:before,
[data-menu-styles=light][data-bg-img=bgimg5] .app-sidebar:before,
[data-menu-styles=light][data-bg-img=bgimg4] .app-sidebar:before,
[data-menu-styles=light][data-bg-img=bgimg3] .app-sidebar:before,
[data-menu-styles=light][data-bg-img=bgimg2] .app-sidebar:before,
[data-menu-styles=light][data-bg-img=bgimg1] .app-sidebar:before {
  background-color: rgba(255, 255, 255, 0.85);
}

@media (min-width: 992px) {

  .bg-img[data-menu-styles=dark][data-nav-layout=horizontal] ul.slide-menu,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-bg-img=bgimg5] ul.slide-menu,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-bg-img=bgimg4] ul.slide-menu,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-bg-img=bgimg3] ul.slide-menu,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-bg-img=bgimg2] ul.slide-menu,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-bg-img=bgimg1] ul.slide-menu {
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
  }

  .bg-img[data-menu-styles=dark][data-nav-layout=horizontal] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-bg-img=bgimg5] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-bg-img=bgimg4] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-bg-img=bgimg3] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-bg-img=bgimg2] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-bg-img=bgimg1] ul.slide-menu:before {
    content: "";
    position: absolute;
    inset-inline-start: 0;
    top: 0;
    inset-inline-end: 0;
    bottom: 0;
    opacity: 0.9;
    background: rgb(var(--body-bg-rgb));
  }

  .bg-img[data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=color] ul.slide-menu:before,
  .bg-img[data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=dark] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg5] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg4] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg3] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg2] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg1] ul.slide-menu:before,
  .bg-img[data-menu-styles=dark][data-nav-layout=horizontal][data-menu-styles=gradient] ul.slide-menu:before {
    display: none;
  }

  .bg-img[data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg5] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg4] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg3] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg2] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg1] ul.slide-menu:before {
    background: rgb(245, 246, 247);
  }

  .bg-img[data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg5] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg4] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg3] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg2] ul.slide-menu:before,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg1] ul.slide-menu:before {
    display: block;
  }

  .bg-img[data-menu-styles=dark][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light] .app-sidebar .slide.has-sub .slide-menu,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
  [data-menu-styles=dark][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu {
    background: #011626;
  }
}

.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header {
  background-color: rgb(255, 255, 255);
}

.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header {
  background-color: var(--primary-color);
}

.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header {
  background: linear-gradient(to right, var(--primary-color) 0, rgb(var(--secondary-rgb)) 100%);
}

.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header {
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
}

.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=dark][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header {
  background-color: rgb(var(--body-bg-rgb));
}

.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=dark][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3 {
  background-color: var(--custom-white) !important;
}

.bg-img[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=dark] .app-sidebar:before,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg5] .app-sidebar:before,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg4] .app-sidebar:before,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg3] .app-sidebar:before,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg2] .app-sidebar:before,
[data-menu-styles=dark][data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg1] .app-sidebar:before {
  background-color: rgba(0, 0, 0, 0.8);
}

.bg-img[data-menu-styles=dark][data-vertical-style=doublemenu] .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-vertical-style=doublemenu][data-bg-img=bgimg5] .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-vertical-style=doublemenu][data-bg-img=bgimg4] .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-vertical-style=doublemenu][data-bg-img=bgimg3] .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-vertical-style=doublemenu][data-bg-img=bgimg2] .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-vertical-style=doublemenu][data-bg-img=bgimg1] .slide.has-sub .slide-menu {
  background-color: var(--custom-white);
  background-image: none;
}

.bg-img[data-menu-styles=dark][data-theme-mode=light][data-vertical-style=doublemenu] .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-theme-mode=light][data-vertical-style=doublemenu][data-bg-img=bgimg5] .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-theme-mode=light][data-vertical-style=doublemenu][data-bg-img=bgimg4] .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-theme-mode=light][data-vertical-style=doublemenu][data-bg-img=bgimg3] .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-theme-mode=light][data-vertical-style=doublemenu][data-bg-img=bgimg2] .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-theme-mode=light][data-vertical-style=doublemenu][data-bg-img=bgimg1] .slide.has-sub .slide-menu {
  background-color: #011626;
}

.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=dark][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu {
  background-color: rgb(var(--body-bg-rgb2));
  background-image: none;
}

.bg-img[data-menu-styles=color] .app-sidebar:before,
[data-menu-styles=color][data-bg-img=bgimg5] .app-sidebar:before,
[data-menu-styles=color][data-bg-img=bgimg4] .app-sidebar:before,
[data-menu-styles=color][data-bg-img=bgimg3] .app-sidebar:before,
[data-menu-styles=color][data-bg-img=bgimg2] .app-sidebar:before,
[data-menu-styles=color][data-bg-img=bgimg1] .app-sidebar:before {
  background-color: var(--primary-color);
  opacity: 0.9;
}

.bg-img[data-menu-styles=color][data-vertical-style=doublemenu] .slide.has-sub .slide-menu,
[data-menu-styles=color][data-vertical-style=doublemenu][data-bg-img=bgimg5] .slide.has-sub .slide-menu,
[data-menu-styles=color][data-vertical-style=doublemenu][data-bg-img=bgimg4] .slide.has-sub .slide-menu,
[data-menu-styles=color][data-vertical-style=doublemenu][data-bg-img=bgimg3] .slide.has-sub .slide-menu,
[data-menu-styles=color][data-vertical-style=doublemenu][data-bg-img=bgimg2] .slide.has-sub .slide-menu,
[data-menu-styles=color][data-vertical-style=doublemenu][data-bg-img=bgimg1] .slide.has-sub .slide-menu {
  background-color: var(--primary-color);
  background-image: none;
}

.bg-img[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=color][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu {
  background-color: var(--primary-color);
  background-image: none;
}

.bg-img[data-menu-styles=gradient] .app-sidebar:before,
[data-menu-styles=gradient][data-bg-img=bgimg5] .app-sidebar:before,
[data-menu-styles=gradient][data-bg-img=bgimg4] .app-sidebar:before,
[data-menu-styles=gradient][data-bg-img=bgimg3] .app-sidebar:before,
[data-menu-styles=gradient][data-bg-img=bgimg2] .app-sidebar:before,
[data-menu-styles=gradient][data-bg-img=bgimg1] .app-sidebar:before {
  background: linear-gradient(to right, var(--primary-color) 0%, rgb(var(--secondary-rgb)) 100%);
  opacity: 0.9;
}

.bg-img[data-menu-styles=gradient][data-vertical-style=doublemenu] .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-vertical-style=doublemenu][data-bg-img=bgimg5] .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-vertical-style=doublemenu][data-bg-img=bgimg4] .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-vertical-style=doublemenu][data-bg-img=bgimg3] .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-vertical-style=doublemenu][data-bg-img=bgimg2] .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-vertical-style=doublemenu][data-bg-img=bgimg1] .slide.has-sub .slide-menu {
  background-color: var(--primary-color);
  background-image: none;
}

.bg-img[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu,
.bg-img[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=gradient][data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu {
  background-color: var(--primary-color);
  background-image: none;
}

.bg-img[data-menu-styles=transparent][data-theme-mode=dark],
[data-menu-styles=transparent][data-theme-mode=dark][data-bg-img=bgimg5],
[data-menu-styles=transparent][data-theme-mode=dark][data-bg-img=bgimg4],
[data-menu-styles=transparent][data-theme-mode=dark][data-bg-img=bgimg3],
[data-menu-styles=transparent][data-theme-mode=dark][data-bg-img=bgimg2],
[data-menu-styles=transparent][data-theme-mode=dark][data-bg-img=bgimg1] {
  --menu-prime-color: rgba(255, 255, 255, 0.6);
}

.bg-img[data-menu-styles=transparent] .app-sidebar:before,
[data-menu-styles=transparent][data-bg-img=bgimg5] .app-sidebar:before,
[data-menu-styles=transparent][data-bg-img=bgimg4] .app-sidebar:before,
[data-menu-styles=transparent][data-bg-img=bgimg3] .app-sidebar:before,
[data-menu-styles=transparent][data-bg-img=bgimg2] .app-sidebar:before,
[data-menu-styles=transparent][data-bg-img=bgimg1] .app-sidebar:before {
  background-color: var(--default-body-bg-color);
  opacity: 0.9;
}

@media (min-width: 992px) {

  .bg-img[data-menu-styles=transparent][data-nav-layout=horizontal] ul.slide-menu,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-bg-img=bgimg5] ul.slide-menu,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-bg-img=bgimg4] ul.slide-menu,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-bg-img=bgimg3] ul.slide-menu,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-bg-img=bgimg2] ul.slide-menu,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-bg-img=bgimg1] ul.slide-menu {
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
  }

  .bg-img[data-menu-styles=transparent][data-nav-layout=horizontal] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-bg-img=bgimg5] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-bg-img=bgimg4] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-bg-img=bgimg3] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-bg-img=bgimg2] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-bg-img=bgimg1] ul.slide-menu:before {
    content: "";
    position: absolute;
    inset-inline-start: 0;
    top: 0;
    inset-inline-end: 0;
    bottom: 0;
    opacity: 0.9;
    background: rgb(var(--body-bg-rgb));
  }

  .bg-img[data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=color] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=color][data-bg-img=bgimg5] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=color][data-bg-img=bgimg4] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=color][data-bg-img=bgimg3] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=color][data-bg-img=bgimg2] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=color][data-bg-img=bgimg1] ul.slide-menu:before,
  .bg-img[data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=dark] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg5] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg4] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg3] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg2] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=dark][data-bg-img=bgimg1] ul.slide-menu:before,
  .bg-img[data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=gradient] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=gradient][data-bg-img=bgimg5] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=gradient][data-bg-img=bgimg4] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=gradient][data-bg-img=bgimg3] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=gradient][data-bg-img=bgimg2] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-menu-styles=gradient][data-bg-img=bgimg1] ul.slide-menu:before {
    display: none;
  }

  .bg-img[data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg5] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg4] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg3] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg2] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=light][data-bg-img=bgimg1] ul.slide-menu:before {
    background: rgb(245, 246, 247);
  }

  .bg-img[data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg5] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg4] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg3] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg2] ul.slide-menu:before,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-theme-mode=dark][data-menu-styles=dark][data-bg-img=bgimg1] ul.slide-menu:before {
    display: block;
  }

  .bg-img[data-menu-styles=transparent][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light] .app-sidebar .slide.has-sub .slide-menu,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
  [data-menu-styles=transparent][data-nav-layout=horizontal][data-nav-layout=horizontal][data-menu-styles=dark][data-theme-mode=light][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu {
    background: #011626;
  }
}

.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=light][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header {
  background-color: rgb(255, 255, 255);
}

.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=color][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header {
  background-color: var(--primary-color);
}

.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=gradient][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header {
  background: linear-gradient(to right, var(--primary-color) 0, rgb(var(--secondary-rgb)) 100%);
}

.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=dark][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header {
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
}

.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header,
.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-menu-styles=transparent][data-nav-layout=vertical][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .main-sidebar-header {
  background-color: rgb(var(--body-bg-rgb));
}

.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=menu-hover][data-toggled=menu-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child1,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child1,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child2,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child2,
.bg-img[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu.child3,
[data-menu-styles=transparent][data-theme-mode=dark][data-menu-styles=transparent][data-nav-style=icon-click][data-toggled=icon-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu.child3 {
  background-color: var(--custom-white) !important;
}

.bg-img[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=dark] .app-sidebar:before,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg5] .app-sidebar:before,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg4] .app-sidebar:before,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg3] .app-sidebar:before,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg2] .app-sidebar:before,
[data-menu-styles=transparent][data-theme-mode=light][data-menu-styles=dark][data-bg-img=bgimg1] .app-sidebar:before {
  background-color: rgba(0, 0, 0, 0.8);
}

.bg-img[data-menu-styles=transparent][data-vertical-style=doublemenu] .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg5] .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg4] .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg3] .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg2] .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-vertical-style=doublemenu][data-bg-img=bgimg1] .slide.has-sub .slide-menu {
  background-color: var(--default-body-bg-color);
  background-image: none;
}

.bg-img[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg5] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg4] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg3] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg2] .app-sidebar .slide.has-sub .slide-menu,
[data-menu-styles=transparent][data-nav-layout=vertical][data-nav-style=menu-click][data-toggled=menu-click-closed][data-bg-img=bgimg1] .app-sidebar .slide.has-sub .slide-menu {
  background-color: var(--default-body-bg-color);
  background-image: none;
}

.bg-img .app-sidebar,
[data-bg-img=bgimg5] .app-sidebar,
[data-bg-img=bgimg4] .app-sidebar,
[data-bg-img=bgimg3] .app-sidebar,
[data-bg-img=bgimg2] .app-sidebar,
[data-bg-img=bgimg1] .app-sidebar {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-inline-end: 0;
}

.bg-img .app-sidebar .main-sidebar-header,
[data-bg-img=bgimg5] .app-sidebar .main-sidebar-header,
[data-bg-img=bgimg4] .app-sidebar .main-sidebar-header,
[data-bg-img=bgimg3] .app-sidebar .main-sidebar-header,
[data-bg-img=bgimg2] .app-sidebar .main-sidebar-header,
[data-bg-img=bgimg1] .app-sidebar .main-sidebar-header {
  background: transparent;
  border-inline-end: 0;
}

.bg-img .app-sidebar:before,
[data-bg-img=bgimg5] .app-sidebar:before,
[data-bg-img=bgimg4] .app-sidebar:before,
[data-bg-img=bgimg3] .app-sidebar:before,
[data-bg-img=bgimg2] .app-sidebar:before,
[data-bg-img=bgimg1] .app-sidebar:before {
  content: "";
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}


/* Menu Background Image Styles */
/* Loader Styles */
[loader=disable] #loader {
  display: none;
}

/* Loader Styles */
/* BOOTSTRAP */
.accordion-button {
  background-color: var(--custom-white);
  color: var(--default-text-color);
  padding: 0.75rem 1rem;
  font-size: 0.85rem;
  font-weight: 500;
}

.accordion-button:not(.collapsed) {
  color: var(--default-text-color);
  background-color: var(--default-background);
  box-shadow: none;
}

.accordion-button:focus {
  border-color: var(--primary03);
  box-shadow: none;
}

.accordion-button:after,
.accordion-button:not(.collapsed)::after {
  background-image: none;
}

.accordion-button:after {
  content: "\ea78";
  font-family: remixicon !important;
  font-size: 1rem;
  line-height: 1;
  background-color: var(--default-background);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3.125rem;
  color: var(--default-text-color);
}

.accordion-body {
  padding: 0.75rem 1rem;
  font-size: 0.8125rem;
  color: var(--text-muted);
}

.accordion-item {
  color: var(--default-text-color);
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
}

.accordions-items-seperate .accordion-item:not(:first-of-type) {
  margin-block-start: 0.5rem;
}

.accordions-items-seperate .accordion-item {
  border-block-start: 1px solid var(--default-border);
  border-radius: 7px;
  overflow: hidden;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 0.35rem;
  border-bottom-left-radius: 0.35rem;
}

.accordion-item:first-of-type {
  border-top-left-radius: 0.35rem;
  border-top-right-radius: 0.35rem;
}

/* Start:: light colored accordions */
.accordion.accordion-primary .accordion-button {
  background-color: var(--primary01);
  color: var(--primary-color);
}

.accordion.accordion-primary .accordion-button:after {
  background-color: var(--primary-color);
  color: #fff;
}

.accordion.accordion-primary .accordion-button.collapsed {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}

.accordion.accordion-primary .accordion-button.collapsed:after {
  background-color: var(--default-background);
  color: var(--default-text-color);
}

.accordion.accordion-secondary .accordion-button {
  background-color: rgba(var(--secondary-rgb), 0.1);
  color: rgb(var(--secondary-rgb));
}

.accordion.accordion-secondary .accordion-button:after {
  background-color: rgb(var(--secondary-rgb));
  color: #fff;
}

.accordion.accordion-secondary .accordion-button.collapsed {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}

.accordion.accordion-secondary .accordion-button.collapsed:after {
  background-color: var(--default-background);
  color: var(--default-text-color);
}

.accordion.accordion-success .accordion-button {
  background-color: rgba(var(--success-rgb), 0.1);
  color: rgb(var(--success-rgb));
}

.accordion.accordion-success .accordion-button:after {
  background-color: rgb(var(--success-rgb));
  color: #fff;
}

.accordion.accordion-success .accordion-button.collapsed {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}

.accordion.accordion-success .accordion-button.collapsed:after {
  background-color: var(--default-background);
  color: var(--default-text-color);
}

.accordion.accordion-danger .accordion-button {
  background-color: rgba(var(--danger-rgb), 0.1);
  color: rgb(var(--danger-rgb));
}

.accordion.accordion-danger .accordion-button:after {
  background-color: rgb(var(--danger-rgb));
  color: #fff;
}

.accordion.accordion-danger .accordion-button.collapsed {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}

.accordion.accordion-danger .accordion-button.collapsed:after {
  background-color: var(--default-background);
  color: var(--default-text-color);
}

.accordion.accordion-warning .accordion-button {
  background-color: rgba(var(--warning-rgb), 0.1);
  color: rgb(var(--warning-rgb));
}

.accordion.accordion-warning .accordion-button:after {
  background-color: rgb(var(--warning-rgb));
  color: #fff;
}

.accordion.accordion-warning .accordion-button.collapsed {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}

.accordion.accordion-warning .accordion-button.collapsed:after {
  background-color: var(--default-background);
  color: var(--default-text-color);
}

.accordion.accordion-info .accordion-button {
  background-color: rgba(var(--info-rgb), 0.1);
  color: rgb(var(--info-rgb));
}

.accordion.accordion-info .accordion-button:after {
  background-color: rgb(var(--info-rgb));
  color: #fff;
}

.accordion.accordion-info .accordion-button.collapsed {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}

.accordion.accordion-info .accordion-button.collapsed:after {
  background-color: var(--default-background);
  color: var(--default-text-color);
}

/* End:: light colored accordions */
/* Start:: solid colored accordions */
.accordion.accordion-solid-primary .accordion-button {
  background-color: var(--primary-color);
  color: #fff;
}

.accordion.accordion-solid-primary .accordion-button:after {
  background-color: var(--custom-white);
  color: var(--primary-color);
}

.accordion.accordion-solid-primary .accordion-button.collapsed {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}

.accordion.accordion-solid-primary .accordion-button.collapsed:after {
  background-color: var(--default-background);
  color: var(--default-text-color);
}

.accordion.accordion-solid-secondary .accordion-button {
  background-color: rgb(var(--secondary-rgb));
  color: #fff;
}

.accordion.accordion-solid-secondary .accordion-button:after {
  background-color: var(--custom-white);
  color: rgb(var(--secondary-rgb));
}

.accordion.accordion-solid-secondary .accordion-button.collapsed {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}

.accordion.accordion-solid-secondary .accordion-button.collapsed:after {
  background-color: var(--default-background);
  color: var(--default-text-color);
}

.accordion.accordion-solid-success .accordion-button {
  background-color: rgb(var(--success-rgb));
  color: #fff;
}

.accordion.accordion-solid-success .accordion-button:after {
  background-color: var(--custom-white);
  color: rgb(var(--success-rgb));
}

.accordion.accordion-solid-success .accordion-button.collapsed {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}

.accordion.accordion-solid-success .accordion-button.collapsed:after {
  background-color: var(--default-background);
  color: var(--default-text-color);
}

.accordion.accordion-solid-danger .accordion-button {
  background-color: rgb(var(--danger-rgb));
  color: #fff;
}

.accordion.accordion-solid-danger .accordion-button:after {
  background-color: var(--custom-white);
  color: rgb(var(--danger-rgb));
}

.accordion.accordion-solid-danger .accordion-button.collapsed {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}

.accordion.accordion-solid-danger .accordion-button.collapsed:after {
  background-color: var(--default-background);
  color: var(--default-text-color);
}

.accordion.accordion-solid-warning .accordion-button {
  background-color: rgb(var(--warning-rgb));
  color: #fff;
}

.accordion.accordion-solid-warning .accordion-button:after {
  background-color: var(--custom-white);
  color: rgb(var(--warning-rgb));
}

.accordion.accordion-solid-warning .accordion-button.collapsed {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}

.accordion.accordion-solid-warning .accordion-button.collapsed:after {
  background-color: var(--default-background);
  color: var(--default-text-color);
}

.accordion.accordion-solid-info .accordion-button {
  background-color: rgb(var(--info-rgb));
  color: #fff;
}

.accordion.accordion-solid-info .accordion-button:after {
  background-color: var(--custom-white);
  color: rgb(var(--info-rgb));
}

.accordion.accordion-solid-info .accordion-button.collapsed {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}

.accordion.accordion-solid-info .accordion-button.collapsed:after {
  background-color: var(--default-background);
  color: var(--default-text-color);
}

/* End:: solid colored accordions */
/* Start:: border colors */
.accordion.accordion-border-primary .accordion-item {
  border: 1px solid var(--primary-color);
}

.accordion.accordion-border-primary .accordion-button {
  background-color: var(--custom-white);
  color: var(--primary-color);
  border-block-end: 0px;
}

.accordion.accordion-border-primary .accordion-button:after {
  background-color: var(--custom-white);
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.accordion.accordion-border-primary .accordion-button:not(.collapsed) {
  border-block-end: 1px solid var(--primary-color);
}

.accordion.accordion-border-secondary .accordion-item {
  border: 1px solid rgb(var(--secondary-rgb));
}

.accordion.accordion-border-secondary .accordion-button {
  background-color: var(--custom-white);
  color: rgb(var(--secondary-rgb));
  border-block-end: 0px;
}

.accordion.accordion-border-secondary .accordion-button:after {
  background-color: var(--custom-white);
  color: rgb(var(--secondary-rgb));
  border: 1px solid rgb(var(--secondary-rgb));
}

.accordion.accordion-border-secondary .accordion-button:not(.collapsed) {
  border-block-end: 1px solid rgb(var(--secondary-rgb));
}

.accordion.accordion-border-success .accordion-item {
  border: 1px solid rgb(var(--success-rgb));
}

.accordion.accordion-border-success .accordion-button {
  background-color: var(--custom-white);
  color: rgb(var(--success-rgb));
  border-block-end: 0px;
}

.accordion.accordion-border-success .accordion-button:after {
  background-color: var(--custom-white);
  color: rgb(var(--success-rgb));
  border: 1px solid rgb(var(--success-rgb));
}

.accordion.accordion-border-success .accordion-button:not(.collapsed) {
  border-block-end: 1px solid rgb(var(--success-rgb));
}

.accordion.accordion-border-danger .accordion-item {
  border: 1px solid rgb(var(--danger-rgb));
}

.accordion.accordion-border-danger .accordion-button {
  background-color: var(--custom-white);
  color: rgb(var(--danger-rgb));
  border-block-end: 0px;
}

.accordion.accordion-border-danger .accordion-button:after {
  background-color: var(--custom-white);
  color: rgb(var(--danger-rgb));
  border: 1px solid rgb(var(--danger-rgb));
}

.accordion.accordion-border-danger .accordion-button:not(.collapsed) {
  border-block-end: 1px solid rgb(var(--danger-rgb));
}

.accordion.accordion-border-warning .accordion-item {
  border: 1px solid rgb(var(--warning-rgb));
}

.accordion.accordion-border-warning .accordion-button {
  background-color: var(--custom-white);
  color: rgb(var(--warning-rgb));
  border-block-end: 0px;
}

.accordion.accordion-border-warning .accordion-button:after {
  background-color: var(--custom-white);
  color: rgb(var(--warning-rgb));
  border: 1px solid rgb(var(--warning-rgb));
}

.accordion.accordion-border-warning .accordion-button:not(.collapsed) {
  border-block-end: 1px solid rgb(var(--warning-rgb));
}

.accordion.accordion-border-info .accordion-item {
  border: 1px solid rgb(var(--info-rgb));
}

.accordion.accordion-border-info .accordion-button {
  background-color: var(--custom-white);
  color: rgb(var(--info-rgb));
  border-block-end: 0px;
}

.accordion.accordion-border-info .accordion-button:after {
  background-color: var(--custom-white);
  color: rgb(var(--info-rgb));
  border: 1px solid rgb(var(--info-rgb));
}

.accordion.accordion-border-info .accordion-button:not(.collapsed) {
  border-block-end: 1px solid rgb(var(--info-rgb));
}

/* End:: border colors */
/* Start:: no icon */
.accordion.accordionicon-none .accordion-button:after {
  display: none;
}

/* Start:: no icon */
/* Start:: left aligned icon */
.accordion.accordionicon-left .accordion-button {
  padding-inline-start: 2.5rem;
}

.accordion.accordionicon-left .accordion-button:after {
  position: absolute;
  inset-inline-start: 0.625rem;
}

/* End:: left aligned icon */
/* Start:: custom icons */
.accordion.accordion-customicon1 .accordion-button:after {
  content: "\ea13";
  font-family: remixicon !important;
}

.accordion.accordion-customicon1 .accordion-button:not(.collapsed)::after {
  content: "\f1af";
  font-family: remixicon !important;
}

/* End:: custom icons */
/* Start:: customized accordion */
.customized-accordion .accordion-item.custom-accordion-primary .accordion-button {
  background-color: var(--primary01);
  border-inline-start: 0.25rem solid var(--primary06);
}

.customized-accordion .accordion-item.custom-accordion-primary .accordion-button:after {
  background-color: var(--primary06);
  color: #fff;
}

.customized-accordion .accordion-item.custom-accordion-primary .accordion-body {
  background-color: var(--primary01);
  padding-block-start: 0;
  border-inline-start: 0.25rem solid var(--primary06);
}

.customized-accordion .accordion-item.custom-accordion-secondary .accordion-button {
  background-color: rgba(var(--secondary-rgb), 0.1);
  border-inline-start: 0.25rem solid rgba(var(--secondary-rgb), 0.6);
}

.customized-accordion .accordion-item.custom-accordion-secondary .accordion-button:after {
  background-color: rgba(var(--secondary-rgb), 0.6);
  color: #fff;
}

.customized-accordion .accordion-item.custom-accordion-secondary .accordion-body {
  background-color: rgba(var(--secondary-rgb), 0.1);
  padding-block-start: 0;
  border-inline-start: 0.25rem solid rgba(var(--secondary-rgb), 0.6);
}

.customized-accordion .accordion-item.custom-accordion-warning .accordion-button {
  background-color: rgba(var(--warning-rgb), 0.1);
  border-inline-start: 0.25rem solid rgba(var(--warning-rgb), 0.6);
}

.customized-accordion .accordion-item.custom-accordion-warning .accordion-button:after {
  background-color: rgba(var(--warning-rgb), 0.6);
  color: #fff;
}

.customized-accordion .accordion-item.custom-accordion-warning .accordion-body {
  background-color: rgba(var(--warning-rgb), 0.1);
  padding-block-start: 0;
  border-inline-start: 0.25rem solid rgba(var(--warning-rgb), 0.6);
}

.customized-accordion .accordion-item.custom-accordion-info .accordion-button {
  background-color: rgba(var(--info-rgb), 0.1);
  border-inline-start: 0.25rem solid rgba(var(--info-rgb), 0.6);
}

.customized-accordion .accordion-item.custom-accordion-info .accordion-button:after {
  background-color: rgba(var(--info-rgb), 0.6);
  color: #fff;
}

.customized-accordion .accordion-item.custom-accordion-info .accordion-body {
  background-color: rgba(var(--info-rgb), 0.1);
  padding-block-start: 0;
  border-inline-start: 0.25rem solid rgba(var(--info-rgb), 0.6);
}

.customized-accordion .accordion-item.custom-accordion-success .accordion-button {
  background-color: rgba(var(--success-rgb), 0.1);
  border-inline-start: 0.25rem solid rgba(var(--success-rgb), 0.6);
}

.customized-accordion .accordion-item.custom-accordion-success .accordion-button:after {
  background-color: rgba(var(--success-rgb), 0.6);
  color: #fff;
}

.customized-accordion .accordion-item.custom-accordion-success .accordion-body {
  background-color: rgba(var(--success-rgb), 0.1);
  padding-block-start: 0;
  border-inline-start: 0.25rem solid rgba(var(--success-rgb), 0.6);
}

.customized-accordion .accordion-item.custom-accordion-danger .accordion-button {
  background-color: rgba(var(--danger-rgb), 0.1);
  border-inline-start: 0.25rem solid rgba(var(--danger-rgb), 0.6);
}

.customized-accordion .accordion-item.custom-accordion-danger .accordion-button:after {
  background-color: rgba(var(--danger-rgb), 0.6);
  color: #fff;
}

.customized-accordion .accordion-item.custom-accordion-danger .accordion-body {
  background-color: rgba(var(--danger-rgb), 0.1);
  padding-block-start: 0;
  border-inline-start: 0.25rem solid rgba(var(--danger-rgb), 0.6);
}

/* End:: customized accordion */
.alert {
  padding: 0.625rem 0.85rem;
  border-radius: 7px;
  font-size: 0.8125rem;
}

.alert.alert-dismissible {
  padding: 0.625rem 2.25rem 0.625rem 0.85rem;
}

.alert .btn-close {
  background-image: none;
  padding: 0.85rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert .btn-close i {
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.alert:last-child {
  margin-bottom: 0;
}

.alert .alert-link {
  font-weight: 600;
}

[dir=rtl] .alert.alert-dismissible {
  padding: 0.625rem 0.85rem 0.625rem 2.25rem;
}

/* Basic Alerts */
.alert-warning {
  background-color: rgba(var(--warning-rgb), 0.1);
  color: rgb(var(--warning-rgb));
  border-color: rgba(var(--warning-rgb), 0.1);
}

.alert-warning .alert-link {
  color: rgb(var(--warning-rgb));
}

.alert-warning .btn-close {
  color: rgb(var(--warning-rgb));
}

.alert-warning .btn-close.custom-close {
  background-color: rgb(var(--warning-rgb));
  color: #fff;
  opacity: 1;
  border-radius: 50px;
  box-shadow: 0 0.125rem 0 rgba(10, 10, 10, 0.04);
  padding: 0.85rem;
}

.alert-primary {
  background-color: var(--primary01);
  color: var(--primary-color);
  border-color: var(--primary01);
}

.alert-primary .alert-link {
  color: var(--primary-color);
}

.alert-primary .btn-close {
  color: var(--primary-color);
}

.alert-primary .btn-close.custom-close {
  background-color: var(--primary-color);
  color: #fff;
  opacity: 1;
  border-radius: 50px;
  box-shadow: 0 0.125rem 0 rgba(10, 10, 10, 0.04);
  padding: 0.85rem;
}

.alert-secondary {
  background-color: rgba(var(--secondary-rgb), 0.1);
  color: rgb(var(--secondary-rgb));
  border-color: rgba(var(--secondary-rgb), 0.1);
}

.alert-secondary .alert-link {
  color: rgb(var(--secondary-rgb));
}

.alert-secondary .btn-close {
  color: rgb(var(--secondary-rgb));
}

.alert-secondary .btn-close.custom-close {
  background-color: rgb(var(--secondary-rgb));
  color: #fff;
  opacity: 1;
  border-radius: 50px;
  box-shadow: 0 0.125rem 0 rgba(10, 10, 10, 0.04);
  padding: 0.85rem;
}

.alert-success {
  background-color: rgba(var(--success-rgb), 0.1);
  color: rgb(var(--success-rgb));
  border-color: rgba(var(--success-rgb), 0.1);
}

.alert-success .alert-link {
  color: rgb(var(--success-rgb));
}

.alert-success .btn-close {
  color: rgb(var(--success-rgb));
}

.alert-success .btn-close.custom-close {
  background-color: rgb(var(--success-rgb));
  color: #fff;
  opacity: 1;
  border-radius: 50px;
  box-shadow: 0 0.125rem 0 rgba(10, 10, 10, 0.04);
  padding: 0.85rem;
}

.alert-info {
  background-color: rgba(var(--info-rgb), 0.1);
  color: rgb(var(--info-rgb));
  border-color: rgba(var(--info-rgb), 0.1);
}

.alert-info .alert-link {
  color: rgb(var(--info-rgb));
}

.alert-info .btn-close {
  color: rgb(var(--info-rgb));
}

.alert-info .btn-close.custom-close {
  background-color: rgb(var(--info-rgb));
  color: #fff;
  opacity: 1;
  border-radius: 50px;
  box-shadow: 0 0.125rem 0 rgba(10, 10, 10, 0.04);
  padding: 0.85rem;
}

.alert-danger {
  background-color: rgba(var(--danger-rgb), 0.1);
  color: rgb(var(--danger-rgb));
  border-color: rgba(var(--danger-rgb), 0.1);
}

.alert-danger .alert-link {
  color: rgb(var(--danger-rgb));
}

.alert-danger .btn-close {
  color: rgb(var(--danger-rgb));
}

.alert-danger .btn-close.custom-close {
  background-color: rgb(var(--danger-rgb));
  color: #fff;
  opacity: 1;
  border-radius: 50px;
  box-shadow: 0 0.125rem 0 rgba(10, 10, 10, 0.04);
  padding: 0.85rem;
}

.alert-light {
  background-color: rgb(var(--light-rgb));
  color: var(--default-text-color);
  border-color: rgb(var(--light-rgb));
}

.alert-light .alert-link {
  color: var(--default-text-color);
}

.alert-light .btn-close {
  color: var(--default-text-color);
}

.alert-light .btn-close.custom-close {
  background-color: rgb(var(--light-rgb));
  color: #fff;
  opacity: 1;
  border-radius: 50px;
  box-shadow: 0 0.125rem 0 rgba(10, 10, 10, 0.04);
  padding: 0.85rem;
}

.alert-dark {
  background-color: rgba(var(--dark-rgb), 0.1);
  color: var(--default-text-color);
  border-color: rgba(var(--dark-rgb), 0.1);
}

.alert-dark .alert-link {
  color: var(--default-text-color);
}

.alert-dark .btn-close {
  color: #fff;
}

.alert-dark .btn-close.custom-close {
  background-color: rgb(var(--dark-rgb));
  color: #fff;
  opacity: 1;
  border-radius: 50px;
  box-shadow: 0 0.125rem 0 rgba(10, 10, 10, 0.04);
  padding: 0.85rem;
}

/* Basic Alerts */
/* Solid Colored Alerts */
.alert-solid-primary {
  background-color: var(--primary-color);
  color: #fff;
  border-color: var(--primary-color);
}

.alert-solid-primary .btn-close {
  color: #fff;
}

.alert-solid-secondary {
  background-color: rgb(var(--secondary-rgb));
  color: #fff;
  border-color: rgb(var(--secondary-rgb));
}

.alert-solid-secondary .btn-close {
  color: #fff;
}

.alert-solid-warning {
  background-color: rgb(var(--warning-rgb));
  color: #fff;
  border-color: rgb(var(--warning-rgb));
}

.alert-solid-warning .btn-close {
  color: #fff;
}

.alert-solid-info {
  background-color: rgb(var(--info-rgb));
  color: #fff;
  border-color: rgb(var(--info-rgb));
}

.alert-solid-info .btn-close {
  color: #fff;
}

.alert-solid-success {
  background-color: rgb(var(--success-rgb));
  color: #fff;
  border-color: rgb(var(--success-rgb));
}

.alert-solid-success .btn-close {
  color: #fff;
}

.alert-solid-danger {
  background-color: rgb(var(--danger-rgb));
  color: #fff;
  border-color: rgb(var(--danger-rgb));
}

.alert-solid-danger .btn-close {
  color: #fff;
}

.alert-solid-light {
  background-color: rgb(var(--light-rgb));
  color: var(--default-text-color);
  border-color: var(--default-border);
}

.alert-solid-dark {
  background-color: rgb(var(--dark-rgb));
  color: #fff;
  border-color: rgb(var(--dark-rgb));
}

.alert-solid-dark .btn-close {
  color: #fff;
}

/* Solid Colored Alerts */
/* Outline Alerts */
.alert-outline-primary {
  background-color: var(--custom-white);
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.alert-outline-primary .btn-close {
  color: var(--primary-color);
}

.alert-outline-secondary {
  background-color: var(--custom-white);
  color: rgb(var(--secondary-rgb));
  border-color: rgb(var(--secondary-rgb));
}

.alert-outline-secondary .btn-close {
  color: rgb(var(--secondary-rgb));
}

.alert-outline-info {
  background-color: var(--custom-white);
  color: rgb(var(--info-rgb));
  border-color: rgb(var(--info-rgb));
}

.alert-outline-info .btn-close {
  color: rgb(var(--info-rgb));
}

.alert-outline-warning {
  background-color: var(--custom-white);
  color: rgb(var(--warning-rgb));
  border-color: rgb(var(--warning-rgb));
}

.alert-outline-warning .btn-close {
  color: rgb(var(--warning-rgb));
}

.alert-outline-success {
  background-color: var(--custom-white);
  color: rgb(var(--success-rgb));
  border-color: rgb(var(--success-rgb));
}

.alert-outline-success .btn-close {
  color: rgb(var(--success-rgb));
}

.alert-outline-danger {
  background-color: var(--custom-white);
  color: rgb(var(--danger-rgb));
  border-color: rgb(var(--danger-rgb));
}

.alert-outline-danger .btn-close {
  color: rgb(var(--danger-rgb));
}

.alert-outline-light {
  background-color: var(--custom-white);
  color: var(--default-text-color);
  border-color: rgb(var(--light-rgb));
}

.alert-outline-dark {
  background-color: var(--custom-white);
  color: rgb(var(--dark-rgb));
  border-color: rgb(var(--dark-rgb));
}

.alert-outline-dark .btn-close {
  color: rgb(var(--dark-rgb));
}

/* Outline Alerts */
/* Customized Alerts */
.alert-primary.custom-alert-icon {
  border-inline-start: 0.313rem solid var(--primary-color) !important;
  color: var(--text-muted);
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
  font-size: 0.813rem;
}

.alert-primary.custom-alert-icon .btn-close {
  color: var(--default-text-color);
}

.alert-secondary.custom-alert-icon {
  border-inline-start: 0.313rem solid rgb(var(--secondary-rgb)) !important;
  color: var(--text-muted);
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
  font-size: 0.813rem;
}

.alert-secondary.custom-alert-icon .btn-close {
  color: var(--default-text-color);
}

.alert-warning.custom-alert-icon {
  border-inline-start: 0.313rem solid rgb(var(--warning-rgb)) !important;
  color: var(--text-muted);
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
  font-size: 0.813rem;
}

.alert-warning.custom-alert-icon .btn-close {
  color: var(--default-text-color);
}

.alert-danger.custom-alert-icon {
  border-inline-start: 0.313rem solid rgb(var(--danger-rgb)) !important;
  color: var(--text-muted);
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
  font-size: 0.813rem;
}

.alert-danger.custom-alert-icon .btn-close {
  color: var(--default-text-color);
}

.alert-success.custom-alert-icon {
  border-inline-start: 0.313rem solid rgb(var(--success-rgb)) !important;
  color: var(--text-muted);
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
  font-size: 0.813rem;
}

.alert-success.custom-alert-icon .btn-close {
  color: var(--default-text-color);
}

.alert-info.custom-alert-icon {
  border-inline-start: 0.313rem solid rgb(var(--info-rgb)) !important;
  color: var(--text-muted);
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
  font-size: 0.813rem;
}

.alert-info.custom-alert-icon .btn-close {
  color: var(--default-text-color);
}

.alert-light.custom-alert-icon {
  border-inline-start: 0.313rem solid rgb(var(--light-rgb)) !important;
  color: var(--text-muted);
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
  font-size: 0.813rem;
}

.alert-light.custom-alert-icon .btn-close {
  color: var(--default-text-color);
}

.alert-dark.custom-alert-icon {
  border-inline-start: 0.313rem solid rgb(var(--dark-rgb)) !important;
  color: var(--text-muted);
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
  font-size: 0.813rem;
}

.alert-dark.custom-alert-icon .btn-close {
  color: var(--default-text-color);
}

/* Customized Alerts */
/* Customized Alerts1 */
.custom-alert1 {
  margin-block-end: 0;
  background-color: var(--custom-white);
  border: 0;
  padding: 1.25rem;
  color: var(--default-text-color);
}

.custom-alert1 p {
  margin-block-end: 2.5rem;
  color: var(--text-muted);
  font-size: 0.8rem;
}

.custom-alert1 .custom-alert-icon {
  width: 3.125rem;
  height: 3.125rem;
  margin-bottom: 0.85rem;
}

.custom-alert1 .btn-close {
  padding: 0;
  margin-block-end: 1rem;
}

.custom-alert1.alert-primary {
  border-block-start: 0.313rem solid var(--primary-color);
}

.custom-alert1.alert-secondary {
  border-block-start: 0.313rem solid rgb(var(--secondary-rgb));
}

.custom-alert1.alert-warning {
  border-block-start: 0.313rem solid rgb(var(--warning-rgb));
}

.custom-alert1.alert-danger {
  border-block-start: 0.313rem solid rgb(var(--danger-rgb));
}

/* Customized Alerts1 */
/* Image alerts */
.alert-img {
  display: flex;
  align-items: center;
}

.alert-img .avatar {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

/* Image alerts */
@media screen and (max-width: 400px) {
  .btn-close.custom-close {
    margin: 9px 9px 9px 0;
  }
}

.badge {
  padding: 0.25rem 0.45rem;
  font-weight: 600;
  border-radius: 0.25rem;
}

.badge-sm {
  font-weight: normal;
  font-size: 0.625rem;
}

.btn.btn-outline-primary .badge {
  background-color: var(--primary-color);
  color: #fff;
}

.btn.btn-outline-primary:hover .badge,
.btn.btn-outline-primary:focus .badge,
.btn.btn-outline-primary:active .badge {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}

.btn.btn-outline-secondary .badge {
  background-color: rgb(var(--secondary-rgb));
  color: #fff;
}

.btn.btn-outline-secondary:hover .badge,
.btn.btn-outline-secondary:focus .badge,
.btn.btn-outline-secondary:active .badge {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}

.btn.btn-outline-warning .badge {
  background-color: rgb(var(--warning-rgb));
  color: #fff;
}

.btn.btn-outline-warning:hover .badge,
.btn.btn-outline-warning:focus .badge,
.btn.btn-outline-warning:active .badge {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}

.btn.btn-outline-info .badge {
  background-color: rgb(var(--info-rgb));
  color: #fff;
}

.btn.btn-outline-info:hover .badge,
.btn.btn-outline-info:focus .badge,
.btn.btn-outline-info:active .badge {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}

.btn.btn-outline-success .badge {
  background-color: rgb(var(--success-rgb));
  color: #fff;
}

.btn.btn-outline-success:hover .badge,
.btn.btn-outline-success:focus .badge,
.btn.btn-outline-success:active .badge {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}

.btn.btn-outline-danger .badge {
  background-color: rgb(var(--danger-rgb));
  color: #fff;
}

.btn.btn-outline-danger:hover .badge,
.btn.btn-outline-danger:focus .badge,
.btn.btn-outline-danger:active .badge {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}

.custom-badge {
  font-size: 0.7rem;
  font-weight: 600;
}

.icon-badge {
  position: relative;
}

.icon-badge .icon {
  width: 2rem;
  height: 2rem;
  fill: var(--text-muted);
  font-size: 2rem;
}

.icon-badge .badge {
  position: absolute;
  inset-inline-end: -0.5rem;
}

.text-badge {
  position: relative;
}

.text-badge .badge {
  position: absolute;
  inset-inline-end: -1rem;
  inset-block-start: -1rem;
}

/* Start:: Breadcrumb Styles */
.page-header-breadcrumb .breadcrumb-item+.breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, "\ea65");
  font-family: "tabler-icons" !important;
}

[dir=rtl] .page-header-breadcrumb .breadcrumb-item+.breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, "\ea64");
  font-family: "tabler-icons" !important;
}

.breadcrumb .breadcrumb-item a {
  color: var(--text-muted);
}

.breadcrumb .breadcrumb-item.active {
  color: var(--primary-color);
}

.breadcrumb-item+.breadcrumb-item::before {
  color: var(--text-muted);
  opacity: 0.4;
}

.breadcrumb-example1 .breadcrumb-item+.breadcrumb-item::before {
  color: var(--text-muted);
  content: var(--bs-breadcrumb-divider, "\ea65");
  font-family: "tabler-icons" !important;
}

.breadcrumb-style1 .breadcrumb-item+.breadcrumb-item::before {
  color: var(--text-muted);
  content: var(--bs-breadcrumb-divider, "\ea1c");
  font-family: "tabler-icons" !important;
}

.breadcrumb-style2 .breadcrumb-item+.breadcrumb-item::before {
  color: var(--text-muted);
  content: var(--bs-breadcrumb-divider, "\ea61");
  font-family: "tabler-icons" !important;
}

.embedded-breadcrumb:before {
  opacity: 0.7;
}

[data-theme-mode=dark] .embedded-breadcrumb:before {
  filter: invert(1);
}

/* End:: Breadcrumb Styles */
/* Start::button default */
.btn {
  font-size: 0.85rem;
  border-radius: 0.35rem;
  padding: 0.5rem 0.85rem;
  box-shadow: none;
  font-weight: 500;
}

.btn.btn-sm {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
}

.btn.btn-lg {
  padding: 0.65rem 1rem;
  border-radius: 7px;
  font-size: 0.95rem;
}

.btn-group .btn {
  padding: 0.45rem 0.75rem;
}

.btn-group-lg .btn {
  padding: 0.65rem 1rem;
}

.btn-group-sm .btn {
  padding: 0.25rem 0.5rem;
}

.btn-check:active+.btn,
.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:active {
  color: #fff;
}

.btn-check:focus+.btn,
.btn:focus {
  box-shadow: none;
}

/* End::button default */
/* Start::button colors */
/* btn-primary starts */
.btn-primary {
  background-color: rgb(var(--primary-rgb)) !important;
  border-color: rgb(var(--primary-rgb)) !important;
  color: #fff !important;
}

.btn-primary:hover {
  background-color: rgba(var(--primary-rgb), 0.9) !important;
  border-color: rgb(var(--primary-rgb)) !important;
  color: #fff;
}

.btn-primary:focus {
  background-color: rgb(var(--primary-rgb));
  border-color: rgb(var(--primary-rgb));
  color: #fff;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
  background-color: rgb(var(--primary-rgb));
  border-color: rgb(var(--primary-rgb));
  outline: 0;
  box-shadow: none;
}

.btn-check:active+.btn-primary:focus,
.btn-check:checked+.btn-primary:focus,
.btn-primary.active:focus,
.btn-primary.show:focus,
.btn-primary:active:focus {
  box-shadow: 0 0.25rem 1rem var(--primary05);
}

.btn-check:active+.btn-primary,
.btn-check:checked+.btn-primary,
.btn-primary.active,
.btn-primary.show,
.btn-primary:active {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-border) !important;
}

/* btn-primary ends */
/* btn-secondary starts */
/* .btn-secondary {
  background-color: rgb(var(--secondary-rgb)) !important;
  border-color: rgb(var(--secondary-rgb)) !important;
  color: #fff !important;
} */

/* .btn-secondary:hover {
  background-color: rgba(var(--secondary-rgb), 0.9) !important;
  border-color: rgb(var(--secondary-rgb)) !important;
  color: #fff;
} */

/* .btn-secondary:focus {
  background-color: rgb(var(--secondary-rgb));
  border-color: rgb(var(--secondary-rgb));
  color: #fff;
} */

.btn-check:focus+.btn-secondary,
.btn-secondary:focus {
  background-color: rgb(var(--secondary-rgb));
  border-color: rgb(var(--secondary-rgb));
  outline: 0;
  box-shadow: none;
}

.btn-check:active+.btn-secondary:focus,
.btn-check:checked+.btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary.show:focus,
.btn-secondary:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--secondary-rgb), 0.5);
}

.btn-check:active+.btn-secondary,
.btn-check:checked+.btn-secondary,
.btn-secondary.active,
.btn-secondary.show,
.btn-secondary:active {
  background-color: rgb(var(--secondary-rgb)) !important;
  border-color: rgb(var(--secondary-rgb)) !important;
}

/* btn-secondary ends */
/* btn-warning starts */
.btn-warning {
  background-color: rgb(var(--warning-rgb)) !important;
  border-color: rgb(var(--warning-rgb)) !important;
  color: #fff !important;
}

.btn-warning:hover {
  background-color: rgba(var(--warning-rgb), 0.9) !important;
  border-color: rgb(var(--warning-rgb)) !important;
  color: #fff;
}

.btn-warning:focus {
  background-color: rgb(var(--warning-rgb));
  border-color: rgb(var(--warning-rgb));
  color: #fff;
}

.btn-check:focus+.btn-warning,
.btn-warning:focus {
  background-color: rgb(var(--warning-rgb));
  border-color: rgb(var(--warning-rgb));
  outline: 0;
  box-shadow: none;
}

.btn-check:active+.btn-warning:focus,
.btn-check:checked+.btn-warning:focus,
.btn-warning.active:focus,
.btn-warning.show:focus,
.btn-warning:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--warning-rgb), 0.5);
}

.btn-check:active+.btn-warning,
.btn-check:checked+.btn-warning,
.btn-warning.active,
.btn-warning.show,
.btn-warning:active {
  background-color: rgb(var(--warning-rgb)) !important;
  border-color: rgb(var(--warning-rgb)) !important;
}

/* btn-warning ends */
/* btn-info starts */
.btn-info {
  background-color: rgb(var(--info-rgb)) !important;
  border-color: rgb(var(--info-rgb)) !important;
  color: #fff !important;
}

.btn-info:hover {
  background-color: rgba(var(--info-rgb), 0.9) !important;
  border-color: rgb(var(--info-rgb)) !important;
  color: #fff;
}

.btn-info:focus {
  background-color: rgb(var(--info-rgb));
  border-color: rgb(var(--info-rgb));
  color: #fff;
}

.btn-check:focus+.btn-info,
.btn-info:focus {
  background-color: rgb(var(--info-rgb));
  border-color: rgb(var(--info-rgb));
  outline: 0;
  box-shadow: none;
}

.btn-check:active+.btn-info:focus,
.btn-check:checked+.btn-info:focus,
.btn-info.active:focus,
.btn-info.show:focus,
.btn-info:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--info-rgb), 0.5);
}

.btn-check:active+.btn-info,
.btn-check:checked+.btn-info,
.btn-info.active,
.btn-info.show,
.btn-info:active {
  background-color: rgb(var(--info-rgb)) !important;
  border-color: rgb(var(--info-rgb)) !important;
}

/* btn-info ends */
/* btn-danger starts */
.btn-danger {
  background-color: rgb(var(--danger-rgb)) !important;
  border-color: rgb(var(--danger-rgb)) !important;
  color: #fff !important;
}

.btn-danger:hover {
  background-color: rgba(var(--danger-rgb), 0.9) !important;
  border-color: rgb(var(--danger-rgb)) !important;
  color: #fff;
}

.btn-danger:focus {
  background-color: rgb(var(--danger-rgb));
  border-color: rgb(var(--danger-rgb));
  color: #fff;
}

.btn-check:focus+.btn-danger,
.btn-danger:focus {
  background-color: rgb(var(--danger-rgb));
  border-color: rgb(var(--danger-rgb));
  outline: 0;
  box-shadow: none;
}

.btn-check:active+.btn-danger:focus,
.btn-check:checked+.btn-danger:focus,
.btn-danger.active:focus,
.btn-danger.show:focus,
.btn-danger:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--danger-rgb), 0.5);
}

.btn-check:active+.btn-danger,
.btn-check:checked+.btn-danger,
.btn-danger.active,
.btn-danger.show,
.btn-danger:active {
  background-color: rgb(var(--danger-rgb)) !important;
  border-color: rgb(var(--danger-rgb)) !important;
}

/* btn-danger ends */
/* btn-success starts */
.btn-success {
  background-color: rgb(var(--success-rgb)) !important;
  border-color: rgb(var(--success-rgb)) !important;
  color: #fff !important;
}

.btn-success:hover {
  background-color: rgba(var(--success-rgb), 0.9) !important;
  border-color: rgb(var(--success-rgb)) !important;
  color: #fff;
}

.btn-success:focus {
  background-color: rgb(var(--success-rgb));
  border-color: rgb(var(--success-rgb));
  color: #fff;
}

.btn-check:focus+.btn-success,
.btn-success:focus {
  background-color: rgb(var(--success-rgb));
  border-color: rgb(var(--success-rgb));
  outline: 0;
  box-shadow: none;
}

.btn-check:active+.btn-success:focus,
.btn-check:checked+.btn-success:focus,
.btn-success.active:focus,
.btn-success.show:focus,
.btn-success:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--success-rgb), 0.5);
}

.btn-check:active+.btn-success,
.btn-check:checked+.btn-success,
.btn-success.active,
.btn-success.show,
.btn-success:active {
  background-color: rgb(var(--success-rgb)) !important;
  border-color: rgb(var(--success-rgb)) !important;
}

/* btn-success ends */
/* btn-dark starts */
.btn-dark {
  background-color: rgb(var(--dark-rgb));
  border-color: rgb(var(--dark-rgb));
  color: var(--custom-white);
}

.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active {
  background-color: rgb(var(--dark-rgb));
  border-color: rgb(var(--dark-rgb));
  color: var(--custom-white) !important;
}

.btn-dark svg {
  fill: var(--custom-black);
}

.btn-check:focus+.btn-dark,
.btn-dark:focus {
  background-color: rgb(var(--dark-rgb));
  border-color: rgb(var(--dark-rgb));
  outline: 0;
  box-shadow: none;
}

.btn-check:active+.btn-dark:focus,
.btn-check:checked+.btn-dark:focus,
.btn-dark.active:focus,
.btn-dark.show:focus,
.btn-dark:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--dark-rgb), 0.5);
}

.btn-check:active+.btn-dark,
.btn-check:checked+.btn-dark,
.btn-dark.active,
.btn-dark.show,
.btn-dark:active {
  background-color: rgb(var(--dark-rgb)) !important;
  border-color: rgb(var(--dark-rgb)) !important;
  color: var(--custom-white);
}

/* btn-dark ends */
/* btn-light starts */
.btn-light {
  background-color: rgb(var(--light-rgb));
  border-color: var(--default-border);
  color: var(--default-text-color);
}

.btn-light:hover {
  background-color: #e4ecf2;
  border-color: rgb(var(--light-rgb));
  color: var(--default-text-color);
}

.btn-light:focus {
  background-color: rgb(var(--light-rgb));
  border-color: rgb(var(--light-rgb));
  box-shadow: none;
  color: var(--default-text-color);
}

[data-theme-mode=dark] .btn-light:hover {
  background-color: rgba(var(--light-rgb), 0.8);
}

.btn-check:focus+.btn-light,
.btn-light:focus {
  background-color: rgb(var(--light-rgb));
  border-color: rgb(var(--light-rgb));
  outline: 0;
  box-shadow: none;
}

.btn-check:active+.btn-light:focus,
.btn-check:checked+.btn-light:focus,
.btn-light.active:focus,
.btn-light.show:focus,
.btn-light:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--light-rgb), 0.5);
}

.btn-check:active+.btn-light,
.btn-check:checked+.btn-light,
.btn-light.active,
.btn-light.show,
.btn-light:active {
  background-color: rgb(var(--light-rgb)) !important;
  border-color: rgb(var(--light-rgb)) !important;
  color: var(--default-text-color) !important;
}

/* btn-light ends */
/* btn-teal starts */
.btn-teal {
  background-color: rgb(var(--teal-rgb)) !important;
  border-color: rgb(var(--teal-rgb)) !important;
  color: #fff !important;
}

.btn-teal:hover {
  background-color: rgba(var(--teal-rgb), 0.9) !important;
  border-color: rgb(var(--teal-rgb)) !important;
  color: #fff;
}

.btn-teal:focus {
  background-color: rgb(var(--teal-rgb));
  border-color: rgb(var(--teal-rgb));
  color: #fff;
}

.btn-check:focus+.btn-teal,
.btn-teal:focus {
  background-color: rgb(var(--teal-rgb));
  border-color: rgb(var(--teal-rgb));
  outline: 0;
  box-shadow: none;
}

.btn-check:active+.btn-teal:focus,
.btn-check:checked+.btn-teal:focus,
.btn-teal.active:focus,
.btn-teal.show:focus,
.btn-teal:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--teal-rgb), 0.5);
}

.btn-check:active+.btn-teal,
.btn-check:checked+.btn-teal,
.btn-teal.active,
.btn-teal.show,
.btn-teal:active {
  background-color: rgb(var(--teal-rgb)) !important;
  border-color: rgb(var(--teal-rgb)) !important;
}

/* btn-teal ends */
/* btn-purple starts */
.btn-purple {
  background-color: rgb(var(--purple-rgb)) !important;
  border-color: rgb(var(--purple-rgb)) !important;
  color: #fff !important;
}

.btn-purple:hover {
  background-color: rgba(var(--purple-rgb), 0.9) !important;
  border-color: rgb(var(--purple-rgb)) !important;
  color: #fff;
}

.btn-purple:focus {
  background-color: rgb(var(--purple-rgb));
  border-color: rgb(var(--purple-rgb));
  color: #fff;
}

.btn-check:focus+.btn-purple,
.btn-purple:focus {
  background-color: rgb(var(--purple-rgb));
  border-color: rgb(var(--purple-rgb));
  outline: 0;
  box-shadow: none;
}

.btn-check:active+.btn-purple:focus,
.btn-check:checked+.btn-purple:focus,
.btn-purple.active:focus,
.btn-purple.show:focus,
.btn-purple:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--purple-rgb), 0.5);
}

.btn-check:active+.btn-purple,
.btn-check:checked+.btn-purple,
.btn-purple.active,
.btn-purple.show,
.btn-purple:active {
  background-color: rgb(var(--purple-rgb)) !important;
  border-color: rgb(var(--purple-rgb)) !important;
}

/* btn-purple ends */
/* btn-orange starts */
.btn-orange {
  background-color: rgb(var(--orange-rgb)) !important;
  border-color: rgb(var(--orange-rgb)) !important;
  color: #fff !important;
}

.btn-orange:hover {
  background-color: rgba(var(--orange-rgb), 0.9) !important;
  border-color: rgb(var(--orange-rgb)) !important;
  color: #fff;
}

.btn-orange:focus {
  background-color: rgb(var(--orange-rgb));
  border-color: rgb(var(--orange-rgb));
  color: #fff;
}

.btn-check:focus+.btn-orange,
.btn-orange:focus {
  background-color: rgb(var(--orange-rgb));
  border-color: rgb(var(--orange-rgb));
  outline: 0;
  box-shadow: none;
}

.btn-check:active+.btn-orange:focus,
.btn-check:checked+.btn-orange:focus,
.btn-orange.active:focus,
.btn-orange.show:focus,
.btn-orange:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--orange-rgb), 0.5);
}

.btn-check:active+.btn-orange,
.btn-check:checked+.btn-orange,
.btn-orange.active,
.btn-orange.show,
.btn-orange:active {
  background-color: rgb(var(--orange-rgb)) !important;
  border-color: rgb(var(--orange-rgb)) !important;
}

/* btn-orange ends */
/* btn-pink starts */
.btn-pink {
  background-color: rgb(var(--pink-rgb)) !important;
  border-color: rgb(var(--pink-rgb)) !important;
  color: #fff !important;
}

.btn-pink:hover {
  background-color: rgba(var(--pink-rgb), 0.9) !important;
  border-color: rgb(var(--pink-rgb)) !important;
  color: #fff;
}

.btn-pink:focus {
  background-color: rgb(var(--pink-rgb));
  border-color: rgb(var(--pink-rgb));
  color: #fff;
}

.btn-check:focus+.btn-pink,
.btn-pink:focus {
  background-color: rgb(var(--pink-rgb));
  border-color: rgb(var(--pink-rgb));
  outline: 0;
  box-shadow: none;
}

.btn-check:active+.btn-pink:focus,
.btn-check:checked+.btn-pink:focus,
.btn-pink.active:focus,
.btn-pink.show:focus,
.btn-pink:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--pink-rgb), 0.5);
}

.btn-check:active+.btn-pink,
.btn-check:checked+.btn-pink,
.btn-pink.active,
.btn-pink.show,
.btn-pink:active {
  background-color: rgb(var(--pink-rgb)) !important;
  border-color: rgb(var(--pink-rgb)) !important;
}

/* btn-pink ends */
/* End::button colors */
/* Start::button outline colors */
/* btn-outline-primary start */
.btn-outline-primary {
  color: rgb(var(--primary-rgb));
  border-color: rgb(var(--primary-rgb));
}

.btn-outline-primary:hover,
.btn-outline-primary:active {
  color: #fff;
  background-color: rgb(var(--primary-rgb));
  border-color: rgb(var(--primary-rgb));
}

.btn-outline-primary:focus {
  color: #fff;
  background-color: rgb(var(--primary-rgb));
  border-color: rgb(var(--primary-rgb));
  box-shadow: none;
}

.btn-outline-primary:focus:active {
  box-shadow: none;
}

/* btn-outline-primary end */
/* btn-outline-secondary start */
.btn-outline-secondary {
  color: rgb(var(--secondary-rgb));
  border-color: rgb(var(--secondary-rgb));
}

.btn-outline-secondary:hover,
.btn-outline-secondary:active {
  color: #fff;
  background-color: rgb(var(--secondary-rgb));
  border-color: rgb(var(--secondary-rgb));
}

.btn-outline-secondary:focus {
  color: #fff;
  background-color: rgb(var(--secondary-rgb));
  border-color: rgb(var(--secondary-rgb));
  box-shadow: none;
}

.btn-outline-secondary:focus:active {
  box-shadow: none;
}

/* btn-outline-secondary end */
/* btn-outline-warning start */
.btn-outline-warning {
  color: rgb(var(--warning-rgb));
  border-color: rgb(var(--warning-rgb));
}

.btn-outline-warning:hover,
.btn-outline-warning:active {
  color: #fff;
  background-color: rgb(var(--warning-rgb));
  border-color: rgb(var(--warning-rgb));
}

.btn-outline-warning:focus {
  color: #fff;
  background-color: rgb(var(--warning-rgb));
  border-color: rgb(var(--warning-rgb));
  box-shadow: none;
}

.btn-outline-warning:focus:active {
  box-shadow: none;
}

/* btn-outline-warning end */
/* btn-outline-danger start */
.btn-outline-danger {
  color: rgb(var(--danger-rgb));
  border-color: rgb(var(--danger-rgb));
}

.btn-outline-danger:hover,
.btn-outline-danger:active {
  color: #fff;
  background-color: rgb(var(--danger-rgb));
  border-color: rgb(var(--danger-rgb));
}

.btn-outline-danger:focus {
  color: #fff;
  background-color: rgb(var(--danger-rgb));
  border-color: rgb(var(--danger-rgb));
  box-shadow: none;
}

.btn-outline-danger:focus:active {
  box-shadow: none;
}

/* btn-outline-danger end */
/* btn-outline-success start */
.btn-outline-success {
  color: rgb(var(--success-rgb));
  border-color: rgb(var(--success-rgb));
}

.btn-outline-success:hover,
.btn-outline-success:active {
  color: #fff;
  background-color: rgb(var(--success-rgb));
  border-color: rgb(var(--success-rgb));
}

.btn-outline-success:focus {
  color: #fff;
  background-color: rgb(var(--success-rgb));
  border-color: rgb(var(--success-rgb));
  box-shadow: none;
}

.btn-outline-success:focus:active {
  box-shadow: none;
}

/* btn-outline-success end */
/* btn-outline-info start */
.btn-outline-info {
  color: rgb(var(--info-rgb));
  border-color: rgb(var(--info-rgb));
}

.btn-outline-info:hover,
.btn-outline-info:active {
  color: #fff;
  background-color: rgb(var(--info-rgb));
  border-color: rgb(var(--info-rgb));
}

.btn-outline-info:focus {
  color: #fff;
  background-color: rgb(var(--info-rgb));
  border-color: rgb(var(--info-rgb));
  box-shadow: none;
}

.btn-outline-info:focus:active {
  box-shadow: none;
}

/* btn-outline-info end */
/* btn-outline-light start */
.btn-outline-light {
  color: var(--default-text-color) !important;
  border-color: var(--default-border);
}

.btn-outline-light:hover,
.btn-outline-light:active {
  color: var(--default-text-color);
  background-color: rgb(var(--light-rgb));
  border-color: rgb(var(--light-rgb));
}

.btn-outline-light:focus {
  color: var(--default-text-color);
  background-color: rgb(var(--light-rgb));
  border-color: rgb(var(--light-rgb));
  box-shadow: none;
}

.btn-outline-light:focus:active {
  box-shadow: none;
}

/* btn-outline-light end */
/* btn-outline-dark start */
.btn-outline-dark {
  color: rgb(var(--dark-rgb));
  border-color: rgb(var(--dark-rgb));
}

.btn-outline-dark:hover,
.btn-outline-dark:active {
  color: var(--custom-white);
  background-color: var(--custom-black);
  border-color: var(--custom-black);
}

.btn-outline-dark:focus {
  color: var(--custom-white);
  background-color: rgb(var(--dark-rgb));
  border-color: rgb(var(--dark-rgb));
  box-shadow: none;
}

.btn-outline-dark:focus:active {
  box-shadow: none;
}

/* btn-outline-dark end */
/* btn-outline-teal start */
.btn-outline-teal {
  color: rgb(var(--teal-rgb));
  border-color: rgb(var(--teal-rgb));
}

.btn-outline-teal:hover,
.btn-outline-teal:active {
  color: #fff;
  background-color: rgb(var(--teal-rgb));
  border-color: rgb(var(--teal-rgb));
}

.btn-outline-teal:focus {
  color: #fff;
  background-color: rgb(var(--teal-rgb));
  border-color: rgb(var(--teal-rgb));
  box-shadow: none;
}

.btn-outline-teal:focus:active {
  box-shadow: none;
}

/* btn-outline-teal end */
/* End::button outline colors */
/* Start::button disabled colors */
/* btn-primary-disabled start */
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

/* btn-primary-disabled end */
/* btn-secondary-disabled start */
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: rgb(var(--secondary-rgb));
  border-color: rgb(var(--secondary-rgb));
}

/* btn-secondary-disabled end */
/* End::button disabled colors */
/* Start::button transparent */
/* btn-primary-transparent starts */
.btn-primary-transparent {
  background-color: rgba(var(--primary-rgb), 0.1);
  border-color: rgba(var(--primary-rgb), 0.1);
  color: rgb(var(--primary-rgb));
}

.btn-primary-transparent:hover,
.btn-primary-transparent:focus:hover {
  background-color: rgb(var(--primary-rgb));
  border-color: rgb(var(--primary-rgb));
  color: #fff;
}

.btn-check:focus+.btn-primary-transparent,
.btn-primary-transparent:focus {
  background-color: rgba(var(--primary-rgb), 0.1);
  border-color: rgba(var(--primary-rgb), 0.1);
  outline: 0;
  box-shadow: 0 0.25rem 1rem rgba(var(--primary-rgb), 0.3);
  color: rgb(var(--primary-rgb));
}

.btn-check:active+.btn-primary-transparent:focus,
.btn-check:checked+.btn-primary-transparent:focus,
.btn-primary-transparent.active:focus,
.btn-primary-transparent.show:focus,
.btn-primary-transparent:active:focus {
  box-shadow: 0 0.25rem 1rem var(--primary05);
}

.btn-check:active+.btn-primary-transparent,
.btn-check:checked+.btn-primary-transparent,
.btn-primary-transparent.active,
.btn-primary-transparent.show,
.btn-primary-transparent:active {
  background-color: var(--primary-color);
  border-color: var(--primary01);
  color: #fff;
}

/* btn-primary-transparent ends */
/* btn-secondary-transparent starts */
.btn-secondary-transparent {
  background-color: rgba(var(--secondary-rgb), 0.1);
  border-color: rgba(var(--secondary-rgb), 0.1);
  color: rgb(var(--secondary-rgb));
}

.btn-secondary-transparent:hover,
.btn-secondary-transparent:focus:hover {
  background-color: rgb(var(--secondary-rgb));
  border-color: rgb(var(--secondary-rgb));
  color: #fff;
}

.btn-check:focus+.btn-secondary-transparent,
.btn-secondary-transparent:focus {
  background-color: rgba(var(--secondary-rgb), 0.1);
  border-color: rgba(var(--secondary-rgb), 0.1);
  outline: 0;
  box-shadow: 0 0.25rem 1rem rgba(var(--secondary-rgb), 0.3);
  color: rgb(var(--secondary-rgb));
}

.btn-check:active+.btn-secondary-transparent:focus,
.btn-check:checked+.btn-secondary-transparent:focus,
.btn-secondary-transparent.active:focus,
.btn-secondary-transparent.show:focus,
.btn-secondary-transparent:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--secondary-rgb), 0.5);
}

.btn-check:active+.btn-secondary-transparent,
.btn-check:checked+.btn-secondary-transparent,
.btn-secondary-transparent.active,
.btn-secondary-transparent.show,
.btn-secondary-transparent:active {
  background-color: rgb(var(--secondary-rgb));
  border-color: rgba(var(--secondary-rgb), 0.1);
  color: #fff;
}

/* btn-secondary-transparent ends */
/* btn-warning-transparent starts */
.btn-warning-transparent {
  background-color: rgba(var(--warning-rgb), 0.1);
  border-color: rgba(var(--warning-rgb), 0.1);
  color: rgb(var(--warning-rgb));
}

.btn-warning-transparent:hover,
.btn-warning-transparent:focus:hover {
  background-color: rgb(var(--warning-rgb));
  border-color: rgb(var(--warning-rgb));
  color: #fff;
}

.btn-check:focus+.btn-warning-transparent,
.btn-warning-transparent:focus {
  background-color: rgba(var(--warning-rgb), 0.1);
  border-color: rgba(var(--warning-rgb), 0.1);
  outline: 0;
  box-shadow: 0 0.25rem 1rem rgba(var(--warning-rgb), 0.3);
  color: rgb(var(--warning-rgb));
}

.btn-check:active+.btn-warning-transparent:focus,
.btn-check:checked+.btn-warning-transparent:focus,
.btn-warning-transparent.active:focus,
.btn-warning-transparent.show:focus,
.btn-warning-transparent:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--warning-rgb), 0.5);
}

.btn-check:active+.btn-warning-transparent,
.btn-check:checked+.btn-warning-transparent,
.btn-warning-transparent.active,
.btn-warning-transparent.show,
.btn-warning-transparent:active {
  background-color: rgb(var(--warning-rgb));
  border-color: rgba(var(--warning-rgb), 0.1);
  color: #fff;
}

/* btn-warning-transparent ends */
/* btn-info-transparent starts */
.btn-info-transparent {
  background-color: rgba(var(--info-rgb), 0.1);
  border-color: rgba(var(--info-rgb), 0.1);
  color: rgb(var(--info-rgb));
}

.btn-info-transparent:hover,
.btn-info-transparent:focus:hover {
  background-color: rgb(var(--info-rgb));
  border-color: rgb(var(--info-rgb));
  color: #fff;
}

.btn-check:focus+.btn-info-transparent,
.btn-info-transparent:focus {
  background-color: rgba(var(--info-rgb), 0.1);
  border-color: rgba(var(--info-rgb), 0.1);
  outline: 0;
  box-shadow: 0 0.25rem 1rem rgba(var(--info-rgb), 0.3);
  color: rgb(var(--info-rgb));
}

.btn-check:active+.btn-info-transparent:focus,
.btn-check:checked+.btn-info-transparent:focus,
.btn-info-transparent.active:focus,
.btn-info-transparent.show:focus,
.btn-info-transparent:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--info-rgb), 0.5);
}

.btn-check:active+.btn-info-transparent,
.btn-check:checked+.btn-info-transparent,
.btn-info-transparent.active,
.btn-info-transparent.show,
.btn-info-transparent:active {
  background-color: rgb(var(--info-rgb));
  border-color: rgba(var(--info-rgb), 0.1);
  color: #fff;
}

/* btn-info-transparent ends */
/* btn-success-transparent starts */
.btn-success-transparent {
  background-color: rgba(var(--success-rgb), 0.1);
  border-color: rgba(var(--success-rgb), 0.1);
  color: rgb(var(--success-rgb));
}

.btn-success-transparent:hover,
.btn-success-transparent:focus:hover {
  background-color: rgb(var(--success-rgb));
  border-color: rgb(var(--success-rgb));
  color: #fff;
}

.btn-check:focus+.btn-success-transparent,
.btn-success-transparent:focus {
  background-color: rgba(var(--success-rgb), 0.1);
  border-color: rgba(var(--success-rgb), 0.1);
  outline: 0;
  box-shadow: 0 0.25rem 1rem rgba(var(--success-rgb), 0.3);
  color: rgb(var(--success-rgb));
}

.btn-check:active+.btn-success-transparent:focus,
.btn-check:checked+.btn-success-transparent:focus,
.btn-success-transparent.active:focus,
.btn-success-transparent.show:focus,
.btn-success-transparent:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--success-rgb), 0.5);
}

.btn-check:active+.btn-success-transparent,
.btn-check:checked+.btn-success-transparent,
.btn-success-transparent.active,
.btn-success-transparent.show,
.btn-success-transparent:active {
  background-color: rgb(var(--success-rgb));
  border-color: rgba(var(--success-rgb), 0.1);
  color: #fff;
}

/* btn-success-transparent ends */
/* btn-danger-transparent starts */
.btn-danger-transparent {
  background-color: rgba(var(--danger-rgb), 0.1);
  border-color: rgba(var(--danger-rgb), 0.1);
  color: rgb(var(--danger-rgb));
}

.btn-danger-transparent:hover,
.btn-danger-transparent:focus:hover {
  background-color: rgb(var(--danger-rgb));
  border-color: rgb(var(--danger-rgb));
  color: #fff;
}

.btn-check:focus+.btn-danger-transparent,
.btn-danger-transparent:focus {
  background-color: rgba(var(--danger-rgb), 0.1);
  border-color: rgba(var(--danger-rgb), 0.1);
  outline: 0;
  box-shadow: 0 0.25rem 1rem rgba(var(--danger-rgb), 0.3);
  color: rgb(var(--danger-rgb));
}

.btn-check:active+.btn-danger-transparent:focus,
.btn-check:checked+.btn-danger-transparent:focus,
.btn-danger-transparent.active:focus,
.btn-danger-transparent.show:focus,
.btn-danger-transparent:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--danger-rgb), 0.5);
}

.btn-check:active+.btn-danger-transparent,
.btn-check:checked+.btn-danger-transparent,
.btn-danger-transparent.active,
.btn-danger-transparent.show,
.btn-danger-transparent:active {
  background-color: rgb(var(--danger-rgb));
  border-color: rgba(var(--danger-rgb), 0.1);
  color: #fff;
}

/* btn-danger-transparent ends */
/* btn-teal-transparent starts */
.btn-teal-transparent {
  background-color: rgba(var(--teal-rgb), 0.1);
  border-color: rgba(var(--teal-rgb), 0.1);
  color: rgb(var(--teal-rgb));
}

.btn-teal-transparent:hover,
.btn-teal-transparent:focus:hover {
  background-color: rgb(var(--teal-rgb));
  border-color: rgb(var(--teal-rgb));
  color: #fff;
}

.btn-check:focus+.btn-teal-transparent,
.btn-teal-transparent:focus {
  background-color: rgba(var(--teal-rgb), 0.1);
  border-color: rgba(var(--teal-rgb), 0.1);
  outline: 0;
  box-shadow: 0 0.25rem 1rem rgba(var(--teal-rgb), 0.3);
  color: rgb(var(--teal-rgb));
}

.btn-check:active+.btn-teal-transparent:focus,
.btn-check:checked+.btn-teal-transparent:focus,
.btn-teal-transparent.active:focus,
.btn-teal-transparent.show:focus,
.btn-teal-transparent:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--teal-rgb), 0.5);
}

.btn-check:active+.btn-teal-transparent,
.btn-check:checked+.btn-teal-transparent,
.btn-teal-transparent.active,
.btn-teal-transparent.show,
.btn-teal-transparent:active {
  background-color: rgb(var(--teal-rgb));
  border-color: rgba(var(--teal-rgb), 0.1);
  color: #fff;
}

/* btn-teal-transparent ends */
/* btn-purple-transparent starts */
.btn-purple-transparent {
  background-color: rgba(var(--purple-rgb), 0.1);
  border-color: rgba(var(--purple-rgb), 0.1);
  color: rgb(var(--purple-rgb));
}

.btn-purple-transparent:hover,
.btn-purple-transparent:focus:hover {
  background-color: rgb(var(--purple-rgb));
  border-color: rgb(var(--purple-rgb));
  color: #fff;
}

.btn-check:focus+.btn-purple-transparent,
.btn-purple-transparent:focus {
  background-color: rgba(var(--purple-rgb), 0.1);
  border-color: rgba(var(--purple-rgb), 0.1);
  outline: 0;
  box-shadow: 0 0.25rem 1rem rgba(var(--purple-rgb), 0.3);
  color: rgb(var(--purple-rgb));
}

.btn-check:active+.btn-purple-transparent:focus,
.btn-check:checked+.btn-purple-transparent:focus,
.btn-purple-transparent.active:focus,
.btn-purple-transparent.show:focus,
.btn-purple-transparent:active:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--purple-rgb), 0.5);
}

.btn-check:active+.btn-purple-transparent,
.btn-check:checked+.btn-purple-transparent,
.btn-purple-transparent.active,
.btn-purple-transparent.show,
.btn-purple-transparent:active {
  background-color: rgb(var(--purple-rgb));
  border-color: rgba(var(--purple-rgb), 0.1);
  color: #fff;
}

/* btn-purple-transparent ends */
/* End::button transparent */
/* Start::btn-sizes::sm */
.btn-group-sm>.btn,
.btn-sm {
  font-size: 0.8rem;
  padding: 0.25rem 0.8rem;
  border-radius: 7px;
}

/* Start::btn-sizes::sm */
/* Start::icon button */
.btn-icon {
  width: 2.313rem;
  height: 2.313rem;
  font-size: 0.95rem;
  flex-shrink: 0;
}

.btn-icon i {
  padding: 0rem;
  margin: -0.5rem;
}

.btn-icon.btn-sm {
  width: 1.75rem;
  height: 1.75rem;
  font-size: 0.8rem;
}

.btn-icon.btn-lg {
  width: 2.75rem;
  height: 2.75rem;
  font-size: 1.2rem;
}

/* End::icon button */
/* Start::button list */
.btn-list button,
.btn-list div,
.btn-list a,
.btn-list input {
  margin: 0 0.375rem 0.375rem 0;
}

[dir=rtl] .btn-list button,
[dir=rtl] .btn-list div,
[dir=rtl] .btn-list a,
[dir=rtl] .btn-list input {
  margin: 0 0 0.375rem 0.375rem;
}

.btn-list {
  margin-block-end: -0.5rem;
}

.btn-bd-primary {
  --bs-btn-font-weight: 600;
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--primary-color);
  --bs-btn-border-color: var(--primary-color);
  --bs-btn-border-radius: 0.5rem;
  --bs-btn-hover-color: var(--primary-color);
  --bs-btn-hover-bg: rgba(var(--primary-color), 0.9);
  --bs-btn-hover-border-color: rgba(var(--primary-color), 0.9);
  --bs-btn-active-color: var(--primary-color);
  --bs-btn-active-bg: rgba(var(--primary-color), 0.9);
  --bs-btn-active-border-color: rgba(var(--primary-color), 0.9);
}

/* End::button list */
.btn-check:active+.btn-outline-primary,
.btn-check:checked+.btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active,
.btn-check+.btn.btn-outline-primary:hover {
  color: #fff !important;
  background-color: rgb(var(--primary-rgb)) !important;
  border-color: rgb(var(--primary-rgb)) !important;
}

.btn-check:active+.btn-outline-secondary,
.btn-check:checked+.btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active,
.btn-check+.btn.btn-outline-secondary:hover {
  color: #fff !important;
  background-color: rgb(var(--secondary-rgb)) !important;
  border-color: rgb(var(--secondary-rgb)) !important;
}

.btn-check:active+.btn-outline-info,
.btn-check:checked+.btn-outline-info,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show,
.btn-outline-info:active,
.btn-check+.btn.btn-outline-info:hover {
  color: #fff !important;
  background-color: rgb(var(--info-rgb)) !important;
  border-color: rgb(var(--info-rgb)) !important;
}

.btn-check:active+.btn-outline-warning,
.btn-check:checked+.btn-outline-warning,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show,
.btn-outline-warning:active,
.btn-check+.btn.btn-outline-warning:hover {
  color: #fff !important;
  background-color: rgb(var(--warning-rgb)) !important;
  border-color: rgb(var(--warning-rgb)) !important;
}

.btn-check:active+.btn-outline-danger,
.btn-check:checked+.btn-outline-danger,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show,
.btn-outline-danger:active,
.btn-check+.btn.btn-outline-danger:hover {
  color: #fff !important;
  background-color: rgb(var(--danger-rgb)) !important;
  border-color: rgb(var(--danger-rgb)) !important;
}

.btn-check:active+.btn-outline-success,
.btn-check:checked+.btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-success:active,
.btn-check+.btn.btn-outline-success:hover {
  color: #fff !important;
  background-color: rgb(var(--success-rgb)) !important;
  border-color: rgb(var(--success-rgb)) !important;
}

.btn-check:active+.btn-outline-light,
.btn-check:checked+.btn-outline-light,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show,
.btn-outline-light:active,
.btn-outline-light:hover,
.btn-check+.btn.btn-outline-light:hover {
  color: var(--default-text-color) !important;
  background-color: rgb(var(--light-rgb)) !important;
  border-color: rgb(var(--light-rgb)) !important;
}

.btn-check:active+.btn-outline-dark,
.btn-check:checked+.btn-outline-dark,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show,
.btn-outline-dark:active,
.btn-check+.btn.btn-outline-dark:hover {
  color: var(--custom-white) !important;
  background-color: var(--custom-black) !important;
  border-color: var(--custom-black) !important;
}

.btn-check:active+.btn-outline-teal,
.btn-check:checked+.btn-outline-teal,
.btn-outline-teal.active,
.btn-outline-teal.dropdown-toggle.show,
.btn-outline-teal:active,
.btn-check+.btn.btn-outline-teal:hover {
  color: #fff !important;
  background-color: rgb(var(--teal-rgb)) !important;
  border-color: rgb(var(--teal-rgb)) !important;
}

.btn-check:checked+.btn-outline-primary:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--primary-color), 0.5);
}

.btn-check:checked+.btn-outline-secondary:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--secondary-rgb), 0.5);
}

.btn-check:checked+.btn-outline-success:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--success-rgb), 0.5);
}

.btn-check:checked+.btn-outline-warning:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--warning-rgb), 0.5);
}

.btn-check:checked+.btn-outline-info:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--info-rgb), 0.5);
}

.btn-check:checked+.btn-outline-danger:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--danger-rgb), 0.5);
}

.btn-check:checked+.btn-outline-light:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--light-rgb), 0.5);
}

.btn-check:checked+.btn-outline-dark:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--dark-rgb), 0.5);
}

.btn-check:checked+.btn-outline-teal:focus {
  box-shadow: 0 0.25rem 1rem rgba(var(--teal-rgb), 0.5);
}

.btn-check:focus+.btn.btn-outline-primary {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  box-shadow: none;
}

.btn-check:checked+.btn.btn-outline-secondary {
  color: #fff;
  background-color: rgb(var(--secondary-rgb));
  border-color: rgb(var(--secondary-rgb));
  box-shadow: 0 0.25rem 1rem rgba(var(--secondary-rgb), 0.3);
}

.btn.show.btn-outline-secondary {
  background-color: rgb(var(--secondary-rgb));
  color: #fff;
  border-color: rgb(var(--secondary-rgb));
}

/* Start:: Light Buttons */
.btn.btn-primary-light {
  background-color: var(--primary01);
  color: var(--primary-color);
}

.btn.btn-primary-light:hover,
.btn.btn-primary-light:focus,
.btn.btn-primary-light:active {
  background-color: var(--primary-color);
  color: #fff;
  border-color: var(--primary-color);
}

.btn.btn-secondary-light {
  background-color: rgba(var(--secondary-rgb), 0.1);
  color: rgb(var(--secondary-rgb));
}

.btn.btn-secondary-light:hover,
.btn.btn-secondary-light:focus,
.btn.btn-secondary-light:active {
  background-color: rgb(var(--secondary-rgb));
  color: #fff;
  border-color: rgb(var(--secondary-rgb));
}

.btn.btn-warning-light {
  background-color: rgba(var(--warning-rgb), 0.1);
  color: rgb(var(--warning-rgb));
}

.btn.btn-warning-light:hover,
.btn.btn-warning-light:focus,
.btn.btn-warning-light:active {
  background-color: rgb(var(--warning-rgb));
  color: #fff;
  border-color: rgb(var(--warning-rgb));
}

.btn.btn-info-light {
  background-color: rgba(var(--info-rgb), 0.1);
  color: rgb(var(--info-rgb));
}

.btn.btn-info-light:hover,
.btn.btn-info-light:focus,
.btn.btn-info-light:active {
  background-color: rgb(var(--info-rgb));
  color: #fff;
  border-color: rgb(var(--info-rgb));
}

.btn.btn-success-light {
  background-color: rgba(var(--success-rgb), 0.1);
  color: rgb(var(--success-rgb));
}

.btn.btn-success-light:hover,
.btn.btn-success-light:focus,
.btn.btn-success-light:active {
  background-color: rgb(var(--success-rgb));
  color: #fff;
  border-color: rgb(var(--success-rgb));
}

.btn.btn-danger-light {
  background-color: rgba(var(--danger-rgb), 0.1);
  color: rgb(var(--danger-rgb));
}

.btn.btn-danger-light:hover,
.btn.btn-danger-light:focus,
.btn.btn-danger-light:active {
  background-color: rgb(var(--danger-rgb));
  color: #fff;
  border-color: rgb(var(--danger-rgb));
}

.btn.btn-purple-light {
  background-color: rgba(var(--purple-rgb), 0.1);
  color: rgb(var(--purple-rgb));
}

.btn.btn-purple-light:hover,
.btn.btn-purple-light:focus,
.btn.btn-purple-light:active {
  background-color: rgb(var(--purple-rgb));
  color: #fff;
  border-color: rgb(var(--purple-rgb));
}

.btn.btn-teal-light {
  background-color: rgba(var(--teal-rgb), 0.1);
  color: rgb(var(--teal-rgb));
}

.btn.btn-teal-light:hover,
.btn.btn-teal-light:focus,
.btn.btn-teal-light:active {
  background-color: rgb(var(--teal-rgb));
  color: #fff;
  border-color: rgb(var(--teal-rgb));
}

.btn.btn-orange-light {
  background-color: rgba(var(--orange-rgb), 0.1);
  color: rgb(var(--orange-rgb));
}

.btn.btn-orange-light:hover,
.btn.btn-orange-light:focus,
.btn.btn-orange-light:active {
  background-color: rgb(var(--orange-rgb));
  color: #fff;
  border-color: rgb(var(--orange-rgb));
}

/* End:: Light Buttons */
/* Start:: Gradient Buttons */
.btn.btn-primary-gradient {
  background: linear-gradient(to right, var(--primary-color) 0%, #8e77fa 100%);
  color: #fff !important;
  border: 0;
}

.btn.btn-primary-gradient:hover {
  border: 0;
}

.btn.btn-secondary-gradient {
  background: linear-gradient(to right, rgb(var(--secondary-rgb)) 0%, #28b7f9 100%);
  color: #fff !important;
  border: 0;
}

.btn.btn-secondary-gradient:hover {
  border: 0;
}

.btn.btn-warning-gradient {
  background: linear-gradient(to right, rgb(var(--warning-rgb)) 0%, #fbc434 100%);
  color: #fff !important;
  border: 0;
}

.btn.btn-warning-gradient:hover {
  border: 0;
}

.btn.btn-info-gradient {
  background: linear-gradient(to right, rgb(var(--info-rgb)) 0%, #00f2fe 100%);
  color: #fff !important;
  border: 0;
}

.btn.btn-info-gradient:hover {
  border: 0;
}

.btn.btn-success-gradient {
  background: linear-gradient(to right, rgb(var(--success-rgb)) 0%, #5cf9e2 100%);
  color: #fff !important;
  border: 0;
}

.btn.btn-success-gradient:hover {
  border: 0;
}

.btn.btn-danger-gradient {
  background: linear-gradient(to right, rgb(var(--danger-rgb)) 0%, #fd4a68 100%);
  color: #fff !important;
  border: 0;
}

.btn.btn-danger-gradient:hover {
  border: 0;
}

.btn.btn-orange-gradient {
  background: linear-gradient(to right, rgb(var(--orange-rgb)) 0%, #FBB034 100%);
  color: #fff !important;
  border: 0;
}

.btn.btn-orange-gradient:hover {
  border: 0;
}

.btn.btn-teal-gradient {
  background: linear-gradient(to right, rgb(var(--teal-rgb)) 0%, #0695DD 100%);
  color: #fff !important;
  border: 0;
}

.btn.btn-teal-gradient:hover {
  border: 0;
}

.btn.btn-purple-gradient {
  background: linear-gradient(to right, rgb(var(--purple-rgb)) 0%, #9b25b7 100%);
  color: #fff !important;
  border: 0;
}

.btn.btn-purple-gradient:hover {
  border: 0;
}

/* End:: Gradient Buttons */
/* Start:: Ghost Buttons */
.btn.btn-primary-ghost {
  background-color: transparent;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.btn.btn-primary-ghost:active {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.btn.btn-secondary-ghost {
  background-color: transparent;
  color: rgb(var(--secondary-rgb));
  border: 1px solid rgb(var(--secondary-rgb));
}

.btn.btn-secondary-ghost:active {
  color: rgb(var(--secondary-rgb));
  border: 1px solid rgb(var(--secondary-rgb));
}

.btn.btn-warning-ghost {
  background-color: transparent;
  color: rgb(var(--warning-rgb));
  border: 1px solid rgb(var(--warning-rgb));
}

.btn.btn-warning-ghost:active {
  color: rgb(var(--warning-rgb));
  border: 1px solid rgb(var(--warning-rgb));
}

.btn.btn-info-ghost {
  background-color: transparent;
  color: rgb(var(--info-rgb));
  border: 1px solid rgb(var(--info-rgb));
}

.btn.btn-info-ghost:active {
  color: rgb(var(--info-rgb));
  border: 1px solid rgb(var(--info-rgb));
}

.btn.btn-success-ghost {
  background-color: transparent;
  color: rgb(var(--success-rgb));
  border: 1px solid rgb(var(--success-rgb));
}

.btn.btn-success-ghost:active {
  color: rgb(var(--success-rgb));
  border: 1px solid rgb(var(--success-rgb));
}

.btn.btn-danger-ghost {
  background-color: transparent;
  color: rgb(var(--danger-rgb));
  border: 1px solid rgb(var(--danger-rgb));
}

.btn.btn-danger-ghost:active {
  color: rgb(var(--danger-rgb));
  border: 1px solid rgb(var(--danger-rgb));
}

.btn.btn-orange-ghost {
  background-color: transparent;
  color: rgb(var(--orange-rgb));
  border: 1px solid rgb(var(--orange-rgb));
}

.btn.btn-orange-ghost:active {
  color: rgb(var(--orange-rgb));
  border: 1px solid rgb(var(--orange-rgb));
}

.btn.btn-teal-ghost {
  background-color: transparent;
  color: rgb(var(--teal-rgb));
  border: 1px solid rgb(var(--teal-rgb));
}

.btn.btn-teal-ghost:active {
  color: rgb(var(--teal-rgb));
  border: 1px solid rgb(var(--teal-rgb));
}

.btn.btn-purple-ghost {
  background-color: transparent;
  color: rgb(var(--purple-rgb));
  border: 1px solid rgb(var(--purple-rgb));
}

.btn.btn-purple-ghost:active {
  color: rgb(var(--purple-rgb));
  border: 1px solid rgb(var(--purple-rgb));
}

/* End:: Ghost Buttons */
/* Start:: Width Buttons */
.btn.btn-w-xs {
  min-width: 5.625rem;
}

.btn.btn-w-sm {
  min-width: 6.975rem;
}

.btn.btn-w-md {
  min-width: 8.125rem;
}

.btn.btn-w-lg {
  min-width: 9.375rem;
}

@media screen and (min-width: 576px) {
  .btn.btn-sm-w-xs {
    min-width: 5.625rem;
  }

  .btn.btn-sm-w-sm {
    min-width: 6.975rem;
  }

  .btn.btn-sm-w-md {
    min-width: 8.125rem;
  }

  .btn.btn-sm-w-lg {
    min-width: 9.375rem;
  }
}

/* End:: Width Buttons */
/* Start:: Loading Buttons */
.btn-loader {
  pointer-events: none;
  position: relative;
  display: flex;
  align-items: center;
}

.btn-loader .loading {
  margin-inline-end: 0.75rem;
}

.btn-loader i {
  animation: loader 1000ms infinite linear;
  position: absolute !important;
  top: 0.25rem;
}

@keyframes loader {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* End:: Loading Buttons */
/* Start:: Raised Buttons  */
.btn-raised-shadow:hover {
  box-shadow: 0 0.5rem 1rem 0.5rem var(--black-1);
}

/* End:: Raised Buttons  */
/* Start:: Label Buttons */
.label-btn {
  position: relative;
  padding-inline-start: 2.6rem;
}

.label-btn-icon {
  position: absolute;
  width: 2.25rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  inset-inline-start: -1px;
  inset-block-start: -1px;
  inset-block-end: -1px;
  background-color: var(--white-2);
}

.label-btn.label-end {
  padding-inline-start: 1rem;
  padding-inline-end: 2.6rem;
}

.label-btn.label-end .label-btn-icon {
  inset-inline-end: -1px;
  inset-inline-start: auto;
}

/* End:: Label Buttons */
/* Start:: Custom Buttons */
.custom-button {
  position: relative;
  padding-inline-start: 2.75rem;
}

.custom-button .custom-btn-icons {
  box-shadow: 0px 0px 1px 0.25rem rgba(0, 0, 0, 0.1);
  position: absolute;
  inset-inline-start: -0.125rem;
  inset-block-start: 0px;
  background-color: var(--custom-white);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0.375rem;
  border-radius: 3.125rem;
  font-size: 1rem;
  width: 2.25rem;
  height: 2.25rem;
}

.custom-button .custom-btn-icons i {
  position: absolute;
}

.btn-hover {
  position: relative;
}

.btn-hover.btn-hover-animate {
  transition: all 0.2s linear 0s;
}

.btn-hover.btn-hover-animate:before {
  content: "\f417";
  font-family: bootstrap-icons !important;
  font-size: 0.8125rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  inset-inline-end: 0;
  inset-block-start: 0;
  opacity: 0;
  height: 100%;
  width: 2rem;
  transition: all 0.2s linear 0s;
}

.btn-hover.btn-hover-animate:hover {
  padding-inline-end: 2rem;
}

.btn-hover.btn-hover-animate:hover:before {
  opacity: 1;
  text-indent: 0;
}

.btn-glare {
  border: 1px solid;
  overflow: hidden;
  position: relative;
}

.btn-glare span {
  z-index: 20;
}

.btn-glare:after {
  background: var(--custom-white);
  content: "";
  height: 4.6875rem;
  inset-inline-start: -1.625rem;
  opacity: 0.15;
  position: absolute;
  inset-block-start: -3.125rem;
  transform: rotate(35deg);
  transition: all 600ms cubic-bezier(0.2, 1, 0.25, 1);
  width: 1.875rem;
  z-index: 30;
}

.btn-glare:hover:after {
  inset-inline-start: 100%;
  inset-block-start: 0;
  transition: all 600ms cubic-bezier(0.2, 1, 0.25, 1);
}

.btn-darken-hover {
  position: relative;
}

.btn-darken-hover:hover:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  inset-block-start: 0;
  inset-inline-start: 0;
}

.btn-custom-border {
  border: 0;
  box-shadow: inset 0 0 1.25rem rgba(var(--warning-rgb), 0);
  outline: 1px solid;
  outline-color: rgba(var(--warning-rgb), 0.5);
  outline-offset: 0;
  text-shadow: none;
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
}

.btn-custom-border:hover {
  border: 0 solid;
  box-shadow: inset 0 0 1.25rem rgba(var(--warning-rgb), 0.5), 0 0 1.25rem rgba(var(--warning-rgb), 0.2);
  outline-color: rgba(var(--warning-rgb), 0);
  outline-offset: 0.938rem;
}

/* End:: Custom Buttons */
/* Start:: Button Borders */
.btn-border-down.btn-primary-light {
  border-block-end: 0.1875rem solid var(--primary-color);
}

.btn-border-down.btn-secondary-light {
  border-block-end: 0.1875rem solid rgb(var(--secondary-rgb));
}

.btn-border-down.btn-info-light {
  border-block-end: 0.1875rem solid rgb(var(--info-rgb));
}

.btn-border-down.btn-warning-light {
  border-block-end: 0.1875rem solid rgb(var(--warning-rgb));
}

.btn-border-down.btn-success-light {
  border-block-end: 0.1875rem solid rgb(var(--success-rgb));
}

.btn-border-down.btn-danger-light {
  border-block-end: 0.1875rem solid rgb(var(--danger-rgb));
}

.btn-border-down.btn-dark-light {
  border-block-end: 0.1875rem solid var(--primary-color);
}

.btn-border-down.btn-teal-light {
  border-block-end: 0.1875rem solid rgb(var(--teal-rgb));
}

.btn-border-down.btn-purple-light {
  border-block-end: 0.1875rem solid rgb(var(--purple-rgb));
}

.btn-border-start.btn-primary-light {
  border-inline-start: 0.1875rem solid var(--primary-color);
}

.btn-border-start.btn-secondary-light {
  border-inline-start: 0.1875rem solid rgb(var(--secondary-rgb));
}

.btn-border-start.btn-info-light {
  border-inline-start: 0.1875rem solid rgb(var(--info-rgb));
}

.btn-border-start.btn-warning-light {
  border-inline-start: 0.1875rem solid rgb(var(--warning-rgb));
}

.btn-border-start.btn-success-light {
  border-inline-start: 0.1875rem solid rgb(var(--success-rgb));
}

.btn-border-start.btn-danger-light {
  border-inline-start: 0.1875rem solid rgb(var(--danger-rgb));
}

.btn-border-start.btn-dark-light {
  border-inline-start: 0.1875rem solid var(--primary-color);
}

.btn-border-start.btn-teal-light {
  border-inline-start: 0.1875rem solid rgb(var(--teal-rgb));
}

.btn-border-start.btn-purple-light {
  border-inline-start: 0.1875rem solid rgb(var(--purple-rgb));
}

.btn-border-end.btn-primary-light {
  border-inline-end: 0.1875rem solid var(--primary-color);
}

.btn-border-end.btn-secondary-light {
  border-inline-end: 0.1875rem solid rgb(var(--secondary-rgb));
}

.btn-border-end.btn-info-light {
  border-inline-end: 0.1875rem solid rgb(var(--info-rgb));
}

.btn-border-end.btn-warning-light {
  border-inline-end: 0.1875rem solid rgb(var(--warning-rgb));
}

.btn-border-end.btn-success-light {
  border-inline-end: 0.1875rem solid rgb(var(--success-rgb));
}

.btn-border-end.btn-danger-light {
  border-inline-end: 0.1875rem solid rgb(var(--danger-rgb));
}

.btn-border-end.btn-dark-light {
  border-inline-end: 0.1875rem solid var(--primary-color);
}

.btn-border-end.btn-teal-light {
  border-inline-end: 0.1875rem solid rgb(var(--teal-rgb));
}

.btn-border-end.btn-purple-light {
  border-inline-end: 0.1875rem solid rgb(var(--purple-rgb));
}

.btn-border-top.btn-primary-light {
  border-block-start: 0.1875rem solid var(--primary-color);
}

.btn-border-top.btn-secondary-light {
  border-block-start: 0.1875rem solid rgb(var(--secondary-rgb));
}

.btn-border-top.btn-info-light {
  border-block-start: 0.1875rem solid rgb(var(--info-rgb));
}

.btn-border-top.btn-warning-light {
  border-block-start: 0.1875rem solid rgb(var(--warning-rgb));
}

.btn-border-top.btn-success-light {
  border-block-start: 0.1875rem solid rgb(var(--success-rgb));
}

.btn-border-top.btn-danger-light {
  border-block-start: 0.1875rem solid rgb(var(--danger-rgb));
}

.btn-border-top.btn-dark-light {
  border-block-start: 0.1875rem solid var(--primary-color);
}

.btn-border-top.btn-teal-light {
  border-block-start: 0.1875rem solid rgb(var(--teal-rgb));
}

.btn-border-top.btn-purple-light {
  border-block-start: 0.1875rem solid rgb(var(--purple-rgb));
}

/* End:: Button Borders */
/* Start:: Social Buttons */
.btn-facebook {
  background-color: #3b5998;
  color: #fff;
  border: 1px solid #3b5998;
}

.btn-facebook:hover,
.btn-facebook:focus,
.btn-facebook:active {
  background-color: #3b5998 !important;
  color: #fff !important;
  border: 1px solid #3b5998 !important;
}

.btn-google {
  background-color: #CF4E43;
  color: #fff;
  border: 1px solid #CF4E43;
}

.btn-google:hover,
.btn-google:focus,
.btn-google:active {
  background-color: #CF4E43 !important;
  color: #fff !important;
  border: 1px solid #CF4E43 !important;
}

.btn-twitter {
  background-color: #00acee;
  color: #fff;
  border: 1px solid #00acee;
}

.btn-twitter:hover,
.btn-twitter:focus,
.btn-twitter:active {
  background-color: #00acee !important;
  color: #fff !important;
  border: 1px solid #00acee !important;
}

.btn-github {
  background-color: #333;
  color: #fff;
  border: 1px solid #333;
}

.btn-github:hover,
.btn-github:focus,
.btn-github:active {
  background-color: #333 !important;
  color: #fff !important;
  border: 1px solid #333 !important;
}

.btn-youtube {
  background-color: #FF0000;
  color: #fff;
  border: 1px solid #FF0000;
}

.btn-youtube:hover,
.btn-youtube:focus,
.btn-youtube:active {
  background-color: #FF0000 !important;
  color: #fff !important;
  border: 1px solid #FF0000 !important;
}

.btn-instagram {
  background: #f09433;
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f09433", endColorstr="#bc1888", GradientType=1);
  color: #fff;
  border: 1px solid transparent;
}

.btn-instagram:hover,
.btn-instagram:focus,
.btn-instagram:active {
  color: #fff;
  border: 1px solid transparent;
}

/* End:: Social Buttons */
/* Start:: Checkbox buttons */
@media (max-width: 575.98px) {

  .checkbox-button-group .btn-group label,
  .radio-button-group .btn-group label {
    font-size: 0.563rem;
  }
}

/* End:: Checkbox buttons */
.btn-outline-secondary {
  --bs-btn-hover-color: rgb(var(--secondary-rgb));
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: rgb(var(--secondary-rgb));
  --bs-btn-active-color: rgb(var(--secondary-rgb));
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  border-color: var(--default-border);
}

.btn:hover {
  border-color: var(--default-border);
}

.card {
  margin-block-end: 1.5rem;
  box-shadow: var(--shadow-color) 0px 4px 25px 0px;
  border: 0;
}

.card .card-header,
.card .card-footer {
  background-color: var(--custom-white) !important;
  color: var(--default-text-color);
}

.card .card-body {
  padding: 25px;
  color: var(--default-text-color);
}

.card .card-title {
  font-size: 1rem;
  font-weight: 500;
  margin-block-end: 0;
}

.card .card-header {
  border-color: var(--default-border);
  padding: 1.2rem 1.5rem;
}

.card .card-header:first-child {
  border-radius: 7px 7px 0 0;
}

.card.custom-card {
  border-radius: 7px;
  border: 0;
  background-color: var(--custom-white);
  box-shadow: var(--shadow-color) 0px 4px 25px 0px;
  position: relative;
  margin-block-end: 1.5rem;
  width: 100%;
  /* Start::Card Background Colors */
  /* End::Card Background Colors */
  /* Start:: Cards With Links */
  /* Start:: Cards With Links */
  /* End:: Cards With Links */
  /* Start:: Card With Collapse */
  /* End:: Card With Links */
  /* Start::Card With FullScreen */
  /* End::Card With FullScreen */
}

.card.custom-card .card-header {
  padding: 1rem 1.25rem;
  background-color: transparent !important;
  border-block-end: 1px solid var(--default-border);
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  gap: 0.25rem;
}

.card.custom-card .card-header:first-child {
  border-radius: 0.625rem 0.625rem 0 0;
}

.card.custom-card .card-header .card-title {
  position: relative;
  margin-block-end: 0;
  font-size: 0.9375rem;
  font-weight: 700;
  text-transform: capitalize;
}

.card.custom-card .card-header .card-title .subtitle {
  margin-block-end: 0;
  text-transform: initial;
}

.card.custom-card .card-footer {
  background-color: transparent !important;
  border-block-start: 1px solid var(--default-border);
  padding: 1rem 1.5rem;
  font-size: 14px !important;
}

.card.custom-card .card-body {
  padding: 1.5rem;
  color: var(--default-text-color);
}

.card.custom-card .card-link {
  font-size: 0.75rem;
}

.card.custom-card .card-text {
  font-size: 0.813rem;
}

.card.custom-card .footer-card-icon {
  width: 60px;
  height: 60px;
}

.card.custom-card.overlay-card {
  position: relative;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.9);
}

.card.custom-card.overlay-card .card-header {
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
}

.card.custom-card.overlay-card .card-footer {
  border-block-start: 1px solid rgba(255, 255, 255, 0.1);
}

.card.custom-card.overlay-card .over-content-bottom {
  top: auto;
}

.card.custom-card.overlay-card:before {
  content: "";
  inset-inline-start: 0;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
}

.card.custom-card.card-bg-primary {
  background-color: var(--primary-color);
  color: #fff;
}

.card.custom-card.card-bg-primary .card-header {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #fff;
  border-block-end: 1px solid rgba(255, 255, 255, 0.2);
}

.card.custom-card.card-bg-primary .card-header:before {
  background-color: #fff;
}

.card.custom-card.card-bg-primary .card-body {
  color: #fff;
}

.card.custom-card.card-bg-primary .card-footer {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #fff;
  border-block-start: 1px solid rgba(255, 255, 255, 0.2);
}

.card.custom-card.card-bg-secondary {
  background-color: rgb(var(--secondary-rgb));
  color: #fff;
}

.card.custom-card.card-bg-secondary .card-header {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #fff;
  border-block-end: 1px solid rgba(255, 255, 255, 0.2);
}

.card.custom-card.card-bg-secondary .card-header:before {
  background-color: #fff;
}

.card.custom-card.card-bg-secondary .card-body {
  color: #fff;
}

.card.custom-card.card-bg-secondary .card-footer {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #fff;
  border-block-start: 1px solid rgba(255, 255, 255, 0.2);
}

.card.custom-card.card-bg-warning {
  background-color: rgb(var(--warning-rgb));
  color: #fff;
}

.card.custom-card.card-bg-warning .card-header {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #fff;
  border-block-end: 1px solid rgba(255, 255, 255, 0.2);
}

.card.custom-card.card-bg-warning .card-header:before {
  background-color: #fff;
}

.card.custom-card.card-bg-warning .card-body {
  color: #fff;
}

.card.custom-card.card-bg-warning .card-footer {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #fff;
  border-block-start: 1px solid rgba(255, 255, 255, 0.2);
}

.card.custom-card.card-bg-info {
  background-color: rgb(var(--info-rgb));
  color: #fff;
}

.card.custom-card.card-bg-info .card-header {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #fff;
  border-block-end: 1px solid rgba(255, 255, 255, 0.2);
}

.card.custom-card.card-bg-info .card-header:before {
  background-color: #fff;
}

.card.custom-card.card-bg-info .card-body {
  color: #fff;
}

.card.custom-card.card-bg-info .card-footer {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #fff;
  border-block-start: 1px solid rgba(255, 255, 255, 0.2);
}

.card.custom-card.card-bg-success {
  background-color: rgb(var(--success-rgb));
  color: #fff;
}

.card.custom-card.card-bg-success .card-header {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #fff;
  border-block-end: 1px solid rgba(255, 255, 255, 0.2);
}

.card.custom-card.card-bg-success .card-header:before {
  background-color: #fff;
}

.card.custom-card.card-bg-success .card-body {
  color: #fff;
}

.card.custom-card.card-bg-success .card-footer {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #fff;
  border-block-start: 1px solid rgba(255, 255, 255, 0.2);
}

.card.custom-card.card-bg-danger {
  background-color: rgb(var(--danger-rgb));
  color: #fff;
}

.card.custom-card.card-bg-danger .card-header {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #fff;
  border-block-end: 1px solid rgba(255, 255, 255, 0.2);
}

.card.custom-card.card-bg-danger .card-header:before {
  background-color: #fff;
}

.card.custom-card.card-bg-danger .card-body {
  color: #fff;
}

.card.custom-card.card-bg-danger .card-footer {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #fff;
  border-block-start: 1px solid rgba(255, 255, 255, 0.2);
}

.card.custom-card.card-bg-light {
  background-color: rgb(var(--light-rgb));
  color: var(--default-text-color);
}

.card.custom-card.card-bg-light .card-header {
  background-color: rgba(0, 0, 0, 0.05) !important;
  color: var(--default-text-color);
  border-block-end: var(--default-border);
}

.card.custom-card.card-bg-light .card-body {
  color: var(--default-text-color);
}

.card.custom-card.card-bg-light .card-footer {
  background-color: rgba(0, 0, 0, 0.05) !important;
  color: var(--default-text-color);
  border-block-start: var(--default-border);
}

.card.custom-card.card-bg-dark {
  background-color: rgb(var(--dark-rgb));
  color: var(--custom-white);
}

.card.custom-card.card-bg-dark .card-header {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #fff;
  border-block-end: 1px solid rgba(255, 255, 255, 0.2);
}

.card.custom-card.card-bg-dark .card-header:before {
  background-color: var(--custom-white);
}

.card.custom-card.card-bg-dark .card-body {
  color: #fff;
}

.card.custom-card.card-bg-dark .card-footer {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #fff;
  border-block-start: 1px solid rgba(255, 255, 255, 0.2);
}

.card.custom-card .card-anchor {
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
}

.card.custom-card .collapse-close {
  display: none;
}

.card.custom-card.card .collapsed .collapse-close {
  display: block;
}

.card.custom-card.card .collapsed .collapse-open {
  display: none;
}

.card.custom-card.card-fullscreen {
  position: fixed;
  top: 0;
  inset-inline-start: 0;
  inset-inline-start: 0;
  bottom: 0;
  z-index: 9999;
  margin: 0;
}

@media (max-width: 575px) {
  .overlay-card .card-text {
    font-size: 0.613rem !important;
  }
}

@media (max-width: 400px) {
  .overlay-card .card-text {
    font-size: 0.413rem !important;
  }
}

.app-header .dropdown-toggle {
  position: relative;
}

.dropdown-menu {
  border-color: var(--default-border);
  box-shadow: 0 1rem 1.125rem rgba(169, 169, 169, 0.15);
  font-size: 0.875rem;
  color: var(--default-text-color);
  background-color: var(--custom-white);
  padding: 0;
  z-index: 10;
}

.dropdown-divider {
  border-color: var(--default-border);
}

.dropdown-item-text {
  color: var(--default-text-color);
}

.dropdown-header {
  color: var(--default-text-color);
}

.dropdown-item {
  color: var(--default-text-color);
  white-space: unset;
  padding: 0.5rem 0.9375rem;
  font-size: 0.8125rem;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: var(--default-text-color);
  opacity: 0.5;
}

.dropdown-item:not(.active):hover,
.dropdown-item:not(.active):focus,
.dropdown-item:not(.active):active {
  color: var(--primary-color);
  background-color: var(--primary005);
}

.dropdown-item.active {
  background-color: var(--primary-color) !important;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-inline-start: 0.25rem;
  vertical-align: 0;
  content: "\f282";
  font-family: bootstrap-icons !important;
  border: 0;
  font-size: 0.6rem;
  font-weight: 600;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-inline-start: 0.25rem;
  vertical-align: 0;
  content: "\f286";
  font-family: bootstrap-icons !important;
  border: 0;
  font-size: 0.6rem;
  font-weight: 600;
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-inline-start: 0.25rem;
  vertical-align: 0;
  content: "\f285";
  font-family: bootstrap-icons !important;
  border: 0;
  font-size: 0.6rem;
  font-weight: 600;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-inline-start: 0.25rem;
  vertical-align: 0;
  content: "\f284";
  font-family: bootstrap-icons !important;
  border: 0;
  font-size: 0.6rem;
  font-weight: 600;
}

.btn.dropdown-toggle-split {
  opacity: 0.85;
}

.dropdown-menu-dark {
  background-color: rgb(var(--dark-rgb));
  border-color: rgba(255, 255, 255, 0.1);
}

.dropdown-menu-dark li a {
  color: var(--custom-white);
}

.btn-list a.dropdown-item {
  margin-block-end: 0;
}

/* Start:: Custom Dropdown Menu */
.dropdown-menu.dropdown-menu-primary {
  background-color: var(--primary-color);
}

.dropdown-menu.dropdown-menu-primary li a {
  color: rgba(255, 255, 255, 0.9);
}

.dropdown-menu.dropdown-menu-primary .dropdown-item:hover,
.dropdown-menu.dropdown-menu-primary .dropdown-item:focus,
.dropdown-menu.dropdown-menu-primary .dropdown-item:active,
.dropdown-menu.dropdown-menu-primary .dropdown-item.active {
  color: #fff;
}

.dropdown-menu.dropdown-menu-secondary {
  background-color: rgb(var(--secondary-rgb));
}

.dropdown-menu.dropdown-menu-secondary li a {
  color: rgba(255, 255, 255, 0.9);
}

.dropdown-menu.dropdown-menu-secondary .dropdown-item:hover,
.dropdown-menu.dropdown-menu-secondary .dropdown-item:focus,
.dropdown-menu.dropdown-menu-secondary .dropdown-item:active,
.dropdown-menu.dropdown-menu-secondary .dropdown-item.active {
  color: #fff;
}

.dropdown-menu.dropdown-menu-warning {
  background-color: rgb(var(--warning-rgb));
}

.dropdown-menu.dropdown-menu-warning li a {
  color: rgba(255, 255, 255, 0.9);
}

.dropdown-menu.dropdown-menu-warning .dropdown-item:hover,
.dropdown-menu.dropdown-menu-warning .dropdown-item:focus,
.dropdown-menu.dropdown-menu-warning .dropdown-item:active,
.dropdown-menu.dropdown-menu-warning .dropdown-item.active {
  color: #fff;
}

.dropdown-menu.dropdown-menu-info {
  background-color: rgb(var(--info-rgb));
}

.dropdown-menu.dropdown-menu-info li a {
  color: rgba(255, 255, 255, 0.9);
}

.dropdown-menu.dropdown-menu-info .dropdown-item:hover,
.dropdown-menu.dropdown-menu-info .dropdown-item:focus,
.dropdown-menu.dropdown-menu-info .dropdown-item:active,
.dropdown-menu.dropdown-menu-info .dropdown-item.active {
  color: #fff;
}

.dropdown-menu.dropdown-menu-success {
  background-color: rgb(var(--success-rgb));
}

.dropdown-menu.dropdown-menu-success li a {
  color: rgba(255, 255, 255, 0.9);
}

.dropdown-menu.dropdown-menu-success .dropdown-item:hover,
.dropdown-menu.dropdown-menu-success .dropdown-item:focus,
.dropdown-menu.dropdown-menu-success .dropdown-item:active,
.dropdown-menu.dropdown-menu-success .dropdown-item.active {
  color: #fff;
}

.dropdown-menu.dropdown-menu-danger {
  background-color: rgb(var(--danger-rgb));
}

.dropdown-menu.dropdown-menu-danger li a {
  color: rgba(255, 255, 255, 0.9);
}

.dropdown-menu.dropdown-menu-danger .dropdown-item:hover,
.dropdown-menu.dropdown-menu-danger .dropdown-item:focus,
.dropdown-menu.dropdown-menu-danger .dropdown-item:active,
.dropdown-menu.dropdown-menu-danger .dropdown-item.active {
  color: #fff;
}

.dropdown-menu.dropmenu-item-primary .dropdown-item:hover,
.dropdown-menu.dropmenu-item-primary .dropdown-item:focus,
.dropdown-menu.dropmenu-item-primary .dropdown-item:active,
.dropdown-menu.dropmenu-item-primary .dropdown-item.active {
  background-color: var(--primary01) !important;
  color: var(--primary-color);
}

.dropdown-menu.dropmenu-item-secondary .dropdown-item:hover,
.dropdown-menu.dropmenu-item-secondary .dropdown-item:focus,
.dropdown-menu.dropmenu-item-secondary .dropdown-item:active,
.dropdown-menu.dropmenu-item-secondary .dropdown-item.active {
  background-color: rgba(var(--secondary-rgb), 0.1) !important;
  color: rgb(var(--secondary-rgb));
}

.dropdown-menu.dropmenu-item-warning .dropdown-item:hover,
.dropdown-menu.dropmenu-item-warning .dropdown-item:focus,
.dropdown-menu.dropmenu-item-warning .dropdown-item:active,
.dropdown-menu.dropmenu-item-warning .dropdown-item.active {
  background-color: rgba(var(--warning-rgb), 0.1) !important;
  color: rgb(var(--warning-rgb));
}

.dropdown-menu.dropmenu-item-info .dropdown-item:hover,
.dropdown-menu.dropmenu-item-info .dropdown-item:focus,
.dropdown-menu.dropmenu-item-info .dropdown-item:active,
.dropdown-menu.dropmenu-item-info .dropdown-item.active {
  background-color: rgba(var(--info-rgb), 0.1) !important;
  color: rgb(var(--info-rgb));
}

.dropdown-menu.dropmenu-item-success .dropdown-item:hover,
.dropdown-menu.dropmenu-item-success .dropdown-item:focus,
.dropdown-menu.dropmenu-item-success .dropdown-item:active,
.dropdown-menu.dropmenu-item-success .dropdown-item.active {
  background-color: rgba(var(--success-rgb), 0.1) !important;
  color: rgb(var(--success-rgb));
}

.dropdown-menu.dropmenu-item-danger .dropdown-item:hover,
.dropdown-menu.dropmenu-item-danger .dropdown-item:focus,
.dropdown-menu.dropmenu-item-danger .dropdown-item:active,
.dropdown-menu.dropmenu-item-danger .dropdown-item.active {
  background-color: rgba(var(--danger-rgb), 0.1) !important;
  color: rgb(var(--danger-rgb));
}

.dropdown-menu.dropmenu-light-primary {
  background-color: var(--primary01);
  -webkit-backdrop-filter: blur(2rem);
  backdrop-filter: blur(2rem);
}

.dropdown-menu.dropmenu-light-primary .dropdown-item:hover,
.dropdown-menu.dropmenu-light-primary .dropdown-item:focus,
.dropdown-menu.dropmenu-light-primary .dropdown-item:active,
.dropdown-menu.dropmenu-light-primary .dropdown-item.active {
  color: #fff;
  background-color: var(--primary-color) !important;
}

.dropdown-menu.dropmenu-light-secondary {
  background-color: rgba(var(--secondary-rgb), 0.1);
  -webkit-backdrop-filter: blur(2rem);
  backdrop-filter: blur(2rem);
}

.dropdown-menu.dropmenu-light-secondary .dropdown-item:hover,
.dropdown-menu.dropmenu-light-secondary .dropdown-item:focus,
.dropdown-menu.dropmenu-light-secondary .dropdown-item:active,
.dropdown-menu.dropmenu-light-secondary .dropdown-item.active {
  color: #fff;
  background-color: rgb(var(--secondary-rgb)) !important;
}

.dropdown-menu.dropmenu-light-info {
  background-color: rgba(var(--info-rgb), 0.1);
  -webkit-backdrop-filter: blur(2rem);
  backdrop-filter: blur(2rem);
}

.dropdown-menu.dropmenu-light-info .dropdown-item:hover,
.dropdown-menu.dropmenu-light-info .dropdown-item:focus,
.dropdown-menu.dropmenu-light-info .dropdown-item:active,
.dropdown-menu.dropmenu-light-info .dropdown-item.active {
  color: #fff;
  background-color: rgb(var(--info-rgb)) !important;
}

.dropdown-menu.dropmenu-light-warning {
  background-color: rgba(var(--warning-rgb), 0.1);
  -webkit-backdrop-filter: blur(2rem);
  backdrop-filter: blur(2rem);
}

.dropdown-menu.dropmenu-light-warning .dropdown-item:hover,
.dropdown-menu.dropmenu-light-warning .dropdown-item:focus,
.dropdown-menu.dropmenu-light-warning .dropdown-item:active,
.dropdown-menu.dropmenu-light-warning .dropdown-item.active {
  color: #fff;
  background-color: rgb(var(--warning-rgb)) !important;
}

.dropdown-menu.dropmenu-light-success {
  background-color: rgba(var(--success-rgb), 0.1);
  -webkit-backdrop-filter: blur(2rem);
  backdrop-filter: blur(2rem);
}

.dropdown-menu.dropmenu-light-success .dropdown-item:hover,
.dropdown-menu.dropmenu-light-success .dropdown-item:focus,
.dropdown-menu.dropmenu-light-success .dropdown-item:active,
.dropdown-menu.dropmenu-light-success .dropdown-item.active {
  color: #fff;
  background-color: rgb(var(--success-rgb)) !important;
}

.dropdown-menu.dropmenu-light-danger {
  background-color: rgba(var(--danger-rgb), 0.1);
  -webkit-backdrop-filter: blur(2rem);
  backdrop-filter: blur(2rem);
}

.dropdown-menu.dropmenu-light-danger .dropdown-item:hover,
.dropdown-menu.dropmenu-light-danger .dropdown-item:focus,
.dropdown-menu.dropmenu-light-danger .dropdown-item:active,
.dropdown-menu.dropmenu-light-danger .dropdown-item.active {
  color: #fff;
  background-color: rgb(var(--danger-rgb)) !important;
}

/* End:: Custom Dropdown Menu */
/* Start:: Dropdown divider */
.dropdown-divider {
  margin: 0;
}

/* End:: Dropdown divider */
@media (max-width: 575.98px) {
  .main-header-container .header-element .header-link {
    padding: 1rem 0.5rem;
  }
}

.form-control {
  border-color: var(--input-border);
  color: var(--default-text-color);
  background-color: var(--form-control-bg);
  font-size: 0.875rem;
  font-weight: var(--default-font-weight);
  line-height: 1.6;
  border-radius: 0.35rem;
  padding: 0.5rem 0.85rem;
}

.form-control:focus {
  box-shadow: none;
  border-color: var(--input-border);
  background-color: var(--custom-white);
  box-shadow: 0 0.0625rem 0.125rem 0 rgba(0, 0, 0, 0.05);
  color: var(--default-text-color);
}

.form-control[type=file] {
  padding: 0.3rem 0.5rem;
}

.form-control[type=file].form-control-sm {
  padding: 0.25rem 0.5rem !important;
}

.form-control[type=file].form-control-lg {
  padding: 0.5rem 1rem !important;
}

.form-label {
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--default-text-color);
}

.form-text {
  color: var(--text-muted);
}

.form-control-light {
  background-color: rgb(var(--light-rgb));
  border: 0;
}

.form-control-light:focus {
  background-color: rgb(var(--light-rgb));
}

.form-control-primary {
  background-color: var(--primary01);
  border: 0;
  color: var(--primary-color);
}

.form-control-primary:focus {
  background-color: var(--primary01);
  color: var(--primary-color);
}

.form-input-color {
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 7px;
  overflow: hidden;
  padding: 0;
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label {
  color: var(--text-muted);
}

.form-floating>.form-control-plaintext~label,
.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
  color: var(--text-muted);
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label::after {
  background-color: transparent;
}

.form-floating>.form-control:not(:placeholder-shown)~label::after {
  background-color: transparent;
}

.form-select {
  background-color: var(--form-control-bg);
  border: 1px solid var(--input-border);
  color: var(--default-text-color);
  font-size: 14px !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-size: 1.25rem;
  line-height: 2;
}

.form-select option {
  background-color: var(--custom-white);
  padding: 0.35rem 0.75rem;
  border-radius: 0.25rem;
  margin-block-end: 0.25rem;
}

.form-select option:last-child {
  margin-block-end: 0;
}

.form-select option:checked {
  background-color: var(--primary02);
  color: var(--primary-color);
}

.form-select option:select {
  background-color: var(--primary02);
  color: var(--primary-color);
}

.form-select option:hover {
  background-color: var(--primary02);
  color: var(--primary-color);
}

.form-select option:focus {
  background-color: var(--primary02);
  color: var(--primary-color);
}

.form-check-input {
  width: 0.9rem;
  height: 0.9rem;
  background-color: var(--custom-white);
  border: 1px solid var(--input-border);
}

.form-check-input:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.form-check-input.form-checked-outline:checked {
  background-color: transparent;
  border-color: var(--primary-color);
}

.form-check-input.form-checked-secondary:checked {
  background-color: rgb(var(--secondary-rgb));
  border-color: rgb(var(--secondary-rgb));
}

.form-check-input.form-checked-warning:checked {
  background-color: rgb(var(--warning-rgb));
  border-color: rgb(var(--warning-rgb));
}

.form-check-input.form-checked-info:checked {
  background-color: rgb(var(--info-rgb));
  border-color: rgb(var(--info-rgb));
}

.form-check-input.form-checked-success:checked {
  background-color: rgb(var(--success-rgb));
  border-color: rgb(var(--success-rgb));
}

.form-check-input.form-checked-danger:checked {
  background-color: rgb(var(--danger-rgb));
  border-color: rgb(var(--danger-rgb));
}

.form-check-input.form-checked-light:checked {
  background-color: rgb(var(--light-rgb));
  border-color: rgb(var(--light-rgb));
}

.form-check-input.form-checked-dark:checked {
  background-color: rgb(var(--dark-rgb));
  border-color: rgb(var(--dark-rgb));
}

.form-check-input:focus {
  box-shadow: none;
}

.form-checked-outline:checked[type=checkbox] {
  background-image: none;
  position: relative;
  background-color: transparent;
}

.form-checked-outline:checked[type=checkbox]:before {
  content: "\f633";
  font-family: bootstrap-icons !important;
  position: absolute;
  color: var(--primary-color);
  width: 0.625rem;
  height: 0.625rem;
  inset-block-start: -1px;
  inset-inline-start: 0px;
  font-size: 0.688rem;
}

.form-checked-outline:checked[type=checkbox].form-checked-secondary:before {
  color: rgb(var(--secondary-rgb));
}

.form-checked-outline:checked[type=checkbox].form-checked-warning:before {
  color: rgb(var(--warning-rgb));
}

.form-checked-outline:checked[type=checkbox].form-checked-info:before {
  color: rgb(var(--info-rgb));
}

.form-checked-outline:checked[type=checkbox].form-checked-success:before {
  color: rgb(var(--success-rgb));
}

.form-checked-outline:checked[type=checkbox].form-checked-danger:before {
  color: rgb(var(--danger-rgb));
}

.form-checked-outline:checked[type=checkbox].form-checked-light:before {
  color: rgb(var(--light-rgb));
}

.form-checked-outline:checked[type=checkbox].form-checked-dark:before {
  color: rgb(var(--dark-rgb));
}

.form-checked-outline:checked[type=radio] {
  background-image: none;
  position: relative;
  background-color: transparent;
}

.form-checked-outline:checked[type=radio]:before {
  content: "\f309";
  font-family: bootstrap-icons !important;
  position: absolute;
  color: var(--primary-color);
  width: 0.625rem;
  height: 0.625rem;
  inset-block-start: -1.13rem;
  inset-inline-start: -0.6rem;
  font-size: 2rem;
}

.form-checked-outline:checked[type=radio].form-checked-secondary:before {
  color: rgb(var(--secondary-rgb));
}

.form-checked-outline:checked[type=radio].form-checked-warning:before {
  color: rgb(var(--warning-rgb));
}

.form-checked-outline:checked[type=radio].form-checked-info:before {
  color: rgb(var(--info-rgb));
}

.form-checked-outline:checked[type=radio].form-checked-success:before {
  color: rgb(var(--success-rgb));
}

.form-checked-outline:checked[type=radio].form-checked-danger:before {
  color: rgb(var(--danger-rgb));
}

.form-checked-outline:checked[type=radio].form-checked-light:before {
  color: rgb(var(--light-rgb));
}

.form-checked-outline:checked[type=radio].form-checked-dark:before {
  color: rgb(var(--dark-rgb));
}

fieldset:disabled .btn {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.form-select:focus {
  border-color: var(--primary05);
  outline: 0;
  box-shadow: none;
}

.btn-check:focus+.btn,
.btn:focus {
  background-color: none;
  border-color: none;
  outline: 0;
}

.btn-check:active+.btn:focus,
.btn-check:checked+.btn:focus,
.btn.active:focus,
.btn.show:focus,
.btn:active:focus {
  box-shadow: none;
}

.form-control:disabled,
.form-select:disabled {
  background-color: rgb(var(--light-rgb));
  color: var(--default-text-color);
}

.form-control-plaintext {
  color: var(--default-text-color);
}

.form-control::-webkit-file-upload-button {
  color: var(--default-text-color);
  background-color: var(--default-background);
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: var(--default-background);
}

/* Start::placeholder */
.form-control:focus::-webkit-input-placeholder,
.form-control:focus::placeholder {
  padding-inline-start: 0.3rem;
  -webkit-transition: padding-inline-start 0.1s ease-in;
  transition: padding-inline-start 0.1s ease-in;
}

.form-control::-webkit-input-placeholder {
  font-size: 0.8rem;
  font-weight: 500;
  opacity: 0.7;
  color: var(--default-text-color);
  padding-inline-start: 0px;
  -webkit-transition: padding-inline-start 0.1s ease-in;
  transition: padding-inline-start 0.1s ease-in;
}

.form-control:-moz-placeholder {
  /* Firefox 18- */
  font-size: 0.8rem;
  font-weight: 500;
  opacity: 0.4;
  color: var(--default-text-color);
}

.form-control::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 0.8rem;
  font-weight: 500;
  opacity: 0.4;
  color: var(--default-text-color);
}

.form-control:-ms-input-placeholder {
  font-size: 0.8rem;
  font-weight: 500;
  opacity: 0.4;
  color: var(--default-text-color);
}

/* End::placeholder */
/* Start:: sizes */
.form-control-sm {
  font-size: 0.8rem;
  padding: 0.25rem 0.8rem;
}

.form-control-lg {
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

.form-check-md .form-check-input {
  width: 1.15rem;
  height: 1.15rem;
}

.form-check-md.form-switch .form-check-input {
  width: 2.25rem;
}

.form-check-md label {
  margin-inline-start: 0.5rem;
  font-size: 0.95rem;
  margin-block-start: 3px;
}

.form-check-lg .form-check-input {
  width: 1.35rem;
  height: 1.35rem;
}

.form-check-lg.form-switch .form-check-input {
  width: 2.5rem;
}

.form-check-lg label {
  margin-inline-start: 0.5rem;
  font-size: 1rem;
  margin-block-start: 3px;
}

/* End:: sizes */
/* Start:: toggle switches-1 */
.toggle {
  width: 3.75rem;
  height: 1.563rem;
  background-color: rgb(var(--light-rgb));
  margin-inline-start: 0.625rem;
  margin-block-end: 0.313rem;
  padding: 0.125rem;
  border-radius: 0.188rem;
  position: relative;
  overflow: hidden;
  transition: all 0.2s ease;
}

.toggle span {
  position: absolute;
  inset-block-start: 0.188rem;
  inset-block-end: 0.25rem;
  inset-inline-start: 0.188rem;
  display: block;
  width: 1.25rem;
  border-radius: 0.125rem;
  background-color: var(--custom-white);
  box-shadow: 0 0.125rem 0.125rem 0 rgba(0, 0, 0, 0.45);
  cursor: pointer;
  transition: all 0.2s ease;
}

.toggle span::before {
  content: "on";
  inset-inline-start: -1.563rem;
}

.toggle span::after {
  content: "off";
  inset-inline-end: -1.813rem;
  color: var(--text-muted);
}

.toggle span::before,
.toggle span::after {
  position: absolute;
  font-size: 0.625rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  inset-block-start: 0.188rem;
  line-height: 1.38;
  transition: all 0.2s ease;
}

.toggle.on {
  background-color: var(--primary03);
}

.toggle.on span {
  background-color: var(--primary-color);
}

.toggle.on span::before {
  color: var(--primary-color);
}

.toggle.on.toggle-secondary {
  background-color: rgba(var(--secondary-rgb), 0.4);
}

.toggle.on.toggle-secondary span {
  background-color: rgb(var(--secondary-rgb));
}

.toggle.on.toggle-secondary span::before {
  color: rgb(var(--secondary-rgb));
}

.toggle.on.toggle-warning {
  background-color: rgba(var(--warning-rgb), 0.4);
}

.toggle.on.toggle-warning span {
  background-color: rgb(var(--warning-rgb));
}

.toggle.on.toggle-warning span::before {
  color: rgb(var(--warning-rgb));
}

.toggle.on.toggle-info {
  background-color: rgba(var(--info-rgb), 0.4);
}

.toggle.on.toggle-info span {
  background-color: rgb(var(--info-rgb));
}

.toggle.on.toggle-info span::before {
  color: rgb(var(--info-rgb));
}

.toggle.on.toggle-success {
  background-color: rgba(var(--success-rgb), 0.4);
}

.toggle.on.toggle-success span {
  background-color: rgb(var(--success-rgb));
}

.toggle.on.toggle-success span::before {
  color: rgb(var(--success-rgb));
}

.toggle.on.toggle-danger {
  background-color: rgba(var(--danger-rgb), 0.4);
}

.toggle.on.toggle-danger span {
  background-color: rgb(var(--danger-rgb));
}

.toggle.on.toggle-danger span::before {
  color: rgb(var(--danger-rgb));
}

.toggle.on.toggle-light {
  background-color: rgba(var(--light-rgb), 0.4);
}

.toggle.on.toggle-light span {
  background-color: rgb(var(--light-rgb));
}

.toggle.on.toggle-light span::before,
.toggle.on.toggle-light span::after {
  color: var(--text-muted);
}

.toggle.on.toggle-dark {
  background-color: rgba(var(--dark-rgb), 0.4);
}

.toggle.on.toggle-dark span {
  background-color: rgb(var(--dark-rgb));
}

.toggle.on.toggle-dark span::before {
  color: var(--custom-white);
}

.toggle.on span {
  inset-inline-start: 2.313rem;
}

.toggle.on.toggle-sm span {
  inset-inline-start: 2.313rem;
}

.toggle.on.toggle-sm span::before {
  inset-block-start: -1px;
  inset-inline-start: -1.563rem;
}

.toggle.on.toggle-lg span {
  inset-inline-start: 2.563rem;
}

.toggle.on.toggle-lg span::before {
  inset-block-start: 0.5rem;
  inset-inline-start: -1.75rem;
}

.toggle.toggle-sm {
  height: 1.063rem;
  width: 3.125rem;
}

.toggle.toggle-sm span {
  width: 0.625rem;
  height: 0.625rem;
}

.toggle.toggle-sm span::after {
  inset-inline-end: -1.875rem;
  inset-block-start: -1px;
}

.toggle.toggle-lg {
  height: 2.125rem;
  width: 4.5rem;
}

.toggle.toggle-lg span {
  width: 1.75rem;
}

.toggle.toggle-lg span::after {
  inset-block-start: 0.5rem;
  inset-inline-end: -1.938rem;
}

/* End:: toggle switches-1 */
/* Start:: toggle switches-2 */
.custom-toggle-switch>input[type=checkbox] {
  display: none;
}

.custom-toggle-switch>label {
  cursor: pointer;
  height: 0;
  position: relative;
  width: 2.5rem;
}

.label-primary {
  background: var(--primary-color);
  color: #fff;
}

.label-secondary {
  background: rgb(var(--secondary-rgb));
  color: #fff;
}

.label-warning {
  background: rgb(var(--warning-rgb));
  color: #fff;
}

.label-info {
  background: rgb(var(--info-rgb));
  color: #fff;
}

.label-success {
  background: rgb(var(--success-rgb));
  color: #fff;
}

.label-danger {
  background: rgb(var(--danger-rgb));
  color: #fff;
}

.label-light {
  background: rgb(var(--light-rgb));
  color: #fff;
}

.label-dark {
  background: rgb(var(--dark-rgb));
  color: #fff;
}

.custom-toggle-switch>input[type=checkbox]:checked+label::before {
  background: inherit;
  opacity: 0.5;
}

.custom-toggle-switch>label::before {
  background: var(--text-muted);
  box-shadow: inset 0px 0px 0.625rem var(--white-5);
  border-radius: 0.5rem;
  content: "";
  height: 1rem;
  margin-block-start: -0.5rem;
  position: absolute;
  opacity: 0.3;
  transition: all 0.4s ease-in-out;
  width: 2.5rem;
}

.custom-toggle-switch>label::after {
  background: var(--custom-white);
  border-radius: 1rem;
  box-shadow: 0 0 0.313rem rgba(228, 229, 237, 0.8);
  content: "";
  height: 1.5rem;
  inset-inline-start: -0.25rem;
  margin-block-start: -0.5rem;
  position: absolute;
  inset-block-start: -0.25rem;
  transition: all 0.3s ease-in-out;
  width: 1.5rem;
}

.custom-toggle-switch>input[type=checkbox]:checked+label::after {
  background: inherit;
  inset-inline-start: 50%;
}

.custom-toggle-switch.toggle-sm>label::before {
  height: 10px;
  width: 27px;
  border-radius: 10px;
}

.custom-toggle-switch.toggle-sm input[type=checkbox]:checked+label::after {
  inset-inline-start: 13px;
}

.custom-toggle-switch.toggle-sm>label::after {
  height: 17px;
  width: 17px;
  border-radius: 50%;
}

.custom-toggle-switch.toggle-lg>label::before {
  height: 27px;
  width: 55px;
  border-radius: 20px;
}

.custom-toggle-switch.toggle-lg input[type=checkbox]:checked+label::after {
  inset-inline-start: 77%;
}

.custom-toggle-switch.toggle-lg>label::after {
  height: 35px;
  width: 35px;
  margin-block-start: -8px;
  border-radius: 50%;
}

/* End:: toggle switches-2 */
/* Start:: floating labels */
.form-floating>.form-control-plaintext::-moz-placeholder,
.form-floating>.form-control::-moz-placeholder {
  color: transparent !important;
}

.form-floating>.form-control-plaintext::placeholder,
.form-floating>.form-control::placeholder {
  color: transparent !important;
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext,
.form-floating>.form-select {
  height: calc(3.5rem - 4px);
}

.form-floating.floating-primary label {
  color: var(--primary-color);
  opacity: 1;
}

.form-floating.floating-primary input {
  border: 1px solid var(--primary-color);
}

.form-floating.floating-primary>.form-control:focus~label {
  color: var(--primary-color) !important;
}

.form-floating.floating-secondary label {
  color: rgb(var(--secondary-rgb));
  opacity: 1;
}

.form-floating.floating-secondary input {
  border: 1px solid rgb(var(--secondary-rgb));
}

.form-floating.floating-secondary>.form-control:focus~label {
  color: rgb(var(--secondary-rgb)) !important;
}

.form-floating.floating-warning label {
  color: rgb(var(--warning-rgb));
  opacity: 1;
}

.form-floating.floating-warning input {
  border: 1px solid rgb(var(--warning-rgb));
}

.form-floating.floating-warning>.form-control:focus~label {
  color: rgb(var(--warning-rgb)) !important;
}

.form-floating.floating-info label {
  color: rgb(var(--info-rgb));
  opacity: 1;
}

.form-floating.floating-info input {
  border: 1px solid rgb(var(--info-rgb));
}

.form-floating.floating-info>.form-control:focus~label {
  color: rgb(var(--info-rgb)) !important;
}

.form-floating.floating-success label {
  color: rgb(var(--success-rgb));
  opacity: 1;
}

.form-floating.floating-success input {
  border: 1px solid rgb(var(--success-rgb));
}

.form-floating.floating-success>.form-control:focus~label {
  color: rgb(var(--success-rgb)) !important;
}

.form-floating.floating-danger label {
  color: rgb(var(--danger-rgb));
  opacity: 1;
}

.form-floating.floating-danger input {
  border: 1px solid rgb(var(--danger-rgb));
}

.form-floating.floating-danger>.form-control:focus~label {
  color: rgb(var(--danger-rgb)) !important;
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label::after {
  background-color: var(--custom-white);
  border-radius: 7px;
}

.form-floating>.form-control-plaintext~label::after,
.form-floating>.form-control:focus~label::after,
.form-floating>.form-control:not(:placeholder-shown)~label::after,
.form-floating>.form-select~label::after {
  background-color: var(--custom-white);
  border-radius: 7px;
}

/* End:: floating labels */
/* Start:: form wizard */
.form-wizard-1 {
  border-bottom: 0;
  border-radius: 50px;
}

.form-wizard-1 .nav-link {
  border: 0;
}

.form-wizard-1.nav-tabs .nav-item {
  margin-inline-end: 1rem;
  position: relative;
}

.form-wizard-1.nav-tabs .nav-link {
  padding: 0.25rem 0.4rem;
  color: var(--text-muted);
  display: flex;
  align-items: center;
  border-radius: 50px;
  border: 2px solid transparent;
}

.form-wizard-1.nav-tabs .nav-link i {
  font-size: 12px;
  padding: 4px;
  line-height: 1;
  border-radius: 50px;
  border: 2px solid var(--default-border);
}

.form-wizard-1.nav-tabs .nav-link:focus,
.form-wizard-1.nav-tabs .nav-link:hover {
  border: 2px solid transparent;
}

.form-wizard-1.nav-tabs .nav-link.active {
  border: 2px solid var(--primary-color);
  border-radius: 50px;
  color: var(--primary-color);
  background-color: transparent;
}

.form-wizard-1.nav-tabs .nav-link.active i {
  border: 2px solid var(--primary-color);
}

.form-wizard-1 .nav-item:focus-visible {
  outline: none;
}

/* End:: form wizard */
/* Start:: form valildation */
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: rgb(var(--success-rgb));
  box-shadow: none;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: rgb(var(--success-rgb));
}

/* End:: form valildation */
/* Start:: color picker */
.form-control-color {
  width: 1.75rem;
  height: 1.75rem;
  overflow: hidden;
  padding: 0;
}

.example-picker .pcr-button {
  border-radius: 0.35rem !important;
  overflow: hidden;
}

/* End:: color picker */
/* Start:: rtl */
[dir=rtl] [type=email],
[dir=rtl] [type=number],
[dir=rtl] [type=tel],
[dir=rtl] [type=url] {
  direction: rtl;
}

/* End:: rtl */
/* Start:: form validations */
.form-select.is-invalid:not([multiple]):not([size]) {
  padding-right: 1.125rem;
  background-size: 16px 28px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

/* End:: form validations */
/* Start:: input group */
.invoice-quantity-container .input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 0;
}

/* End:: input group */
/* Start:: range slider */
input[type=range]::-webkit-slider-runnable-track {
  background-color: rgb(var(--light-rgb));
}

/* End:: range slider */
/* Start:: form outline checkbox */
[dir=rtl] .form-checked-outline:checked[type=checkbox]:before {
  inset-inline-start: 1px;
}

/* End:: form outline checkbox */
/* Start:: from check */
.form-check {
  min-height: inherit !important;
}

/* End:: from check */
/* Start:: Col Form Label */
.col-form-label-lg,
.col-form-label-sm,
.col-form-label {
  font-weight: 600;
}

/* End:: Col Form Label */
#exampleColorInput::-webkit-color-swatch-wrapper {
  direction: rtl;
  /* Set the direction to RTL */
}

[data-theme-mode=dark] input[type=week]::-webkit-calendar-picker-indicator,
[data-theme-mode=dark] input[type=month]::-webkit-calendar-picker-indicator,
[data-theme-mode=dark] input[type=date]::-webkit-calendar-picker-indicator,
[data-theme-mode=dark] input[type=datetime-local]::-webkit-calendar-picker-indicator,
[data-theme-mode=dark] input[type=time]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

[dir=rtl] input[type=week],
[dir=rtl] input[type=month],
[dir=rtl] input[type=date],
[dir=rtl] input[type=datetime-local],
[dir=rtl] input[type=time] {
  text-align: end;
}

.input-group-text {
  border-color: var(--input-border);
  font-size: 0.875rem;
  border-radius: 0.3125rem;
  background-color: rgb(var(--light-rgb));
  color: var(--default-text-color);
  letter-spacing: 0;
}

.input-group-text .form-control {
  border: 0;
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.list-group-item {
  color: var(--default-text-color);
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
  padding: 0.75rem 1.25rem;
}

.list-group-item.active {
  color: #fff !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color);
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: var(--default-text-color);
  background-color: rgb(var(--light-rgb));
  opacity: 0.5;
}

.list-group-item-action:focus,
.list-group-item-action:hover {
  color: var(--default-text-color);
  background-color: var(--list-hover-focus-bg);
}

.list-group-item-primary {
  color: var(--primary-color);
  background-color: var(--primary01);
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  color: var(--primary-color);
  background-color: var(--primary02);
}

.list-group-item-secondary {
  color: rgb(var(--secondary-rgb));
  background-color: rgba(var(--secondary-rgb), 0.1);
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  color: rgb(var(--secondary-rgb));
  background-color: rgba(var(--secondary-rgb), 0.2);
}

.list-group-item-info {
  color: rgb(var(--info-rgb));
  background-color: rgba(var(--info-rgb), 0.1);
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  color: rgb(var(--info-rgb));
  background-color: rgba(var(--info-rgb), 0.2);
}

.list-group-item-warning {
  color: rgb(var(--warning-rgb));
  background-color: rgba(var(--warning-rgb), 0.1);
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  color: rgb(var(--warning-rgb));
  background-color: rgba(var(--warning-rgb), 0.2);
}

.list-group-item-danger {
  color: rgb(var(--danger-rgb));
  background-color: rgba(var(--danger-rgb), 0.1);
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  color: rgb(var(--danger-rgb));
  background-color: rgba(var(--danger-rgb), 0.2);
}

.list-group-item-success {
  color: rgb(var(--success-rgb));
  background-color: rgba(var(--success-rgb), 0.1);
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  color: rgb(var(--success-rgb));
  background-color: rgba(var(--success-rgb), 0.2);
}

.list-group-item-dark {
  color: rgb(var(--dark-rgb));
  background-color: rgba(var(--dark-rgb), 0.1);
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  color: rgb(var(--dark-rgb));
  background-color: rgba(var(--dark-rgb), 0.2);
}

.list-group-item-light {
  color: var(--default-text-color);
  background-color: rgba(var(--light-rgb), 0.1);
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  color: var(--default-text-color);
  background-color: rgba(var(--light-rgb), 0.2);
}

.list-item-solid-primary {
  background-color: var(--primary-color);
  color: #fff;
  border-color: var(--primary-color);
}

.list-item-solid-secondary {
  background-color: rgb(var(--secondary-rgb));
  color: #fff;
  border-color: rgb(var(--secondary-rgb));
}

.list-item-solid-success {
  background-color: rgb(var(--success-rgb));
  color: #fff;
  border-color: rgb(var(--success-rgb));
}

.list-item-solid-info {
  background-color: rgb(var(--info-rgb));
  color: #fff;
  border-color: rgb(var(--info-rgb));
}

.list-item-solid-warning {
  background-color: rgb(var(--warning-rgb));
  color: #fff;
  border-color: rgb(var(--warning-rgb));
}

.list-item-solid-danger {
  background-color: rgb(var(--danger-rgb));
  color: #fff;
  border-color: rgb(var(--danger-rgb));
}

.list-item-solid-light {
  background-color: rgb(var(--light-rgb));
  color: var(--default-text-color);
  border-color: rgb(var(--light-rgb));
}

.list-item-solid-dark {
  background-color: rgb(var(--dark-rgb));
  color: #fff;
  border-color: rgb(var(--dark-rgb));
}

.modal {
  z-index: 105;
}

.modal-backdrop {
  z-index: 104;
}

.modal-content {
  color: var(--default-text-color);
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
  border-radius: 7px;
}

.modal-header {
  padding: 1rem 1.25rem;
  border-block-end: 1px solid var(--default-border);
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.modal-header .modal-title {
  font-weight: 600;
  line-height: 1;
}

.modal-header .btn-close {
  font-size: 0.625rem;
}

.modal-footer {
  padding: 1rem 1.25rem;
  border-block-start: 1px solid var(--default-border);
}

/* modal animations */
.modal.animated .modal-dialog {
  transform: translate(0, 0);
}

.modal.effect-scale .modal-dialog {
  transform: scale(0.7);
  opacity: 0;
  transition: all 0.3s;
}

.modal.effect-scale.show .modal-dialog {
  transform: scale(1);
  opacity: 1;
}

.modal.effect-slide-in-right .modal-dialog {
  transform: translateX(20%);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}

.modal.effect-slide-in-right.show .modal-dialog {
  transform: translateX(0);
  opacity: 1;
}

.modal.effect-slide-in-bottom .modal-dialog {
  transform: translateY(20%);
  opacity: 0;
  transition: all 0.3s;
}

.modal.effect-slide-in-bottom.show .modal-dialog {
  transform: translateY(0);
  opacity: 1;
}

.modal.effect-newspaper .modal-dialog {
  transform: scale(0) rotate(720deg);
  opacity: 0;
}

.modal.effect-newspaper.show~.modal-backdrop,
.modal.effect-newspaper .modal-dialog {
  transition: all 0.5s;
}

.modal.effect-newspaper.show .modal-dialog {
  transform: scale(1) rotate(0deg);
  opacity: 1;
}

.modal.effect-fall {
  perspective: 1300px;
}

.modal.effect-fall .modal-dialog {
  transform-style: preserve-3d;
  transform: translateZ(600px) rotateX(20deg);
  opacity: 0;
}

.modal.effect-fall.show .modal-dialog {
  transition: all 0.3s ease-in;
  transform: translateZ(0px) rotateX(0deg);
  opacity: 1;
}

.modal.effect-flip-horizontal {
  perspective: 1300px;
}

.modal.effect-flip-horizontal .modal-dialog {
  transform-style: preserve-3d;
  transform: rotateY(-70deg);
  transition: all 0.3s;
  opacity: 0;
}

.modal.effect-flip-horizontal.show .modal-dialog {
  transform: rotateY(0deg);
  opacity: 1;
}

.modal.effect-flip-vertical {
  perspective: 1300px;
}

.modal.effect-flip-vertical .modal-dialog {
  transform-style: preserve-3d;
  transform: rotateX(-70deg);
  transition: all 0.3s;
  opacity: 0;
}

.modal.effect-flip-vertical.show .modal-dialog {
  transform: rotateX(0deg);
  opacity: 1;
}

.modal.effect-super-scaled .modal-dialog {
  transform: scale(2);
  opacity: 0;
  transition: all 0.3s;
}

.modal.effect-super-scaled.show .modal-dialog {
  transform: scale(1);
  opacity: 1;
}

.modal.effect-sign {
  perspective: 1300px;
}

.modal.effect-sign .modal-dialog {
  transform-style: preserve-3d;
  transform: rotateX(-60deg);
  transform-origin: 50% 0;
  opacity: 0;
  transition: all 0.3s;
}

.modal.effect-sign.show .modal-dialog {
  transform: rotateX(0deg);
  opacity: 1;
}

.modal.effect-rotate-bottom {
  perspective: 1300px;
}

.modal.effect-rotate-bottom .modal-dialog {
  transform-style: preserve-3d;
  transform: translateY(100%) rotateX(90deg);
  transform-origin: 0 100%;
  opacity: 0;
  transition: all 0.3s ease-out;
}

.modal.effect-rotate-bottom.show .modal-dialog {
  transform: translateY(0%) rotateX(0deg);
  opacity: 1;
}

.modal.effect-rotate-left {
  perspective: 1300px;
}

.modal.effect-rotate-left .modal-dialog {
  transform-style: preserve-3d;
  transform: translateZ(100px) translateX(-30%) rotateY(90deg);
  transform-origin: 0 100%;
  opacity: 0;
  transition: all 0.3s;
}

.modal.effect-rotate-left.show .modal-dialog {
  transform: translateZ(0px) translateX(0%) rotateY(0deg);
  opacity: 1;
}

.navbar {
  border-radius: 7px;
}

.navbar .navbar-nav .nav-link {
  line-height: 1;
  padding: 0.4rem 1rem;
  font-weight: 500;
}

.navbar .navbar-toggler {
  padding: 0.4rem;
  font-size: 1rem;
  line-height: 1;
  color: var(--custom-black);
  border: 1px solid var(--default-border);
  border-radius: 7px;
}

.navbar .navbar-toggler .navbar-toggler-icon {
  width: 1rem;
  height: 1rem;
  position: relative;
  background-image: none;
}

.navbar .navbar-toggler .navbar-toggler-icon:before {
  content: "\f479";
  font-family: bootstrap-icons !important;
  position: absolute;
  font-size: 1rem;
  color: var(--default-text-color);
  inset-inline-start: 0;
}

.navbar .navbar-toggler:focus {
  box-shadow: none;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: var(--custom-black);
}

.navbar-brand {
  margin-inline-end: 1.5rem;
}

.navbar-brand img {
  height: 2rem;
  line-height: 2rem;
}

/* Start:: navbar transparent color schemes */
.navbar-primary-transparent {
  background-color: var(--primary01);
  color: var(--default-text-color);
}

.navbar-primary-transparent .nav-link.active {
  background-color: var(--primary-color);
  color: #fff !important;
}

.navbar-primary-transparent .nav-link:hover {
  color: var(--default-text-color);
}

.navbar-primary-transparent .form-control:focus {
  border-color: var(--primary-color);
}

.navbar-secondary-transparent {
  background-color: rgba(var(--secondary-rgb), 0.1);
  color: var(--default-text-color);
}

.navbar-secondary-transparent .nav-link.active {
  background-color: rgb(var(--secondary-rgb));
  color: #fff !important;
}

.navbar-secondary-transparent .nav-link:hover {
  color: var(--default-text-color);
}

.navbar-secondary-transparent .form-control:focus {
  border-color: rgb(var(--secondary-rgb));
}

.navbar-warning-transparent {
  background-color: rgba(var(--warning-rgb), 0.1);
  color: var(--default-text-color);
}

.navbar-warning-transparent .nav-link.active {
  background-color: rgb(var(--warning-rgb));
  color: #fff !important;
}

.navbar-warning-transparent .nav-link:hover {
  color: var(--default-text-color);
}

.navbar-warning-transparent .form-control:focus {
  border-color: rgb(var(--warning-rgb));
}

.navbar-info-transparent {
  background-color: rgba(var(--info-rgb), 0.1);
  color: var(--default-text-color);
}

.navbar-info-transparent .nav-link.active {
  background-color: rgb(var(--info-rgb));
  color: #fff !important;
}

.navbar-info-transparent .nav-link:hover {
  color: var(--default-text-color);
}

.navbar-info-transparent .form-control:focus {
  border-color: rgb(var(--info-rgb));
}

.navbar-success-transparent {
  background-color: rgba(var(--success-rgb), 0.1);
  color: var(--default-text-color);
}

.navbar-success-transparent .nav-link.active {
  background-color: rgb(var(--success-rgb));
  color: #fff !important;
}

.navbar-success-transparent .nav-link:hover {
  color: var(--default-text-color);
}

.navbar-success-transparent .form-control:focus {
  border-color: rgb(var(--success-rgb));
}

.navbar-danger-transparent {
  background-color: rgba(var(--danger-rgb), 0.1);
  color: var(--default-text-color);
}

.navbar-danger-transparent .nav-link.active {
  background-color: rgb(var(--danger-rgb));
  color: #fff !important;
}

.navbar-danger-transparent .nav-link:hover {
  color: var(--default-text-color);
}

.navbar-danger-transparent .form-control:focus {
  border-color: rgb(var(--danger-rgb));
}

.navbar-dark-transparent {
  background-color: rgba(var(--dark-rgb), 0.1);
  color: var(--default-text-color);
}

.navbar-dark-transparent .nav-link.active {
  background-color: var(--custom-black);
  color: var(--custom-white) !important;
}

.navbar-dark-transparent .nav-link:hover {
  color: var(--default-text-color);
}

.navbar-dark-transparent .form-control:focus {
  border-color: rgb(var(--dark-rgb));
}

/* End:: navbar transparent color schemes */
/* Start:: navbar solid color schemes */
.navbar-primary {
  background-color: var(--primary-color);
  color: #fff;
}

.navbar-primary .nav-link {
  color: #fff !important;
}

.navbar-primary .nav-link.active {
  background-color: var(--white-2);
  color: #fff !important;
}

.navbar-primary .nav-link:hover {
  color: #fff !important;
}

.navbar-primary .form-control:focus {
  border-color: var(--white-2);
}

.navbar-primary .navbar-toggler {
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.navbar-primary .navbar-toggler .navbar-toggler-icon:before {
  color: #fff;
}

.navbar-secondary {
  background-color: rgb(var(--secondary-rgb));
  color: #fff;
}

.navbar-secondary .nav-link {
  color: #fff !important;
}

.navbar-secondary .nav-link.active {
  background-color: var(--white-2);
  color: #fff !important;
}

.navbar-secondary .nav-link:hover {
  color: #fff !important;
}

.navbar-secondary .form-control:focus {
  border-color: var(--white-2);
}

.navbar-secondary .navbar-toggler {
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.navbar-secondary .navbar-toggler .navbar-toggler-icon:before {
  color: #fff;
}

.navbar-warning {
  background-color: rgb(var(--warning-rgb));
  color: #fff;
}

.navbar-warning .nav-link {
  color: #fff !important;
}

.navbar-warning .nav-link.active {
  background-color: var(--white-2);
  color: #fff !important;
}

.navbar-warning .nav-link:hover {
  color: #fff !important;
}

.navbar-warning .form-control:focus {
  border-color: var(--white-2);
}

.navbar-warning .navbar-toggler {
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.navbar-warning .navbar-toggler .navbar-toggler-icon:before {
  color: #fff;
}

.navbar-info {
  background-color: rgb(var(--info-rgb));
  color: #fff;
}

.navbar-info .nav-link {
  color: #fff !important;
}

.navbar-info .nav-link.active {
  background-color: var(--white-2);
  color: #fff !important;
}

.navbar-info .nav-link:hover {
  color: #fff !important;
}

.navbar-info .form-control:focus {
  border-color: var(--white-2);
}

.navbar-info .navbar-toggler {
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.navbar-info .navbar-toggler .navbar-toggler-icon:before {
  color: #fff;
}

.navbar-success {
  background-color: rgb(var(--success-rgb));
  color: #fff;
}

.navbar-success .nav-link {
  color: #fff !important;
}

.navbar-success .nav-link.active {
  background-color: var(--white-2);
  color: #fff !important;
}

.navbar-success .nav-link:hover {
  color: #fff !important;
}

.navbar-success .form-control:focus {
  border-color: var(--white-2);
}

.navbar-success .navbar-toggler {
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.navbar-success .navbar-toggler .navbar-toggler-icon:before {
  color: #fff;
}

.navbar-danger {
  background-color: rgb(var(--danger-rgb));
  color: #fff;
}

.navbar-danger .nav-link {
  color: #fff !important;
}

.navbar-danger .nav-link.active {
  background-color: var(--white-2);
  color: #fff !important;
}

.navbar-danger .nav-link:hover {
  color: #fff !important;
}

.navbar-danger .form-control:focus {
  border-color: var(--white-2);
}

.navbar-danger .navbar-toggler {
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.navbar-danger .navbar-toggler .navbar-toggler-icon:before {
  color: #fff;
}

.navbar-dark {
  background-color: rgb(var(--dark-rgb));
  color: var(--custom-white);
}

.navbar-dark .nav-link {
  color: var(--custom-white) !important;
}

.navbar-dark .nav-link.active {
  background-color: var(--white-2);
  color: var(--custom-white) !important;
}

.navbar-dark .nav-link:hover {
  color: var(--custom-white) !important;
}

.navbar-dark .form-control:focus {
  border-color: var(--white-2);
}

.navbar-dark .navbar-toggler {
  border: 1px solid var(--white-1);
}

.navbar-dark .navbar-toggler .navbar-toggler-icon:before {
  color: var(--custom-white);
}

/* End:: navbar solid color schemes */
/* Start::Nav-link */
.nav-item {
  margin-inline-end: 0.5rem;
}

.nav-item:last-child {
  margin-inline-end: 0;
}

.nav-link {
  color: var(--default-text-color);
  border-radius: 7px;
  font-weight: 600;
}

.nav-link.active {
  background-color: var(--primary01);
  color: var(--primary-color);
}

.navbar-brand {
  color: var(--default-text-color);
}

.navbar-brand:focus,
.navbar-brand:hover {
  color: var(--default-text-color);
  opacity: 0.9;
}

.navbar-nav .nav-link.active,
.navbar-nav .show>.nav-link {
  color: var(--primary-color);
}

.nav-link.disabled {
  color: var(--default-text-color);
  opacity: 0.3;
}

.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link:disabled {
  color: rgba(var(--dark-rgb), 0.5);
}

.navbar-text {
  color: var(--default-text-color);
}

.navbar-dark.bg-dark .nav-link,
.navbar-dark.bg-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show>.nav-link {
  color: #fff;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: var(--primary-color);
}

/* End::Nav-link */
/* Start::Nav-style-1 */
.nav.nav-style-1 {
  border: 0;
}

.nav.nav-style-1 .nav-item {
  margin-inline-end: 0.25rem;
}

.nav.nav-style-1 .nav-item:last-child {
  margin-inline-end: 0;
}

.nav.nav-style-1 .nav-link.active {
  background-color: var(--primary-color);
  color: #fff;
}

/* End::Nav-style-1 */
/* Start::Nav-style-2 */
.nav.nav-style-2 .nav-item .nav-link {
  border-block-start: 3px solid transparent;
}

.nav.nav-style-2 .nav-item .nav-link.active {
  background-color: var(--primary01);
  border-block-start: 3px solid var(--primary-color);
  color: var(--primary-color);
}

/* End::Nav-style-2 */
/* Start::Nav-style-3 */
.nav.nav-style-3 .nav-link {
  border-radius: 0;
}

.nav.nav-style-3 .nav-link {
  border-block-end: 3px solid transparent;
}

.nav.nav-style-3 .nav-link.active {
  background-color: transparent;
  border-block-end: 3px solid var(--primary-color);
  color: var(--primary-color);
}

/* End::Nav-style-3 */
/* Start::Nav-style-4 */
.nav.nav-style-4 {
  border: 0;
}

.nav.nav-style-4 .nav-link {
  color: var(--text-muted);
  border: 0;
}

.nav.nav-style-4 .nav-link.active {
  background-color: rgb(var(--light-rgb));
  color: var(--default-text-color);
  border: 0;
}

.nav.nav-style-4 .nav-link:hover,
.nav.nav-style-4 .nav-link:focus {
  border: 0;
}

/* End::Nav-style-4 */
/* Start:: Nav-style-5 */
.nav-tabs.nav-style-5 {
  border: 0;
}

.nav-tabs.nav-style-5 .nav-link {
  color: var(--text-muted);
  border: 0;
}

.nav-tabs.nav-style-5 .nav-link.active {
  background-color: var(--primary-color);
  color: #fff;
  border: 0;
}

.nav-tabs.nav-style-5 .nav-link:hover,
.nav-tabs.nav-style-5 .nav-link:focus {
  border: 0;
}

/* End:: Nav-style-5 */
/* Start:: Nav-style-6 */
.nav-style-6 {
  border: 0;
}

.nav-style-6.nav-pills .nav-link.active {
  border: 1px solid var(--primary-color);
  border-block-end: 2px solid var(--primary-color);
  background-color: transparent;
  color: var(--primary-color);
}

/* End:: Nav-style-6 */
/* Start:: Nav-tabs-header */
.nav-tabs-header {
  border: 0;
}

.nav-tabs-header .nav-item {
  margin-inline-end: 0.5rem;
}

.nav-tabs-header .nav-item:last-child {
  margin-inline-end: 0;
}

.nav-tabs-header .nav-item .nav-link {
  border: 1px solid transparent;
  font-size: 0.8rem;
  padding: 0.4rem 0.5rem;
  font-weight: 500;
}

.nav-tabs-header .nav-item .nav-link.active {
  background: var(--primary01);
  color: var(--primary-color);
  border: 1px solid transparent;
}

.nav-tabs-header .nav-item .nav-link:hover,
.nav-tabs-header .nav-item .nav-link:focus {
  border: 1px solid transparent;
}

/* End:: Nav-tabs-header */
/* Start:: Nav-tabs-footer */
.nav-tabs-footer {
  border: 0;
}

.nav-tabs-footer .nav-item {
  margin-inline-end: 0.5rem;
}

.nav-tabs-footer .nav-item:last-child {
  margin-inline-end: 0;
}

.nav-tabs-footer .nav-item .nav-link {
  background-color: transparent;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  font-size: 0.8rem;
}

.nav-tabs-footer .nav-item .nav-link.active {
  background: var(--primary-color);
  color: #fff;
  border: 1px solid var(--primary-color);
}

.nav-tabs-footer .nav-item .nav-link:hover,
.nav-tabs-footer .nav-item .nav-link:focus {
  border: 1px solid var(--primary-color);
}

/* End:: Nav-tabs-footer */
/* Start:: Vertical-tabs-2 */
.vertical-tabs-2 {
  border: 0;
}

.vertical-tabs-2 .nav-item {
  margin-inline-end: 0.5rem;
}

.vertical-tabs-2 .nav-item:last-child {
  margin-inline-end: 0;
}

.vertical-tabs-2 .nav-item .nav-link {
  min-width: 7.5rem;
  max-width: 7.5rem;
  text-align: center;
  border: 1px solid var(--default-border);
  margin-bottom: 0.5rem;
  color: var(--text-muted);
  background-color: rgb(var(--light-rgb));
}

.vertical-tabs-2 .nav-item .nav-link i {
  font-size: 1rem;
}

.vertical-tabs-2 .nav-item .nav-link.active {
  background-color: var(--primary-color);
  color: #fff;
  position: relative;
}

.vertical-tabs-2 .nav-item .nav-link.active:before {
  content: "";
  position: absolute;
  inset-inline-end: -0.5rem;
  inset-block-start: 38%;
  transform: rotate(45deg);
  width: 1rem;
  height: 1rem;
  background-color: var(--primary-color);
}

/* End:: Vertical-tabs-2 */
/* Start:: Vertical-tabs-3 */
.vertical-tabs-3 {
  border: 0;
}

.vertical-tabs-3 .nav-item {
  border: 0;
  margin-inline-end: 0.5rem;
}

.vertical-tabs-3 .nav-item:last-child {
  margin-inline-end: 0;
}

.vertical-tabs-3 .nav-item .nav-link {
  min-width: 160px;
  max-width: 160px;
  border: 1px solid var(--primary-color);
  padding: 1.3rem;
  margin-block-end: 0.5rem;
}

.vertical-tabs-3 .nav-item .nav-link i {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  font-size: 1rem;
  background: var(--primary-color);
  color: #fff;
  border-radius: 3.125rem;
  font-weight: 500;
}

.vertical-tabs-3 .nav-item .nav-link.active {
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: #fff;
}

.vertical-tabs-3 .nav-item .nav-link.active i {
  background: #fff;
  color: var(--primary-color);
}

.vertical-tabs-3 .nav-item .nav-link:hover,
.vertical-tabs-3 .nav-item .nav-link:focus {
  border: 1px solid var(--primary-color);
}

/* End:: Vertical-tabs-3 */
/* Start:: Nav-tabs */
.nav-link:focus,
.nav-link:hover {
  color: var(--primary-color);
}

.nav-tabs {
  border-color: var(--default-border);
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--default-text-color);
  background-color: rgb(var(--light-rgb));
  border-color: var(--default-border);
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: var(--black-1);
}

/* End:: Nav-tabs */
/* Start:: Tab-style-1 */
.nav.tab-style-1 {
  border-block-end: 0;
  padding: 0.65rem;
  background-color: rgb(var(--light-rgb));
  border-radius: 7px;
  margin-block-end: 1rem;
}

.nav.tab-style-1 .nav-item {
  margin-inline-end: 0.5rem;
}

.nav.tab-style-1 .nav-item:last-child {
  margin-inline-end: 0;
}

.nav.tab-style-1 .nav-link {
  border-radius: 7px;
  padding: 0.35rem 1rem;
}

.nav.tab-style-1 .nav-link.active {
  background-color: var(--primary-color);
  color: #fff;
  box-shadow: 0 0.125rem 0 rgba(10, 10, 10, 0.04);
  transition: color 0.15s ease-in;
}

.tab-content .tab-pane {
  padding: 1rem;
  border: 1px solid var(--default-border);
  border-radius: 7px;
  color: var(--default-text-color);
}

/* End:: Tab-style-1 */
/* Start:: Tab-style-2 */
.tab-style-2 {
  border-block-end: 0;
}

.tab-style-2 .nav-item {
  margin-inline-end: 0.5rem;
}

.tab-style-2 .nav-item:last-child {
  margin-inline-end: 0;
}

.tab-style-2 .nav-item .nav-link {
  border: 0;
}

.tab-style-2 .nav-item .nav-link i {
  width: 1.875rem;
  height: 1.875rem;
  padding: 0.4rem;
  border-radius: 50px;
  background-color: rgb(var(--light-rgb));
  color: var(--default-text-color);
  display: inline-block;
}

.tab-style-2 .nav-item .nav-link:hover {
  border: 0;
}

.tab-style-2 .nav-item .nav-link.active {
  background-color: transparent;
  position: relative;
  border: 0;
  color: var(--primary-color);
}

.tab-style-2 .nav-item .nav-link.active i {
  background-color: var(--primary01);
  color: var(--primary-color);
}

.tab-style-2 .nav-item .nav-link.active:before {
  content: "";
  position: absolute;
  inset-inline-start: 0;
  inset-inline-end: 0;
  inset-block-end: 0;
  width: 100%;
  height: 0.175rem;
  background-color: var(--primary-color);
  border-radius: 50px;
}

/* End:: Tab-style-2 */
/* Start:: Tab-style-3 */
.tab-style-3 {
  border-block-end: 0;
}

.tab-style-3 .nav-item {
  position: relative;
  margin-inline-end: 0.5rem;
}

.tab-style-3 .nav-item:last-child {
  margin-inline-end: 0;
}

.tab-style-3 .nav-item .nav-link {
  position: relative;
  border: 0;
  font-size: 0.75rem;
}

.tab-style-3 .nav-item .nav-link:before {
  position: absolute;
  content: "";
  inset-block-end: -1.063rem;
  inset-inline-end: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 0.1rem;
  background-color: rgb(var(--light-rgb));
  border-radius: 50%;
}

.tab-style-3 .nav-item .nav-link:after {
  position: absolute;
  content: "";
  inset-block-end: -2rem;
  inset-inline-start: 1.75rem;
  inset-inline-end: 0;
  inset-inline-start: 30%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--custom-white);
  border-radius: 50%;
  border: 2px solid var(--default-border);
}

.tab-style-3 .nav-item .nav-link.home:after {
  content: "1";
}

.tab-style-3 .nav-item .nav-link.about:after {
  content: "2";
}

.tab-style-3 .nav-item .nav-link.services:after {
  content: "3";
}

.tab-style-3 .nav-item .nav-link:hover {
  border: 0;
}

.tab-style-3 .nav-item .nav-link.active {
  background-color: transparent;
  border: 0;
  color: var(--primary-color);
}

.tab-style-3 .nav-item .nav-link.active:before {
  background-color: var(--primary-color);
}

.tab-style-3 .nav-item .nav-link.active:after {
  border-color: var(--primary-color);
  color: var(--primary-color);
}

/* End:: Tab-style-3 */
/* Start:: Tab-style-4 */
.tab-style-4.nav-tabs {
  border: 0;
}

.tab-style-4.nav-tabs .nav-item {
  margin-inline-end: 0.5rem;
}

.tab-style-4.nav-tabs .nav-item:last-child {
  margin-inline-end: 0;
}

.tab-style-4.nav-tabs .nav-link {
  border: 0;
}

.tab-style-4.nav-tabs .nav-link:hover {
  border: 0;
}

.tab-style-4.nav-tabs .nav-link.active {
  background-color: transparent;
  border: 0;
  color: var(--primary-color);
  position: relative;
  overflow: hidden;
}

.tab-style-4.nav-tabs .nav-link.active::before {
  position: absolute;
  content: "";
  width: 0.75rem;
  inset-block-start: 31px;
  transform: rotate(45deg);
  inset-inline-start: 47%;
  overflow: hidden;
  z-index: 1;
  height: 0.75rem;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.tab-style-4.nav-tabs .nav-link.active:after {
  position: absolute;
  content: "";
  width: 40%;
  height: 1px;
  inset-inline-start: 30%;
  inset-block-end: 0;
  background-color: var(--primary-color);
}

#tab-style-4 .tab-pane.active {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  color: #fff;
}

/* End:: Tab-style-4 */
/* Start:: Tab-style-5 */
.tab-style-5 .nav-item {
  margin-inline-end: 0.5rem;
}

.tab-style-5 .nav-item:last-child {
  margin-inline-end: 0;
}

.tab-style-5 .nav-item .nav-link.active {
  background: var(--primary-color);
}

/* End:: Tab-style-5 */
/* Start:: Tab-style-6 */
.tab-style-6 {
  border: 0;
  background-color: var(--custom-white);
  border-radius: 7px;
  color: var(--primary-color);
  padding: 0.5rem;
}

.tab-style-6 .nav-item {
  border: 0;
  margin-inline-end: 0.5rem;
}

.tab-style-6 .nav-item:last-child {
  margin-inline-end: 0;
}

.tab-style-6 .nav-item .nav-link {
  color: var(--primary-color);
  padding: 0.5rem 1rem;
  font-size: 0.813rem;
  border: 0;
  font-weight: 500;
}

.tab-style-6 .nav-item .nav-link.active {
  border: 0;
  background-color: var(--primary-color);
  color: #fff;
  border: 0;
  box-shadow: 0px 3px 10px 0px rgba(var(--dark-rgb), 0.05);
}

.tab-style-6 .nav-item .nav-link:hover,
.tab-style-6 .nav-item .nav-link:focus {
  border: 0;
}

/* End:: Tab-style-6 */
/* Start:: Tab-style-7 */
.tab-style-7 .nav-link {
  margin-block-end: 0.5rem;
  font-size: 0.813rem;
  color: var(--text-muted);
  text-align: left;
}

.tab-style-7 .nav-link:active,
.tab-style-7 .nav-link.active {
  background-color: var(--primary01);
  color: var(--primary-color);
}

/* End:: Tab-style-7 */
/* Start:: Nav-style-1 */
.nav-style-1 .nav-item .nav-link.active {
  background-color: var(--primary01);
  color: var(--primary-color);
}

/* End:: Nav-style-1 */
@media screen and (max-width: 400px) {
  .tab-style-4 .nav-link {
    padding-inline: 0.5rem;
  }
}

.page-link {
  color: var(--default-text-color);
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
}

.page-link:focus {
  box-shadow: none;
  background-color: rgb(var(--light-rgb));
}

.page-link:hover {
  color: var(--primary-color);
  background-color: rgb(var(--light-rgb));
  border-color: var(--default-border);
}

.page-item.active .page-link {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.disabled>.page-link,
.page-link.disabled {
  color: var(--default-text-color);
  background-color: var(--custom-white);
  border-color: var(--default-border);
  opacity: 0.7;
}

[dir=rtl] .pagination .page-link .bx-chevron-left::before {
  content: "\ea50";
}

[dir=rtl] .pagination .page-link .bx-chevron-right::before {
  content: "\ea4d";
}

[dir=rtl] .pagination .page-link .ri-arrow-right-s-line:before {
  content: "\ea64";
}

[dir=rtl] .pagination .page-link .ri-arrow-left-s-line:before {
  content: "\ea6e";
}

/* Start:: pagination-style-1 */
.pagination-style-1 .pagination .page-item {
  margin: 0 0.25rem;
}

.pagination-style-1 .pagination .page-item .page-link {
  border: 0;
  border-radius: 7px;
  font-size: 0.8125rem;
}

.pagination-style-1 .pagination .page-item .page-link i {
  font-weight: 600;
}

.pagination-style-1 .pagination .page-item.active .page-link {
  border-radius: 7px;
  background-color: var(--primary-color);
  color: #fff;
}

.pagination-style-1 .pagination .page-item.active:hover .page-link {
  border-radius: 7px;
  background-color: var(--primary-color);
  color: #fff;
}

.pagination-style-1 .pagination .page-item:hover .page-link {
  background-color: rgb(var(--light-rgb));
  color: var(--default-text-color);
}

/* End:: pagination-style-1 */
/* Start:: pagination-style-2 */
.pagination-style-2 .pagination {
  border-radius: 7px;
}

.pagination-style-2 .pagination .page-item {
  margin: 0 0.25rem;
}

.pagination-style-2 .pagination .page-item .page-link {
  border: 0 !important;
  font-size: 0.8125rem;
}

.pagination-style-2 .pagination .page-item.active .page-link {
  background-color: var(--custom-white);
  color: var(--primary-color);
  position: relative;
  font-weight: bold;
}

.pagination-style-2 .pagination .page-item.active .page-link:before {
  position: absolute;
  content: "";
  inset-block-end: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 0.1rem;
  background-color: var(--primary-color);
}

.pagination-style-2 .pagination .page-item:hover .page-link {
  background-color: transparent;
}

/* End:: pagination-style-2 */
/* Start:: pagination-sytle-3 */
.pagination-style-3 .pagination {
  border-radius: 50px;
  padding: 0.25rem;
  align-items: center;
}

.pagination-style-3 .pagination .page-item {
  margin: 0 0.25rem;
}

.pagination-style-3 .pagination .page-item .page-link {
  border: 0;
  border-radius: 50px;
  font-size: 0.8125rem;
}

.pagination-style-3 .pagination .page-item .page-link i {
  font-weight: 600;
}

.pagination-style-3 .pagination .page-item.active .page-link {
  background-color: var(--primary-color);
  color: #fff;
}

.pagination-style-3 .pagination .page-item.active:hover .page-link {
  background-color: var(--primary-color);
}

.pagination-style-3 .pagination .page-item:hover .page-link {
  background-color: rgb(var(--light-rgb));
}

/* End:: pagination-sytle-3 */
/* Start:: pagination-style-4 */
.pagination-style-4 .pagination .page-item .page-link {
  border: 0 !important;
  font-size: 0.8125rem;
  border-radius: 7px;
}

.pagination-style-4 .pagination .page-item .page-link i {
  font-weight: 600;
}

.pagination-style-4 .pagination .page-item.active .page-link {
  border: 0;
  border-radius: 7px;
  background-color: var(--primary-color);
  color: #fff;
}

.pagination-style-4 .pagination .page-item.active:hover .page-link {
  background-color: var(--primary-color);
}

.pagination-style-4 .pagination .page-item:hover .page-link {
  background-color: transparent;
}

/* End:: pagination-style-4 */
/* Start::Popovers */
.custom-popover {
  --bs-popover-max-width: 12.5rem;
  --bs-popover-border-color: var(--primary-color);
  --bs-popover-header-bg: var(--primary-color);
  --bs-popover-header-color: #fff;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 0.5rem;
}

/* End::Popovers */
.popover {
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
  box-shadow: 0 0.125rem 0 rgba(10, 10, 10, 0.04);
  font-size: 0.8rem;
  border-radius: 7px;
  z-index: 999;
}

.popover .popover-header {
  background-color: var(--custom-white);
  border-block-end: 1px solid var(--default-border);
}

.popover .popover-body {
  color: var(--default-text-color);
}

.popover.only-body {
  border-radius: 0.3rem;
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.bs-popover-top>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.bs-popover-top>.popover-arrow::before {
  border-top-color: rgb(var(--light-rgb));
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.bs-popover-end>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.bs-popover-end>.popover-arrow::before {
  border-right-color: rgb(var(--light-rgb));
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.bs-popover-start>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.bs-popover-start>.popover-arrow::before {
  border-left-color: rgb(var(--light-rgb));
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.bs-popover-bottom>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.bs-popover-bottom>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgb(var(--light-rgb));
}

.header-primary .popover-header {
  background-color: var(--primary-color);
  color: #fff;
}

.header-primary.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.header-primary.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: var(--primary-color);
}

.header-secondary .popover-header {
  background-color: rgb(var(--secondary-rgb));
  color: #fff;
}

.header-secondary.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.header-secondary.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: rgb(var(--secondary-rgb));
}

.header-warning .popover-header {
  background-color: rgb(var(--warning-rgb));
  color: #fff;
}

.header-warning.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.header-warning.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: rgb(var(--warning-rgb));
}

.header-info .popover-header {
  background-color: rgb(var(--info-rgb));
  color: #fff;
}

.header-info.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.header-info.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: rgb(var(--info-rgb));
}

.header-success .popover-header {
  background-color: rgb(var(--success-rgb));
  color: #fff;
}

.header-success.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.header-success.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: rgb(var(--success-rgb));
}

.header-danger .popover-header {
  background-color: rgb(var(--danger-rgb));
  color: #fff;
}

.header-danger.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.header-danger.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: rgb(var(--danger-rgb));
}

.popover-primary.popover {
  border: 1px solid var(--primary-color);
}

.popover-primary.popover .popover-header {
  background-color: var(--primary-color);
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  border-radius: 0.3rem 0.3rem 0 0;
}

.popover-primary.popover .popover-body {
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 0 0 0.3rem 0.3rem;
}

.popover-primary.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-primary.bs-popover-top>.popover-arrow::after,
.popover-primary.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-primary.bs-popover-top>.popover-arrow::before {
  border-top-color: var(--primary-color);
}

.popover-primary.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-primary.bs-popover-end>.popover-arrow::after,
.popover-primary.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-primary.bs-popover-end>.popover-arrow::before {
  border-right-color: var(--primary-color);
}

.popover-primary.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-primary.bs-popover-start>.popover-arrow::after,
.popover-primary.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-primary.bs-popover-start>.popover-arrow::before {
  border-left-color: var(--primary-color);
}

.popover-primary.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-primary.bs-popover-bottom>.popover-arrow::after,
.popover-primary.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-primary.bs-popover-bottom>.popover-arrow::before,
.popover-primary.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-primary.bs-popover-bottom .popover-header::before {
  border-bottom-color: var(--primary-color);
}

.popover-secondary.popover {
  border: 1px solid rgb(var(--secondary-rgb));
}

.popover-secondary.popover .popover-header {
  background-color: rgb(var(--secondary-rgb));
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  border-radius: 0.3rem 0.3rem 0 0;
}

.popover-secondary.popover .popover-body {
  background-color: rgb(var(--secondary-rgb));
  color: #fff;
  border-radius: 0 0 0.3rem 0.3rem;
}

.popover-secondary.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-secondary.bs-popover-top>.popover-arrow::after,
.popover-secondary.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-secondary.bs-popover-top>.popover-arrow::before {
  border-top-color: rgb(var(--secondary-rgb));
}

.popover-secondary.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-secondary.bs-popover-end>.popover-arrow::after,
.popover-secondary.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-secondary.bs-popover-end>.popover-arrow::before {
  border-right-color: rgb(var(--secondary-rgb));
}

.popover-secondary.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-secondary.bs-popover-start>.popover-arrow::after,
.popover-secondary.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-secondary.bs-popover-start>.popover-arrow::before {
  border-left-color: rgb(var(--secondary-rgb));
}

.popover-secondary.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-secondary.bs-popover-bottom>.popover-arrow::after,
.popover-secondary.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-secondary.bs-popover-bottom>.popover-arrow::before,
.popover-secondary.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-secondary.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgb(var(--secondary-rgb));
}

.popover-warning.popover {
  border: 1px solid rgb(var(--warning-rgb));
}

.popover-warning.popover .popover-header {
  background-color: rgb(var(--warning-rgb));
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  border-radius: 0.3rem 0.3rem 0 0;
}

.popover-warning.popover .popover-body {
  background-color: rgb(var(--warning-rgb));
  color: #fff;
  border-radius: 0 0 0.3rem 0.3rem;
}

.popover-warning.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-warning.bs-popover-top>.popover-arrow::after,
.popover-warning.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-warning.bs-popover-top>.popover-arrow::before {
  border-top-color: rgb(var(--warning-rgb));
}

.popover-warning.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-warning.bs-popover-end>.popover-arrow::after,
.popover-warning.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-warning.bs-popover-end>.popover-arrow::before {
  border-right-color: rgb(var(--warning-rgb));
}

.popover-warning.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-warning.bs-popover-start>.popover-arrow::after,
.popover-warning.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-warning.bs-popover-start>.popover-arrow::before {
  border-left-color: rgb(var(--warning-rgb));
}

.popover-warning.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-warning.bs-popover-bottom>.popover-arrow::after,
.popover-warning.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-warning.bs-popover-bottom>.popover-arrow::before,
.popover-warning.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-warning.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgb(var(--warning-rgb));
}

.popover-info.popover {
  border: 1px solid rgb(var(--info-rgb));
}

.popover-info.popover .popover-header {
  background-color: rgb(var(--info-rgb));
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  border-radius: 0.3rem 0.3rem 0 0;
}

.popover-info.popover .popover-body {
  background-color: rgb(var(--info-rgb));
  color: #fff;
  border-radius: 0 0 0.3rem 0.3rem;
}

.popover-info.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-info.bs-popover-top>.popover-arrow::after,
.popover-info.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-info.bs-popover-top>.popover-arrow::before {
  border-top-color: rgb(var(--info-rgb));
}

.popover-info.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-info.bs-popover-end>.popover-arrow::after,
.popover-info.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-info.bs-popover-end>.popover-arrow::before {
  border-right-color: rgb(var(--info-rgb));
}

.popover-info.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-info.bs-popover-start>.popover-arrow::after,
.popover-info.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-info.bs-popover-start>.popover-arrow::before {
  border-left-color: rgb(var(--info-rgb));
}

.popover-info.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-info.bs-popover-bottom>.popover-arrow::after,
.popover-info.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-info.bs-popover-bottom>.popover-arrow::before,
.popover-info.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-info.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgb(var(--info-rgb));
}

.popover-success.popover {
  border: 1px solid rgb(var(--success-rgb));
}

.popover-success.popover .popover-header {
  background-color: rgb(var(--success-rgb));
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  border-radius: 0.3rem 0.3rem 0 0;
}

.popover-success.popover .popover-body {
  background-color: rgb(var(--success-rgb));
  color: #fff;
  border-radius: 0 0 0.3rem 0.3rem;
}

.popover-success.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-success.bs-popover-top>.popover-arrow::after,
.popover-success.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-success.bs-popover-top>.popover-arrow::before {
  border-top-color: rgb(var(--success-rgb));
}

.popover-success.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-success.bs-popover-end>.popover-arrow::after,
.popover-success.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-success.bs-popover-end>.popover-arrow::before {
  border-right-color: rgb(var(--success-rgb));
}

.popover-success.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-success.bs-popover-start>.popover-arrow::after,
.popover-success.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-success.bs-popover-start>.popover-arrow::before {
  border-left-color: rgb(var(--success-rgb));
}

.popover-success.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-success.bs-popover-bottom>.popover-arrow::after,
.popover-success.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-success.bs-popover-bottom>.popover-arrow::before,
.popover-success.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-success.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgb(var(--success-rgb));
}

.popover-danger.popover {
  border: 1px solid rgb(var(--danger-rgb));
}

.popover-danger.popover .popover-header {
  background-color: rgb(var(--danger-rgb));
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  border-radius: 0.3rem 0.3rem 0 0;
}

.popover-danger.popover .popover-body {
  background-color: rgb(var(--danger-rgb));
  color: #fff;
  border-radius: 0 0 0.3rem 0.3rem;
}

.popover-danger.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-danger.bs-popover-top>.popover-arrow::after,
.popover-danger.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-danger.bs-popover-top>.popover-arrow::before {
  border-top-color: rgb(var(--danger-rgb));
}

.popover-danger.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-danger.bs-popover-end>.popover-arrow::after,
.popover-danger.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-danger.bs-popover-end>.popover-arrow::before {
  border-right-color: rgb(var(--danger-rgb));
}

.popover-danger.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-danger.bs-popover-start>.popover-arrow::after,
.popover-danger.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-danger.bs-popover-start>.popover-arrow::before {
  border-left-color: rgb(var(--danger-rgb));
}

.popover-danger.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-danger.bs-popover-bottom>.popover-arrow::after,
.popover-danger.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-danger.bs-popover-bottom>.popover-arrow::before,
.popover-danger.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-danger.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgb(var(--danger-rgb));
}

.popover-purple.popover {
  border: 1px solid rgb(var(--purple-rgb));
}

.popover-purple.popover .popover-header {
  background-color: rgb(var(--purple-rgb));
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  border-radius: 0.3rem 0.3rem 0 0;
}

.popover-purple.popover .popover-body {
  background-color: rgb(var(--purple-rgb));
  color: #fff;
  border-radius: 0 0 0.3rem 0.3rem;
}

.popover-purple.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-purple.bs-popover-top>.popover-arrow::after,
.popover-purple.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-purple.bs-popover-top>.popover-arrow::before {
  border-top-color: rgb(var(--purple-rgb));
}

.popover-purple.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-purple.bs-popover-end>.popover-arrow::after,
.popover-purple.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-purple.bs-popover-end>.popover-arrow::before {
  border-right-color: rgb(var(--purple-rgb));
}

.popover-purple.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-purple.bs-popover-start>.popover-arrow::after,
.popover-purple.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-purple.bs-popover-start>.popover-arrow::before {
  border-left-color: rgb(var(--purple-rgb));
}

.popover-purple.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-purple.bs-popover-bottom>.popover-arrow::after,
.popover-purple.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-purple.bs-popover-bottom>.popover-arrow::before,
.popover-purple.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-purple.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgb(var(--purple-rgb));
}

.popover-teal.popover {
  border: 1px solid rgb(var(--teal-rgb));
}

.popover-teal.popover .popover-header {
  background-color: rgb(var(--teal-rgb));
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  border-radius: 0.3rem 0.3rem 0 0;
}

.popover-teal.popover .popover-body {
  background-color: rgb(var(--teal-rgb));
  color: #fff;
  border-radius: 0 0 0.3rem 0.3rem;
}

.popover-teal.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-teal.bs-popover-top>.popover-arrow::after,
.popover-teal.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-teal.bs-popover-top>.popover-arrow::before {
  border-top-color: rgb(var(--teal-rgb));
}

.popover-teal.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-teal.bs-popover-end>.popover-arrow::after,
.popover-teal.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-teal.bs-popover-end>.popover-arrow::before {
  border-right-color: rgb(var(--teal-rgb));
}

.popover-teal.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-teal.bs-popover-start>.popover-arrow::after,
.popover-teal.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-teal.bs-popover-start>.popover-arrow::before {
  border-left-color: rgb(var(--teal-rgb));
}

.popover-teal.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-teal.bs-popover-bottom>.popover-arrow::after,
.popover-teal.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-teal.bs-popover-bottom>.popover-arrow::before,
.popover-teal.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-teal.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgb(var(--teal-rgb));
}

.popover-primary-light.popover {
  border: 1px solid var(--primary01);
}

.popover-primary-light.popover .popover-header {
  background-color: var(--primary01);
  border-block-end: 1px solid var(--primary01);
  color: var(--primary-color);
  border-radius: 0;
}

.popover-primary-light.popover .popover-body {
  background-color: var(--primary01);
  color: var(--primary-color);
}

.popover-primary-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-primary-light.bs-popover-top>.popover-arrow::after,
.popover-primary-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-primary-light.bs-popover-top>.popover-arrow::before {
  border-top-color: var(--primary01);
}

.popover-primary-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-primary-light.bs-popover-end>.popover-arrow::after,
.popover-primary-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-primary-light.bs-popover-end>.popover-arrow::before {
  border-right-color: var(--primary01);
}

.popover-primary-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-primary-light.bs-popover-start>.popover-arrow::after,
.popover-primary-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-primary-light.bs-popover-start>.popover-arrow::before {
  border-left-color: var(--primary01);
}

.popover-primary-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-primary-light.bs-popover-bottom>.popover-arrow::after,
.popover-primary-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-primary-light.bs-popover-bottom>.popover-arrow::before,
.popover-primary-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-primary-light.bs-popover-bottom .popover-header::before {
  border-bottom-color: var(--primary01);
}

.popover-secondary-light.popover {
  border: 1px solid rgba(var(--secondary-rgb), 0.1);
}

.popover-secondary-light.popover .popover-header {
  background-color: rgba(var(--secondary-rgb), 0.1);
  border-block-end: 1px solid rgba(var(--secondary-rgb), 0.1);
  color: rgb(var(--secondary-rgb));
  border-radius: 0;
}

.popover-secondary-light.popover .popover-body {
  background-color: rgba(var(--secondary-rgb), 0.1);
  color: rgb(var(--secondary-rgb));
}

.popover-secondary-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-secondary-light.bs-popover-top>.popover-arrow::after,
.popover-secondary-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-secondary-light.bs-popover-top>.popover-arrow::before {
  border-top-color: rgba(var(--secondary-rgb), 0.1);
}

.popover-secondary-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-secondary-light.bs-popover-end>.popover-arrow::after,
.popover-secondary-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-secondary-light.bs-popover-end>.popover-arrow::before {
  border-right-color: rgba(var(--secondary-rgb), 0.1);
}

.popover-secondary-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-secondary-light.bs-popover-start>.popover-arrow::after,
.popover-secondary-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-secondary-light.bs-popover-start>.popover-arrow::before {
  border-left-color: rgba(var(--secondary-rgb), 0.1);
}

.popover-secondary-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-secondary-light.bs-popover-bottom>.popover-arrow::after,
.popover-secondary-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-secondary-light.bs-popover-bottom>.popover-arrow::before,
.popover-secondary-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-secondary-light.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgba(var(--secondary-rgb), 0.1);
}

.popover-warning-light.popover {
  border: 1px solid rgba(var(--warning-rgb), 0.1);
}

.popover-warning-light.popover .popover-header {
  background-color: rgba(var(--warning-rgb), 0.1);
  border-block-end: 1px solid rgba(var(--warning-rgb), 0.1);
  color: rgb(var(--warning-rgb));
  border-radius: 0;
}

.popover-warning-light.popover .popover-body {
  background-color: rgba(var(--warning-rgb), 0.1);
  color: rgb(var(--warning-rgb));
}

.popover-warning-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-warning-light.bs-popover-top>.popover-arrow::after,
.popover-warning-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-warning-light.bs-popover-top>.popover-arrow::before {
  border-top-color: rgba(var(--warning-rgb), 0.1);
}

.popover-warning-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-warning-light.bs-popover-end>.popover-arrow::after,
.popover-warning-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-warning-light.bs-popover-end>.popover-arrow::before {
  border-right-color: rgba(var(--warning-rgb), 0.1);
}

.popover-warning-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-warning-light.bs-popover-start>.popover-arrow::after,
.popover-warning-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-warning-light.bs-popover-start>.popover-arrow::before {
  border-left-color: rgba(var(--warning-rgb), 0.1);
}

.popover-warning-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-warning-light.bs-popover-bottom>.popover-arrow::after,
.popover-warning-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-warning-light.bs-popover-bottom>.popover-arrow::before,
.popover-warning-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-warning-light.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgba(var(--warning-rgb), 0.1);
}

.popover-info-light.popover {
  border: 1px solid rgba(var(--info-rgb), 0.1);
}

.popover-info-light.popover .popover-header {
  background-color: rgba(var(--info-rgb), 0.1);
  border-block-end: 1px solid rgba(var(--info-rgb), 0.1);
  color: rgb(var(--info-rgb));
  border-radius: 0;
}

.popover-info-light.popover .popover-body {
  background-color: rgba(var(--info-rgb), 0.1);
  color: rgb(var(--info-rgb));
}

.popover-info-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-info-light.bs-popover-top>.popover-arrow::after,
.popover-info-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-info-light.bs-popover-top>.popover-arrow::before {
  border-top-color: rgba(var(--info-rgb), 0.1);
}

.popover-info-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-info-light.bs-popover-end>.popover-arrow::after,
.popover-info-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-info-light.bs-popover-end>.popover-arrow::before {
  border-right-color: rgba(var(--info-rgb), 0.1);
}

.popover-info-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-info-light.bs-popover-start>.popover-arrow::after,
.popover-info-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-info-light.bs-popover-start>.popover-arrow::before {
  border-left-color: rgba(var(--info-rgb), 0.1);
}

.popover-info-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-info-light.bs-popover-bottom>.popover-arrow::after,
.popover-info-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-info-light.bs-popover-bottom>.popover-arrow::before,
.popover-info-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-info-light.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgba(var(--info-rgb), 0.1);
}

.popover-success-light.popover {
  border: 1px solid rgba(var(--success-rgb), 0.1);
}

.popover-success-light.popover .popover-header {
  background-color: rgba(var(--success-rgb), 0.1);
  border-block-end: 1px solid rgba(var(--success-rgb), 0.1);
  color: rgb(var(--success-rgb));
  border-radius: 0;
}

.popover-success-light.popover .popover-body {
  background-color: rgba(var(--success-rgb), 0.1);
  color: rgb(var(--success-rgb));
}

.popover-success-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-success-light.bs-popover-top>.popover-arrow::after,
.popover-success-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-success-light.bs-popover-top>.popover-arrow::before {
  border-top-color: rgba(var(--success-rgb), 0.1);
}

.popover-success-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-success-light.bs-popover-end>.popover-arrow::after,
.popover-success-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-success-light.bs-popover-end>.popover-arrow::before {
  border-right-color: rgba(var(--success-rgb), 0.1);
}

.popover-success-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-success-light.bs-popover-start>.popover-arrow::after,
.popover-success-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-success-light.bs-popover-start>.popover-arrow::before {
  border-left-color: rgba(var(--success-rgb), 0.1);
}

.popover-success-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-success-light.bs-popover-bottom>.popover-arrow::after,
.popover-success-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-success-light.bs-popover-bottom>.popover-arrow::before,
.popover-success-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-success-light.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgba(var(--success-rgb), 0.1);
}

.popover-danger-light.popover {
  border: 1px solid rgba(var(--danger-rgb), 0.1);
}

.popover-danger-light.popover .popover-header {
  background-color: rgba(var(--danger-rgb), 0.1);
  border-block-end: 1px solid rgba(var(--danger-rgb), 0.1);
  color: rgb(var(--danger-rgb));
  border-radius: 0;
}

.popover-danger-light.popover .popover-body {
  background-color: rgba(var(--danger-rgb), 0.1);
  color: rgb(var(--danger-rgb));
}

.popover-danger-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-danger-light.bs-popover-top>.popover-arrow::after,
.popover-danger-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-danger-light.bs-popover-top>.popover-arrow::before {
  border-top-color: rgba(var(--danger-rgb), 0.1);
}

.popover-danger-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-danger-light.bs-popover-end>.popover-arrow::after,
.popover-danger-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-danger-light.bs-popover-end>.popover-arrow::before {
  border-right-color: rgba(var(--danger-rgb), 0.1);
}

.popover-danger-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-danger-light.bs-popover-start>.popover-arrow::after,
.popover-danger-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-danger-light.bs-popover-start>.popover-arrow::before {
  border-left-color: rgba(var(--danger-rgb), 0.1);
}

.popover-danger-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-danger-light.bs-popover-bottom>.popover-arrow::after,
.popover-danger-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-danger-light.bs-popover-bottom>.popover-arrow::before,
.popover-danger-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-danger-light.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgba(var(--danger-rgb), 0.1);
}

.popover-purple-light.popover {
  border: 1px solid rgba(var(--purple-rgb), 0.1);
}

.popover-purple-light.popover .popover-header {
  background-color: rgba(var(--purple-rgb), 0.1);
  border-block-end: 1px solid rgba(var(--purple-rgb), 0.1);
  color: rgb(var(--purple-rgb));
  border-radius: 0;
}

.popover-purple-light.popover .popover-body {
  background-color: rgba(var(--purple-rgb), 0.1);
  color: rgb(var(--purple-rgb));
}

.popover-purple-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-purple-light.bs-popover-top>.popover-arrow::after,
.popover-purple-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-purple-light.bs-popover-top>.popover-arrow::before {
  border-top-color: rgba(var(--purple-rgb), 0.1);
}

.popover-purple-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-purple-light.bs-popover-end>.popover-arrow::after,
.popover-purple-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-purple-light.bs-popover-end>.popover-arrow::before {
  border-right-color: rgba(var(--purple-rgb), 0.1);
}

.popover-purple-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-purple-light.bs-popover-start>.popover-arrow::after,
.popover-purple-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-purple-light.bs-popover-start>.popover-arrow::before {
  border-left-color: rgba(var(--purple-rgb), 0.1);
}

.popover-purple-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-purple-light.bs-popover-bottom>.popover-arrow::after,
.popover-purple-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-purple-light.bs-popover-bottom>.popover-arrow::before,
.popover-purple-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-purple-light.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgba(var(--purple-rgb), 0.1);
}

.popover-teal-light.popover {
  border: 1px solid rgba(var(--teal-rgb), 0.1);
}

.popover-teal-light.popover .popover-header {
  background-color: rgba(var(--teal-rgb), 0.1);
  border-block-end: 1px solid rgba(var(--teal-rgb), 0.1);
  color: rgb(var(--teal-rgb));
  border-radius: 0;
}

.popover-teal-light.popover .popover-body {
  background-color: rgba(var(--teal-rgb), 0.1);
  color: rgb(var(--teal-rgb));
}

.popover-teal-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.popover-teal-light.bs-popover-top>.popover-arrow::after,
.popover-teal-light.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,
.popover-teal-light.bs-popover-top>.popover-arrow::before {
  border-top-color: rgba(var(--teal-rgb), 0.1);
}

.popover-teal-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.popover-teal-light.bs-popover-end>.popover-arrow::after,
.popover-teal-light.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.popover-teal-light.bs-popover-end>.popover-arrow::before {
  border-right-color: rgba(var(--teal-rgb), 0.1);
}

.popover-teal-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.popover-teal-light.bs-popover-start>.popover-arrow::after,
.popover-teal-light.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.popover-teal-light.bs-popover-start>.popover-arrow::before {
  border-left-color: rgba(var(--teal-rgb), 0.1);
}

.popover-teal-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.popover-teal-light.bs-popover-bottom>.popover-arrow::after,
.popover-teal-light.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,
.popover-teal-light.bs-popover-bottom>.popover-arrow::before,
.popover-teal-light.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,
.popover-teal-light.bs-popover-bottom .popover-header::before {
  border-bottom-color: rgba(var(--teal-rgb), 0.1);
}

.progress {
  background-color: rgb(var(--light-rgb));
  height: 0.75rem;
  border-radius: 0.25rem;
}

.progress-stacked {
  background-color: rgb(var(--light-rgb));
}

.progress-bar {
  color: #fff;
  background-color: var(--primary-color);
}

@keyframes progress-animate {
  0% {
    width: 0;
  }
}

.progress-animate {
  position: relative;
  border-radius: 0.625rem;
}

.progress-animate .progress-bar {
  position: relative;
  border-radius: 0.625rem;
  animation: 2s progress-animate;
}

/* Start:: progressbar-height */
.progress.progress-xs,
.progress-stacked.progress-xs {
  height: 0.3125rem;
}

.progress.progress-sm,
.progress-stacked.progress-sm {
  height: 0.5rem;
}

.progress.progress-lg,
.progress-stacked.progress-lg {
  height: 1rem;
}

.progress.progress-xl,
.progress-stacked.progress-xl {
  height: 1.25rem;
}

/* End:: progressbar-height */
/* Start:: custom-progress */
.progress-custom {
  overflow: visible;
  position: relative;
}

.progress-custom .progress-bar {
  position: relative;
  overflow: visible;
}

.progress-custom .progress-bar:after {
  content: "";
  width: 1.15rem;
  height: 1.15rem;
  border-radius: 50%;
  box-shadow: 0 0.313rem 0.313rem var(--primary02);
  background: #fff;
  position: absolute;
  inset-inline-end: -0.375rem;
  inset-block-start: -0.375rem;
  border: 0.25rem solid var(--primary-color);
}

.progress-custom .progress-bar.bg-secondary:after {
  border: 0.25rem solid rgb(var(--secondary-rgb));
  box-shadow: 0 0.313rem 0.313rem rgba(var(--secondary-rgb), 0.2);
}

.progress-custom .progress-bar.bg-warning:after {
  border: 0.25rem solid rgb(var(--warning-rgb));
  box-shadow: 0 0.313rem 0.313rem rgba(var(--warning-rgb), 0.2);
}

.progress-custom .progress-bar.bg-info:after {
  border: 0.25rem solid rgb(var(--info-rgb));
  box-shadow: 0 0.313rem 0.313rem rgba(var(--info-rgb), 0.2);
}

.progress-custom .progress-bar.bg-success:after {
  border: 0.25rem solid rgb(var(--success-rgb));
  box-shadow: 0 0.313rem 0.313rem rgba(var(--success-rgb), 0.2);
}

.progress-custom .progress-bar.bg-danger:after {
  border: 0.25rem solid rgb(var(--danger-rgb));
  box-shadow: 0 0.313rem 0.313rem rgba(var(--danger-rgb), 0.2);
}

.progress-custom .progress-bar-title {
  padding: 0.313rem 0.625rem;
  margin: 0;
  background: var(--primary-color);
  border-radius: 0.313rem 0 0 0.313rem;
  font-size: 0.625rem;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  position: absolute;
  inset-block-start: -0.438rem;
  inset-inline-start: 0;
  z-index: 1;
}

.progress-custom .progress-bar-title:after {
  content: "";
  border-inline-start: 1.063rem solid var(--primary-color);
  border-block-start: 0.688rem solid transparent;
  border-block-end: 0.688rem solid transparent;
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: -1.063rem;
}

.progress-custom .progress-bar-title.bg-secondary {
  background-color: rgb(var(--secondary-rgb));
}

.progress-custom .progress-bar-title.bg-secondary:after {
  border-inline-start: 1.063rem solid rgb(var(--secondary-rgb));
}

.progress-custom .progress-bar-title.bg-success {
  background-color: rgb(var(--success-rgb));
}

.progress-custom .progress-bar-title.bg-success:after {
  border-inline-start: 1.063rem solid rgb(var(--success-rgb));
}

.progress-custom .progress-bar-title.bg-warning {
  background-color: rgb(var(--warning-rgb));
}

.progress-custom .progress-bar-title.bg-warning:after {
  border-inline-start: 1.063rem solid rgb(var(--warning-rgb));
}

.progress-custom .progress-bar-title.bg-danger {
  background-color: rgb(var(--danger-rgb));
}

.progress-custom .progress-bar-title.bg-danger:after {
  border-inline-start: 1.063rem solid rgb(var(--danger-rgb));
}

.progress-custom .progress-bar-title.bg-info {
  background-color: rgb(var(--info-rgb));
}

.progress-custom .progress-bar-title.bg-info:after {
  border-inline-start: 1.063rem solid rgb(var(--info-rgb));
}

.progress-custom .progress-bar-value {
  width: 1.875rem;
  height: 1.25rem;
  line-height: 1.25rem;
  border-radius: 0.188rem;
  background: var(--primary-color);
  box-shadow: 0 0.313rem 0.313rem rgba(0, 0, 0, 0.4);
  font-size: 0.625rem;
  font-weight: 600;
  color: #fff;
  text-align: center;
  position: absolute;
  inset-block-end: 1.5rem;
  inset-inline-end: -0.688rem;
}

.progress-custom .progress-bar-value:after {
  content: "";
  border-block-start: 0.438rem solid var(--primary-color);
  border-inline-start: 0.438rem solid transparent;
  border-inline-end: 0.438rem solid transparent;
  position: absolute;
  inset-block-end: -0.375rem;
  inset-inline-start: 28%;
}

.progress-custom .progress-bar-value.bg-secondary {
  background-color: rgb(var(--secondary-rgb));
}

.progress-custom .progress-bar-value.bg-secondary:after {
  border-block-start: 0.438rem solid rgb(var(--secondary-rgb));
}

.progress-custom .progress-bar-value.bg-success {
  background-color: rgb(var(--success-rgb));
}

.progress-custom .progress-bar-value.bg-success:after {
  border-block-start: 0.438rem solid rgb(var(--success-rgb));
}

.progress-custom .progress-bar-value.bg-warning {
  background-color: rgb(var(--warning-rgb));
}

.progress-custom .progress-bar-value.bg-warning:after {
  border-block-start: 0.438rem solid rgb(var(--warning-rgb));
}

.progress-custom .progress-bar-value.bg-danger {
  background-color: rgb(var(--danger-rgb));
}

.progress-custom .progress-bar-value.bg-danger:after {
  border-block-start: 0.438rem solid rgb(var(--danger-rgb));
}

.progress-custom .progress-bar-value.bg-info {
  background-color: rgb(var(--info-rgb));
}

.progress-custom .progress-bar-value.bg-info:after {
  border-block-start: 0.438rem solid rgb(var(--info-rgb));
}

[dir=rtl] .progress-bar-title {
  border-radius: 0 0.313rem 0.313rem 0;
}

/* End:: custom-progress */
/* Start:: custom-progress-2 */
.progress-item-1,
.progress-item-2,
.progress-item-3 {
  position: absolute;
  margin-block-start: -0.25rem;
  z-index: 1;
  height: 0.938rem;
  width: 0.938rem;
  border-radius: 1.563rem;
  background-color: rgb(var(--light-rgb));
}

.progress-item-1 {
  inset-inline-start: 25%;
}

.progress-item-2 {
  inset-inline-start: 50%;
}

.progress-item-3 {
  inset-inline-start: 75%;
}

/* End:: custom-progress-2 */
/* Start:: custom-progress-3 */
.custom-progress-3 {
  overflow: visible;
  border-radius: 0.625rem;
}

.custom-progress-3 .progress-bar {
  position: relative;
  overflow: visible;
  margin: 0.313rem;
  border-radius: 0.25rem;
}

.custom-progress-3 .progress-bar:before {
  content: "";
  position: absolute;
  width: 0.25rem;
  height: 0.25rem;
  inset-inline-start: 0.125rem;
  background-color: var(--custom-white);
  border-radius: 50%;
}

.custom-progress-3 .progress-bar .progress-bar-value {
  border: 0.125rem solid var(--primary-color);
  width: 2.5rem;
  height: 1.563rem;
  line-height: 1.313rem;
  border-radius: 3.125rem;
  background: var(--custom-white);
  font-size: 0.625rem;
  font-weight: 600;
  color: var(--primary-color);
  text-align: center;
  position: absolute;
  inset-inline-end: -0.625rem;
}

.custom-progress-3 .progress-bar .progress-bar-value.secondary {
  border: 0.125rem solid rgb(var(--secondary-rgb));
  color: rgb(var(--secondary-rgb));
}

.custom-progress-3 .progress-bar .progress-bar-value.warning {
  border: 0.125rem solid rgb(var(--warning-rgb));
  color: rgb(var(--warning-rgb));
}

.custom-progress-3 .progress-bar .progress-bar-value.info {
  border: 0.125rem solid rgb(var(--info-rgb));
  color: rgb(var(--info-rgb));
}

.custom-progress-3 .progress-bar .progress-bar-value.success {
  border: 0.125rem solid rgb(var(--success-rgb));
  color: rgb(var(--success-rgb));
}

.custom-progress-3 .progress-bar .progress-bar-value.danger {
  border: 0.125rem solid rgb(var(--danger-rgb));
  color: rgb(var(--danger-rgb));
}

/* End:: custom-progress-3 */
/* Start:: custom-progress-4 */
.custom-progress-4.progress {
  background-color: var(--primary01);
  border-radius: 0.25rem;
}

.custom-progress-4.progress.secondary {
  background-color: rgba(var(--secondary-rgb), 0.1);
}

.custom-progress-4.progress.secondary .progress-bar-label {
  color: rgb(var(--secondary-rgb));
}

.custom-progress-4.progress.warning {
  background-color: rgba(var(--warning-rgb), 0.1);
}

.custom-progress-4.progress.warning .progress-bar-label {
  color: rgb(var(--warning-rgb));
}

.custom-progress-4.progress.info {
  background-color: rgba(var(--info-rgb), 0.1);
}

.custom-progress-4.progress.info .progress-bar-label {
  color: rgb(var(--info-rgb));
}

.custom-progress-4.progress.success {
  background-color: rgba(var(--success-rgb), 0.1);
}

.custom-progress-4.progress.success .progress-bar-label {
  color: rgb(var(--success-rgb));
}

.custom-progress-4.progress.danger {
  background-color: rgba(var(--danger-rgb), 0.1);
}

.custom-progress-4.progress.danger .progress-bar-label {
  color: rgb(var(--danger-rgb));
}

.custom-progress-4.progress .progress-bar {
  border-radius: 0.25rem;
}

.custom-progress-4.progress .progress-bar-label {
  position: absolute;
  inset-inline-end: 0.313rem;
  font-weight: 600;
  font-size: 0.813rem;
  color: var(--primary-color);
}

/* End:: custom-progress-4 */
caption {
  color: var(--text-muted);
}

.table-active {
  --bs-table-color-state: var(--default-text-color);
}

.table {
  color: var(--default-text-color);
  border-color: var(--default-border);
  margin-block-end: 0;
}

.table tbody tr th {
  font-weight: 500;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  line-height: 1.462;
  font-size: 0.813rem;
}

.table thead tr th {
  font-weight: 600;
  font-size: 0.85rem;
}

.table.table-sm> :not(caption)>*>* {
  padding: 0.3rem;
}

.table.table-dark {
  color: var(--white-7);
  border-color: rgba(255, 255, 255, 0.1);
}

.table.table-primary {
  background-color: #fff;
  color: #000;
  --bs-table-color-state: $black;
  border-color: var(--bs-table-border-color);
  --bs-table-bg: rgba(var(--primary-rgb), 0.1);
  --bs-table-border-color: rgba(var(--primary-rgb), 0.05);
  --bs-table-striped-bg: rgba(var(--primary-rgb), 0.2);
  --bs-table-active-bg: rgba(var(--primary-rgb), 0.2);
  --bs-table-hover-bg: rgba(var(--primary-rgb), 0.2);
}

.table.table-primary.table-striped>tbody>tr:nth-of-type(odd)>* {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--primary-rgb), 0.2);
  color: #000;
}

.table.table-primary.table.table-hover>tbody>tr:hover>* {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--primary-rgb), 0.4);
  color: #fff;
}

.table.table-primary .table-active {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgb(var(--primary-rgb));
  color: #fff;
}

.table.table-secondary {
  background-color: #fff;
  color: #000;
  --bs-table-color-state: $black;
  border-color: var(--bs-table-border-color);
  --bs-table-bg: rgba(var(--secondary-rgb), 0.1);
  --bs-table-border-color: rgba(var(--secondary-rgb), 0.05);
  --bs-table-striped-bg: rgba(var(--secondary-rgb), 0.2);
  --bs-table-active-bg: rgba(var(--secondary-rgb), 0.2);
  --bs-table-hover-bg: rgba(var(--secondary-rgb), 0.2);
}

.table.table-secondary.table-striped>tbody>tr:nth-of-type(odd)>* {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--secondary-rgb), 0.2);
  color: #000;
}

.table.table-secondary.table.table-hover>tbody>tr:hover>* {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--secondary-rgb), 0.4);
  color: #fff;
}

.table.table-secondary .table-active {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgb(var(--secondary-rgb));
  color: #fff;
}

.table.table-warning {
  background-color: #fff;
  color: #000;
  --bs-table-color-state: $black;
  border-color: var(--bs-table-border-color);
  --bs-table-bg: rgba(var(--warning-rgb), 0.1);
  --bs-table-border-color: rgba(var(--warning-rgb), 0.05);
  --bs-table-striped-bg: rgba(var(--warning-rgb), 0.2);
  --bs-table-active-bg: rgba(var(--warning-rgb), 0.2);
  --bs-table-hover-bg: rgba(var(--warning-rgb), 0.2);
}

.table.table-warning.table-striped>tbody>tr:nth-of-type(odd)>* {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--warning-rgb), 0.2);
  color: #000;
}

.table.table-warning.table.table-hover>tbody>tr:hover>* {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--warning-rgb), 0.4);
  color: #fff;
}

.table.table-warning .table-active {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgb(var(--warning-rgb));
  color: #fff;
}

.table.table-success {
  background-color: #fff;
  color: #000;
  --bs-table-color-state: $black;
  border-color: var(--bs-table-border-color);
  --bs-table-bg: rgba(var(--success-rgb), 0.1);
  --bs-table-border-color: rgba(var(--success-rgb), 0.05);
  --bs-table-striped-bg: rgba(var(--success-rgb), 0.2);
  --bs-table-active-bg: rgba(var(--success-rgb), 0.2);
  --bs-table-hover-bg: rgba(var(--success-rgb), 0.2);
}

.table.table-success.table-striped>tbody>tr:nth-of-type(odd)>* {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--success-rgb), 0.2);
  color: #000;
}

.table.table-success.table.table-hover>tbody>tr:hover>* {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--success-rgb), 0.4);
  color: #fff;
}

.table.table-success .table-active {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgb(var(--success-rgb));
  color: #fff;
}

.table.table-danger {
  background-color: #fff;
  color: #000;
  --bs-table-color-state: $black;
  border-color: var(--bs-table-border-color);
  --bs-table-bg: rgba(var(--danger-rgb), 0.1);
  --bs-table-border-color: rgba(var(--danger-rgb), 0.05);
  --bs-table-striped-bg: rgba(var(--danger-rgb), 0.2);
  --bs-table-active-bg: rgba(var(--danger-rgb), 0.2);
  --bs-table-hover-bg: rgba(var(--danger-rgb), 0.2);
}

.table.table-danger.table-striped>tbody>tr:nth-of-type(odd)>* {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--danger-rgb), 0.2);
  color: #000;
}

.table.table-danger.table.table-hover>tbody>tr:hover>* {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--danger-rgb), 0.4);
  color: #fff;
}

.table.table-danger .table-active {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgb(var(--danger-rgb));
  color: #fff;
}

.table.table-info {
  background-color: #fff;
  color: #000;
  --bs-table-color-state: $black;
  border-color: var(--bs-table-border-color);
  --bs-table-bg: rgba(var(--info-rgb), 0.1);
  --bs-table-border-color: rgba(var(--info-rgb), 0.05);
  --bs-table-striped-bg: rgba(var(--info-rgb), 0.2);
  --bs-table-active-bg: rgba(var(--info-rgb), 0.2);
  --bs-table-hover-bg: rgba(var(--info-rgb), 0.2);
}

.table.table-info.table-striped>tbody>tr:nth-of-type(odd)>* {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--info-rgb), 0.2);
  color: #000;
}

.table.table-info.table.table-hover>tbody>tr:hover>* {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--info-rgb), 0.4);
  color: #fff;
}

.table.table-info .table-active {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgb(var(--info-rgb));
  color: #fff;
}

.table.table-light {
  background-color: #fff;
  color: #000;
  --bs-table-color-state: $black;
  border-color: var(--bs-table-border-color);
  --bs-table-bg: rgba(var(--light-rgb), 0.1);
  --bs-table-border-color: rgba(var(--light-rgb), 0.05);
  --bs-table-striped-bg: rgba(var(--light-rgb), 0.2);
  --bs-table-active-bg: rgba(var(--light-rgb), 0.2);
  --bs-table-hover-bg: rgba(var(--light-rgb), 0.2);
}

.table.table-light.table-striped>tbody>tr:nth-of-type(odd)>* {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--light-rgb), 0.2);
  color: #000;
}

.table.table-light.table.table-hover>tbody>tr:hover>* {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--light-rgb), 0.4);
  color: #fff;
}

.table.table-light .table-active {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgb(var(--light-rgb));
  color: #fff;
}

.table.table-dark {
  background-color: #fff;
  color: #000;
  --bs-table-color-state: $black;
  border-color: var(--bs-table-border-color);
  --bs-table-bg: rgba(var(--dark-rgb), 0.1);
  --bs-table-border-color: rgba(var(--dark-rgb), 0.05);
  --bs-table-striped-bg: rgba(var(--dark-rgb), 0.2);
  --bs-table-active-bg: rgba(var(--dark-rgb), 0.2);
  --bs-table-hover-bg: rgba(var(--dark-rgb), 0.2);
}

.table.table-dark.table-striped>tbody>tr:nth-of-type(odd)>* {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--dark-rgb), 0.2);
  color: #000;
}

.table.table-dark.table.table-hover>tbody>tr:hover>* {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgba(var(--dark-rgb), 0.4);
  color: #fff;
}

.table.table-dark .table-active {
  background-color: var(--bs-table-accent-bg);
  --bs-table-accent-bg: rgb(var(--dark-rgb));
  color: #fff;
}

.table.table-bordered.border-primary tbody,
.table.table-bordered.border-primary td,
.table.table-bordered.border-primary tfoot,
.table.table-bordered.border-primary th,
.table.table-bordered.border-primary thead,
.table.table-bordered.border-primary tr {
  border-color: rgba(var(--primary-rgb), 0.1);
}

.table.table-bordered.border-info tbody,
.table.table-bordered.border-info td,
.table.table-bordered.border-info tfoot,
.table.table-bordered.border-info th,
.table.table-bordered.border-info thead,
.table.table-bordered.border-info tr {
  border-color: rgba(var(--info-rgb), 0.1);
}

.table.table-bordered.border-secondary tbody,
.table.table-bordered.border-secondary td,
.table.table-bordered.border-secondary tfoot,
.table.table-bordered.border-secondary th,
.table.table-bordered.border-secondary thead,
.table.table-bordered.border-secondary tr {
  border-color: rgba(var(--secondary-rgb), 0.1);
}

.table.table-bordered.border-warning tbody,
.table.table-bordered.border-warning td,
.table.table-bordered.border-warning tfoot,
.table.table-bordered.border-warning th,
.table.table-bordered.border-warning thead,
.table.table-bordered.border-warning tr {
  border-color: rgba(var(--warning-rgb), 0.1);
}

.table.table-bordered.border-success tbody,
.table.table-bordered.border-success td,
.table.table-bordered.border-success tfoot,
.table.table-bordered.border-success th,
.table.table-bordered.border-success thead,
.table.table-bordered.border-success tr {
  border-color: rgba(var(--success-rgb), 0.1);
}

.table.table-bordered.border-danger tbody,
.table.table-bordered.border-danger td,
.table.table-bordered.border-danger tfoot,
.table.table-bordered.border-danger th,
.table.table-bordered.border-danger thead,
.table.table-bordered.border-danger tr {
  border-color: rgba(var(--danger-rgb), 0.1);
}

.table.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: rgba(var(--dark-rgb), 0.0125);
  color: var(--default-text-color);
}

.table.table-striped-columns> :not(caption)>tr> :nth-child(2n) {
  --bs-table-accent-bg: rgba(var(--dark-rgb), 0.025);
  color: var(--default-text-color);
}

.table tbody.table-group-divider {
  border-top: 1px solid var(--default-border);
}

.table.table-hover>tbody>tr:hover>* {
  --bs-table-accent-bg: var(--default-background);
  color: var(--default-text-color);
}

.table .table-active {
  --bs-table-accent-bg: rgb(var(--light-rgb));
  color: var(--default-text-color);
}

.table thead.table-primary {
  background-color: #fff;
  color: var(--bs-table-color);
  --bs-table-color-state: #000;
  --bs-table-color: #000;
  --bs-table-bg: rgba(var(--primary-rgb), 0.1);
  --bs-table-border-color: rgba(var(--primary-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--primary-rgb), 0.5);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--primary-rgb), 0.5);
  --bs-table-hover-bg: rgba(var(--primary-rgb), 0.5);
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table thead.table-secondary {
  background-color: #fff;
  color: var(--bs-table-color);
  --bs-table-color-state: #000;
  --bs-table-color: #000;
  --bs-table-bg: rgba(var(--secondary-rgb), 0.1);
  --bs-table-border-color: rgba(var(--secondary-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--secondary-rgb), 0.5);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--secondary-rgb), 0.5);
  --bs-table-hover-bg: rgba(var(--secondary-rgb), 0.5);
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table thead.table-warning {
  background-color: #fff;
  color: var(--bs-table-color);
  --bs-table-color-state: #000;
  --bs-table-color: #000;
  --bs-table-bg: rgba(var(--warning-rgb), 0.1);
  --bs-table-border-color: rgba(var(--warning-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--warning-rgb), 0.5);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--warning-rgb), 0.5);
  --bs-table-hover-bg: rgba(var(--warning-rgb), 0.5);
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table thead.table-success {
  background-color: #fff;
  color: var(--bs-table-color);
  --bs-table-color-state: #000;
  --bs-table-color: #000;
  --bs-table-bg: rgba(var(--success-rgb), 0.1);
  --bs-table-border-color: rgba(var(--success-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--success-rgb), 0.5);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--success-rgb), 0.5);
  --bs-table-hover-bg: rgba(var(--success-rgb), 0.5);
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table thead.table-danger {
  background-color: #fff;
  color: var(--bs-table-color);
  --bs-table-color-state: #000;
  --bs-table-color: #000;
  --bs-table-bg: rgba(var(--danger-rgb), 0.1);
  --bs-table-border-color: rgba(var(--danger-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--danger-rgb), 0.5);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--danger-rgb), 0.5);
  --bs-table-hover-bg: rgba(var(--danger-rgb), 0.5);
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table thead.table-info {
  background-color: #fff;
  color: var(--bs-table-color);
  --bs-table-color-state: #000;
  --bs-table-color: #000;
  --bs-table-bg: rgba(var(--info-rgb), 0.1);
  --bs-table-border-color: rgba(var(--info-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--info-rgb), 0.5);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--info-rgb), 0.5);
  --bs-table-hover-bg: rgba(var(--info-rgb), 0.5);
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table thead.table-light {
  background-color: #fff;
  color: var(--bs-table-color);
  --bs-table-color-state: #000;
  --bs-table-color: #000;
  --bs-table-bg: rgba(var(--light-rgb), 0.1);
  --bs-table-border-color: rgba(var(--light-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--light-rgb), 0.5);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--light-rgb), 0.5);
  --bs-table-hover-bg: rgba(var(--light-rgb), 0.5);
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table thead.table-dark {
  background-color: #fff;
  color: var(--bs-table-color);
  --bs-table-color-state: #000;
  --bs-table-color: #000;
  --bs-table-bg: rgba(var(--dark-rgb), 0.1);
  --bs-table-border-color: rgba(var(--dark-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--dark-rgb), 0.5);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--dark-rgb), 0.5);
  --bs-table-hover-bg: rgba(var(--dark-rgb), 0.5);
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-color-state: $black;
  --bs-table-bg: rgba(var(--primary-rgb), 0.1);
  --bs-table-border-color: rgba(var(--primary-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--primary-rgb), 0.3);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--primary-rgb), 0.3);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgba(var(--primary-rgb), 0.3);
  --bs-table-hover-color: #000;
  color: #000;
  border-color: rgba(var(--primary-rgb), 0.1);
  background-color: #fff;
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-color-state: $black;
  --bs-table-bg: rgba(var(--secondary-rgb), 0.1);
  --bs-table-border-color: rgba(var(--secondary-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--secondary-rgb), 0.3);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--secondary-rgb), 0.3);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgba(var(--secondary-rgb), 0.3);
  --bs-table-hover-color: #000;
  color: #000;
  border-color: rgba(var(--secondary-rgb), 0.1);
  background-color: #fff;
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-color-state: $black;
  --bs-table-bg: rgba(var(--warning-rgb), 0.1);
  --bs-table-border-color: rgba(var(--warning-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--warning-rgb), 0.3);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--warning-rgb), 0.3);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgba(var(--warning-rgb), 0.3);
  --bs-table-hover-color: #000;
  color: #000;
  border-color: rgba(var(--warning-rgb), 0.1);
  background-color: #fff;
}

.table-success {
  --bs-table-color: #000;
  --bs-table-color-state: $black;
  --bs-table-bg: rgba(var(--success-rgb), 0.1);
  --bs-table-border-color: rgba(var(--success-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--success-rgb), 0.3);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--success-rgb), 0.3);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgba(var(--success-rgb), 0.3);
  --bs-table-hover-color: #000;
  color: #000;
  border-color: rgba(var(--success-rgb), 0.1);
  background-color: #fff;
}

.table-info {
  --bs-table-color: #000;
  --bs-table-color-state: $black;
  --bs-table-bg: rgba(var(--info-rgb), 0.1);
  --bs-table-border-color: rgba(var(--info-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--info-rgb), 0.3);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--info-rgb), 0.3);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgba(var(--info-rgb), 0.3);
  --bs-table-hover-color: #000;
  color: #000;
  border-color: rgba(var(--info-rgb), 0.1);
  background-color: #fff;
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-color-state: $black;
  --bs-table-bg: rgba(var(--danger-rgb), 0.1);
  --bs-table-border-color: rgba(var(--danger-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--danger-rgb), 0.3);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--danger-rgb), 0.3);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgba(var(--danger-rgb), 0.3);
  --bs-table-hover-color: #000;
  color: #000;
  border-color: rgba(var(--danger-rgb), 0.1);
  background-color: #fff;
}

.table-dark {
  --bs-table-color: #000;
  --bs-table-color-state: $black;
  --bs-table-bg: rgba(var(--dark-rgb), 0.1);
  --bs-table-border-color: rgba(var(--dark-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--dark-rgb), 0.3);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--dark-rgb), 0.3);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgba(var(--dark-rgb), 0.3);
  --bs-table-hover-color: #000;
  color: #000;
  border-color: rgba(var(--dark-rgb), 0.1);
  background-color: #fff;
}

.table-light {
  --bs-table-color: #000;
  --bs-table-color-state: $black;
  --bs-table-bg: rgba(var(--light-rgb), 0.1);
  --bs-table-border-color: rgba(var(--light-rgb), 0.1);
  --bs-table-striped-bg: rgba(var(--light-rgb), 0.3);
  --bs-table-striped-color: #000;
  --bs-table-active-bg: rgba(var(--light-rgb), 0.3);
  --bs-table-active-color: #000;
  --bs-table-hover-bg: rgba(var(--light-rgb), 0.3);
  --bs-table-hover-color: #000;
  color: #000;
  border-color: rgba(var(--light-rgb), 0.1);
  background-color: #fff;
}

[data-theme-mode=dark] .table-dark {
  border-color: rgba(0, 0, 0, 0.025);
}

[dir=rtl] .table {
  margin-inline-end: 1px;
}

.toast {
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
  box-shadow: 0 0.125rem 0 rgba(10, 10, 10, 0.04);
  border-radius: 7px;
}

.toast .toast-header {
  background-color: rgb(var(--light-rgb));
  padding: 0.375rem 0.75rem;
}

.toast .toast-header img {
  width: 1.25rem;
  height: 1.25rem;
}

.toast .toast-header {
  border-bottom: 1px solid var(--default-border);
}

.toast .toast-header .btn-close {
  margin-inline-end: 0.125rem;
}

.toast .toast-body {
  font-size: 0.8rem;
}

.toast .btn-close {
  font-size: 0.563rem;
}

.toast.colored-toast {
  border: 0 !important;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}

.toast.colored-toast .btn-close {
  filter: invert(1);
}

.toast.colored-toast .toast-header {
  border-block-end: 1px solid rgba(255, 255, 255, 0.1);
}

.bd-example-toasts {
  min-height: 15rem;
}

.tooltip.tooltip-primary .tooltip-inner {
  background-color: var(--primary-color);
}

.tooltip.tooltip-primary.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-primary.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: var(--primary-color);
}

.tooltip.tooltip-primary.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-primary.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: var(--primary-color);
}

.tooltip.tooltip-primary.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-primary.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: var(--primary-color);
}

.tooltip.tooltip-primary.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-primary.bs-tooltip-end .tooltip-arrow::before {
  border-bottom-color: var(--primary-color);
}

.tooltip.tooltip-secondary .tooltip-inner {
  background-color: rgb(var(--secondary-rgb));
}

.tooltip.tooltip-secondary.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-secondary.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: rgb(var(--secondary-rgb));
}

.tooltip.tooltip-secondary.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-secondary.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: rgb(var(--secondary-rgb));
}

.tooltip.tooltip-secondary.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-secondary.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: rgb(var(--secondary-rgb));
}

.tooltip.tooltip-secondary.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-secondary.bs-tooltip-end .tooltip-arrow::before {
  border-bottom-color: rgb(var(--secondary-rgb));
}

.tooltip.tooltip-warning .tooltip-inner {
  background-color: rgb(var(--warning-rgb));
}

.tooltip.tooltip-warning.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-warning.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: rgb(var(--warning-rgb));
}

.tooltip.tooltip-warning.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-warning.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: rgb(var(--warning-rgb));
}

.tooltip.tooltip-warning.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-warning.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: rgb(var(--warning-rgb));
}

.tooltip.tooltip-warning.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-warning.bs-tooltip-end .tooltip-arrow::before {
  border-bottom-color: rgb(var(--warning-rgb));
}

.tooltip.tooltip-info .tooltip-inner {
  background-color: rgb(var(--info-rgb));
}

.tooltip.tooltip-info.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-info.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: rgb(var(--info-rgb));
}

.tooltip.tooltip-info.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-info.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: rgb(var(--info-rgb));
}

.tooltip.tooltip-info.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-info.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: rgb(var(--info-rgb));
}

.tooltip.tooltip-info.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-info.bs-tooltip-end .tooltip-arrow::before {
  border-bottom-color: rgb(var(--info-rgb));
}

.tooltip.tooltip-success .tooltip-inner {
  background-color: rgb(var(--success-rgb));
}

.tooltip.tooltip-success.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-success.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: rgb(var(--success-rgb));
}

.tooltip.tooltip-success.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-success.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: rgb(var(--success-rgb));
}

.tooltip.tooltip-success.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-success.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: rgb(var(--success-rgb));
}

.tooltip.tooltip-success.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-success.bs-tooltip-end .tooltip-arrow::before {
  border-bottom-color: rgb(var(--success-rgb));
}

.tooltip.tooltip-danger .tooltip-inner {
  background-color: rgb(var(--danger-rgb));
}

.tooltip.tooltip-danger.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-danger.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: rgb(var(--danger-rgb));
}

.tooltip.tooltip-danger.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-danger.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: rgb(var(--danger-rgb));
}

.tooltip.tooltip-danger.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-danger.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: rgb(var(--danger-rgb));
}

.tooltip.tooltip-danger.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-danger.bs-tooltip-end .tooltip-arrow::before {
  border-bottom-color: rgb(var(--danger-rgb));
}

.tooltip.tooltip-dark .tooltip-inner {
  background-color: rgb(var(--dark-rgb));
}

.tooltip.tooltip-dark.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-dark.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: rgb(var(--dark-rgb));
}

.tooltip.tooltip-dark.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-dark.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: rgb(var(--dark-rgb));
}

.tooltip.tooltip-dark.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-dark.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: rgb(var(--dark-rgb));
}

.tooltip.tooltip-dark.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-dark.bs-tooltip-end .tooltip-arrow::before {
  border-bottom-color: rgb(var(--dark-rgb));
}

.tooltip.tooltip-dark .tooltip-inner {
  color: var(--custom-white);
}

.tooltip.tooltip-light .tooltip-inner {
  background-color: rgb(var(--light-rgb));
}

.tooltip.tooltip-light.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.tooltip.tooltip-light.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: rgb(var(--light-rgb));
}

.tooltip.tooltip-light.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.tooltip.tooltip-light.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: rgb(var(--light-rgb));
}

.tooltip.tooltip-light.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.tooltip.tooltip-light.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: rgb(var(--light-rgb));
}

.tooltip.tooltip-light.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.tooltip.tooltip-light.bs-tooltip-end .tooltip-arrow::before {
  border-bottom-color: rgb(var(--light-rgb));
}

.tooltip.tooltip-light .tooltip-inner {
  color: var(--default-text-color);
}

/* CUSTOM */
.authentication {
  min-height: 100vh;
}

.authentication .display-1 {
  font-size: 8.5rem;
  margin-block: 0px !important;
  font-weight: 500;
}

.authentication .authentication-brand.desktop-logo {
  display: block;
}

.authentication .authentication-brand.desktop-dark {
  display: none;
}

/* .authentication .swiper-button-next,
.authentication .swiper-button-prev {
  background-color: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.5) !important;
} */

.authentication .swiper-pagination-bullet {
  opacity: 0.1;
}

.authentication .swiper-pagination-bullet-active {
  opacity: 0.5;
}

.authentication .google-svg {
  width: 0.75rem;
  height: 0.75rem;
  margin-inline-end: 0.5rem;
}

.authentication .authentication-barrier {
  position: relative;
}

.authentication .authentication-barrier span {
  position: relative;
  z-index: 2;
}

.authentication .authentication-barrier:before {
  position: absolute;
  content: "";
  width: 45%;
  height: 0.125rem;
  background-image: linear-gradient(to left, transparent, var(--default-border));
  border-radius: 50%;
  inset-inline-end: 0;
  inset-block-start: 0.563rem;
  z-index: 1;
}

.authentication .authentication-barrier:after {
  position: absolute;
  content: "";
  width: 45%;
  height: 0.125rem;
  background-image: linear-gradient(to left, var(--default-border), transparent);
  border-radius: 50%;
  inset-inline-start: 0;
  inset-block-start: 0.563rem;
  z-index: 1;
}

.authentication.coming-soon .authentication-cover .aunthentication-cover-content,
.authentication.under-maintenance .authentication-cover .aunthentication-cover-content {
  width: 100%;
  height: 100%;
  padding: 3rem;
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
  background-color: transparent;
}

.authentication.coming-soon .authentication-cover .aunthentication-cover-content:before,
.authentication.coming-soon .authentication-cover .aunthentication-cover-content:after,
.authentication.under-maintenance .authentication-cover .aunthentication-cover-content:before,
.authentication.under-maintenance .authentication-cover .aunthentication-cover-content:after {
  display: none;
}

.authentication.coming-soon .authentication-cover .aunthentication-cover-content .coming-soon-time,
.authentication.coming-soon .authentication-cover .aunthentication-cover-content .under-maintenance-time,
.authentication.under-maintenance .authentication-cover .aunthentication-cover-content .coming-soon-time,
.authentication.under-maintenance .authentication-cover .aunthentication-cover-content .under-maintenance-time {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  background-color: var(--white-1);
  color: #fff;
}

.authentication.coming-soon .authentication-cover .aunthentication-cover-content .authentication-brand,
.authentication.under-maintenance .authentication-cover .aunthentication-cover-content .authentication-brand {
  width: auto;
  height: auto;
  border: 0;
}

.authentication .coming-soom-image-container img,
.authentication .under-maintenance-image-container img {
  width: 100%;
  height: auto;
}

.authentication .authentication-cover {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.authentication .authentication-cover .aunthentication-cover-content {
  width: 70%;
  height: 37.5rem;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: blur(1.875rem);
  backdrop-filter: blur(1.875rem);
  padding: 2rem;
  position: relative;
}

.authentication .authentication-cover .aunthentication-cover-content img {
  margin: 0 auto;
  width: 13.75rem;
  height: 13.75rem;
}

.authentication.authentication-basic .desktop-dark {
  display: none;
}

.authentication.authentication-basic .desktop-logo {
  display: block;
}

.authentication.authentication-basic .form-control-lg#one,
.authentication.authentication-basic .form-control-lg#two,
.authentication.authentication-basic .form-control-lg#three,
.authentication.authentication-basic .form-control-lg#four {
  padding-inline: 0;
}

@media (max-width: 320px) {
  .authentication .display-1 {
    font-size: 6.5rem;
  }
}

[data-theme-mode=dark] .authentication.authentication-basic .desktop-dark {
  display: block;
}

[data-theme-mode=dark] .authentication.authentication-basic .desktop-logo {
  display: none;
}

[data-theme-mode=dark] .authentication .authentication-brand.desktop-logo {
  display: none;
}

[data-theme-mode=dark] .authentication .authentication-brand.desktop-dark {
  display: block;
}

.authentication .desktop-logo,
.authentication .desktop-dark {
  height: 2rem;
  line-height: 2rem;
}

.coming-soon {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.coming-soon::before {
  content: "";
  background: linear-gradient(to right, var(--primary06) 0%, rgba(5, 195, 251, 0.568627451) 100%);
  height: 100%;
  width: 100%;
  position: absolute;
}

.coming-soon .page {
  position: relative;
}

.coming-soon .btn.btn-icon {
  background: var(--white-1);
  color: #fff;
  border-radius: 50%;
}

.autentication-bg {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.autentication-bg::before {
  content: "";
  background: linear-gradient(to right, var(--primary06) 0%, rgba(5, 195, 251, 0.568627451) 100%);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
}

/* Start::body */
body {
  font-size: 14px !important;
  font-family: var(--default-font-family);
  font-weight: var(--default-font-weight);
  background-color: var(--default-body-bg-color);
  color: var(--default-text-color);
  line-height: 1.5;
  text-align: start;
  overflow-y: scroll;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

/* End::body */
/* Start::Page header  */
.page-header {
  display: flex;
  align-items: center;
  margin: 1.5rem 0rem 1.5rem;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  position: relative;
  border: 1px solid transparent;
  border-radius: 5px;
  min-height: 50px;
}

.page-title {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  position: relative;
  margin-block-end: 0.2rem;
}

.page-options {
  margin-inline-start: auto;
}

.page-header .breadcrumb {
  font-size: 13px;
  padding-inline-start: 0 !important;
}

/* End::Page header  */
.page *::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
  -webkit-transition: all ease 0.05s;
  transition: all ease 0.05s;
}

.page *:hover::-webkit-scrollbar-thumb {
  background: rgb(var(--light-rgb));
}

a,
button {
  outline: 0 !important;
}

/* Start::basic */
.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

@media (min-width: 992px) {
  .app-content {
    min-height: calc(100vh - 7.5rem);
    margin-block-end: 0;
    margin-block-start: 4.7rem;
    margin-inline-start: 17rem;
    transition: all 0.05s ease;
  }

  .main-content {
    padding: 0px 20px 0 20px;
  }
}

/* End::basic */
/* Start::App Content */
.bd-example>.dropdown-menu {
  position: static;
  display: block;
}

/* Start::width */
.min-w-fit-content {
  min-width: -moz-fit-content;
  min-width: fit-content;
}

/* End::width */
/* Start::Scrollspy */
.scrollspy-example {
  height: 12.5rem;
  margin-block-start: 0.5rem;
  overflow: auto;
}

.scrollspy-example-2 {
  height: 21.875;
  overflow: auto;
}

.scrollspy-example-3 {
  height: 13.75rem;
  overflow: auto;
}

.simple-list-example-scrollspy .active {
  background-color: var(--primary-color);
  color: #fff;
}

.scrollspy-example-4 {
  height: 12.5rem;
  margin-block-start: 0.5rem;
  overflow: auto;
}

/* End::Scrollspy */
/* Start::Carousel */
.carousel-inner {
  border-radius: 0.35rem;
}

.carousel-caption {
  color: #fff;
}

/* End::Carousel */
/* Start::navbar */
.fixed-top {
  position: static;
  margin: -1rem -1rem 0rem -1rem;
  z-index: 0 !important;
}

.fixed-bottom {
  position: static;
  margin: 1rem -0.5rem -1rem -0.5rem;
}

.sticky-top {
  position: static;
  margin: -1rem -1rem 0rem -1rem;
}

/*End::navbar*/
/* Start::Helpers */
.bd-example-ratios .ratio {
  display: inline-block;
  width: 10rem;
  color: var(--text-muted);
  background-color: var(--primary01);
  border: var(--default-border);
  border-radius: 7px;
}

@media (min-width: 768px) {
  .bd-example-ratios-breakpoint .ratio-4x3 {
    --bs-aspect-ratio: 50%;
  }
}

.bd-example-ratios-breakpoint .ratio-4x3 {
  width: 16rem;
}

/* End::Helpers */
/* Start::Layouts */
.bd-example-row [class^=col],
.bd-example-cssgrid .grid>* {
  padding-block-start: 0.75rem;
  padding-block-end: 0.75rem;
  background-color: rgba(var(--light-rgb), 0.75);
  border: 1px solid var(--default-border);
}

.bd-example-cssgrid .grid>* {
  border-radius: 0.25rem;
}

.bd-example-row-flex-cols .row {
  min-height: 10rem;
  background-color: rgba(var(--light-rgb), 0.5);
}

.grid {
  display: grid;
  grid-template-rows: repeat(var(--bs-rows, 1), 1fr);
  grid-template-columns: repeat(var(--bs-columns, 12), 1fr);
  gap: var(--bs-gap, 1.5rem);
}

.grid .g-start-2 {
  grid-column-start: 2 !important;
}

.grid .g-col-2 {
  grid-column: auto/span 2;
}

.grid .g-start-3 {
  grid-column-start: 3;
}

.grid .g-col-3 {
  grid-column: auto/span 3;
}

.grid .g-col-4,
.grid .g-col-md-4 {
  grid-column: auto/span 4;
}

.grid .g-col-6 {
  grid-column: auto/span 6;
}

.grid .g-start-6 {
  grid-column-start: 6;
}

/* End::Layouts */
.callout {
  padding: 1.25rem;
  margin-block-start: 1.25rem;
  margin-block-end: 1.25rem;
  background-color: rgb(var(--light-rgb));
  border-inline-start: 0.25rem solid var(--default-border);
}

.callout-info {
  background-color: rgba(var(--info-rgb), 0.075);
  border-color: rgba(var(--info-rgb), 0.5);
}

.callout-warning {
  background-color: rgba(var(--warning-rgb), 0.075);
  border-color: rgba(var(--warning-rgb), 0.5);
}

.callout-danger {
  background-color: rgba(var(--danger-rgb), 0.075);
  border-color: rgba(var(--danger-rgb), 0.5);
}

.flex-container div {
  background-color: transparent;
  border: 0;
}

.flex-container div>div {
  background-color: rgb(var(--light-rgb));
  border: 1px solid var(--custom-white);
}

.bd-example-position-utils {
  position: relative;
  padding: 2rem;
}

.bd-example-position-utils .position-relative {
  height: 12.5rem;
  background-color: var(--default-background);
}

.bd-example-position-utils .position-absolute {
  width: 2rem;
  height: 2rem;
  background-color: var(--primary01);
  border-radius: 0.375rem;
}

/* End::Utilities Page */
/* Start:: Images & Figures */
.bd-placeholder-img-lg {
  font-size: 3.5rem;
}

.figure-caption {
  color: var(--text-muted);
}

/* End:: Images & Figures */
/* End:App-Content */
/*  Start::Footer*/
@media (min-width: 992px) {
  .footer {
    padding-inline-start: 17rem;
  }
}

.footer {
  background: transparent;
  font-size: 0.875rem;
  padding: 1.25rem 1.25rem;
  border-top: 1px solid var(--black-1);
}

/*  End::Footer*/
/* Start::OffCanvas */
.offcanvas {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

/* End::OffCanvas */
/* Start::Switcher */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#switcher-main-tab {
  border-block-end: 0;
}

#switcher-canvas {
  width: 27.5rem;
}

#switcher-canvas .offcanvas-body {
  padding: 0 0 4.75rem 0;
}

#switcher-canvas .canvas-footer {
  padding: 0.75rem 1.563rem;
  position: absolute;
  inset-block-end: 0;
  width: 100%;
  background-color: var(--custom-white);
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
}

#switcher-canvas #switcher-main-tab button.nav-link {
  border-radius: 0;
  color: var(--default-text-color);
  font-weight: 600;
}

#switcher-canvas #switcher-main-tab button.nav-link.active {
  color: rgb(var(--success-rgb));
  background-color: rgba(var(--success-rgb), 0.2);
  border-color: transparent;
}

#switcher-canvas #switcher-main-tab button.nav-link:hover {
  border-color: transparent;
}

.switcher-style {
  padding: 0.875rem 1.563rem;
}

.switcher-style h6 {
  margin-block-end: 0.625rem;
}

.switcher-icon .header-link-icon {
  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.switch-select .form-check-label {
  font-size: 0.813rem;
  font-weight: 600;
}

.switch-select.form-check {
  margin-block-end: 0;
  min-height: auto;
}

.menu-image .bgimage-input {
  width: 3.5rem;
  height: 5.625rem;
  border-radius: 7px;
  border: 0;
}

.menu-image .bgimage-input.form-check-input:focus {
  border-color: transparent;
  box-shadow: 0 0 0 0.25rem var(--black-1);
}

.menu-image .bgimage-input.bg-img1 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.menu-image .bgimage-input.bg-img1.form-check-input:checked[type=radio] {
  background-color: none;
}

.menu-image .bgimage-input.bg-img2 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.menu-image .bgimage-input.bg-img2.form-check-input:checked[type=radio] {
  background-color: none;
}

.menu-image .bgimage-input.bg-img3 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.menu-image .bgimage-input.bg-img3.form-check-input:checked[type=radio] {
  background-color: none;
}

.menu-image .bgimage-input.bg-img4 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.menu-image .bgimage-input.bg-img4.form-check-input:checked[type=radio] {
  background-color: none;
}

.menu-image .bgimage-input.bg-img5 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.menu-image .bgimage-input.bg-img5.form-check-input:checked[type=radio] {
  background-color: none;
}

.theme-colors.switcher-style {
  padding: 0.938rem 1.563rem;
}

.theme-colors .switch-select .color-input {
  width: 2rem;
  height: 2rem;
  border-radius: 50px;
}

.theme-colors .switch-select .color-input.form-check-input:checked {
  border: 1px solid var(--input-border);
  position: relative;
  box-shadow: 0 0.125rem 0 rgba(10, 10, 10, 0.04);
}

.theme-colors .switch-select .color-input.form-check-input:checked:before {
  position: absolute;
  content: "\ea5e";
  font-family: tabler-icons !important;
  color: rgb(var(--success-rgb));
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.35rem;
  font-weight: 600;
}

.theme-colors .switch-select .color-input.color-white {
  background-color: #fff;
}

.theme-colors .switch-select .color-input.color-dark {
  background-color: #000;
}

.theme-colors .switch-select .color-input.color-primary {
  background-color: var(--primary-color);
}

.theme-colors .switch-select .color-input.color-primary-1 {
  background-color: rgb(58, 88, 146);
}

.theme-colors .switch-select .color-input.color-primary-2 {
  background-color: rgb(92, 144, 163);
}

.theme-colors .switch-select .color-input.color-primary-3 {
  background-color: rgb(161, 90, 223);
}

.theme-colors .switch-select .color-input.color-primary-4 {
  background-color: rgb(78, 172, 76);
}

.theme-colors .switch-select .color-input.color-primary-5 {
  background-color: rgb(223, 90, 90);
}

.theme-colors .switch-select .color-input.color-gradient {
  background-image: linear-gradient(to right top, var(--primary-color), #6e72a8);
}


.theme-colors .switch-select .color-input.color-bg-1 {
  background-color: rgb(20, 30, 96);
}

.theme-colors .switch-select .color-input.color-bg-2 {
  background-color: rgb(8, 78, 115);
}

.theme-colors .switch-select .color-input.color-bg-3 {
  background-color: rgb(90, 37, 135);
}

.theme-colors .switch-select .color-input.color-bg-4 {
  background-color: rgb(24, 101, 51);
}

.theme-colors .switch-select .color-input.color-bg-5 {
  background-color: rgb(120, 66, 20);
}

.theme-colors .switch-select .form-check-input:checked[type=radio] {
  background-image: none;
}

.theme-colors .switch-select .form-check-input:focus {
  box-shadow: none;
}

.theme-colors .switch-select .form-check-input:active {
  filter: brightness(100%);
}

.switcher-style-head {
  font-size: 0.8rem;
  font-weight: 600;
  margin-block-end: 0;
  background: rgb(var(--light-rgb));
  padding: 0.313rem 0.625rem;
  color: var(--default-text-color);
}

.switcher-style-head .switcher-style-description {
  float: right;
  font-size: 0.625rem;
  background-color: rgba(var(--secondary-rgb), 0.2);
  color: rgb(var(--secondary-rgb));
  padding: 0.125rem 0.313rem;
  border-radius: 7px;
}

#switcher-home,
#switcher-profile {
  padding: 0;
}

.theme-container-primary button,
.theme-container-background button {
  display: none;
}

.pickr-container-primary .pickr .pcr-button,
.pickr-container-background .pickr .pcr-button {
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid var(--input-border);
}

.pickr-container-primary .pickr .pcr-button:focus,
.pickr-container-background .pickr .pcr-button:focus {
  box-shadow: none;
}

.pickr-container-primary .pickr .pcr-button::after,
.pickr-container-background .pickr .pcr-button::after {
  content: "\efc5";
  font-family: remixicon !important;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.5;
  font-size: 1.25rem;
}

/* End::Switcher */
/* Start::Bootstrap Styles */
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--default-text-color);
}

.card {
  background-color: var(--custom-white);
  color: var(--default-text-color);
}

.img-thumbnail {
  background-color: var(--custom-white);
  border: 1px solid var(--default-border);
}

input::-moz-placeholder {
  color: var(--default-text-color) !important;
}

input::placeholder {
  color: var(--default-text-color) !important;
}

/* End::Bootstrap Styles */
/* Start::Dashboard Apex Charts  */
#btcCoin .apexcharts-grid line,
#btcCoin .apexcharts-xaxis line,
#ethCoin .apexcharts-grid line,
#ethCoin .apexcharts-xaxis line,
#dshCoin .apexcharts-grid line,
#dshCoin .apexcharts-xaxis line,
#glmCoin .apexcharts-grid line,
#glmCoin .apexcharts-xaxis line {
  stroke: transparent;
}

/* Start::Dashboard Apex Charts  */
/* Start::Switcher */
@media (max-width: 991.98px) {
  .navigation-menu-styles {
    display: none;
  }
}

/* Start::Switcher */
/* Start:Responsive Dropdowns */
@media (max-width: 575.98px) {

  .cart-dropdown,
  .timelines-dropdown,
  .notifications-dropdown,
  .header-shortcuts-dropdown,
  .header-fullscreen {
    display: none !important;
  }
}

@media (max-width: 390.98px) {

  .message-dropdown,
  .header-search {
    display: none !important;
  }
}

/* End:Responsive Dropdowns */
/* Start::Close Button */
.btn-close:focus {
  box-shadow: none !important;
}

/* End::Close Button */
/* Start::Icons Page */
.icons-list {
  list-style: none;
  margin: 0 -1px -1px 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.icons-list .icons-list-item {
  text-align: center;
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--default-border);
  margin: 0.25rem;
  border-radius: 0.25rem;
}

.icons-list .icons-list-item i {
  font-size: 1.05rem;
  color: var(--default-text-color);
}

/* End::Icons Page */
.bd-placeholder-img {
  margin: 0.125rem;
}

/* Start::Shadows */
.shadow-primary {
  box-shadow: 0 0.25rem 1rem rgba(var(--primary-rgb), 0.3) !important;
}

.shadow-secondary {
  box-shadow: 0 0.25rem 1rem rgba(var(--secondary-rgb), 0.3) !important;
}

.shadow-success {
  box-shadow: 0 0.25rem 1rem rgba(var(--success-rgb), 0.3) !important;
}

.shadow-info {
  box-shadow: 0 0.25rem 1rem rgba(var(--info-rgb), 0.3) !important;
}

.shadow-warning {
  box-shadow: 0 0.25rem 1rem rgba(var(--warning-rgb), 0.3) !important;
}

.shadow-danger {
  box-shadow: 0 0.25rem 1rem rgba(var(--danger-rgb), 0.3) !important;
}

.shadow-orange {
  box-shadow: 0 0.25rem 1rem rgba(var(--orange-rgb), 0.3) !important;
}

.shadow-purple {
  box-shadow: 0 0.25rem 1rem rgba(var(--purple-rgb), 0.3) !important;
}

/* End::Shadows */
/* Start::placeholders */
.placeholder-xl {
  min-height: 1.5em;
}

.placeholder {
  background-color: var(--gray-7);
}

/* End:::placeholders */
/* Start::scrollspy */
.scrollspy-example-2 {
  height: 21.875rem;
  border: 1px solid var(--default-border);
  padding: 0.75rem;
  border-radius: 7px;
}

/* End::scrollspy */
/* Start::object-fit */
.object-fit-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.object-fit-container img,
.object-fit-container video {
  width: 15.625rem;
  height: 15.625rem;
}

/* End::object-fit */
/* Start::invoice */
.invoice-amount-input {
  width: 9.375rem;
}

.choices-control .choices__inner {
  background-color: rgb(var(--light-rgb)) !important;
  border: 0 !important;
}

.svg-icon-background {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.625rem;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.svg-icon-background svg {
  width: 1.25rem;
  height: 1.25rem;
}

.invoice-quantity-container {
  width: 8.75rem;
}

/* End::invoice */
/* Start::pricing */
.pricing-svg1 [fill="#845adf"] {
  fill: var(--primary-color);
}

.pricing-svg1 [fill="#403161"] {
  fill: rgb(var(--secondary-rgb));
}

.pricing-basic,
.pricing-pro,
.pricing-premium {
  position: relative;
}

.pricing-basic:before,
.pricing-pro:before,
.pricing-premium:before {
  position: absolute;
  content: "";
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.pricing-svg1 {
  position: relative;
  z-index: 2;
}

.pricing-svg1 svg {
  width: 4rem;
  height: 4rem;
}

.pricing-offer {
  position: relative;
}

.pricing-offer .pricing-offer-details {
  position: absolute;
  width: 5.438rem;
  height: 3.75rem;
  background-color: var(--primary-color);
  color: #fff;
  font-size: 0.75rem;
  transform: rotate(45deg);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  inset-inline-end: -2.063rem;
  inset-block-start: -1.25rem;
  padding: 0.313rem;
}

[dir=rtl] .pricing-offer-details {
  transform: rotate(315deg);
}

/* End::pricing */
/* Start::Team */
.teams-nav {
  max-height: calc(100vh - 9rem);
}

.teams-nav ul li {
  padding: 0.625rem 1.25rem;
}

.team-member-card {
  overflow: hidden;
}

.team-member-card .avatar {
  position: absolute;
  inset-block-start: 65%;
  inset-inline-start: 38%;
  outline: 0.25rem solid rgba(0, 0, 0, 0.075);
}

@media (min-width: 576px) {
  .team-member-card .team-member-details {
    padding-inline-start: 4.75rem;
  }

  .team-member-card .avatar {
    inset-inline-start: 1rem;
  }

  .team-member-card .team-member-stats div {
    border-inline-end: 1px dashed var(--default-border);
  }

  .team-member-card .team-member-stats div:last-child {
    border-inline-end: 0;
  }
}

@media (max-width: 575.98px) {
  .team-member-card .team-member-stats div {
    border-block-end: 1px dashed var(--default-border);
  }

  .team-member-card .team-member-stats div:last-child {
    border-block-end: 0;
  }
}

@media (min-width: 1400px) and (max-width: 1700px) {
  .team-member-card .team-member-details {
    max-width: 12rem;
  }
}

.team-member-card .teammember-cover-image {
  position: relative;
}

.team-member-card .teammember-cover-image .card-img-top {
  width: 100%;
  height: 6.25rem;
}

.team-member-card .teammember-cover-image:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: var(--primary03);
  inset-inline-start: 0;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
}

.team-member-card .teammember-cover-image .team-member-star {
  position: absolute;
  inset-block-start: 0.75rem;
  padding: 0.375rem;
  inset-inline-end: 0.75rem;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* End::Team */
/* Start:: Contacts */
.contact-action {
  position: relative;
  padding: 1.25rem;
}

.contact-action .contact-overlay {
  position: absolute;
  width: 0;
  height: 100%;
  background-color: var(--primary07);
  inset-inline-start: 0;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  z-index: 1;
  border-radius: 7px;
}

.contact-action:hover .contact-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  inset-inline-start: 0;
}

.contact-action:hover .contact-hover-btn,
.contact-action:hover .contact-hover-dropdown,
.contact-action:hover .contact-hover-dropdown1 {
  display: block;
  opacity: 1;
}

.contact-action .contact-hover-btn {
  opacity: 0;
  z-index: 2;
}

.contact-action .contact-hover-dropdown {
  opacity: 0;
  z-index: 2;
}

.contact-action .contact-hover-dropdown1 {
  opacity: 0;
  z-index: 2;
}

.contact-action .contact-hover-buttons {
  position: absolute;
  width: 100%;
  height: 100%;
  inset-inline-start: 0;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
}

@media (min-width: 1400px) and (max-width: 1700px) {
  .contact-mail {
    max-width: 8.125rem;
  }
}

@media (max-width: 400px) {
  .contact-mail {
    max-width: 8.125rem;
  }
}

/* End:: Contacts */
/* Start:: Notifications */
.notification-container li .un-read {
  border-inline-start: 0.25rem solid var(--primary03);
}

.alt-notify {
  padding: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 20px;
}

.badge-notify {
  font-weight: 400;
  font-size: 0.65em;
}

.notify-border-start-warning {
  border-left: 3px solid rgb(var(--warning-rgb));
  border-radius: 5px;
}

.notify-border-start-success {
  border-left: 3px solid rgb(var(--success-rgb));
  border-radius: 5px;
}

.notify-border-start-danger {
  border-left: 3px solid rgb(var(--danger-rgb));
  border-radius: 5px;
}

.notify-border-start-info {
  border-left: 3px solid rgb(var(--info-rgb));
  border-radius: 5px;
}

/* End:: Notifications */
/* Start:: To Do Task */
.task-navigation ul.task-main-nav li {
  padding: 0.5rem;
  border-radius: var(--default-border);
  border-radius: 0.3rem;
}

.task-navigation ul.task-main-nav li.active div {
  color: var(--primary-color);
}

.task-navigation ul.task-main-nav li:hover div {
  color: var(--primary-color);
}

.task-pending-card .card-body,
.task-inprogress-card .card-body,
.task-completed-card .card-body {
  position: relative;
}

.task-pending-card .card-body .btn-list,
.task-inprogress-card .card-body .btn-list,
.task-completed-card .card-body .btn-list {
  position: absolute;
  inset-block-end: 2.75rem;
  inset-inline-end: 1.25rem;
}

.task-pending-card .card-body .btn-list .btn,
.task-inprogress-card .card-body .btn-list .btn,
.task-completed-card .card-body .btn-list .btn {
  margin-block-end: 0px;
}

.task-pending-card:hover,
.task-inprogress-card:hover,
.task-completed-card:hover {
  box-shadow: 0 0.25rem 1rem var(--black-1);
}

.task-tabs-container .tab-pane {
  min-height: calc(100vh - 22rem);
  border: 0;
}

/* End:: To Do Task */
/* Start:: Terms & Conditions */
.terms-conditions {
  max-height: 44.75rem;
}

.card-fullscreen .terms-conditions {
  max-height: 34.75rem;
}

.terms-heading {
  position: relative;
}

.terms-heading:before {
  position: absolute;
  content: "";
  width: 50%;
  height: 0.25rem;
  background: linear-gradient(to right, var(--primary-color), var(--primary01));
  inset-inline-start: 25%;
  inset-block-end: -0.65rem;
  border-radius: 7px;
}

.card.card-fullscreen {
  border-radius: 0;
}

/* End:: Terms & Conditions */
/* Start:: Tree View */
.tree-leaf .tree-expando {
  top: 8px !important;
  height: 20px !important;
  width: 20px !important;
  padding: 4px !important;
  line-height: 9px !important;
  background: rgb(var(--light-rgb)) !important;
}

.tree-leaf .tree-leaf-text {
  margin: 0.25rem !important;
}

.tree-leaf .tree-leaf-text:hover {
  color: var(--primary-color) !important;
}

[dir=rtl] .tree-leaf .tree-expando {
  float: right;
}

[dir=rtl] .tree-leaf .tree-leaf-text {
  float: right;
}

[dir=rtl] .tree-leaf .tree-child-leaves {
  margin-right: 15px;
  margin-left: 0px;
}

/* End:: Tree View */
/* Start:: Reviews */
.reviews-container .card {
  position: relative;
}

.reviews-container .card:before {
  content: "\ec52";
  font-family: remixicon !important;
  position: absolute;
  width: 2.188rem;
  height: 2.188rem;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  border-radius: 3.125rem;
  justify-content: center;
  color: #fff;
  background-color: var(--primary-color);
  box-shadow: 0 0 0.5rem var(--primary05);
  inset-inline-end: 1.125rem;
  inset-block-start: -1.125rem;
}

/* End:: Reviews */
/* Start:: Faq's */
#accordionFAQ {
  max-height: 31.25rem;
}

/* End:: Faq's */
/* Start:: Timeline */
.timeline {
  position: relative;
}

.timeline li:before {
  content: "";
  position: absolute;
  inset-block-start: 4.625rem;
  inset-block-end: -3rem;
  width: 1px;
  border: 1px dashed var(--primary02);
  inset-inline-start: 20%;
  margin-inline-start: -1.5px;
}

.timeline>li {
  position: relative;
  min-height: 3.125rem;
  padding: 0.938rem 0;
}

.timeline .timeline-time {
  position: absolute;
  inset-inline-start: 0;
  width: 18%;
  inset-block-start: 3rem;
}

.timeline .timeline-time .date,
.timeline .timeline-time .time {
  display: block;
  font-weight: 500;
}

.timeline .timeline-time .date {
  line-height: 1rem;
  font-size: 0.6rem;
  margin-bottom: 0;
  color: var(--text-muted);
}

.timeline .timeline-time .time {
  line-height: 1.5rem;
  font-size: 0.725rem;
  color: var(--primary-color);
  background-color: var(--primary01);
  padding: 2px 10px;
  border-radius: 3px;
}

.timeline .timeline-icon {
  inset-inline-start: 15%;
  position: absolute;
  width: 10%;
  text-align: center;
  inset-block-start: 3.188rem;
}

.timeline .timeline-icon a {
  width: 1.125rem;
  height: 1.125rem;
  display: inline-block;
  border-radius: 50%;
  background: var(--custom-white);
  color: var(--primary-color);
  line-height: 0.625rem;
  font-size: 0.875rem;
  border: 0.308rem solid var(--primary07);
}

.timeline .timeline-body {
  box-shadow: 0 1px 1rem rgba(0, 0, 0, 0.05);
  margin-inline-start: 22%;
  background: var(--custom-white);
  position: relative;
  padding: 0.875rem 1.25rem 0.875rem 1.25rem;
  border-radius: 7px;
  width: 70%;
}

.timeline .timeline-body>div+div {
  margin-block-start: 0.938rem;
}

.timeline-loadmore-container {
  margin-block-start: 3.5rem;
  margin-block-end: 1.5rem;
}

.timeline li:last-child:before {
  display: none;
}

@media (max-width: 575.98px) {
  .timeline .timeline-body {
    width: 100%;
  }

  .timeline .timeline-icon a {
    display: none;
  }

  .timeline li:before {
    display: none;
  }

  .timeline-body .media {
    flex-direction: column;
  }

  .timeline-body .media .main-img-user {
    margin-block-end: 0.625rem !important;
  }

  .timeline .timeline-time {
    z-index: 99;
    width: 100%;
    inset-inline-end: 0.313rem !important;
    position: absolute;
    inset-block-start: 3.8rem !important;
  }

  .timeline-main-content {
    flex-direction: column;
  }

  .timeline .timeline-body {
    margin-inline-start: 0;
    margin-inline-end: 0;
    position: relative;
  }

  .timeline .timeline-time .date,
  .timeline .timeline-time .time {
    display: inline;
  }

  .timeline .timeline-time .time {
    line-height: 1rem;
    font-size: 0.688rem;
    margin-inline-start: -4.687rem;
    margin-inline-end: 0.625rem;
    margin-block-end: 43px;
    top: -35px;
    position: absolute;
  }
}

.timeline-center {
  position: relative;
}

.timeline-center li:before {
  content: "";
  position: absolute;
  inset-block-start: 4.625rem;
  inset-block-end: -3rem;
  width: 1px;
  border: 1px dashed var(--primary02);
  inset-inline-start: 52%;
  margin-inline-start: -1.5px;
}

.timeline-center>li {
  position: relative;
  min-height: 3.125rem;
  padding: 0.938rem 0;
}

.timeline-center .timeline-center-time {
  position: absolute;
  width: 18%;
  inset-block-start: 3rem;
}

.timeline-center .timeline-center-time .date,
.timeline-center .timeline-center-time .time {
  display: block;
  font-weight: 500;
}

.timeline-center .timeline-center-time .date {
  line-height: 1rem;
  font-size: 0.6rem;
  margin-bottom: 0;
  color: var(--text-muted);
}

.timeline-center .timeline-center-time .time {
  line-height: 1.5rem;
  font-size: 0.725rem;
  color: var(--primary-color);
  background-color: var(--primary01);
  padding: 2px 10px;
  border-radius: 3px;
  inset-inline-end: -174%;
  position: absolute;
}

.timeline-center .timeline-center-icon {
  inset-inline-start: 47%;
  position: absolute;
  width: 10%;
  text-align: center;
  inset-block-start: 3.188rem;
}

.timeline-center .timeline-center-icon a {
  width: 0.625rem;
  height: 0.625rem;
  display: inline-block;
  border-radius: 50%;
  background: rgb(var(--light-rgb));
  color: var(--primary-color);
  line-height: 0.625rem;
  font-size: 0.875rem;
  border: 0.188rem solid var(--primary05);
}

.timeline-center .timeline-center-body {
  box-shadow: 0 1px 1rem rgba(0, 0, 0, 0.05);
  margin-inline-start: 54%;
  margin-inline-end: -5%;
  background: var(--custom-white);
  position: relative;
  padding: 0.875rem 1.25rem 0.875rem 1.25rem;
  border-radius: 7px;
  width: 55%;
}

.timeline-center .timeline-center-body>div+div {
  margin-block-start: 0.938rem;
}

.timeline-center-loadmore-container {
  margin-block-start: 3.5rem;
  margin-block-end: 1.5rem;
}

.timeline-center li:last-child:before {
  display: none;
}

.timeline-center-widget .timeline-center-time-right {
  position: absolute;
  inset-inline-end: 27%;
  width: 18%;
  inset-block-start: 3rem;
}

.timeline-center-widget .timeline-center-time-right .time {
  line-height: 1.5rem;
  font-size: 0.725rem;
  color: var(--primary-color);
  background-color: var(--primary01);
  padding: 2px 10px;
  border-radius: 3px;
}

.timeline-center-widget .timeline-center-icon-right {
  inset-inline-end: 43%;
  position: absolute;
  width: 10%;
  text-align: center;
  inset-block-start: 3.188rem;
}

.timeline-center-widget .timeline-center-icon-right a {
  width: 1.625rem;
  height: 1.625rem;
  display: inline-block;
  border-radius: 50%;
  background: var(--custom-white);
  color: var(--primary-color);
  line-height: 0.625rem;
  font-size: 0.875rem;
  border: 0.188rem solid var(--primary05);
}

.timeline-center-widget .timeline-center-body-right {
  box-shadow: 0 1px 1rem rgba(0, 0, 0, 0.05);
  margin-inline-start: -6%;
  margin-inline-end: 51%;
  background: var(--custom-white);
  position: relative;
  padding: 0.875rem 1.25rem 0.875rem 1.25rem;
  border-radius: 7px;
  width: 55%;
}

@media (max-width: 800.98px) {
  .timeline-center .timeline-center-body {
    margin-inline-start: 0%;
  }

  .timeline-center-body-right,
  .timeline-center .timeline-center-body,
  .timeline-center-widget .timeline-center-body-right {
    width: 100%;
  }

  .timeline-center .timeline-center-icon {
    display: none;
  }

  .timeline-center .timeline-center-icon-right a {
    display: none;
  }

  .timeline-center li:before {
    display: none;
  }

  .timeline-center-body-right .media {
    flex-direction: column;
  }

  .timeline-center-body-right .media .main-img-user {
    margin-block-end: 0.625rem !important;
  }

  .timeline-center .timeline-center-time-right {
    width: 100%;
    position: initial;
    margin-bottom: 15px;
  }

  .timeline-center-main-content {
    flex-direction: column;
  }

  .timeline-center .timeline-center-body-right {
    margin-inline-start: 0;
    margin-inline-end: 0;
    position: relative;
  }

  .timeline-center-badge-right {
    position: absolute;
    inset-inline-start: 1.15rem;
    inset-block-start: 0.45rem;
  }

  .timeline-center .timeline-center-time .date,
  .timeline-center .timeline-center-time .time {
    display: inline;
  }

  .timeline-center .timeline-center-time .time {
    position: inherit;
  }

  .timeline-center .timeline-center-time {
    width: 100%;
    position: initial;
    margin-bottom: 15px;
  }
}

/* End:: Timeline */
/* Start:: Blog */
.card-aside-column {
  min-width: 5rem;
  width: 30%;
  flex: 0 0 30%;
  background: no-repeat center/cover;
}

/* End:: Blog */
/* Start:: Blog Details */
.media {
  display: flex;
  align-items: flex-start;
}

a.tag {
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s color, 0.3s background;
}

.tag {
  font-size: 0.75rem;
  color: var(--default-text-color);
  background-color: rgb(var(--light-rgb));
  border-radius: 3px;
  padding: 0 0.5rem;
  line-height: 2em;
  display: inline-flex;
  cursor: default;
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  margin-block-end: 0.5rem;
}

.tag:not(:last-child) {
  margin-inline-end: 0.5rem;
}

/* End:: Blog Details */
/* Start:: Create Blog */
.blog-images-container .filepond--root {
  width: 100%;
}

.blog-images-container .filepond--panel-root {
  border-color: var(--input-border) !important;
  border-radius: 7px;
}

.blog-images-container .filepond--root .filepond--drop-label label {
  color: var(--text-muted);
}

#blog-content {
  height: auto;
}

@media screen and (max-width: 400px) {
  .choices__inner .choices__list--multiple .choices__item {
    margin-block-end: 0.25rem !important;
  }
}

@media screen and (max-width: 991px) {
  .ql-toolbar.ql-snow .ql-formats {
    margin-block-end: 0.25rem !important;
  }
}

/* End:: Create Blog */
/* Start:: Profile */
.main-profile-info {
  z-index: 10;
  position: relative;
}

.profile-works img {
  width: 5.5rem;
  height: 5.5rem;
  border-radius: 0.3rem;
  margin-bottom: 1.25rem;
}

.profile-timeline {
  margin-block-end: 0;
}

.profile-timeline li {
  padding-inline-start: 5.313rem;
  position: relative;
  margin-block-end: 1.75rem;
}

.profile-timeline li .profile-timeline-avatar {
  position: absolute;
  inset-inline-start: 2.125rem;
  inset-block-start: 0.188rem;
}

.profile-timeline li:last-child {
  margin-block-end: 0;
}

.profile-timeline li:last-child::before {
  display: none;
}

.profile-timeline li::before {
  content: "";
  background-color: transparent;
  border: 1px dashed rgba(var(--dark-rgb), 0.1);
  height: 100%;
  position: absolute;
  inset-inline-start: 2.813rem;
  inset-block-start: 1.813rem;
}

.profile-timeline .profile-activity-media img {
  width: 4rem;
  height: 3rem;
  border-radius: 7px;
  margin: 0.25rem;
}

.profile-recent-posts {
  max-width: 10rem;
}

.profile-post-content {
  width: 42rem;
}

#profile-posts-scroll {
  max-height: 35rem;
}

@media (max-width: 420px) {
  .profile-post-link {
    max-width: 9.375rem;
  }
}

@media screen and (max-width: 1770px) and (min-width: 1400px) {
  .followers-list .list-group-item>div>div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 4rem;
  }
}

/* End:: Profile */
/* Start:: Aboutus */
.user-social-detail {
  margin: 0 auto;
  justify-content: center;
  display: flex;
}

.user-social-detail .social-profile {
  height: 35px;
  width: 35px;
  background: rgb(var(--light-rgb));
  font-size: 16px;
  line-height: 2.3;
  flex-shrink: 0;
  text-align: center;
  border-radius: 50%;
}

@media screen and (max-width: 1200px) {
  .about-img {
    width: 100%;
  }
}

.counter-icon {
  margin-block-end: 1.5rem;
  margin-block-start: 14px !important;
  display: inline-flex;
  width: 4rem;
  height: 4rem;
  padding: 1rem;
  border-radius: 7px;
  text-align: center;
  line-height: 4rem;
  font-size: 30px;
}

/* End:: Aboutus */
/* Start:: Full Calendar */
[dir=rtl] .fullcalendar-events-activity li {
  padding: 0.25rem 2rem 0.25rem 1rem;
}

.fullcalendar-events-activity li {
  margin-block-end: 0.75rem;
  font-size: 0.8125rem;
  padding: 0.25rem 1rem 0.25rem 2rem;
  position: relative;
}

.fullcalendar-events-activity li::before {
  position: absolute;
  content: "";
  width: 0.75rem;
  height: 0.75rem;
  border: 0.125rem solid var(--primary03);
  border-radius: 3.125rem;
  background-color: var(--custom-white);
  inset-inline-start: 0.25rem;
  inset-block-start: 0.563rem;
}

.fullcalendar-events-activity li::after {
  position: absolute;
  content: "";
  height: 100%;
  background-color: transparent;
  border-inline-end: 2px dashed var(--primary01);
  inset-inline-start: 0.563rem;
  inset-block-start: 1.25rem;
}

.fullcalendar-events-activity li:last-child {
  margin-block-end: 0;
}

.fullcalendar-events-activity li:last-child::after {
  border-inline-end: 0px dashed var(--default-border);
}

#full-calendar-activity {
  max-height: 21rem;
}

/* End:: Full Calendar */
/* Start:: Draggable Cards */
#draggable-left .card,
#draggable-right .card {
  cursor: move;
}

/* End:: Draggable Cards */
/* Start:: Back to Top */
.scrollToTop {
  background-color: var(--primary-color);
  color: #fff;
  position: fixed;
  bottom: 20px;
  inset-inline-end: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: none;
  z-index: 10000;
  height: 2.5rem;
  width: 2.5rem;
  background-repeat: no-repeat;
  background-position: center;
  transition: background-color 0.1s linear;
  border-radius: 7px;
  box-shadow: rgba(100, 100, 110, 0.2) 0 0.438rem 1.813rem 0;
}

/* End:: Back to Top */
[dir=rtl] .recruiter .btn-icon.btn-sm {
  transform: rotate(180deg);
}

[dir=rtl] .rtl-rotate {
  transform: rotate(180deg);
}

#address-add {
  min-height: calc(1.5em + 2.75rem + var(--bs-border-width) * 2);
}

/* Start:: Loader */
#loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--custom-white);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* End:: Loader */
/* Start:: sidebar-right */
.cover-image {
  background-size: cover !important;
}

#sidebar-right {
  width: 300px;
}

#sidebar-right .btn-close {
  filter: invert(1);
}

#sidebar-right .nav-tabs {
  white-space: nowrap;
}

#sidebar-right .nav-link {
  border: 0;
  background: none;
  color: var(--default-text-color);
  padding: 15px;
}

#sidebar-right .nav-link.active,
#sidebar-right .nav-link:hover {
  color: var(--primary-color);
}

#sidebar-right .feeds.avatar-rounded {
  display: inline-block;
  position: relative;
  text-align: center;
  width: 2rem;
  height: 2rem;
  line-height: 2.3rem;
}

#sidebar-right .feeds.avatar-rounded i {
  font-size: initial;
}

#sidebar-right .feeds.avatar-rounded::before {
  content: "";
  position: absolute;
  inset-inline-start: -15px;
  padding: 1px;
  height: 25px;
  background: var(--primary-color);
  inset-block-start: 4px;
}

#sidebar-right .feeds.avatar-rounded.bg-secondary-transparent::before {
  background: rgb(var(--secondary-rgb));
}

#sidebar-right .feeds.avatar-rounded.bg-danger-transparent::before {
  background: rgb(var(--danger-rgb));
}

#sidebar-right .feeds.avatar-rounded.bg-warning-transparent::before {
  background: rgb(var(--warning-rgb));
}

#sidebar-right .feeds.avatar-rounded.bg-pink-transparent::before {
  background: rgb(var(--pink-rgb));
}

#sidebar-right .feeds.avatar-rounded.bg-info-transparent::before {
  background: rgb(var(--info-rgb));
}

#sidebar-right .task-list {
  list-style: none;
  position: relative;
  margin: 0;
  padding: 0px 0 0;
  color: var(--default-text-color);
}

#sidebar-right .task-list:before {
  content: "";
  position: absolute;
  inset-block-start: 4px;
  inset-block-end: 0;
  height: 90%;
  inset-inline-start: 10px;
  border-inline-start: 1px solid var(--default-border);
}

#sidebar-right .task-list li {
  position: relative;
  margin-block-end: 23px;
  padding-inline-start: 35px;
}

#sidebar-right .task-list li:first-child {
  margin-block-start: 0.25rem;
}

#sidebar-right .task-list li .task-icon1 {
  position: absolute;
  inset-inline-start: 5px;
  inset-block-start: 2px;
  border-radius: 50%;
  padding: 2px;
  width: 12px;
  height: 12px;
  z-index: 2;
  font-size: 16px;
  text-align: center;
  line-height: 2;
  border: 2px solid var(--primary-color);
}

#sidebar-right .task-list li .task-icon-link i {
  font-size: 0.875rem !important;
}

/* End:: sidebar-right */
/* Start:: Bootstrap Updated Styles */
[data-theme-mode=dark] .bg-dark .h1,
[data-theme-mode=dark] .bg-dark .h2,
[data-theme-mode=dark] .bg-dark .h3,
[data-theme-mode=dark] .bg-dark .h4,
[data-theme-mode=dark] .bg-dark .h5,
[data-theme-mode=dark] .bg-dark .h6,
[data-theme-mode=dark] .bg-dark h1,
[data-theme-mode=dark] .bg-dark h2,
[data-theme-mode=dark] .bg-dark h3,
[data-theme-mode=dark] .bg-dark h4,
[data-theme-mode=dark] .bg-dark h5,
[data-theme-mode=dark] .bg-dark h6,
[data-theme-mode=dark] .card-bg-dark .h1,
[data-theme-mode=dark] .card-bg-dark .h2,
[data-theme-mode=dark] .card-bg-dark .h3,
[data-theme-mode=dark] .card-bg-dark .h4,
[data-theme-mode=dark] .card-bg-dark .h5,
[data-theme-mode=dark] .card-bg-dark .h6,
[data-theme-mode=dark] .card-bg-dark h1,
[data-theme-mode=dark] .card-bg-dark h2,
[data-theme-mode=dark] .card-bg-dark h3,
[data-theme-mode=dark] .card-bg-dark h4,
[data-theme-mode=dark] .card-bg-dark h5,
[data-theme-mode=dark] .card-bg-dark h6 {
  color: var(--custom-white) !important;
}

[data-theme-mode=dark] .bg-dark.card.custom-card,
[data-theme-mode=dark] .card-bg-dark.card.custom-card {
  color: var(--custom-white) !important;
}

[data-theme-mode=dark] .bg-dark.card.custom-card .card-body,
[data-theme-mode=dark] .bg-dark.card.custom-card .card-footer,
[data-theme-mode=dark] .card-bg-dark.card.custom-card .card-body,
[data-theme-mode=dark] .card-bg-dark.card.custom-card .card-footer {
  color: var(--custom-white) !important;
}

[data-theme-mode=dark] .bg-light,
[data-theme-mode=dark] .card-bg-light {
  color: var(--custom-black) !important;
}

[data-theme-mode=dark] .choices[data-type*=select-one] .choices__button {
  filter: invert(1);
}

[data-theme-mode=dark] #circle-custom .apexcharts-legend.apx-legend-position-left {
  background-color: var(--custom-white);
}

[data-theme-mode=dark] .navbar-nav .nav-link.active,
[data-theme-mode=dark] .navbar-nav .nav-link.show {
  color: #fff;
}

.d-inline-table {
  display: inline-table;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--default-text-color);
}

.table> :not(caption)>*>* {
  --bs-body-bg: var(--custom-white);
  --bs-table-bg: var(--custom-white);
  color: var(--default-text-color);
}

.card.custom-card {
  color: var(--default-text-color) !important;
}

[data-theme-mode=light][data-header-styles=transparent] .page-header-breadcrumb h4,
[data-theme-mode=light][data-default-header-styles=transparent] .page-header-breadcrumb h4 {
  color: var(--default-text-color) !important;
}

[data-theme-mode=light][data-header-styles=transparent] .app-header .main-header-container .form-control,
[data-theme-mode=light][data-default-header-styles=transparent] .app-header .main-header-container .form-control {
  background: rgba(0, 0, 0, 0.05) !important;
  color: var(--default-text-color);
  box-shadow: none;
}

[data-theme-mode=light] .page-header-breadcrumb h4 {
  color: #fff !important;
}

[data-header-styles=dark] .header-link.dropdown-toggle .user-name,
[data-header-styles=color] .header-link.dropdown-toggle .user-name,
[data-header-styles=gradient] .header-link.dropdown-toggle .user-name {
  color: #fff !important;
}

[data-default-header-styles=transparent] .page-header-breadcrumb,
[data-default-header-styles=light] .page-header-breadcrumb,
[data-default-header-styles=dark] .page-header-breadcrumb,
[data-default-header-styles=color] .page-header-breadcrumb,
[data-default-header-styles=gradient] .page-header-breadcrumb {
  margin: 0px 0px -32px 0px;
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label::after {
  background-color: transparent !important;
}

.form-floating>.form-control-plaintext~label::after,
.form-floating>.form-control:focus~label::after,
.form-floating>.form-control:not(:placeholder-shown)~label::after,
.form-floating>.form-select~label::after {
  background-color: transparent !important;
}

.form-floating>.form-control:not(:-moz-placeholder-shown)~label {
  color: var(--text-muted) !important;
  font-weight: 600;
}

.form-floating>.form-control-plaintext~label,
.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
  color: var(--text-muted) !important;
  font-weight: 600;
}

.dropdown-item-text {
  color: var(--default-text-color) !important;
}

.blockquote-footer {
  color: var(--default-text-color) !important;
}

.no-offcanvas-padding {
  padding-right: 0 !important;
  overflow: auto !important;
}

/* End:: Bootstrap Updated Styles */
.vr {
  background-color: var(--default-border);
  opacity: 1;
}

[dir=rtl] .dropdown-menu {
  --bs-position: start;
}

[dir=rtl] .dropdown-menu.dropdown-menu-end {
  --bs-position: end;
}

[dir=rtl] .transform-arrow {
  transform: rotate(180deg);
}

[dir=rtl] .app-sidebar .slide-menu.child1 .side-menu__item:before,
[dir=rtl] .app-sidebar .slide-menu.child2 .side-menu__item:before,
[dir=rtl] .app-sidebar .slide-menu.child3 .side-menu__item:before {
  transform: rotate(180deg);
}

[dir=rtl] .breadcrumb-item+.breadcrumb-item::before {
  padding-right: 0.5rem;
  padding-left: 0rem;
  transform: rotate(180deg);
}

#saleschart,
#leadschart,
#profitchart,
#costchart {
  width: 6rem !important;
  height: 4rem !important;
  filter: drop-shadow(-6px 12px 4px rgba(0, 0, 0, 0.1));
}

.dot-label {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 100%;
  margin-inline-end: 5px;
}

.bg-recentorder {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.bg-recentorder:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(to right, var(--primary-color) 100%, var(--primary01) 0%);
  inset-inline-end: 0;
  inset-inline-start: 0;
  inset-block-start: 0;
  opacity: 0.7;
}

.bg-recentorder h3 {
  position: inherit;
}

#back-chart {
  position: absolute;
  inset-inline-start: 0px;
  inset-inline-end: 0px;
  height: 300px;
  inset-block-start: 7rem;
}

#back-chart .apexcharts-grid {
  display: none;
}

#recentorders {
  z-index: 1;
  position: relative;
}

.task-list {
  list-style: none;
  position: relative;
  margin: 0;
  padding: 0px 0 0;
  color: var(--default-text-color);
}

.task-list:before {
  content: "";
  position: absolute;
  inset-block-start: 4px;
  inset-block-end: 0;
  height: 90%;
  inset-inline-start: 15px;
  border-inline-start: 1px solid var(--default-border);
}

.task-list li {
  position: relative;
  margin-block-end: 2.15rem;
  padding-inline-start: 40px;
}

.task-list li .task-icon {
  position: absolute;
  inset-inline-start: 10px;
  inset-block-start: 5px;
  border-radius: 50%;
  padding: 2px;
  width: 12px;
  height: 12px;
  z-index: 2;
}

#visitors-countries {
  height: 225px;
}

#visitors-countries #jvm-markers-labels-group {
  display: none;
}

#visitors-countries circle:nth-child(1) {
  fill: var(--primary-color);
}

#visitors-countries circle:nth-child(2) {
  fill: rgb(var(--secondary-rgb));
}

#visitors-countries circle:nth-child(3) {
  fill: rgb(var(--danger-rgb));
}

#visitors-countries circle:nth-child(4) {
  fill: rgb(var(--info-rgb));
}

#visitors-countries circle:nth-child(5) {
  fill: rgb(var(--warning-rgb));
}

.sales-icon {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  width: 34px;
  height: 34px;
  display: flex;
  line-height: 22px;
  padding: 8px;
  border-radius: 50%;
}

.sales-icon i {
  font-size: 18px;
}

.browser-stats h6 {
  font-size: 0.875rem;
  font-weight: 400;
}

.browser-stats img {
  background: rgb(var(--light-rgb));
  padding: 10px;
  border-radius: 50px;
  width: 40px;
  height: 40px;
}

@media (max-width: 480px) {
  .tabs-menu1 ul li {
    width: 100%;
    margin: 15px 15px 0 15px;
  }

  .tabs-menu1 ul li:last-child {
    margin-block-end: 15px;
  }

  .tabs-menu1 ul li a {
    width: 100%;
  }
}

.tabs-menu1 {
  font-size: 16px;
  font-weight: 500;
}

.tabs-menu1 ul li a {
  padding: 0 20px 0 20px;
  display: block;
  color: var(--default-text-color);
}

.tabs-menu1 ul li.active {
  border-block-end: 1px solid var(--primary-color);
}

.nav.product-sale a.active {
  background-color: transparent;
  border-block-end: none;
  color: var(--primary-color);
}

.product-table label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.product-table label input {
  margin-inline-start: 0.5em;
  display: inline-block;
  width: auto;
  margin-block-start: 2px;
}

.product-table label .choices__list--dropdown .choices__item--selectable {
  padding: 10px !important;
}

.product-table label .choices__list--dropdown .choices__item--selectable::after {
  display: none;
}

.product-table label .choices__list--dropdown .choices__item--selectable.is-highlighted,
.product-table label .choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
  background-color: var(--primary-color) !important;
  color: #fff;
}

.chart-dropshadow {
  filter: drop-shadow(-6px 12px 4px rgba(0, 0, 0, 0.1));
}

[data-theme-mode=dark] .chart-dropshadow {
  filter: drop-shadow(-6px 5px 4px #2a2635);
}

#particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--custom-white);
}

.error-page {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.error-text {
  font-size: 8.25rem;
  font-weight: 500;
  line-height: 1;
}

@media (max-width: 575.98px) {
  .error-text {
    font-size: 3rem;
  }
}

/* Start::app-header */
.app-header {
  max-width: 100%;
  z-index: 100;
  position: fixed;
  inset-block-start: 0;
  inset-inline: 0;
  background: var(--header-bg);
  border-block-end: 1px solid var(--header-border-color);
  transition: all 0.1s ease;
}

.app-header #mainHeaderProfile {
  color: var(--header-prime-color);
}

.app-header .main-header-dropdown:before,
.app-header .main-header-dropdown:after {
  inset-inline-start: auto;
  inset-inline-end: 18px;
}

.app-header .main-header-dropdown:before {
  content: "";
  width: 13px;
  height: 13px;
  background: var(--custom-white);
  position: absolute;
  inset-block-start: -7px;
  inset-inline-end: 14px !important;
  transform: rotate(45deg);
  border-block-start: 1px solid var(--default-border);
}

@media (min-width: 992px) {
  .app-header {
    padding-inline-start: 17rem;
  }
}

.header-icon-badge {
  position: absolute;
  padding: 0.15rem 0.25rem;
  inset-block-start: 1.2rem;
  inset-inline-end: 0.45rem;
  font-size: 0.625rem;
}

.header-icon-badge.pulse {
  inset-block-start: 1.5rem;
  inset-inline-end: 1rem;
}

.related-app {
  text-align: center;
  border-radius: 7px;
  border: 1px solid var(--default-border);
}

.related-app:hover {
  background-color: rgb(var(--light-rgb));
}

.header-profile-dropdown {
  min-width: 11rem;
}

/* End::app-header */
/* Start::main-header-container */
.main-header-container {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  height: 100%;
}

.main-header-container .header-content-left,
.main-header-container .header-content-right {
  display: flex;
  align-items: stretch;
}

.main-header-container .header-element {
  display: flex;
  align-items: stretch;
}

.main-header-container .header-link {
  display: flex;
  align-items: center;
  padding: 1rem 0.65rem;
}

.main-header-container .header-link:hover .header-link-icon,
.main-header-container .header-link.show .header-link-icon {
  fill: var(--header-prime-color);
  color: var(--header-prime-color);
}

.main-header-container .header-link-icon {
  width: 1.75rem;
  height: 1.75rem;
  font-size: 1.25rem;
  color: var(--header-prime-color);
  fill: var(--header-prime-color);
  transition: all ease 0.05s;
  position: relative;
  padding: 0.25rem;
  border-radius: 50%;
}

.main-header-container .dropdown-toggle {
  text-decoration: none;
}

.main-header-container .dropdown-toggle::after {
  content: none;
}

.main-header-container .country-selector .main-header-dropdown .dropdown-item {
  padding: 0.5rem;
}

.main-header-container .main-profile-user .dropdown-menu {
  width: 12rem;
}

.main-header-container .main-profile-user .dropdown-item {
  font-weight: 400;
  font-size: 0.813rem;
  color: var(--header-prime-color);
  padding: 0.75rem 1.5rem !important;
  display: flex;
  align-items: center;
}

.main-header-container .main-profile-user .dropdown-item:hover {
  color: var(--header-prime-color);
}

.main-header-container .main-profile-user .dropdown-item:hover i {
  color: var(--header-prime-color);
  opacity: 1;
}

.main-header-container .main-header-dropdown {
  box-shadow: 0 10px 40px 0 rgba(104, 113, 123, 0.2);
  inset-block-start: -3px !important;
  border-color: var(--default-border);
}

.main-header-container .main-header-dropdown.header-shortcuts-dropdown {
  width: 22rem;
}

.main-header-container .main-header-dropdown .dropdown-item {
  padding: 1rem;
  border-block-end: 1px solid var(--default-border);
}

.main-header-container .main-header-dropdown .dropdown-item:last-child {
  border-block-end: 0;
}

.main-header-container .cart-dropdown .main-header-dropdown {
  width: 25rem;
}

.main-header-container .notifications-dropdown .main-header-dropdown {
  width: 18rem;
}

.main-header-container .message-dropdown .main-header-dropdown {
  width: 19rem;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(6rem);
  }

  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
    transform: translateY(6rem);
  }
}

[dir=rtl] .sidemenu-toggle .open-toggle {
  transform: rotate(180deg);
}

/* End::main-header-container */
/* Start::Header-dropdown */
.header-product-item {
  padding-inline-start: 0;
  display: flex;
}

.header-product-item li {
  position: relative;
  list-style-type: none;
  font-size: 0.75rem;
  font-weight: 400;
  color: var(--text-muted);
  padding-inline-end: 0.938rem;
}

.header-product-item li:nth-child(2):before,
.header-product-item li:nth-child(3):before,
.header-product-item li:nth-child(4):before {
  position: absolute;
  content: "";
  inset-inline-start: -0.438rem;
  width: 1px;
  inset-block-start: 0.25rem;
  height: 0.625rem;
  background-color: var(--text-muted);
  opacity: 0.1;
}

.header-cart-remove {
  border-radius: 0.25rem;
  margin-inline-start: 0.75rem;
  border: 1px solid var(--default-border);
  font-size: 16px;
  padding: 0;
  border-radius: 50%;
}

.header-cart-remove i {
  font-size: 16px;
  display: block;
  padding: 0.5rem;
  color: rgb(var(--danger-rgb));
  border-radius: 50%;
}

.header-cart-remove:hover {
  background-color: rgba(var(--danger-rgb), 0.1);
  color: rgb(var(--danger-rgb));
}

/* End::Header-dropdown */
/* Start::header-search */
#searchModal .form-control {
  position: relative;
}

#searchModal .input-group {
  border: 2px solid var(--primary-color);
  border-radius: 0.25rem;
}

#searchModal .input-group i {
  color: var(--text-muted);
}

/* End::header-search */
/* Start::header-country-selector */
.country-selector .header-link img {
  width: 1.25rem;
  height: 1.25rem;
}

.country-selector .dropdown-menu img {
  width: 1rem;
  height: 1rem;
}

/* End::header-country-selector */
/* Start:header dropdowns scroll */
#header-shortcut-scroll,
#header-notification-scroll,
#header-message-scroll,
#header-cart-items-scroll {
  max-height: 18rem;
}

/* End:header dropdowns scroll */
/* Start::header badge pulse */
.pulse {
  display: block;
  cursor: pointer;
  animation: pulse-secondary 2s infinite;
}

.pulse.pulse-secondary {
  box-shadow: 0 0 0 rgba(var(--secondary-rgb), 0.4);
}

@keyframes pulse-secondary {
  0% {
    box-shadow: 0 0 0 0 rgba(var(--secondary-rgb), 0.4);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(var(--secondary-rgb), 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(var(--secondary-rgb), 0);
  }
}

/* End::header badge pulse */
/* Start::Header theme-mode icon style */
[data-theme-mode=light] .layout-setting .dark-layout {
  display: none;
}

[data-theme-mode=light] .layout-setting .light-layout {
  display: block;
}

.layout-setting .dark-layout {
  display: none;
}

.layout-setting .light-layout {
  display: block;
}

[data-theme-mode=dark] .layout-setting .light-layout {
  display: none;
}

[data-theme-mode=dark] .layout-setting .dark-layout {
  display: block;
}

/* End::Header theme-mode icon style */
/* Start::Header fullscreen responsive */
@media (max-width: 767.98px) {
  .header-element.header-fullscreen {
    display: none;
  }
}

/* End::Header fullscreen responsive */
/* Start::Responsive header dropdowns */
@media (max-width: 575.98px) {
  .app-header .dropdown-menu {
    width: 100%;
  }
}

/* End::Responsive header dropdowns */
/* Start::toggle */
.animated-arrow.hor-toggle {
  text-align: center;
  width: 2rem;
  font-size: 1.2rem;
  position: relative;
  margin: 0.3125rem;
  margin-inline-end: 0.625rem;
}

.animated-arrow {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  z-index: 102;
  transition: all 0.05s ease-in-out;
  cursor: pointer;
  padding: 0.3125rem;
  margin-block-start: 0.375rem;
  margin-block-end: 0;
  margin-inline-start: 0.5rem;
  margin-inline-end: 0;
}

.animated-arrow.hor-toggle span {
  vertical-align: middle;
}

.animated-arrow span {
  cursor: pointer;
  height: 0.125rem;
  width: 0.75rem;
  background: var(--header-prime-color);
  position: absolute;
  display: block;
  content: "";
  transition: all 0.05s ease-in-out;
}

.animated-arrow span:before,
.animated-arrow span:after {
  transition: all 0.05s ease-in-out;
}

.animated-arrow span:before {
  inset-block-start: -0.375rem;
  width: 1.4375rem;
}

.animated-arrow span:after {
  bottom: -0.375rem;
  width: 15px;
}

.animated-arrow span:before,
.animated-arrow span:after {
  cursor: pointer;
  height: 0.125rem;
  width: 1.0625rem;
  background: var(--header-prime-color);
  position: absolute;
  display: block;
  content: "";
}

[data-toggled=icon-overlay-close] .animated-arrow span,
[data-toggled=close-menu-close] .animated-arrow span,
[data-toggled=icon-text-close] .animated-arrow span,
[data-toggled=detached-close] .animated-arrow span,
[data-toggled=menu-click-closed] .animated-arrow span,
[data-toggled=menu-hover-closed] .animated-arrow span,
[data-toggled=icon-click-closed] .animated-arrow span,
[data-toggled=icon-hover-closed] .animated-arrow span {
  background-color: transparent;
  background: none !important;
}

[data-toggled=icon-overlay-close] .animated-arrow span:before,
[data-toggled=close-menu-close] .animated-arrow span:before,
[data-toggled=icon-text-close] .animated-arrow span:before,
[data-toggled=detached-close] .animated-arrow span:before,
[data-toggled=menu-click-closed] .animated-arrow span:before,
[data-toggled=menu-hover-closed] .animated-arrow span:before,
[data-toggled=icon-click-closed] .animated-arrow span:before,
[data-toggled=icon-hover-closed] .animated-arrow span:before {
  transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  bottom: 0px;
}

[data-toggled=icon-overlay-close] .animated-arrow span:before,
[data-toggled=close-menu-close] .animated-arrow span:before,
[data-toggled=icon-text-close] .animated-arrow span:before,
[data-toggled=detached-close] .animated-arrow span:before,
[data-toggled=menu-click-closed] .animated-arrow span:before,
[data-toggled=menu-hover-closed] .animated-arrow span:before,
[data-toggled=icon-click-closed] .animated-arrow span:before,
[data-toggled=icon-hover-closed] .animated-arrow span:before {
  inset-block-start: -1px;
}

[data-toggled=icon-overlay-close] .animated-arrow span:after,
[data-toggled=close-menu-close] .animated-arrow span:after,
[data-toggled=icon-text-close] .animated-arrow span:after,
[data-toggled=detached-close] .animated-arrow span:after,
[data-toggled=menu-click-closed] .animated-arrow span:after,
[data-toggled=menu-hover-closed] .animated-arrow span:after,
[data-toggled=icon-click-closed] .animated-arrow span:after,
[data-toggled=icon-hover-closed] .animated-arrow span:after {
  transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

[data-toggled=icon-overlay-close] .animated-arrow span:after,
[data-toggled=close-menu-close] .animated-arrow span:after,
[data-toggled=icon-text-close] .animated-arrow span:after,
[data-toggled=detached-close] .animated-arrow span:after,
[data-toggled=menu-click-closed] .animated-arrow span:after,
[data-toggled=menu-hover-closed] .animated-arrow span:after,
[data-toggled=icon-click-closed] .animated-arrow span:after,
[data-toggled=icon-hover-closed] .animated-arrow span:after {
  width: 1.0625rem;
  inset-block-start: -1px;
}

/* End::toggle */
/* Start::header notification dropdown */
.header-notification-text {
  max-width: 14.5rem;
}

/* Start::header notification dropdown */
/* Start::header-search */
.headersearch {
  display: none;
}

.header-search {
  display: none;
}

.header-search.searchdrop {
  display: block;
}

.searchdrop {
  border-radius: 0.4rem;
  border: 1px solid var(--default-border);
  background-color: var(--custom-white);
  box-shadow: 0 0.4rem 0.5rem var(--black-1);
  position: relative;
  margin-block-end: 1.5rem;
  width: 350px;
  display: block;
  position: absolute;
  inset-block-start: 59px;
}

.search-app:hover a {
  color: var(--header-prime-color);
}

.search-tags {
  font-size: 0.75rem;
  color: var(--default-text-color);
  border: 1px solid var(--default-border);
  border-radius: 10rem;
  padding: 0.125rem 0.55rem;
  line-height: 1.2rem;
  display: inline-flex;
  align-items: center;
  font-weight: 400;
  margin: 0.25rem 0;
}

.search-tags .tag-addon:last-child {
  border-radius: 0.4rem;
}

.search-tags a.tag-addon {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  padding: 0 0.5rem;
  color: inherit;
  text-decoration: none;
  margin-inline-end: -0.4rem;
  margin-inline-start: 0.5rem;
  text-align: center;
  min-width: 1.5rem;
}

.search-tags a.tag-addon i {
  vertical-align: middle;
  margin: 0 -0.25rem;
  line-height: 1.5px;
  font-size: 0.813rem;
}

.search-tags i {
  font-size: 0.813rem;
}

.main-header-center {
  position: relative;
  align-items: center;
}

@media (min-width: 1165px) {
  .main-header-center .form-control {
    width: 350px;
  }
}

@media (min-width: 992px) {
  .main-header-center .form-control {
    background: transparent;
    border: 1px solid var(--header-border-color);
    border-radius: 7px;
    color: var(--default-text-color);
    height: 2.6rem;
  }

  .main-header-center .btn {
    position: absolute;
    inset-block-start: 17px;
    inset-inline-end: 20px;
    background-color: transparent;
    height: 2.5rem;
    color: var(--default-text-color);
    transition: none;
    font-size: 16px;
    border: 0;
    opacity: 0.5;
  }
}

#typehead::-moz-placeholder {
  color: var(--default-text-color) !important;
}

#typehead::placeholder {
  color: var(--default-text-color) !important;
}

.drop-heading {
  padding: 1rem 1rem;
  border-bottom: 1px solid var(--default-border);
}

/* End::header-search */
/* Start:Choices JS */
.choices__inner {
  padding: 0.5rem 0.85rem !important;
  background-color: var(--form-control-bg) !important;
  border-radius: 0.35rem !important;
  font-size: 14px !important;
  border: 1px solid var(--input-border) !important;
  min-height: auto !important;
  line-height: 1.8;
}

.choices__input {
  padding: 0 !important;
}

.choices__list--single {
  padding-block: 0 !important;
  padding-inline-start: 0;
  padding-inline-end: 1rem !important;
}

.choices[data-type*=select-one]::after {
  inset-inline-end: 0.7188rem !important;
}

.choices[data-type*=select-one]::after {
  color: var(--text-muted) !important;
}

.choices[data-type*=select-one] .choices__input {
  padding: 0.625rem !important;
}

.choices__list--dropdown {
  display: none;
}

.choices__list--dropdown.is-active {
  display: block;
}

.choices__list--dropdown,
.choices__list[aria-expanded] {
  background-color: var(--custom-white) !important;
  border: 1px solid var(--default-border) !important;
  z-index: 99 !important;
  border-radius: 0 0 7px 7px !important;
}

.choices[data-type*=select-one] .choices__input {
  border-block-end: 1px solid var(--default-border) !important;
  background-color: var(--custom-white) !important;
  color: var(--default-text-color);
}

.choices__list--dropdown .choices__item--selectable.is-highlighted {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}

.choices__list--dropdown .choices__list {
  max-height: 250px !important;
}

.choices[data-type*=select-one]::after {
  border-color: var(--text-muted) transparent transparent !important;
}

.choices__input {
  background-color: transparent !important;
  color: var(--custom-black);
}

.choices__list--multiple .choices__item {
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
}

.choices[data-type*=select-multiple] .choices__button,
.choices[data-type*=text] .choices__button {
  border-inline-start: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.choices__list--multiple .choices__item {
  margin-block-end: 1px !important;
}

.choices__list--single .choices__item {
  color: var(--default-text-color) !important;
}

.choices__input {
  margin-block-end: 0 !important;
}

.choices__list--multiple .choices__item {
  border-radius: 0.25rem !important;
  padding: 0.025rem 0.625rem !important;
}

.form-control-select-sm .choices__inner {
  padding: 0.275rem !important;
}

.choices[data-type*=select-one].is-open::after {
  margin-top: -0.156rem !important;
}

.input-group .choices__inner {
  border-radius: 0 7px 7px 0 !important;
}

.choices__heading {
  border-bottom: 1px solid var(--default-border) !important;
  color: var(--text-muted) !important;
}

[dir=rtl] .input-group .choices__inner {
  border-radius: 7px 0 0 7px !important;
}

[dir=rtl] .choices[data-type*=select-one]::after {
  left: 0.7188rem;
  right: inherit;
}

[dir=rtl] .choices[data-type*=select-one] .choices__button {
  left: 0;
  right: inherit;
  margin-left: 1.5625rem;
  margin-right: inherit;
}

[dir=rtl][data-theme-mode=dark] .choices[data-type*=select-one] .choices__button {
  filter: invert(1);
}

[dir=rtl] .choices[data-type*=select-multiple] .choices__button,
[dir=rtl] .choices[data-type*=text] .choices__button {
  margin: 0 8px 0 -4px;
  padding-right: 1rem;
  padding-left: inherit;
  border-right: 0px solid #008fa1;
  border-left: 0;
}

.choices__list--dropdown,
.choices__list[aria-expanded] {
  box-shadow: rgba(149, 157, 165, 0.2) 0 0.5rem 1.5rem !important;
}

[data-theme-mode=dark] .choices[data-type*=select-one] .choices__button {
  filter: invert(1);
}

/* End:Choices JS */
/* Start:Apex Charts */
#radialbar-multiple .apexcharts-text.apexcharts-datalabel-label,
#gradient-circle .apexcharts-text.apexcharts-datalabel-label {
  fill: var(--default-text-color) !important;
}

#marketCap .apexcharts-canvas line {
  stroke: var(--default-border);
}

.apexcharts-legend {
  padding: 0 1.25rem !important;
}

.apexcharts-tooltip {
  box-shadow: none !important;
}

.apexcharts-tooltip-marker {
  margin-inline-end: 0.625rem;
}

.apexcharts-tooltip.apexcharts-theme-light {
  border: 1px solid var(--default-border) !important;
  background: var(--custom-white) !important;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: var(--custom-white) !important;
  border-block-end: 1px solid var(--default-border) !important;
}

.apexcharts-grid line,
.apexcharts-xaxis line,
.apexcharts-grid-borders line {
  stroke: var(--default-border);
}

.apexcharts-radialbar-track.apexcharts-track path {
  stroke: rgb(var(--light-rgb));
}

.apexcharts-selection-rect {
  fill: var(--custom-black);
}

.apexcharts-menu {
  background: var(--custom-white) !important;
  border: 1px solid var(--default-border) !important;
  border-radius: 7px !important;
}

.apexcharts-theme-light .apexcharts-menu-item:hover {
  background: rgb(var(--light-rgb)) !important;
}

.apexcharts-inner.apexcharts-graphical line.apexcharts-xaxis-tick {
  stroke: transparent !important;
}

#column-rotated-labels .apexcharts-xaxis-texts-g {
  transform: translateY(2.5rem);
}

#chart-year,
#chart-quarter {
  background: var(--custom-white) !important;
  border: 1px solid var(--default-border) !important;
}

.apexcharts-bar-series.apexcharts-plot-series .apexcharts-series .apexcharts-bar-area {
  stroke: var(--default-border) !important;
}

.apexcharts-treemap .apexcharts-series.apexcharts-treemap-series rect {
  stroke: var(--custom-white) !important;
}

.apexcharts-series.apexcharts-pie-series .apexcharts-pie-area {
  stroke: var(--custom-white) !important;
}

.apexcharts-datalabels-group .apexcharts-text.apexcharts-datalabel-value {
  fill: var(--default-text-color);
}

.apexcharts-radialbar-hollow {
  fill: var(--custom-white);
}

.apexcharts-radar-series.apexcharts-plot-series polygon,
.apexcharts-radar-series.apexcharts-plot-series line {
  stroke: var(--default-border);
}

.apexcharts-pie line,
.apexcharts-pie circle {
  stroke: var(--default-border);
}

.apexcharts-pie text {
  fill: var(--default-text-color);
}

.apexcharts-canvas .apexcharts-toolbar {
  z-index: 1;
}

@media screen and (max-width: 500px) {
  .apexcharts-toolbar {
    position: relative !important;
  }
}

.apexcharts-subtitle-text {
  fill: var(--text-muted);
}

#polararea-basic .apexcharts-pie text {
  fill: var(--custom-black);
}

.apexcharts-pie .apexcharts-datalabels rect {
  fill: transparent;
}

.apexcharts-pie text {
  fill: #fff;
}

[dir=rtl] .apexcharts-canvas {
  direction: ltr;
}

.apexcharts-boxPlot-area {
  stroke: var(--default-text-color) !important;
}

/* End:Apex Charts */
/* Start:Full Calendar */
.fc-theme-standard .fc-scrollgrid.fc-scrollgrid-liquid {
  border: 0px !important;
  border-top: 1px solid var(--default-border) !important;
}

.fc .fc-scrollgrid-section-footer>*,
.fc .fc-scrollgrid-section-header>* {
  border-bottom-width: 0 !important;
}

.fc-daygrid-block-event .fc-event-time,
.fc-daygrid-block-event .fc-event-title {
  padding: 0 0.25rem !important;
}

.fc .fc-button-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.fc .fc-non-business {
  background: var(--custom-white) !important;
}

.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  opacity: 0.9;
}

.fc .fc-button-primary:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled):active:focus {
  box-shadow: none !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid var(--default-border) !important;
  border-top: 0px !important;
}

.fc-list-table td,
.fc-list-table th {
  border-left: 0 !important;
  border-right: 0 !important;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: var(--primary01) !important;
}

.fc-theme-standard .fc-list {
  border: 1px solid var(--default-border) !important;
}

.fc .fc-list-event:hover td {
  background-color: rgb(var(--light-rgb)) !important;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror,
.fc-timegrid-more-link {
  box-shadow: none !important;
}

.fc-theme-standard .fc-list-day-cushion {
  background-color: rgb(var(--light-rgb)) !important;
}

.fc-theme-standard .fc-scrollgrid {
  border: 1px solid var(--default-border) !important;
}

.fc-theme-bootstrap5 .fc-list,
.fc-theme-bootstrap5 .fc-scrollgrid,
.fc-theme-bootstrap5 td,
.fc-theme-bootstrap5 th {
  border: 1px solid var(--default-border) !important;
}

@media (max-width: 420px) {
  .fc-scroller.fc-scroller-liquid {
    overflow: scroll !important;
  }
}

@media (max-width: 380px) {
  .fc .fc-daygrid-day-bottom {
    font-size: 0.75em !important;
    padding: 0px 3px 0 !important;
  }

  .fc .fc-daygrid-more-link {
    z-index: 99 !important;
  }
}

@media (max-width: 767.98px) {
  .fc .fc-toolbar {
    display: block !important;
  }

  .fc-toolbar-chunk {
    margin-block-start: 0.5rem;
  }
}

/* End:Full Calendar */
/* Start:Pickers */
.flatpickr-calendar {
  background-color: var(--custom-white) !important;
  box-shadow: 0 0.125rem 0 rgba(10, 10, 10, 0.04) !important;
  border: 1px solid var(--default-border) !important;
  font-size: 14px !important;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  color: var(--black-9) !important;
  fill: var(--black-9) !important;
}

.flatpickr-monthDropdown-months,
.numInput {
  color: var(--custom-black) !important;
}

.flatpickr-day.today.inRange {
  color: var(--primary-color) !important;
}

.dayContainer {
  padding: 0.25rem !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: var(--custom-white) !important;
  font-size: 0.813rem;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 1.25rem !important;
  height: 1.25rem !important;
  padding: 0.25rem;
  fill: var(--primary-color);
}

.flatpickr-day.inRange {
  box-shadow: none !important;
}

.flatpickr-calendar.open {
  z-index: 106 !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  background-color: var(--primary01) !important;
  border-radius: 0.25rem !important;
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after,
.flatpickr-calendar.arrowBottom:before {
  border-top-color: var(--text-muted) !important;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: var(--text-muted) !important;
}

.flatpickr-day.selected.startRange+.endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange+.endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange+.endRange:not(:nth-child(7n+1)) {
  box-shadow: none !important;
}

.flatpickr-day {
  color: var(--default-text-color) !important;
  font-weight: 500 !important;
}

.flatpickr-day.nextMonthDay,
.flatpickr-day.prevMonthDay {
  opacity: 0.5 !important;
}

.flatpickr-day.today {
  border-color: var(--primary-color) !important;
  background-color: var(--primary-color) !important;
  color: #fff !important;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: var(--primary-color) !important;
  color: #fff !important;
  border-color: var(--primary-color) !important;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  background: var(--default-background) !important;
  border-color: var(--default-border) !important;
}

.flatpickr-day.today:hover {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: #fff !important;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  border-top: 1px solid var(--default-border) !important;
}

.flatpickr-calendar.arrowTop:after,
.flatpickr-calendar.arrowTop:before {
  border-bottom-color: var(--default-border) !important;
}

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: var(--default-background) !important;
}

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  color: var(--text-muted) !important;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
  inset-inline-start: 0 !important;
}

.flatpickr-months,
.flatpickr-weekdays {
  background-color: var(--primary01) !important;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  inset-block-start: 0 !important;
  padding-block-start: 0.313rem !important;
  padding-inline: 0.313rem !important;
  padding-block-end: 0 !important;
  color: var(--primary-color) !important;
  fill: var(--primary-color) !important;
}

.flatpickr-weekdays {
  border-block-end: 1px solid var(--default-border) !important;
}

.numInputWrapper span.arrowUp {
  top: -0.125rem !important;
}

.flatpickr-current-month .numInputWrapper {
  width: 3.5rem !important;
}

.flatpickr-calendar.hasTime.noCalendar {
  width: 150px;
}

[dir=rtl] .flatpickr-months .numInputWrapper span {
  left: 0 !important;
  right: inherit !important;
}

[dir=rtl] .flatpickr-input {
  text-align: start;
}

@media (max-width: 575.98) {
  .flatpickr-calendar {
    width: 250px !important;
  }
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  margin: -1px 2.25rem 0 0 !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-current-month input.cur-year {
  padding-block: 0 !important;
  padding-inline-start: 0 !important;
  padding-inline-end: 0.5ch !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
}

.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  stroke: var(--primary-color);
}

.flatpickr-day {
  border-radius: 7px !important;
}

.numInputWrapper:hover {
  background: transparent !important;
}

.numInputWrapper span {
  border: 0px !important;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-block-end-color: var(--primary-color) !important;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #fff !important;
}

.numInputWrapper span:hover {
  background: transparent !important;
}

.numInputWrapper span.arrowUp:after {
  border-inline-start: 0.25rem solid transparent !important;
  border-inline-end: 0.25rem solid transparent !important;
  border-block-end: 0.25rem solid var(--primary05) !important;
  inset-block-start: 75% !important;
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-block-start-color: var(--primary-color) !important;
}

.numInputWrapper span.arrowDown:after {
  border-inline-start: 0.25rem solid transparent !important;
  border-inline-end: 0.25rem solid transparent !important;
  border-block-start: 0.25rem solid var(--primary05) !important;
  inset-block-start: 15% !important;
}

span.flatpickr-weekday {
  color: var(--primary08) !important;
  font-weight: 700 !important;
}

.flatpickr-months .flatpickr-month {
  color: var(--primary-color) !important;
  fill: var(--primary-color) !important;
}

.flatpickr-monthDropdown-months,
.numInput {
  color: var(--primary-color) !important;
}

.pcr-app {
  background: var(--custom-white) !important;
}

.pcr-app .pcr-interaction .pcr-result {
  color: var(--default-text-color) !important;
  background: var(--default-background) !important;
}

.theme-container button,
.theme-container1 button,
.theme-container2 button {
  display: none;
}

.pcr-app[data-theme=classic] .pcr-selection .pcr-color-preview {
  margin-inline-end: 0.75em !important;
}

.pcr-app[data-theme=classic] .pcr-selection .pcr-color-chooser,
.pcr-app[data-theme=classic] .pcr-selection .pcr-color-opacity {
  margin-inline-start: 0.75em !important;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  box-shadow: 1px 0 0 var(--default-border) !important;
}

/* End:Pickers */
/* Start:noUi Slider */
.noUi-horizontal .noUi-handle {
  width: 1rem !important;
  height: 1rem !important;
  inset-inline-end: -0.063rem !important;
  top: -0.375rem !important;
}

.noUi-handle:after,
.noUi-handle:before {
  height: 0.35rem !important;
  width: 1px !important;
  inset-inline-start: 0.3rem !important;
  top: 0.25rem !important;
}

.noUi-handle:after {
  inset-inline-start: 0.45rem !important;
}

.noUi-horizontal {
  height: 0.35rem !important;
}

.noUi-vertical {
  width: 0.35rem !important;
}

.noUi-vertical .noUi-handle {
  width: 1rem !important;
  height: 1rem !important;
}

.noUi-target {
  background: var(--default-background) !important;
  border: 1px solid var(--default-border) !important;
  box-shadow: 0 0.125rem 0 rgba(10, 10, 10, 0.04) !important;
}

.noUi-handle {
  border: 1px solid var(--default-border) !important;
  background: var(--custom-white) !important;
  box-shadow: 0 0.125rem 0 rgba(10, 10, 10, 0.04) !important;
}

#result {
  border: 1px solid var(--default-border) !important;
}

.noUi-handle:after,
.noUi-handle:before {
  background: var(--black-3) !important;
}

.noUi-marker {
  position: absolute;
  background: var(--default-border) !important;
}

.noUi-tooltip {
  border: 1px solid var(--default-border) !important;
  border-radius: 7px !important;
  background: var(--custom-white) !important;
  color: var(--default-text-color) !important;
  padding: 0.25rem 0.75rem !important;
  font-size: 14px !important;
  line-height: 1 !important;
}

#slider-fit {
  padding: 0 1rem;
}

.noUi-connect {
  background: var(--primary-color) !important;
}

.noUi-vertical .noUi-handle {
  bottom: -0.275rem !important;
  inset-inline-end: -0.375rem;
}

#secondary-colored-slider .noUi-connect {
  background-color: rgb(var(--secondary-rgb)) !important;
}

#warning-colored-slider .noUi-connect {
  background-color: rgb(var(--warning-rgb)) !important;
}

#info-colored-slider .noUi-connect {
  background-color: rgb(var(--info-rgb)) !important;
}

#success-colored-slider .noUi-connect {
  background-color: rgb(var(--success-rgb)) !important;
}

#danger-colored-slider .noUi-connect {
  background-color: rgb(var(--danger-rgb)) !important;
}

#slider-round {
  height: 0.625rem !important;
}

#slider-round .noUi-handle {
  height: 1.125rem !important;
  width: 1.125rem !important;
  inset-block-start: -0.313rem !important;
  inset-inline-end: -0.563rem;
  /* half the width */
  border-radius: 50px !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--custom-white) !important;
}

#slider-round .noUi-handle:before,
#slider-round .noUi-handle:after {
  display: none !important;
}

#slider-square {
  border-radius: 0 !important;
}

#slider-square .noUi-handle {
  border: 0 !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  background: var(--primary-color) !important;
  height: 1.125rem !important;
  width: 1.125rem !important;
  inset-block-start: -0.45rem !important;
  inset-inline-end: -0.563rem !important;
}

#slider-square .noUi-handle:before,
#slider-square .noUi-handle:after {
  display: none !important;
}

#color1,
#color2,
#color3 {
  margin: 0.625rem !important;
  display: inline-block !important;
  height: 12.5rem !important;
}

#colorpicker {
  height: 15rem !important;
  width: 19.375rem !important;
  margin: 0 auto !important;
  padding: 0.625rem !important;
  border: 1px solid var(--default-border) !important;
}

#result {
  margin-block-start: 4.25rem;
  margin-inline-end: 0px;
  margin-block-end: 4.25rem;
  margin-inline-start: 4rem;
  height: 6.25rem;
  width: 6.25rem;
  display: inline-block;
  vertical-align: top;
  color: var(--gray-5);
  background: var(--gray-5);
  border: 1px solid #fff;
  box-shadow: 0 0 0.625rem;
}

#color1 .noUi-connect {
  background: rgb(var(--danger-rgb)) !important;
}

#color2 .noUi-connect {
  background: rgb(var(--secondary-rgb)) !important;
}

#color3 .noUi-connect {
  background: var(--primary-color) !important;
}

#slider-hide .noUi-tooltip {
  display: none;
}

#slider-hide .noUi-active .noUi-tooltip {
  display: block;
}

.c-1-color {
  background: rgb(var(--secondary-rgb)) !important;
}

.c-2-color {
  background: rgb(var(--warning-rgb)) !important;
}

.c-3-color {
  background: rgb(var(--info-rgb)) !important;
}

.c-4-color {
  background: rgb(var(--danger-rgb)) !important;
}

.c-5-color {
  background: #4d5ddb !important;
}

#slider-toggle {
  height: 3.125rem;
}

#slider-toggle.off .noUi-handle {
  border-color: rgb(var(--success-rgb)) !important;
}

/* End:noUi Slider */
/* Start::Gallery */
.glightbox {
  overflow: hidden;
}

@media (min-width: 769px) {
  .gslide-image img {
    border-radius: 7px;
  }

  .glightbox-clean .gclose,
  .glightbox-clean .gnext,
  .glightbox-clean .gprev {
    background-color: rgba(255, 255, 255, 0.05) !important;
    width: 2.5rem !important;
    height: 2.5rem !important;
    padding: 0.875rem;
  }
}

/* End::Gallery */
/* Start::Calendar */
#external-events .fc-event {
  cursor: move;
  margin: 0 0 0.4rem 0;
  padding: 0.375rem 0.75rem;
  font-size: 0.75rem;
  border-radius: 0.35rem;
}

#calendar-container {
  position: relative;
  z-index: 1;
}

#calendar {
  max-width: 68.75rem;
  margin: 1.25rem auto;
}

/* End::Calendar */
/* Start::Leaflet Maps */
#map,
#map1,
#map-popup,
#map-custom-icon,
#interactive-map {
  height: 18.75rem;
  z-index: 10;
}

/* End::Leaflet Maps */
/* Start::Vector Maps */
#vector-map,
#marker-map,
#marker-image-map,
#lines-map,
#us-map,
#canada-map,
#spain-map,
#russia-map {
  height: 21.875rem;
}

.jvm-tooltip {
  background-color: #1a1c1e !important;
}

#vector-map #jvm-regions-group path,
#marker-map #jvm-regions-group path,
#marker-image-map #jvm-regions-group path,
#lines-map #jvm-regions-group path,
#visitors-countries #jvm-regions-group path,
#users-map #jvm-regions-group path {
  fill: rgb(var(--light-rgb)) !important;
}

.jvm-zoom-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px !important;
  height: 20px !important;
  line-height: 20px !important;
}

.jvm-zoom-btn.jvm-zoomin {
  inset-block-start: 5px !important;
}

#jvm-markers-labels-group text {
  fill: var(--text-muted);
}

[dir=rtl] #jvm-markers-labels-group text {
  transform: translatex(-21px);
}

/* End::Vector Maps */
/* Start::Google Maps */
#google-map,
#google-map-overlay,
#map-layers,
#map-markers,
#streetview-map,
#map-geofencing {
  height: 18.75rem;
}

.google-map-overlay {
  display: block;
  text-align: center;
  color: #fff;
  font-size: 1.25rem;
  line-height: 0.875rem;
  opacity: 0.8;
  background: var(--primary-color);
  border: solid 0.188rem var(--primary-color);
  border-radius: 0.25rem;
  box-shadow: 0.125rem 0.125rem 0.625rem var(--black-3);
  text-shadow: 0.063rem 0.063rem 0.063rem var(--gray-6);
  padding: 0 0.25rem;
}

.google-overlay_arrow {
  inset-inline-start: 50%;
  margin-inline-start: -1rem;
  width: 0;
  height: 0;
  position: absolute;
}

.google-overlay_arrow.above {
  inset-block-end: -0.938rem;
  border-inline-start: 0.938rem solid transparent;
  border-inline-end: 1rem solid transparent;
  border-block-start: 1rem solid #336699;
}

.google-overlay_arrow.below {
  inset-block-start: -0.938rem;
  border-inline-start: 1rem solid transparent;
  border-inline-end: 1rem solid transparent;
  border-block-end: 1rem solid #336699;
}

/* End::Google Maps */
/* Start::Apex Charts */
.content-wrapper {
  width: 100%;
}

.apexcharts-svg,
.apexcharts-canvas {
  width: 100% !important;
}

#pie-basic .apexcharts-canvas,
#donut-update .apexcharts-canvas,
#pie-monochrome .apexcharts-canvas,
#donut-gradient .apexcharts-canvas,
#donut-pattern .apexcharts-canvas,
#pie-image .apexcharts-canvas,
#polararea-basic .apexcharts-canvas,
#polararea-monochrome .apexcharts-canvas {
  margin: 0 auto;
}

.apexcharts-theme-light .apexcharts-menu-icon:hover svg,
.apexcharts-theme-light .apexcharts-reset-icon:hover svg,
.apexcharts-theme-light .apexcharts-selection-icon:not(.apexcharts-selected):hover svg,
.apexcharts-theme-light .apexcharts-zoom-icon:not(.apexcharts-selected):hover svg,
.apexcharts-theme-light .apexcharts-zoomin-icon:hover svg,
.apexcharts-theme-light .apexcharts-zoomout-icon:hover svg {
  fill: var(--default-text-color) !important;
}

.apexcharts-legend-text {
  color: var(--default-text-color) !important;
  padding-inline-start: 0.9375rem !important;
  margin-inline-start: -0.625rem !important;
}

.apexcharts-title-text {
  color: var(--default-text-color) !important;
  fill: var(--default-text-color) !important;
}

.apexcharts-text.apexcharts-yaxis-label tspan,
.apexcharts-text.apexcharts-xaxis-label tspan {
  fill: var(--text-muted);
}

.apexcharts-canvas .apexcharts-series.apexcharts-heatmap-series rect {
  stroke: var(--custom-white);
}

.apexcharts-canvas .apexcharts-series-markers.apexcharts-series-bubble circle {
  stroke: var(--custom-white);
}

.apexcharts-yaxis .apexcharts-text {
  fill: var(--text-muted);
}

/* End::Apex Charts */
/* Start::Chartjs Charts */
.chartjs-chart {
  max-height: 18.75rem;
}

/* Start::Chartjs Charts */
/* Start::Apex Column Charts */
#chart-year,
#chart-quarter {
  width: 96%;
  max-width: 48%;
  box-shadow: none;
  padding-inline-start: 0;
  padding-block-start: 1.25rem;
  background: #fff;
  border: 1px solid #ddd;
}

#chart-year {
  float: left;
  position: relative;
  transition: 1s ease transform;
  z-index: 3;
}

#chart-year.chart-quarter-activated {
  transform: translateX(0);
  transition: 1s ease transform;
}

#chart-quarter {
  float: left;
  position: relative;
  z-index: -2;
  transition: 1s ease transform;
}

#chart-quarter.active {
  transition: 1.1s ease-in-out transform;
  transform: translateX(0);
  z-index: 1;
}

@media screen and (min-width: 480px) {
  #chart-year {
    transform: translateX(50%);
  }

  #chart-quarter {
    transform: translateX(-50%);
  }
}

/* End::Apex Column Charts */
/* Start::ECharts */
.echart-charts {
  height: 20rem;
}

#echart-funnel div:nth-child(3) {
  background-color: rgb(var(--light-rgb)) !important;
}

#echart-funnel div:nth-child(3) textarea {
  background-color: var(--custom-white) !important;
  border-color: var(--default-border) !important;
  color: var(--default-text-color) !important;
}

#echart-funnel div:nth-child(3) h4 {
  color: var(--default-text-color) !important;
}

/* End::ECharts */
/* Start::Simplebar */
.simplebar-scrollbar:before {
  background: var(--gray-4) !important;
  border-radius: 0.3rem !important;
  inset-inline-end: 0 !important;
  width: 0.375rem;
}

.simplebar-track.simplebar-horizontal {
  display: none;
}

/* End::Simplebar */
/* Start::dropzone */
.dropzone {
  border: 0.125rem dashed var(--default-border) !important;
  background-color: transparent !important;
}

.dropzone .dz-message .dz-button {
  font-size: 1.25rem !important;
  color: var(--default-text-color) !important;
}

.dropzone .dz-preview {
  border-radius: 1.25rem;
}

/* End::dropzone */
/* Start::filepond */
.filepond--drop-label {
  color: var(--default-text-color) !important;
  border-radius: 0.3rem !important;
}

.filepond--credits {
  display: none;
}

.filepond--panel-root {
  background-color: var(--custom-white) !important;
  border: 0.125rem dashed var(--default-border) !important;
}

.filepond--drop-label.filepond--drop-label label {
  padding: 1.5em !important;
  font-size: 14px !important;
}

.filepond--root {
  margin-block-end: 0 !important;
}

.filepond--file {
  background-color: var(--primary-color) !important;
}

.single-fileupload {
  width: 8rem !important;
  height: 8rem !important;
  margin: 0 auto !important;
}

/* End::filepond */
/* Start:: quill editor */
.ql-bubble .ql-tooltip {
  background-color: rgb(var(--light-rgb)) !important;
  color: var(--default-text-color) !important;
}

.ql-bubble .ql-tooltip .ql-stroke {
  stroke: var(--default-text-color) !important;
}

.ql-bubble .ql-tooltip .ql-fill {
  fill: var(--default-text-color) !important;
}

.ql-bubble .ql-tooltip-editor input[type=text] {
  color: var(--default-text-color) !important;
}

.ql-bubble .ql-tooltip:not(.ql-flip) .ql-tooltip-arrow {
  border-block-end-color: var(--default-border) !important;
}

.ql-bubble .ql-editor blockquote {
  border-inline-start: 4px solid var(--default-background) !important;
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  border: 1px solid var(--default-border) !important;
}

.ql-snow .ql-picker {
  color: var(--default-text-color) !important;
}

.ql-snow .ql-stroke,
.ql-snow .ql-stroke.ql-fill {
  stroke: var(--default-text-color) !important;
}

.ql-snow .ql-fill {
  fill: var(--default-text-color) !important;
}

.ql-toolbar.ql-snow+.ql-container.ql-snow {
  border-top: 0px !important;
}

.ql-snow .ql-picker-options .ql-picker-item {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.ql-editor {
  min-height: 15.62rem !important;
  overflow: visible !important;
}

.ql-snow .ql-formats {
  border-inline-end: 1px solid var(--default-border);
  padding: 7px;
  margin: 0 !important;
}

.ql-editor {
  text-align: start !important;
}

.ql-editor .ql-align-right {
  text-align: end !important;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: transparent !important;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: var(--primary-color) !important;
}

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: var(--primary-color) !important;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  margin-top: 10px !important;
}

.ql-snow .ql-picker-options {
  background-color: var(--custom-white) !important;
}

.ql-snow .ql-tooltip {
  background-color: var(--custom-white) !important;
  border: 1px solid var(--default-border) !important;
  box-shadow: 0 0.125rem 0 rgba(10, 10, 10, 0.04) !important;
  color: var(--default-text-color) !important;
}

.ql-snow .ql-tooltip input[type=text] {
  border: 1px solid var(--default-border) !important;
  background-color: var(--default-background) !important;
  color: var(--default-text-color) !important;
  outline: 0;
}

.ql-snow .ql-tooltip {
  transform: translatex(12.5rem) !important;
  z-index: 9 !important;
}

.ql-snow a {
  color: var(--primary-color) !important;
}

.ql-toolbar.ql-snow {
  border-radius: 0.3rem 0.3rem 0 0 !important;
  background-color: rgb(var(--light-rgb));
  padding: 0 !important;
}

.ql-snow .ql-picker-label {
  padding-inline-start: 0.5rem !important;
  padding-inline-end: 0.125rem !important;
}

.ql-snow .ql-formats .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  inset-inline-end: 0 !important;
  inset-inline-start: auto !important;
}

.ql-container {
  border-radius: 0 0 0.3rem 0.3rem !important;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: var(--default-text-color) !important;
}

.ql-editor {
  text-align: start !important;
}

.ql-container {
  font-family: var(--default-font-family) !important;
}

.ql-snow .ql-editor {
  padding: 1.25rem;
}

.ql-bubble {
  border: 1px solid var(--default-border) !important;
  border-radius: 7px !important;
}

.ql-editor li:not(.ql-direction-rtl)::before {
  margin-inline-start: -1.5em !important;
  margin-inline-end: 0.3em !important;
  text-align: start !important;
}

.ql-editor ol li:not(.ql-direction-rtl),
.ql-editor ul li:not(.ql-direction-rtl) {
  padding-inline-start: 1.5em !important;
}

.ql-toolbar.ql-snow .ql-formats {
  margin: 0.25rem;
}

[dir=rtl] .ql-tooltip.ql-editing {
  left: 0 !important;
  top: 0 !important;
}

[dir=rtl] .ql-bubble .ql-toolbar .ql-formats:first-child {
  margin-right: 12px;
}

[dir=rtl] .ql-bubble .ql-toolbar .ql-formats {
  margin: 8px 0px 8px 12px;
}

/* end:: quill editor */
/* Start:: select2 */
.select2.select2-container {
  width: 100% !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: var(--default-text-color) !important;
  line-height: 2.33rem !important;
  border: 1px solid var(--input-border) !important;
  border-radius: 7px !important;
}

.select2-container--default .select2-selection--single {
  background-color: var(--form-control-bg) !important;
  border: 0px solid var(--input-border) !important;
  border-radius: 7px !important;
}

.select2-container .select2-selection--single,
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 2.37rem !important;
}

.select2-dropdown {
  background-color: var(--custom-white) !important;
  border: 1px solid var(--input-border) !important;
  border-radius: 7px !important;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid var(--input-border) !important;
  border-radius: 7px !important;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: var(--default-background) !important;
  color: var(--default-text-color) !important;
}

.select2-results__option--selectable {
  font-size: 0.813rem;
}

.select2-container--default .select2-results__option--selected {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}

.select2-container--default .select2-results__option--selected.select2-results__option--highlighted {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}

.select2-search__field {
  background-color: var(--custom-white) !important;
  color: var(--default-text-color) !important;
}

.select2-search__field:focus-visible {
  outline: none !important;
}

.select2-container--default .select2-selection--multiple {
  background-color: var(--form-control-bg) !important;
  border: 1px solid var(--input-border) !important;
  border-radius: 7px !important;
}

.select2-container .select2-selection--multiple {
  min-height: 2.25rem !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: var(--primary-color) !important;
  color: #fff !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: 0.15rem !important;
  margin-block-start: 0.375rem !important;
}

.select2-selection--multiple .select2-search__field {
  background-color: transparent !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  border-inline-end: 1px solid rgba(255, 255, 255, 0.1) !important;
  color: #fff !important;
  inset-block-start: -0.3rem !important;
  font-weight: 500 !important;
  font-size: 1.125rem !important;
}

.select2-selection--multiple .select2-selection__choice__display {
  font-size: 0.75rem !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover,
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus {
  background-color: var(--primary-color) !important;
}

.select2-results__option span img,
.select2-selection__rendered span img {
  width: 1.45rem;
  height: 1.45rem;
  border-radius: 1.25rem;
  margin-inline-end: 0.25rem;
  box-shadow: 0 0.125rem 0 rgba(10, 10, 10, 0.04);
}

.select2-container .select2-search--inline .select2-search__field {
  margin-block-start: 0.5rem !important;
}

.select2-container--disabled.select2-container--default .select2-selection--single .select2-selection__rendered,
.select2-container--disabled .select2-selection.select2-selection--multiple {
  background-color: var(--default-background) !important;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  font-weight: 400 !important;
  height: 1.25rem !important;
  font-size: 1.5625rem !important;
  width: 1.25rem !important;
  position: absolute !important;
  inset-inline-end: 0.625rem !important;
}

.select2-selection__clear {
  color: var(--text-muted);
}

.select2-dropdown {
  z-index: 10 !important;
}

[dir=rtl] .select2-container--default .select2-selection--single .select2-selection__clear {
  margin-left: 20px !important;
  padding-left: 0px !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  right: 10px !important;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 10px !important;
  right: auto !important;
}

.select2-container--open .select2-dropdown--above {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  overflow: hidden;
}

.select2-container--open .select2-dropdown--below {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  overflow: hidden;
}

/* End:: select2 */
/* Start:: grid js tables */
.gridjs-table {
  width: 100%;
}

table.gridjs-table {
  text-align: start !important;
  font-size: 0.813rem !important;
  font-weight: 500 !important;
}

.gridjs-wrapper {
  box-shadow: none !important;
  border-radius: 0 !important;
}

.gridjs-wrapper:nth-last-of-type(2) {
  border-radius: 0 !important;
}

.gridjs-container {
  color: var(--default-text-color) !important;
}

th.gridjs-th {
  background-color: var(--custom-white) !important;
  border: 1px solid var(--default-border) !important;
  color: var(--default-text-color) !important;
  padding: 0.75rem !important;
}

td.gridjs-td {
  border: 1px solid var(--default-border) !important;
  padding: 0.75rem !important;
}

.gridjs-tbody,
td.gridjs-td {
  background-color: var(--custom-white) !important;
}

.gridjs-footer {
  background-color: var(--custom-white) !important;
  border-bottom-width: 0 !important;
  border-color: transparent !important;
  border-radius: 0 !important;
  border-top: 0 !important;
  box-shadow: none !important;
  padding: 0.75rem 0 0 0 !important;
}

.gridjs-pagination {
  color: var(--default-text-color) !important;
}

.gridjs-pagination .gridjs-pages button:first-child {
  border-bottom-left-radius: 7px !important;
  border-top-left-radius: 7px !important;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.gridjs-pagination .gridjs-pages button:last-child {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-right-radius: 7px !important;
  border-top-right-radius: 7px !important;
}

@media (max-width: 575.98px) {
  .gridjs-search-input {
    width: 12.5rem !important;
  }
}

[dir=rtl] .gridjs-pagination .gridjs-pages button:first-child {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-right-radius: 7px !important;
  border-top-right-radius: 7px !important;
}

[dir=rtl] .gridjs-pagination .gridjs-pages button:last-child {
  border-bottom-left-radius: 7px !important;
  border-top-left-radius: 7px !important;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.gridjs-pagination .gridjs-pages button:disabled,
.gridjs-pagination .gridjs-pages button:hover:disabled,
.gridjs-pagination .gridjs-pages button[disabled] {
  background-color: var(--custom-white) !important;
  color: var(--default-text-color) !important;
}

.gridjs-pagination .gridjs-pages button {
  background-color: var(--custom-white) !important;
  border: 1px solid var(--default-border) !important;
  padding: 0.375 0.75rem !important;
  color: var(--default-text-color) !important;
}

.gridjs-pagination .gridjs-pages button:focus {
  box-shadow: none !important;
  margin-inline-end: 0 !important;
}

.gridjs-pagination .gridjs-pages button.gridjs-currentPage {
  font-weight: 600 !important;
  background-color: var(--primary-color) !important;
  color: #fff !important;
}

.gridjs-pagination .gridjs-pages {
  float: end !important;
}

input.gridjs-input {
  background-color: var(--custom-white) !important;
  border: 1px solid var(--default-border) !important;
  border-radius: 7px !important;
  font-size: 14px !important;
  padding: 0.375rem 0.75rem !important;
  line-height: 1.6 !important;
  color: var(--default-text-color) !important;
}

input.gridjs-input:focus {
  box-shadow: none !important;
  border: 1px solid var(--primary-color) !important;
}

button.gridjs-sort {
  float: end !important;
  height: 1.25rem !important;
  width: 0.625rem !important;
}

button.gridjs-sort-desc {
  background-position-y: 54% !important;
  background-size: 0.5rem !important;
}

button.gridjs-sort-asc {
  background-position-y: 44% !important;
  background-size: 0.5rem !important;
}

th.gridjs-th-fixed {
  background-color: rgb(var(--light-rgb)) !important;
}

#grid-header-fixed .gridjs-wrapper {
  border-block-start: 1px solid var(--default-border) !important;
  border-block-end: 1px solid var(--default-border) !important;
}

#grid-header-fixed .gridjs-container .gridjs-wrapper .gridjs-thead .gridjs-tr th {
  top: -1px !important;
}

/* End:: grid js tables */
/* Start:: bootstrap5 datatables */
.dataTables_wrapper .dataTables_paginate {
  margin: 0;
  text-align: end;
}

.dataTables_wrapper .dataTables_paginate .pagination {
  margin-block-end: 0;
  justify-content: end;
}

div.dataTables_scrollBody>table#datatable-basic {
  margin-block-end: 0.375rem !important;
}

.dataTables_filter {
  text-align: end !important;
}

.dataTables_info {
  padding-block-start: 0.625rem;
}

table.dataTable>thead .sorting:before,
table.dataTable>thead .sorting_asc:before,
table.dataTable>thead .sorting_asc_disabled:before,
table.dataTable>thead .sorting_desc:before,
table.dataTable>thead .sorting_desc_disabled:before {
  content: "\f235" !important;
  font-family: bootstrap-icons !important;
  position: absolute;
  inset-inline-end: 0.8rem !important;
  top: 0.813rem !important;
  font-size: 0.5rem !important;
}

table.dataTable>thead .sorting:after,
table.dataTable>thead .sorting_asc:after,
table.dataTable>thead .sorting_asc_disabled:after,
table.dataTable>thead .sorting_desc:after,
table.dataTable>thead .sorting_desc_disabled:after {
  content: "\f229" !important;
  font-family: bootstrap-icons !important;
  position: absolute;
  inset-inline-end: 0.8rem !important;
  top: 1.25rem !important;
  font-size: 0.5rem !important;
}

table.dataTable>thead .sorting,
table.dataTable>thead .sorting_asc,
table.dataTable>thead .sorting_desc,
table.dataTable>thead .sorting_asc_disabled,
table.dataTable>thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}

table.dataTable>thead .sorting:before,
table.dataTable>thead .sorting:after,
table.dataTable>thead .sorting_asc:before,
table.dataTable>thead .sorting_asc:after,
table.dataTable>thead .sorting_desc:before,
table.dataTable>thead .sorting_desc:after,
table.dataTable>thead .sorting_asc_disabled:before,
table.dataTable>thead .sorting_asc_disabled:after,
table.dataTable>thead .sorting_desc_disabled:before,
table.dataTable>thead .sorting_desc_disabled:after {
  position: absolute;
  display: block;
  opacity: 0.2;
}

table.dataTable>thead .sorting_asc:before,
table.dataTable>thead .sorting_desc:after {
  opacity: 0.8;
}

div.dataTables_wrapper div.dataTables_length select {
  width: auto;
  display: inline-block;
  margin: 0 0.25rem;
}

.dataTables_wrapper .dataTables_scrollHead table.dataTable {
  margin-block-end: 0;
}

.dataTables_wrapper .dataTables_scrollBody #datatable-basic {
  margin-block-start: -3px;
  border-block-start-color: transparent !important;
}

.dataTables_wrapper .dataTables_scrollBody table.dataTable>thead .sorting:before,
.dataTables_wrapper .dataTables_scrollBody table.dataTable>thead .sorting:after,
.dataTables_wrapper .dataTables_scrollBody table.dataTable>thead .sorting_asc:before,
.dataTables_wrapper .dataTables_scrollBody table.dataTable>thead .sorting_asc:after,
.dataTables_wrapper .dataTables_scrollBody table.dataTable>thead .sorting_desc:before,
.dataTables_wrapper .dataTables_scrollBody table.dataTable>thead .sorting_desc:after,
.dataTables_wrapper .dataTables_scrollBody table.dataTable>thead .sorting_asc_disabled:before,
.dataTables_wrapper .dataTables_scrollBody table.dataTable>thead .sorting_asc_disabled:after,
.dataTables_wrapper .dataTables_scrollBody table.dataTable>thead .sorting_desc_disabled:before,
.dataTables_wrapper .dataTables_scrollBody table.dataTable>thead .sorting_desc_disabled:after {
  display: none;
}

div.dt-button-info {
  background-color: var(--custom-white) !important;
  border: 1px solid var(--default-border) !important;
}

div.dt-button-info h2 {
  background-color: var(--custom-white) !important;
  border-bottom: 1px solid var(--default-border) !important;
}

@media (max-width: 767.98px) {
  .data-table-btn {
    margin: 0.5625rem 0 0.5rem 0 !important;
  }

  .dataTables_length,
  .dataTables_filter,
  .dataTables_info {
    text-align: center;
  }

  .dataTables_filter {
    margin-block-start: 0.5rem;
  }

  .dataTables_paginate .pagination {
    justify-content: center !important;
  }

  .dataTables_info {
    padding-block-start: 0;
    padding-block-end: 0.5rem;
  }

  div.dtr-modal div.dtr-modal-display {
    width: 95% !important;
    height: 95% !important;
    background-color: var(--custom-white) !important;
    border: 1px solid var(--default-border) !important;
    box-shadow: 0 0.125rem 0 rgba(10, 10, 10, 0.04) !important;
  }

  div.dataTables_wrapper div.dataTables_length,
  div.dataTables_wrapper div.dataTables_filter,
  div.dataTables_wrapper div.dataTables_info,
  div.dataTables_wrapper div.dataTables_paginate {
    text-align: center !important;
  }
}

@media (max-width: 575.98px) {
  .dataTables_paginate .pagination .paginate_button .page-link {
    font-size: 0.625rem;
    padding: 0.25rem 0.5rem;
  }
}

table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control {
  padding-inline-start: 1.875rem !important;
}

table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before,
table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control:before {
  color: #fff !important;
  border: 0.15em solid #fff !important;
  box-shadow: 0 0 0.2em var(--primary-color) !important;
  background-color: var(--primary-color) !important;
}

table.dataTable.dtr-inline.collapsed>tbody>tr.parent>td.dtr-control:before,
table.dataTable.dtr-inline.collapsed>tbody>tr.parent>th.dtr-control:before {
  content: "-";
  background-color: rgb(var(--success-rgb)) !important;
}

table.dataTable>tbody>tr.child ul.dtr-details>li:first-child {
  padding-block-start: 0 !important;
}

table.dataTable>tbody>tr.child ul.dtr-details>li {
  border-block-end: 1px solid var(--default-border) !important;
}

table.dataTable>tbody>tr.child span.dtr-title {
  min-width: 4.688rem !important;
}

div.dtr-modal div.dtr-modal-close {
  top: 0px !important;
  border: 0px solid var(--default-border) !important;
  font-size: 1.5rem !important;
  background-color: transparent !important;
}

div.dtr-modal div.dtr-modal-background {
  background: rgba(0, 0, 0, 0.3) !important;
}

.dtr-modal-content h2 {
  font-size: 0.875rem !important;
  font-weight: 600 !important;
}

.dt-button {
  font-size: 0.8125rem !important;
  border-radius: 0.3rem !important;
  box-shadow: none !important;
  font-weight: 500 !important;
  padding: 0.375rem 0.75rem !important;
  background-color: var(--primary-color);
  color: #fff;
  border: 0;
}

.dt-buttons {
  float: left !important;
}

table.dataTable thead>tr>th.sorting,
table.dataTable thead>tr>th.sorting_asc,
table.dataTable thead>tr>th.sorting_desc,
table.dataTable thead>tr>th.sorting_asc_disabled,
table.dataTable thead>tr>th.sorting_desc_disabled,
table.dataTable thead>tr>td.sorting,
table.dataTable thead>tr>td.sorting_asc,
table.dataTable thead>tr>td.sorting_desc,
table.dataTable thead>tr>td.sorting_asc_disabled,
table.dataTable thead>tr>td.sorting_desc_disabled {
  padding-inline-end: 1.625rem;
}

table.dataTable thead th,
table.dataTable thead td,
table.dataTable tfoot th,
table.dataTable tfoot td {
  text-align: start !important;
}

table.table-bordered.dataTable th:first-child,
table.table-bordered.dataTable th:first-child,
table.table-bordered.dataTable td:first-child,
table.table-bordered.dataTable td:first-child {
  border-inline-start-width: 1px !important;
}

table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable td:last-child,
table.table-bordered.dataTable td:last-child {
  border-inline-end-width: 1px !important;
}

div.dataTables_wrapper div.dataTables_filter#scroll-vertical_filter input {
  margin-right: 0.2em;
}

[dir=rtl] div.dtr-modal div.dtr-modal-close {
  left: 6px;
  right: auto;
}

[dir=rtl] div.table-responsive>div.dataTables_wrapper>div.row>div[class^=col-]:last-child {
  padding-left: 0;
  padding-right: initial;
}

[dir=rtl] div.dataTables_wrapper div.dataTables_filter#scroll-vertical_filter input {
  margin-right: 0.5rem;
  margin-left: 0.2em;
}

[dir=rtl] div.dataTables_wrapper div.dataTables_filter input {
  margin-right: 0.5rem;
  margin-left: 0;
}

[dir=rtl] table.table-bordered.dataTable th:first-child,
[dir=rtl] table.table-bordered.dataTable th:first-child,
[dir=rtl] table.table-bordered.dataTable td:first-child,
[dir=rtl] table.table-bordered.dataTable td:first-child {
  border-right-width: 1px;
  border-left-width: 0;
}

[dir=rtl] table.table-bordered.dataTable th:last-child,
[dir=rtl] table.table-bordered.dataTable th:last-child,
[dir=rtl] table.table-bordered.dataTable td:last-child,
[dir=rtl] table.table-bordered.dataTable td:last-child {
  border-inline-start-width: 1px;
}

[dir=rtl] div.table-responsive>div.dataTables_wrapper>div.row>div[class^=col-]:first-child {
  padding-inline-end: 0;
}

[dir=rtl] div.table-responsive>div.dataTables_wrapper>div.row>div[class^=col-]:last-child {
  padding-inline-start: 0;
}

[dir=rtl] .dt-buttons {
  float: right !important;
}

table.dataTable>tbody>tr.selected>* {
  background-color: var(--primary01);
  color: var(--default-text-color) !important;
  box-shadow: none !important;
}

@media (min-width: 768px) {
  .data-table-btn {
    position: absolute;
    inset-inline-start: 13.5rem;
    z-index: 1;
  }
}

#file-export_wrapper .dt-buttons button {
  margin: 0.25rem;
}

.data-table-btn {
  margin: -0.4375rem 0 0 0;
}

/* End:: bootstrap5 datatables */
/* Start:: sweet alerts */
.swal2-container .swal2-title {
  font-size: 1.15rem;
  padding: 2rem 2rem 0.5rem;
}

.swal2-container .swal2-footer {
  padding: 1.5rem 1.5rem 0;
}

.swal2-container .swal2-popup {
  background: var(--custom-white);
  color: var(--default-text-color);
  padding: 0 0 2rem;
}

.swal2-container .swal2-center>.swal2-popup {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.swal2-container .swal2-actions {
  margin: 1rem auto 0;
}

.swal2-container .swal2-styled.swal2-confirm {
  margin: 0;
  padding: 0.375em 0.75rem;
  box-shadow: none;
  font-size: 0.8125rem;
  border-radius: 0.3rem;
}

.swal2-container .swal2-styled.swal2-confirm:focus,
.swal2-container .swal2-styled.swal2-cancel:focus,
.swal2-container .swal2-styled.swal2-deny:focus {
  box-shadow: none;
}

.swal2-container .swal2-styled.swal2-confirm {
  background-color: var(--primary-color) !important;
  color: #fff;
}

.swal2-container .swal2-footer {
  border-top: 1px solid var(--default-border);
}

.swal2-container .swal2-icon {
  width: 4rem;
  height: 4rem;
  margin: 2rem auto 0rem;
}

.swal2-container .swal2-icon.swal2-question {
  border-color: rgb(var(--success-rgb));
  color: rgb(var(--success-rgb));
}

.swal2-container .swal2-icon.swal2-error {
  border-color: rgb(var(--danger-rgb));
  color: rgb(var(--danger-rgb));
}

.swal2-container .swal2-icon.swal2-info {
  border-color: rgb(var(--info-rgb));
  color: rgb(var(--info-rgb));
}

.swal2-container .swal2-icon.swal2-warning {
  border-color: rgb(var(--warning-rgb));
  color: rgb(var(--warning-rgb));
}

.swal2-container .swal2-icon .swal2-icon-content {
  font-size: 3rem;
}

.swal2-container .swal2-image {
  border-radius: 7px;
}

.swal2-container .swal2-html-container {
  margin: 0rem 1.6rem 0.3rem;
  font-size: 0.8rem;
  color: var(--text-muted);
}

.swal2-container .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
  inset-inline-start: 1em;
}

.swal2-container .swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  top: 1.9em;
  width: 2em;
  height: 0.3em;
  background-color: rgb(var(--danger-rgb));
}

.swal2-container .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
  inset-inline-end: 1em;
  inset-block-start: 1.875rem;
}

.swal2-container .swal2-close:focus {
  box-shadow: none;
}

.swal2-container .swal2-deny,
.swal2-container .swal2-cancel {
  margin-inline-start: 0.625rem;
}

.swal2-container .swal2-close {
  font-size: 2rem;
  color: var(--text-muted);
  opacity: 0.5;
}

.swal2-container .swal2-close:hover {
  color: var(--primary-color);
}

.swal2-container .swal2-styled.swal2-deny {
  background-color: rgb(var(--danger-rgb));
  padding: 0.375em 0.75rem;
  box-shadow: none;
  font-size: 0.8125rem;
  border-radius: 0.3rem;
}

.swal2-container .swal2-styled.swal2-cancel {
  background-color: rgb(var(--light-rgb)) !important;
  color: var(--default-text-color);
  padding: 0.375em 0.75rem;
  box-shadow: none;
  font-size: 0.8125rem;
  border-radius: 0.3rem;
}

.swal2-container .swal2-icon.swal2-success [class^=swal2-success-line][class$=long] {
  top: 2.05em;
  inset-inline-end: 0.45em;
  width: 2em;
}

.swal2-container .swal2-icon.swal2-success [class^=swal2-success-line][class$=tip] {
  top: 2.375em;
  inset-inline-start: 0.9125em;
  width: 1.2625em;
}

.swal2-container .swal2-file:focus,
.swal2-container .swal2-input:focus,
.swal2-container .swal2-textarea:focus {
  border: 1px solid var(--default-border);
  box-shadow: none;
}

[dir=rtl] .swal2-container .swal2-icon.swal2-success [class^=swal2-success-line][class$=tip] {
  inset-inline-start: 1.9125em;
}

/* End:: sweet alerts */
/* Start:: swiper js */
.swiper {
  border-radius: 7px;
}

[dir=rtl] .swiper {
  direction: ltr;
}

[dir=rtl] .swiper-backface-hidden .swiper-slide {
  direction: rtl;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border: #ffffff 2px solid;
}

.swiper-horizontal1 .swiper-slide {
  height: auto;
}

.swiper-pagination-bullet {
  width: 1.25rem !important;
  height: 0.25rem !important;
  border-radius: 7px !important;
  background: #fff !important;
}

.swiper-pagination-bullet-active {
  background: #fff !important;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: rgb(var(--success-rgb)) !important;
}

.swiper-pagination {
  color: #fff;
}

.custom-pagination .swiper-pagination-bullet {
  width: 1.5rem !important;
  height: 1.5rem !important;
  padding: 0.188rem;
  background-color: rgba(255, 255, 255, 0.3) !important;
  color: #fff !important;
  opacity: 1 !important;
}

.custom-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #fff !important;
  color: #000 !important;
}

.swiper-scrollbar {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.swiper-scrollbar-drag {
  background-color: #fff !important;
}

.swiper.vertical {
  height: 21.875rem;
}

.swiper-preview {
  height: 80%;
  width: 100%;
}

.swiper-view {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.swiper-view .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.swiper-view .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-preview .swiper-wrapper {
  margin-block-end: 0.65rem;
}
.swiper-wrapper {
  margin: -12px !important;
}
.swiper-thumbs .swiper-slide img {
  border-radius: 7px;
}

/* End:: swiper js */
/* Start:: prism js */
pre[class*=language-]:after,
pre[class*=language-]:before {
  display: none !important;
}

pre[class*=language-]>code {
  border-inline-start: 1px solid var(--default-border) !important;
  box-shadow: none !important;
  background-color: rgb(var(--light-rgb)) !important;
  border: 1px solid var(--default-border);
  border-radius: 7px;
  background-image: none !important;
  white-space: pre-wrap;
}

:not(pre)>code[class*=language-],
pre[class*=language-] {
  background-color: var(--custom-white) !important;
  margin-bottom: 0 !important;
  max-height: 400px;
  overflow-y: hidden !important;
  border-radius: 7px;
}

code[class*=language-],
pre[class*=language-] {
  color: var(--default-text-color) !important;
  font-size: 0.82rem !important;
}

pre[class*=language-] {
  margin: 0 !important;
}

code[class*=language-] {
  padding: 1rem !important;
}

.prism-toggle {
  margin: 0.25rem;
}

[dir=rtl] pre[class*=language-]>code {
  border-left: 0;
  text-align: right;
}

/* End:: prism js */
/* Start:: Draggable Cards */
#draggable-left .card,
#draggable-right .card {
  touch-action: none;
}

/* End:: Draggable Cards */
/* Start:: Rater Js */
.star-rating {
  touch-action: none;
}

.star-rating .star-value {
  touch-action: none;
}

/* End:: Rater Js */
.upcoming-events-list li {
  margin-block-end: 1.5rem;
  padding-inline-start: 1.5rem;
  position: relative;
}

.upcoming-events-list li::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 0.25rem;
  inset-inline-start: 0;
  border-radius: 7px;
}

.upcoming-events-list li:nth-child(1)::before {
  background-color: var(--primary-color);
  opacity: 0.3;
}

.upcoming-events-list li:nth-child(2)::before {
  background-color: rgb(var(--secondary-rgb));
  opacity: 0.3;
}

.upcoming-events-list li:nth-child(3)::before {
  background-color: rgb(var(--success-rgb));
  opacity: 0.3;
}

.upcoming-events-list li:nth-child(4)::before {
  background-color: rgb(var(--danger-rgb));
  opacity: 0.3;
}

.upcoming-events-list li:nth-child(5)::before {
  background-color: rgb(var(--info-rgb));
  opacity: 0.3;
}

.upcoming-events-list li:nth-child(5)::before {
  background-color: rgb(var(--warning-rgb));
  opacity: 0.3;
}

.upcoming-events-list li:last-child {
  margin-block-end: 0;
}

.border-top-card {
  border-top-width: 0.188rem !important;
  border-top-style: solid !important;
}

.border-top-card.border-top-primary {
  border-top-color: var(--primary-color);
}

.border-top-card.border-top-secondary {
  border-top-color: rgb(var(--secondary-rgb));
}

.border-top-card.border-top-warning {
  border-top-color: rgb(var(--warning-rgb));
}

.border-top-card.border-top-info {
  border-top-color: rgb(var(--info-rgb));
}

.border-top-card.border-top-success {
  border-top-color: rgb(var(--success-rgb));
}

.border-top-card.border-top-danger {
  border-top-color: rgb(var(--danger-rgb));
}

#users-map {
  height: 14rem;
}

#users-map #jvm-markers-labels-group {
  display: none;
}

[dir=ltr] #views .apexcharts-yaxis {
  transform: translate(0, 0);
}

.ribbon1 {
  position: absolute;
  inset-block-start: -6.1px;
  inset-inline-end: 10px;
  color: #fff;
  z-index: 1;
}

.ribbon1:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-inline-start: 20px solid transparent;
  border-inline-end: 24px solid transparent;
  border-block-start: 13px solid rgb(var(--danger-rgb));
}

.ribbon1 span {
  position: relative;
  display: block;
  text-align: center;
  background: rgb(var(--danger-rgb));
  font-size: 14px !important;
  line-height: 1;
  padding: 12px 8px 10px;
  border-start-end-radius: 8px;
}

.ribbon1 span:before,
.ribbon1 span:after {
  position: absolute;
  content: "";
}

.ribbon1 span:before {
  height: 6px;
  width: 6px;
  inset-inline-start: -6px;
  inset-block-start: 0;
  background: rgb(var(--danger-rgb));
}

.ribbon1 span:after {
  height: 6px;
  width: 8px;
  inset-inline-start: -8px;
  inset-block-start: 0;
  border-radius: 8px 8px 0 0;
  background: rgb(var(--danger-rgb));
}

/*----- Ribbons -----*/
.ribbone {
  width: 100%;
  position: relative;
  background-size: cover;
  text-transform: uppercase;
  color: #fff;
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  z-index: 6;
}

.ribbone::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid;
}

.ribbone::before {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid;
}

.ribbone span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 3px 0;
  box-shadow: 0 5px 10px var(--black-1);
  color: #fff;
  text-shadow: 0 1px 1px var(--black-2);
  text-transform: capitalize;
  text-align: center;
  font-size: 11px;
}

.ribbone1 {
  position: absolute;
  inset-block-start: -6.1px;
  inset-inline-start: 10px;
  z-index: 1;
}

.ribbone1:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-inline-start: 20px solid transparent;
  border-inline-end: 24px solid transparent;
  border-block-start: 13px solid rgb(var(--danger-rgb));
}

.ribbone1 span {
  position: relative;
  display: block;
  text-align: center;
  background: rgb(var(--danger-rgb));
  font-size: 14px !important;
  line-height: 1;
  padding: 12px 8px 10px;
  border-start-end-radius: 8px;
}

.ribbone1 span:after {
  position: absolute;
  content: "";
  height: 6px;
  width: 8px;
  inset-inline-start: -8px;
  inset-block-start: 0;
  border-radius: 8px 8px 0 0;
  background: rgb(var(--danger-rgb));
}

.ribbone1 span:before {
  position: absolute;
  content: "";
  height: 6px;
  width: 6px;
  inset-inline-start: -6px;
  inset-block-start: 0;
  background: rgb(var(--danger-rgb));
}

.arrow-ribbone-left {
  color: #fff;
  padding: 2px 8px;
  position: absolute;
  inset-block-start: 10px;
  inset-inline-start: 0;
  z-index: 6;
  font-size: 13px;
}

.arrow-ribbone-left.bg-purple:before {
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-end: -12px;
  border-block-start: 12px solid transparent;
  border-inline-start: 12px solid rgb(var(--purple-rgb));
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-left.bg-danger:before {
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-end: -12px;
  border-block-start: 112px solid transparent;
  border-inline-start: 12px solid rgb(var(--danger-rgb));
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-left.bg-primary:before {
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-end: -12px;
  border-block-start: 12px solid transparent;
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-left.bg-secondary:before {
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-end: -12px;
  border-block-start: 12px solid transparent;
  border-inline-start: 12px solid rgb(var(--secondary-rgb));
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-left.bg-success:before {
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-end: -12px;
  border-block-start: 12px solid transparent;
  border-inline-start: 12px solid var(--primary-color);
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-left.bg-info:before {
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-end: -12px;
  border-block-start: 12px solid transparent;
  border-inline-start: 12px solid rgb(var(--info-rgb));
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-left.bg-warning:before {
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-end: -12px;
  border-block-start: 12px solid transparent;
  border-inline-start: 12px solid rgb(var(--warning-rgb));
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-left.bg-pink:before {
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-end: -12px;
  border-block-start: 12px solid transparent;
  border-inline-start: 12px solid rgb(var(--pink-rgb));
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-left.bg-teal:before {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-start: -12px;
  border-block-start: 12px solid transparent;
  border-inline-end: 12px solid rgb(var(--teal-rgb));
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-right {
  color: #fff;
  padding: 2px 8px;
  position: absolute;
  inset-block-start: 10px;
  inset-inline-end: 0;
  z-index: 6;
  font-size: 13px;
}

.arrow-ribbone-right.bg-purple:before {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-start: -12px;
  border-block-start: 12px solid transparent;
  border-inline-end: 12px solid rgb(var(--purple-rgb));
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-right.bg-danger:before {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-start: -12px;
  border-block-start: 12px solid transparent;
  border-inline-end: 12px solid rgb(var(--danger-rgb));
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-right.bg-primary:before {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-start: -12px;
  border-block-start: 12px solid transparent;
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-right.bg-secondary:before {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-start: -12px;
  border-block-start: 12px solid transparent;
  border-inline-end: 12px solid rgb(var(--secondary-rgb));
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-right.bg-success:before {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-start: -12px;
  border-block-start: 12px solid transparent;
  border-inline-end: 12px solid var(--primary-color);
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-right.bg-info:before {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-start: -12px;
  border-block-start: 12px solid transparent;
  border-inline-end: 12px solid rgb(var(--info-rgb));
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-right.bg-warning:before {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-start: -12px;
  border-block-start: 12px solid transparent;
  border-inline-end: 12px solid rgb(var(--warning-rgb));
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-right.bg-pink:before {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-start: -12px;
  border-block-start: 12px solid transparent;
  border-inline-end: 12px solid rgb(var(--pink-rgb));
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone-right.bg-teal:before {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-start: -12px;
  border-block-start: 12px solid transparent;
  border-inline-end: 12px solid rgb(var(--teal-rgb));
  border-block-end: 11px solid transparent;
  width: 0;
}

.arrow-ribbone2 {
  color: #fff;
  padding: 3px 8px;
  position: absolute;
  inset-block-start: 10px;
  inset-inline-start: -1px;
  z-index: 6;
  font-size: 30px;
}

.arrow-ribbone2:before {
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-end: -24px;
  border-block-start: 24px solid transparent;
  border-block-end: 24px solid transparent;
  width: 0;
}

.power-ribbone {
  width: 56px;
  height: 56px;
  overflow: hidden;
  position: absolute;
  z-index: 6;
}

.power-ribbone span {
  position: absolute;
  display: block;
  width: 82px;
  padding: 8px 0;
  color: #fff;
  font: 500 16px/1 Lato, sans-serif;
  text-shadow: 0 1px 1px var(--black-2);
  text-transform: capitalize;
  text-align: center;
}

.power-ribbone-top-left {
  inset-block-start: -6px;
  inset-inline-start: -9px;
}

.power-ribbone-top-left span {
  inset-inline-end: -5px;
  inset-block-start: 1px;
  transform: rotate(-45deg);
}

.power-ribbone-top-left span i {
  transform: rotate(45deg);
  padding-block-start: 2px;
  padding-inline-start: 7px;
}

.power-ribbone-bottom-left {
  inset-block-end: -6px;
  inset-inline-start: -9px;
}

.power-ribbone-bottom-left span {
  inset-inline-end: -5px;
  inset-block-end: 1px;
  transform: rotate(45deg);
}

.power-ribbone-bottom-left span i {
  transform: rotate(-45deg);
  padding-block-end: 2px;
  padding-inline-start: 7px;
}

.power-ribbone-top-right {
  inset-block-start: -6px;
  inset-inline-end: -9px;
}

.power-ribbone-top-right span {
  inset-inline-start: -5px;
  inset-block-start: 1px;
  transform: rotate(45deg);
}

.power-ribbone-top-right span i {
  transform: rotate(-45deg);
  padding-block-start: 2px;
  padding-inline-end: 7px;
}

.power-ribbone-bottom-right {
  inset-block-end: -6px;
  inset-inline-end: -9px;
}

.power-ribbone-bottom-right span {
  inset-inline-start: -5px;
  inset-block-end: 1px;
  transform: rotate(-45deg);
}

.power-ribbone-bottom-right span i {
  transform: rotate(45deg);
  padding-block-end: 2px;
  padding-inline-end: 7px;
}

.ribbone-top-left {
  inset-block-start: -10px;
  inset-inline-start: -10px;
}

.ribbone-top-left::after {
  border-block-start-color: transparent;
  border-inline-start-color: transparent;
  inset-block-end: 50px;
  inset-inline-start: 0;
}

.ribbone-top-left::before {
  border-block-start-color: transparent;
  border-inline-start-color: transparent;
  inset-block-start: 0;
  inset-inline-end: 50px;
}

.ribbone-top-left span {
  inset-inline-end: 0px;
  inset-block-start: 24px;
  transform: rotate(-45deg);
}

.ribbone-top-right {
  inset-block-start: -10px;
  inset-inline-end: -10px;
}

.ribbone-top-right::after {
  border-block-start-color: transparent;
  border-inline-end-color: transparent;
  inset-block-end: 50px;
  inset-inline-end: 0;
}

.ribbone-top-right::before {
  border-block-start-color: transparent;
  border-inline-end-color: transparent;
  inset-block-start: 0;
  inset-inline-start: 50px;
}

.ribbone-top-right span {
  inset-inline-start: 0px;
  inset-block-start: 24px;
  transform: rotate(45deg);
}

.ribbone-warning-right {
  position: absolute;
  inset-block-start: -8px;
  inset-inline-end: 10px;
  color: #fff;
}

.ribbone-warning-right:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-inline-start: 17px solid transparent;
  border-inline-end: 17px solid transparent;
  border-block-start: 10px solid rgb(var(--warning-rgb));
  z-index: 6;
}

.ribbone-warning-right span {
  position: relative;
  display: block;
  text-align: center;
  background: rgb(var(--warning-rgb));
  font-size: 13px;
  line-height: 1;
  padding: 10px 2px 5px;
  border-start-end-radius: 10px;
  z-index: 6;
}

.ribbone-warning-right span:after {
  position: absolute;
  content: "";
  inset-inline-end: -6px;
  background: rgb(var(--warning-rgb));
  height: 6px;
  width: 10px;
  inset-inline-start: -9px;
  inset-block-start: 0;
  border-start-start-radius: 50px;
  border-start-end-radius: 0;
  border-end-start-radius: 0;
  border-end-end-radius: 0;
}

.ribbone-warning-right span:before {
  position: absolute;
  content: "";
  height: 5px;
  width: 10px;
  inset-inline-start: -9px;
  inset-block-start: 3px;
  background: rgb(var(--warning-rgb));
}

.ribbone-warning-right i {
  color: #fff;
  font-size: 16px;
  padding-inline-start: 7px;
  padding-inline-end: 7px;
}

.ribbone-danger-right {
  position: absolute;
  inset-block-start: -8px;
  inset-inline-end: 10px;
  color: #fff;
}

.ribbone-danger-right:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-inline-start: 17px solid transparent;
  border-inline-end: 17px solid transparent;
  border-block-start: 10px solid rgb(var(--danger-rgb));
  z-index: 6;
}

.ribbone-danger-right span {
  position: relative;
  display: block;
  text-align: center;
  background: rgb(var(--danger-rgb));
  font-size: 13px;
  line-height: 1;
  padding: 10px 2px 5px;
  border-start-end-radius: 10px;
  z-index: 6;
}

.ribbone-danger-right span:after {
  position: absolute;
  content: "";
  inset-inline-end: -6px;
  background: rgb(var(--danger-rgb));
  height: 6px;
  width: 10px;
  inset-inline-start: -9px;
  inset-block-start: 0;
  border-start-start-radius: 50px;
  border-start-end-radius: 0;
  border-end-start-radius: 0;
  border-end-end-radius: 0;
}

.ribbone-danger-right span:before {
  position: absolute;
  content: "";
  height: 5px;
  width: 10px;
  inset-inline-start: -9px;
  inset-block-start: 3px;
  background: rgb(var(--danger-rgb));
}

.ribbone-danger-right i {
  color: #fff;
  font-size: 16px;
  padding-inline-start: 7px;
  padding-inline-end: 7px;
}

.ribbone-success-left {
  position: absolute;
  inset-block-start: -8px;
  inset-inline-start: 10px;
  color: #fff;
}

.ribbone-success-left:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-inline-start: 17px solid transparent;
  border-inline-end: 17px solid transparent;
  border-block-start: 10px solid rgb(var(--success-rgb));
  z-index: 6;
}

.ribbone-success-left span {
  position: relative;
  display: block;
  text-align: center;
  background: rgb(var(--success-rgb));
  font-size: 13px;
  line-height: 1;
  padding: 10px 2px 5px;
  border-start-start-radius: 10px;
  z-index: 6;
}

.ribbone-success-left span:after {
  position: absolute;
  content: "";
  inset-inline-end: -6px;
  background: rgb(var(--success-rgb));
  height: 6px;
  width: 10px;
  inset-inline-end: -9px;
  inset-block-start: 0;
  border-start-start-radius: 0;
  border-start-end-radius: 50px;
  border-end-start-radius: 0;
  border-end-end-radius: 0;
}

.ribbone-success-left span:before {
  position: absolute;
  content: "";
  height: 5px;
  width: 10px;
  inset-inline-end: -9px;
  inset-block-start: 3px;
  background: rgb(var(--success-rgb));
}

.ribbone-success-left i {
  color: #fff;
  font-size: 16px;
  padding-inline-start: 7px;
  padding-inline-end: 7px;
}

.ribbone-info-left {
  position: absolute;
  inset-block-start: -8px;
  inset-inline-start: 10px;
  color: #fff;
}

.ribbone-info-left:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-inline-start: 17px solid transparent;
  border-inline-end: 17px solid transparent;
  border-block-start: 10px solid rgb(var(--info-rgb));
  z-index: 6;
}

.ribbone-info-left span {
  position: relative;
  display: block;
  text-align: center;
  background: rgb(var(--info-rgb));
  font-size: 13px;
  line-height: 1;
  padding: 10px 2px 5px;
  border-start-start-radius: 10px;
  z-index: 6;
}

.ribbone-info-left span:after {
  position: absolute;
  content: "";
  inset-inline-end: -6px;
  background: rgb(var(--info-rgb));
  height: 6px;
  width: 10px;
  inset-inline-end: -9px;
  inset-block-start: 0;
  border-start-start-radius: 0;
  border-start-end-radius: 50px;
  border-end-start-radius: 0;
  border-end-end-radius: 0;
}

.ribbone-info-left span:before {
  position: absolute;
  content: "";
  height: 5px;
  width: 10px;
  inset-inline-end: -9px;
  inset-block-start: 3px;
  background: rgb(var(--info-rgb));
}

.ribbone-info-left i {
  color: #fff;
  font-size: 16px;
  padding-inline-start: 7px;
  padding-inline-end: 7px;
}

.bookmark-ribbone-success-left {
  position: absolute;
  inset-inline-start: 10px;
  color: #fff;
}

.bookmark-ribbone-success-left:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  z-index: 6;
  inset-inline-start: 0;
  border-inline-start: 18px solid rgb(var(--success-rgb));
  border-inline-end: 18px solid rgb(var(--success-rgb));
  border-block-end: 10px solid transparent;
}

.bookmark-ribbone-success-left span {
  position: relative;
  display: block;
  text-align: center;
  background: rgb(var(--success-rgb));
  font-size: 13px;
  line-height: 1;
  padding: 12px 3px 20px;
  z-index: 6;
}

.bookmark-ribbone-success-left i {
  color: #fff;
  font-size: 16px;
  padding-inline-start: 7px;
  padding-inline-end: 7px;
}

.bookmark-ribbone-danger-left {
  position: absolute;
  inset-inline-start: 10px;
  color: #fff;
  width: 30px;
}

.bookmark-ribbone-danger-left:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  z-index: 6;
  inset-inline-start: 0;
  border-inline-start: 15px solid rgb(var(--danger-rgb));
  border-inline-end: 15px solid rgb(var(--danger-rgb));
  border-block-end: 8px solid transparent;
}

.bookmark-ribbone-danger-left span {
  position: relative;
  display: block;
  text-align: center;
  background: rgb(var(--danger-rgb));
  font-size: 12px;
  line-height: 1;
  padding: 12px 0px;
  z-index: 6;
}

.bookmark-ribbone-danger-left i {
  color: #fff;
  font-size: 16px;
  padding-inline-start: 7px;
  padding-inline-end: 7px;
}

.bookmark-ribbone-secondary-right {
  position: absolute;
  inset-inline-end: 10px;
  color: #fff;
  width: 30px;
}

.bookmark-ribbone-secondary-right:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  z-index: 6;
  inset-inline-end: 0;
  border-inline-start: 15px solid rgb(var(--secondary-rgb));
  border-inline-end: 15px solid rgb(var(--secondary-rgb));
  border-block-end: 8px solid transparent;
}

.bookmark-ribbone-secondary-right span {
  position: relative;
  display: block;
  text-align: center;
  background: rgb(var(--secondary-rgb));
  font-size: 11px;
  line-height: 1;
  padding: 12px 0px;
  z-index: 6;
}

.bookmark-ribbone-secondary-right i {
  color: #fff;
  font-size: 16px;
  padding-inline-start: 7px;
  padding-inline-end: 7px;
}

.bookmark-sideleft-ribbone-success-left {
  position: absolute;
  inset-block-start: 10px;
  color: #fff;
}

.bookmark-sideleft-ribbone-success-left:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  z-index: 6;
  inset-block-start: 0;
  inset-inline-end: -8px;
  border-block-start: 13px solid rgb(var(--success-rgb));
  border-block-end: 13px solid rgb(var(--success-rgb));
  border-inline-end: 8px solid transparent;
}

.bookmark-sideleft-ribbone-success-left span {
  position: relative;
  display: block;
  text-align: center;
  background: rgb(var(--success-rgb));
  font-size: 11px;
  padding: 5px 13px;
  z-index: 6;
  height: 26px;
}

.bookmark-sideleft-ribbone-success-left i {
  color: #fff;
  font-size: 16px;
  padding-inline-start: 7px;
  padding-inline-end: 7px;
}

.bookmark-sideright-ribbone-primary-right {
  position: absolute;
  inset-block-start: 10px;
  inset-inline-end: 0;
  color: #fff;
}

.bookmark-sideright-ribbone-primary-right:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  z-index: 6;
  inset-block-start: 0;
  inset-inline-start: -8px;
  border-block-start: 13px solid var(--primary-color);
  border-block-end: 13px solid var(--primary-color);
  border-inline-start: 8px solid transparent;
}

.bookmark-sideright-ribbone-primary-right span {
  position: relative;
  display: block;
  text-align: center;
  background: var(--primary-color);
  font-size: 11px;
  padding: 5px 13px;
  z-index: 6;
  height: 26px;
}

.bookmark-sideright-ribbone-primary-right i {
  color: #fff;
  font-size: 16px;
  padding-inline-start: 7px;
  padding-inline-end: 7px;
}

.ribbone-1 span:after {
  position: absolute;
  content: "";
}

.arrow-ribbone-2 {
  color: #fff;
  padding: 3px 8px;
  position: absolute;
  z-index: 6;
  font-size: 20px;
}

.arrow-ribbone-2:before {
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  content: "";
  inset-inline-end: -24px;
  border-block-start: 16px solid transparent;
  border-inline-start: 24px solid rgb(var(--purple-rgb));
  border-block-end: 16px solid transparent;
  width: 0;
}

.ribbone-card {
  overflow: hidden;
}

.fullwidth-primary-ribbons .bar {
  color: #fff;
  background-color: var(--primary09);
  padding: 5px;
  text-align: center;
  position: relative;
  border: 2px transparent solid;
  box-shadow: 0 10px 30px -10px rgb(var(--light-rgb));
}

.fullwidth-primary-ribbons .bar span {
  position: absolute;
}

.fullwidth-primary-ribbons .bar span:nth-child(1) {
  border-style: solid;
  border-width: 11px;
  z-index: -1;
  border-color: transparent;
  inset-block-start: 31px;
  inset-inline-start: -2px;
}

.fullwidth-primary-ribbons .bar span:nth-child(3) {
  border-style: solid;
  border-width: 11px;
  z-index: -1;
  border-color: transparent;
  inset-block-start: 31px;
  inset-inline-end: -2px;
}

.fullwidth-primary-ribbons .bar span:nth-child(2) {
  inset-block-start: 18px;
  border-style: solid;
  border-width: 18px;
  z-index: -2;
  border-color: var(--primary-color) var(--primary-color) var(--primary-color) transparent;
  border-block-start-color: var(--primary-color);
  border-inline-end-color: var(--primary-color);
  border-block-end-color: var(--primary-color);
  border-inline-start-color: transparent;
  inset-inline-start: -30px;
}

.fullwidth-primary-ribbons .bar span:nth-child(4) {
  inset-block-start: 18px;
  border-style: solid;
  border-width: 18px;
  z-index: -2;
  border-block-start-color: var(--primary-color);
  border-inline-end-color: transparent;
  border-block-end-color: var(--primary-color);
  border-inline-start-color: var(--primary-color);
  inset-inline-end: -30px;
}

.fullwidth-secondary-ribbons .bar {
  color: #fff;
  background-color: rgb(var(--secondary-rgb));
  padding: 5px;
  text-align: center;
  position: relative;
  border: 2px transparent solid;
  box-shadow: 0 10px 30px -10px rgb(var(--light-rgb));
}

.fullwidth-secondary-ribbons .bar span {
  position: absolute;
}

.fullwidth-secondary-ribbons .bar span:nth-child(1) {
  border-style: solid;
  border-width: 11px;
  z-index: -1;
  border-color: transparent;
  inset-block-start: 31px;
  inset-inline-start: -2px;
}

.fullwidth-secondary-ribbons .bar span:nth-child(3) {
  border-style: solid;
  border-width: 11px;
  z-index: -1;
  border-color: transparent;
  inset-block-start: 31px;
  inset-inline-end: -2px;
}

.fullwidth-secondary-ribbons .bar span:nth-child(2) {
  inset-block-start: 18px;
  border-style: solid;
  border-width: 18px;
  z-index: -2;
  border-block-start-color: rgb(var(--secondary-rgb));
  border-inline-end-color: rgb(var(--secondary-rgb));
  border-block-end-color: rgb(var(--secondary-rgb));
  border-inline-start-color: transparent;
  inset-inline-start: -30px;
}

.fullwidth-secondary-ribbons .bar span:nth-child(4) {
  inset-block-start: 18px;
  border-style: solid;
  border-width: 18px;
  z-index: -2;
  border-block-start-color: rgb(var(--secondary-rgb));
  border-inline-end-color: transparent;
  border-block-end-color: rgb(var(--secondary-rgb));
  border-inline-start-color: rgb(var(--secondary-rgb));
  inset-inline-end: -30px;
}

.fullwidth-arrow-warning-ribbons .bar {
  color: #fff;
  background-color: rgb(var(--warning-rgb));
  padding: 5px;
  text-align: center;
  position: relative;
  border: 2px transparent solid;
  box-shadow: 0 10px 30px -10px rgb(var(--light-rgb));
}

.fullwidth-arrow-warning-ribbons .bar span {
  position: absolute;
}

.fullwidth-arrow-warning-ribbons .bar span:nth-child(1) {
  border-style: solid;
  border-width: 11px;
  z-index: -1;
  border-color: transparent;
  inset-block-start: 31px;
  inset-inline-start: -2px;
}

.fullwidth-arrow-warning-ribbons .bar span:nth-child(3) {
  border-style: solid;
  border-width: 11px;
  z-index: -1;
  border-color: transparent;
  inset-block-start: 31px;
  inset-inline-end: -2px;
}

.fullwidth-arrow-warning-ribbons .bar span:nth-child(2) {
  inset-block-start: 18px;
  border-style: solid;
  border-width: 18px;
  z-index: -2;
  border-block-start-color: rgb(var(--warning-rgb));
  border-inline-end-color: rgb(var(--warning-rgb));
  border-block-end-color: rgb(var(--warning-rgb));
  border-inline-start-color: transparent;
  inset-inline-start: -30px;
}

.fullwidth-arrow-warning-ribbons .bar span:nth-child(4) {
  inset-block-start: 18px;
  border-style: solid;
  border-width: 18px;
  z-index: -2;
  border-block-start: 18px solid transparent;
  border-inline-start: 18px solid rgb(var(--warning-rgb));
  border-block-end: 18px solid transparent;
  border-inline-end: transparent;
  inset-inline-end: -20px;
  inset-block-start: -2px;
}

.fullwidth-arrow-danger-ribbons-right .bar {
  color: #fff;
  background-color: rgb(var(--danger-rgb));
  padding: 5px;
  text-align: center;
  position: relative;
  border: 2px transparent solid;
  box-shadow: 0 10px 30px -10px rgb(var(--light-rgb));
}

.fullwidth-arrow-danger-ribbons-right .bar span {
  position: absolute;
}

.fullwidth-arrow-danger-ribbons-right .bar span:nth-child(1) {
  border-style: solid;
  border-width: 11px;
  z-index: -1;
  border-color: transparent;
  inset-block-start: 31px;
  inset-inline-end: -2px;
}

.fullwidth-arrow-danger-ribbons-right .bar span:nth-child(3) {
  border-style: solid;
  border-width: 11px;
  z-index: -1;
  border-color: transparent;
  inset-block-start: 31px;
  inset-inline-start: -2px;
}

.fullwidth-arrow-danger-ribbons-right .bar span:nth-child(2) {
  inset-block-start: 18px;
  border-style: solid;
  border-width: 18px;
  z-index: -2;
  border-block-start-color: rgb(var(--danger-rgb));
  border-inline-end-color: transparent;
  border-block-end-color: rgb(var(--danger-rgb));
  border-inline-start-color: rgb(var(--danger-rgb));
  inset-inline-end: -30px;
}

.fullwidth-arrow-danger-ribbons-right .bar span:nth-child(4) {
  inset-block-start: 18px;
  border-style: solid;
  border-width: 18px;
  z-index: -2;
  border-block-start: 18px solid transparent;
  border-inline-end: 18px solid rgb(var(--danger-rgb));
  border-block-end: 18px solid transparent;
  border-inline-start: transparent;
  inset-inline-start: -20px;
  inset-block-start: -2px;
}

@media (min-width: 576px) {
  .mx-sm-7 {
    margin-inline: 3rem !important;
  }
}

[dir=rtl] .power-ribbone-top-left span {
  transform: rotate(45deg);
}

[dir=rtl] .power-ribbone-top-left span i {
  transform: rotate(-45deg);
}

[dir=rtl] .power-ribbone-bottom-left span {
  transform: rotate(-45deg);
}

[dir=rtl] .power-ribbone-bottom-left span i {
  transform: rotate(45deg);
}

[dir=rtl] .power-ribbone-top-right span {
  transform: rotate(-45deg);
}

[dir=rtl] .power-ribbone-top-right span i {
  transform: rotate(45deg);
}

[dir=rtl] .power-ribbone-bottom-right span {
  transform: rotate(45deg);
}

[dir=rtl] .power-ribbone-bottom-right span i {
  transform: rotate(-45deg);
}

[dir=rtl] .ribbone-top-left span {
  transform: rotate(45deg);
}

[dir=rtl] .ribbone-top-right span {
  transform: rotate(-45deg);
}

[dir=rtl] .ribbon-price .badge {
  transform: rotate(-45deg);
}

/* MENU-STYLES */
[data-vertical-style=closed][data-toggled=close-menu-close] .app-sidebar {
  display: none;
}

@media (min-width: 992px) {
  [data-vertical-style=closed][data-toggled=close-menu-close] .app-content {
    margin-inline-start: 0;
  }

  [data-vertical-style=closed][data-toggled=close-menu-close] .app-header {
    padding-inline-start: 0;
  }
}

[data-vertical-style=closed][data-toggled=close-menu-open] .app-sidebar {
  display: block;
}

@media (min-width: 992px) {
  [data-vertical-style=closed][data-toggled=close-menu-open] .app-content {
    margin-inline-start: 17rem;
  }

  [data-vertical-style=closed][data-toggled=close-menu-open] .app-header {
    padding-inline-start: 17rem;
  }
}

@media (min-width: 992px) {
  [data-vertical-style=detached] .page {
    width: 95%;
    margin: 0 auto;
    padding-inline-start: 1.25rem;
  }

  [data-vertical-style=detached] .app-sidebar {
    inset-block-start: calc(64px + 1.5rem);
    inset-block-end: 1.5rem;
    inset-inline-start: inherit;
    border-radius: 7px;
    border: 1px solid var(--menu-border-color);
    height: auto;
  }

  [data-vertical-style=detached] .app-sidebar .main-sidebar-header {
    display: none;
  }

  [data-vertical-style=detached] .main-sidebar {
    height: 100%;
    margin-block-start: 0;
  }

  [data-vertical-style=detached] .app-content {
    margin-inline-start: 17rem;
    position: relative;
  }

  [data-vertical-style=detached][data-page-style=classic] .app-sidebar {
    border: 1px solid var(--default-border);
  }

  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=dark] .horizontal-logo .header-logo .desktop-logo,
  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=dark] .horizontal-logo .header-logo .toggle-logo,
  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=dark] .horizontal-logo .header-logo .toggle-dark,
  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=dark] .horizontal-logo .header-logo .desktop-white,
  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=dark] .horizontal-logo .header-logo .toggle-white {
    display: none;
  }

  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=dark] .horizontal-logo .header-logo .desktop-dark {
    display: block;
  }

  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=color] .horizontal-logo .header-logo .desktop-logo,
  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=color] .horizontal-logo .header-logo .toggle-logo,
  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=color] .horizontal-logo .header-logo .toggle-dark,
  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=color] .horizontal-logo .header-logo .desktop-dark,
  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=color] .horizontal-logo .header-logo .toggle-white,
  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=gradient] .horizontal-logo .header-logo .desktop-logo,
  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=gradient] .horizontal-logo .header-logo .toggle-logo,
  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=gradient] .horizontal-logo .header-logo .toggle-dark,
  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=gradient] .horizontal-logo .header-logo .desktop-dark,
  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=gradient] .horizontal-logo .header-logo .toggle-white {
    display: none;
  }

  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=color] .horizontal-logo .header-logo .desktop-white,
  [data-vertical-style=detached][data-theme-mode=light][data-header-styles=gradient] .horizontal-logo .header-logo .desktop-white {
    display: block;
  }

  [data-vertical-style=detached][data-theme-mode=dark] .horizontal-logo .header-logo .desktop-logo,
  [data-vertical-style=detached][data-theme-mode=dark] .horizontal-logo .header-logo .toggle-logo,
  [data-vertical-style=detached][data-theme-mode=dark] .horizontal-logo .header-logo .toggle-dark,
  [data-vertical-style=detached][data-theme-mode=dark] .horizontal-logo .header-logo .desktop-white,
  [data-vertical-style=detached][data-theme-mode=dark] .horizontal-logo .header-logo .toggle-white {
    display: none;
  }

  [data-vertical-style=detached][data-theme-mode=dark] .horizontal-logo .header-logo .desktop-dark {
    display: block;
  }

  [data-vertical-style=detached][data-theme-mode=dark][data-header-styles=light] .horizontal-logo .header-logo .desktop-dark,
  [data-vertical-style=detached][data-theme-mode=dark][data-header-styles=light] .horizontal-logo .header-logo .toggle-logo,
  [data-vertical-style=detached][data-theme-mode=dark][data-header-styles=light] .horizontal-logo .header-logo .toggle-dark,
  [data-vertical-style=detached][data-theme-mode=dark][data-header-styles=light] .horizontal-logo .header-logo .desktop-white,
  [data-vertical-style=detached][data-theme-mode=dark][data-header-styles=light] .horizontal-logo .header-logo .toggle-white {
    display: none;
  }

  [data-vertical-style=detached][data-theme-mode=dark][data-header-styles=light] .horizontal-logo .header-logo .desktop-logo {
    display: block;
  }

  [data-vertical-style=detached][data-toggled=detached-close]:not([data-icon-overlay=open]) .app-sidebar {
    width: 5rem;
  }

  [data-vertical-style=detached][data-toggled=detached-close]:not([data-icon-overlay=open]) .app-sidebar .main-menu {
    padding: 0;
  }

  [data-vertical-style=detached][data-toggled=detached-close]:not([data-icon-overlay=open]) .app-sidebar .side-menu__label,
  [data-vertical-style=detached][data-toggled=detached-close]:not([data-icon-overlay=open]) .app-sidebar .side-menu__angle,
  [data-vertical-style=detached][data-toggled=detached-close]:not([data-icon-overlay=open]) .app-sidebar .category-name,
  [data-vertical-style=detached][data-toggled=detached-close]:not([data-icon-overlay=open]) .app-sidebar .slide-menu,
  [data-vertical-style=detached][data-toggled=detached-close]:not([data-icon-overlay=open]) .app-sidebar .side-menu__item .badge {
    display: none !important;
  }

  [data-vertical-style=detached][data-toggled=detached-close]:not([data-icon-overlay=open]) .app-sidebar .side-menu__item {
    justify-content: center;
  }

  [data-vertical-style=detached][data-toggled=detached-close]:not([data-icon-overlay=open]) .app-sidebar .side-menu__item .side-menu__icon {
    margin-inline-end: 0;
    color: var(--menu-prime-color);
    fill: var(--menu-prime-color);
  }

  [data-vertical-style=detached][data-toggled=detached-close]:not([data-icon-overlay=open]) .app-sidebar .slide__category {
    padding: 1.2rem 1.65rem;
    position: relative;
  }

  [data-vertical-style=detached][data-toggled=detached-close]:not([data-icon-overlay=open]) .app-sidebar .slide__category:before {
    content: "";
    position: absolute;
    inset-inline-start: 2.25rem;
    inset-inline-end: 0;
    inset-block-start: 1.25rem;
    inset-block-end: 0;
    width: 0.35rem;
    border-radius: 3.125rem;
    height: 0.35rem;
    border: 1px solid var(--menu-prime-color);
    opacity: 1;
  }

  [data-vertical-style=detached][data-toggled=detached-close] .app-content {
    margin-inline-start: 5rem;
    position: relative;
  }

  [data-vertical-style=detached][data-toggled=detached-close][data-icon-overlay=open] .app-sidebar {
    width: 17rem;
  }

  [data-vertical-style=detached][data-toggled=detached-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header {
    width: 17rem;
  }

  [data-vertical-style=detached][data-toggled=detached-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-logo {
    display: block !important;
  }

  [data-vertical-style=detached][data-toggled=detached-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-vertical-style=detached][data-toggled=detached-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
  [data-vertical-style=detached][data-toggled=detached-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
    display: none !important;
  }

  [data-vertical-style=detached][data-toggled=detached-close][data-icon-overlay=open] .app-sidebar .side-menu__item {
    justify-content: flex-start;
  }

  [data-vertical-style=detached][data-toggled=detached-close][data-icon-overlay=open] .app-sidebar .side-menu__icon {
    margin-inline-end: 0.625rem;
  }

  [data-vertical-style=detached][data-toggled=detached-close][data-icon-overlay=open] .app-sidebar .slide__category {
    padding: 0.75rem 1.65rem;
  }

  [data-vertical-style=detached][data-toggled=detached-close][data-icon-overlay=open] .app-sidebar .slide__category:before {
    display: none;
  }

  [data-vertical-style=detached] .app-header {
    padding-inline-start: 0;
  }

  [data-vertical-style=detached] .app-header .main-header-container {
    width: 94%;
  }

  [data-vertical-style=detached] .app-header .horizontal-logo {
    padding: 0.85rem 0;
    display: block;
    margin: auto;
  }

  [data-vertical-style=detached] .app-header .horizontal-logo img {
    height: 2rem;
    line-height: 2rem;
  }

  [data-vertical-style=detached] .app-header .horizontal-logo .desktop-logo {
    display: block;
  }

  [data-vertical-style=detached] .app-header .horizontal-logo .desktop-dark,
  [data-vertical-style=detached] .app-header .horizontal-logo .toggle-logo,
  [data-vertical-style=detached] .app-header .horizontal-logo .toggle-dark,
  [data-vertical-style=detached] .app-header .horizontal-logo .desktop-white,
  [data-vertical-style=detached] .app-header .horizontal-logo .toggle-white {
    display: none;
  }

  [data-vertical-style=detached] .footer {
    box-shadow: none;
    background-color: transparent !important;
  }
}

@media (min-width: 992px) {
  [data-vertical-style=doublemenu] .app-sidebar {
    width: 5rem;
  }

  [data-vertical-style=doublemenu] .app-sidebar .main-menu {
    padding: 0;
  }

  [data-vertical-style=doublemenu] .app-sidebar .main-sidebar {
    overflow: visible;
    height: 100%;
    box-shadow: none;
    margin-block-start: 0;
  }

  [data-vertical-style=doublemenu] .app-sidebar .main-sidebar-header {
    width: 5rem;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
  }

  [data-vertical-style=doublemenu] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
    display: block !important;
  }

  [data-vertical-style=doublemenu] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-vertical-style=doublemenu] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  [data-vertical-style=doublemenu] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
  [data-vertical-style=doublemenu] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
  [data-vertical-style=doublemenu] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
    display: none !important;
  }

  [data-vertical-style=doublemenu] .app-sidebar .main-menu-container {
    margin-block-start: 3.75rem;
  }

  [data-vertical-style=doublemenu] .app-sidebar .main-menu>.slide>.side-menu__item {
    justify-content: center;
  }

  [data-vertical-style=doublemenu] .app-sidebar .category-name,
  [data-vertical-style=doublemenu] .app-sidebar .side-menu__label,
  [data-vertical-style=doublemenu] .app-sidebar .side-menu__angle {
    display: none;
  }

  [data-vertical-style=doublemenu] .app-sidebar .side-menu__icon {
    margin-inline-end: 0;
    color: var(--menu-prime-color);
    fill: var(--menu-prime-color);
  }

  [data-vertical-style=doublemenu] .app-sidebar .slide__category {
    display: none;
  }

  [data-vertical-style=doublemenu] .app-sidebar .simplebar-content-wrapper {
    position: initial;
  }

  [data-vertical-style=doublemenu] .app-sidebar .simplebar-mask {
    position: inherit;
  }

  [data-vertical-style=doublemenu] .app-sidebar .simplebar-placeholder {
    height: auto !important;
  }

  [data-vertical-style=doublemenu] .app-header {
    padding-inline-start: 17rem;
  }

  [data-vertical-style=doublemenu] .app-content {
    margin-inline-start: 17rem;
  }

  [data-vertical-style=doublemenu] .slide.has-sub .slide-menu {
    position: absolute !important;
    inset-inline-start: 5rem !important;
    background: var(--menu-bg);
    box-shadow: none;
    transition: none !important;
    height: 100% !important;
    inset-inline-end: 0;
    border-inline-end: 1px solid var(--default-border);
  }

  [data-vertical-style=doublemenu] .slide.has-sub .slide-menu.child2,
  [data-vertical-style=doublemenu] .slide.has-sub .slide-menu.child3 {
    border-inline-end: 0 !important;
    inset-inline-start: 0 !important;
    position: relative !important;
    height: auto !important;
    inset-block-start: 0 !important;
  }

  [data-vertical-style=doublemenu] .slide.has-sub .slide-menu.child2 .slide:nth-child(2),
  [data-vertical-style=doublemenu] .slide.has-sub .slide-menu.child3 .slide:nth-child(2) {
    padding-block-start: 0rem;
  }

  [data-vertical-style=doublemenu] .slide-menu.child1 .slide,
  [data-vertical-style=doublemenu] .slide-menu.child2 .slide,
  [data-vertical-style=doublemenu] .slide-menu.child3 .slide {
    padding-inline-start: 0;
  }

  [data-vertical-style=doublemenu] .slide-menu.child1 .slide .side-menu__item,
  [data-vertical-style=doublemenu] .slide-menu.child2 .slide .side-menu__item,
  [data-vertical-style=doublemenu] .slide-menu.child3 .slide .side-menu__item {
    text-align: start;
  }

  [data-vertical-style=doublemenu] .slide-menu.child1 .slide .side-menu__item:before,
  [data-vertical-style=doublemenu] .slide-menu.child2 .slide .side-menu__item:before,
  [data-vertical-style=doublemenu] .slide-menu.child3 .slide .side-menu__item:before {
    inset-inline-start: 0.75rem;
  }

  [data-vertical-style=doublemenu] .slide-menu.child1 .side-menu__angle,
  [data-vertical-style=doublemenu] .slide-menu.child2 .side-menu__angle,
  [data-vertical-style=doublemenu] .slide-menu.child3 .side-menu__angle {
    display: block;
    inset-inline-end: 1rem;
    inset-block-start: 0.65rem;
  }

  [data-vertical-style=doublemenu] .slide-menu.child2,
  [data-vertical-style=doublemenu] .slide-menu.child3 {
    min-width: 10rem;
  }

  [data-vertical-style=doublemenu] .slide-menu.child1 {
    min-width: 12rem;
  }

  [data-vertical-style=doublemenu] .slide-menu.child1 .slide:nth-child(2) {
    padding-block-start: 0.75rem;
  }

  [data-vertical-style=doublemenu] .side-menu__label1 {
    display: block !important;
    font-size: 1rem;
    font-weight: 500;
    color: var(--menu-prime-color);
    border-block-end: 1px solid var(--default-border);
    padding: 2.32rem 0.75rem !important;
    width: 12rem;
    height: 3.75rem;
    line-height: 0.5;
  }

  [data-vertical-style=doublemenu] .slide-menu {
    display: none;
  }

  [data-vertical-style=doublemenu] .slide-menu.double-menu-active {
    visibility: visible !important;
    display: block !important;
    height: 100% !important;
    position: absolute;
    top: 0;
    border-radius: 0 !important;
    border-block-start: 0;
    overflow: hidden auto;
  }

  [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
    display: none !important;
  }

  [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
    display: block !important;
  }

  [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=color] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=color] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=color] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
  [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=color] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
  [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=color] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
  [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=gradient] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=gradient] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=gradient] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
  [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=gradient] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
  [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=gradient] .app-sidebar .main-sidebar-header .header-logo .desktop-white {
    display: none !important;
  }

  [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=color] .app-sidebar .main-sidebar-header .header-logo .toggle-white,
  [data-vertical-style=doublemenu][data-theme-mode=light][data-menu-styles=gradient] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
    display: block !important;
  }

  [data-vertical-style=doublemenu][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  [data-vertical-style=doublemenu][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-vertical-style=doublemenu][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
  [data-vertical-style=doublemenu][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
  [data-vertical-style=doublemenu][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
    display: none !important;
  }

  [data-vertical-style=doublemenu][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
    display: block !important;
  }

  [data-vertical-style=doublemenu][data-theme-mode=dark][data-menu-styles=light] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  [data-vertical-style=doublemenu][data-theme-mode=dark][data-menu-styles=light] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-vertical-style=doublemenu][data-theme-mode=dark][data-menu-styles=light] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
  [data-vertical-style=doublemenu][data-theme-mode=dark][data-menu-styles=light] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
  [data-vertical-style=doublemenu][data-theme-mode=dark][data-menu-styles=light] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
    display: none !important;
  }

  [data-vertical-style=doublemenu][data-theme-mode=dark][data-menu-styles=light] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
    display: block !important;
  }

  [data-vertical-style=doublemenu][data-theme-mode=dark][data-page-style=classic] .slide.has-sub .slide-menu {
    border-inline-end: 1px solid var(--default-border);
  }

  [data-vertical-style=doublemenu][data-toggled=double-menu-close] .app-header {
    padding-inline-start: 5rem;
  }

  [data-vertical-style=doublemenu][data-toggled=double-menu-close] .app-content {
    margin-inline-start: 5rem;
  }

  [data-vertical-style=doublemenu][data-toggled=double-menu-close] .app-sidebar .slide.has-sub .slide-menu {
    display: none !important;
  }

  [data-vertical-style=doublemenu][data-toggled=double-menu-close] .app-sidebar .main-sidebar {
    box-shadow: 0 0.125rem 0 rgba(10, 10, 10, 0.04);
  }

  [data-vertical-style=doublemenu][data-toggled=double-menu-open] .app-sidebar .main-sidebar .slide.side-menu__label1 {
    border-inline-end: 1px solid var(--default-border);
  }
}

@media (min-width: 992px) {
  [data-nav-layout=horizontal] {
    /* horizontal arrows */
  }

  [data-nav-layout=horizontal] .sidemenu-layout-styles {
    display: none;
  }

  [data-nav-layout=horizontal] .main-menu {
    display: flex !important;
    transition: all 0.5s ease;
  }

  [data-nav-layout=horizontal] .main-menu-container {
    display: inline-flex;
  }

  [data-nav-layout=horizontal] .side-menu__label .badge {
    display: none;
  }

  [data-nav-layout=horizontal] .mega-menu {
    columns: 3;
    -webkit-columns: 3;
    -moz-columns: 3;
  }

  [data-nav-layout=horizontal] .app-header {
    padding-inline-start: 0 !important;
    z-index: 104;
    box-shadow: none;
  }

  [data-nav-layout=horizontal] .app-content {
    min-height: calc(100vh - 10.5rem);
    margin-block-start: 8.5rem;
    margin-inline-start: 0rem !important;
    padding: 0;
  }

  [data-nav-layout=horizontal] .app-content>.container-fluid {
    width: 85%;
    margin-inline-start: auto;
    margin-inline-end: auto;
  }

  [data-nav-layout=horizontal] .app-sidebar .main-sidebar,
  [data-nav-layout=horizontal] .app-sidebar .simplebar-mask {
    overflow: visible;
  }

  [data-nav-layout=horizontal] .app-sidebar .main-menu>.slide {
    margin: 0 0.1875rem;
  }

  [data-nav-layout=horizontal] .app-sidebar .main-sidebar {
    box-shadow: none;
  }

  [data-nav-layout=horizontal] .main-sidebar .simplebar-vertical {
    visibility: hidden !important;
  }

  [data-nav-layout=horizontal] .main-sidebar,
  [data-nav-layout=horizontal] .main-header-container {
    width: 85%;
    margin: 0 auto;
  }

  [data-nav-layout=horizontal] .horizontal-logo {
    padding: 0.85rem 0;
    display: block;
    margin: auto;
  }
}

@media (min-width: 992px) and (min-width: 992px) {
  [data-nav-layout=horizontal] .horizontal-logo .header-logo img {
    height: 2rem;
    line-height: 2rem;
  }

  [data-nav-layout=horizontal] .horizontal-logo .header-logo .desktop-logo {
    display: block;
  }

  [data-nav-layout=horizontal] .horizontal-logo .header-logo .desktop-dark,
  [data-nav-layout=horizontal] .horizontal-logo .header-logo .toggle-logo,
  [data-nav-layout=horizontal] .horizontal-logo .header-logo .toggle-dark,
  [data-nav-layout=horizontal] .horizontal-logo .header-logo .desktop-white,
  [data-nav-layout=horizontal] .horizontal-logo .header-logo .toggle-white {
    display: none;
  }
}

@media (min-width: 992px) {
  [data-nav-layout=horizontal] .main-header-container .sidemenu-toggle {
    display: none;
  }

  [data-nav-layout=horizontal] .app-sidebar {
    width: 100% !important;
    height: auto;
    inset-block-start: 4.71rem;
    border-inline-end: 0;
    border-block-end: 1px solid var(--menu-border-color);
  }

  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child1,
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child2,
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 {
    border: 1px solid var(--menu-border-color);
  }

  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 li,
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 li,
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 li {
    padding-inline-start: 0.5rem;
  }

  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .side-menu__item:before,
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 .side-menu__item:before,
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 .side-menu__item:before {
    inset-block-start: 1rem;
  }

  [data-nav-layout=horizontal] .app-sidebar .simplebar-content {
    padding: 0 !important;
    overflow: hidden;
  }

  [data-nav-layout=horizontal] .app-sidebar .simplebar-content-wrapper {
    overflow: visible !important;
    height: auto;
  }

  [data-nav-layout=horizontal] .app-sidebar .simplebar-wrapper {
    width: 100%;
  }

  [data-nav-layout=horizontal] .app-sidebar .main-sidebar {
    padding: 0;
  }

  [data-nav-layout=horizontal] .app-sidebar .simplebar-offset {
    position: inherit;
  }

  [data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child1 {
    inset-inline-start: 1.5rem !important;
    padding: 0.1875rem 0;
  }

  [data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child2,
  [data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child3 {
    inset-inline-end: 100% !important;
    padding: 0.1875rem 0;
  }

  [data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.active {
    inset-inline-start: auto !important;
    inset-inline-end: auto !important;
  }

  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 {
    position: absolute !important;
  }

  [data-nav-layout=horizontal] .app-sidebar .side-menu__item {
    width: 100%;
    display: flex;
    border-radius: 0;
    padding: 0.92rem;
  }

  [data-nav-layout=horizontal] .app-sidebar .side-menu__angle {
    inset-inline-end: 0.45rem;
    display: block;
  }

  [data-nav-layout=horizontal] .app-sidebar .side-menu__icon {
    margin-inline-end: 0.25rem;
    margin-block-end: 0;
  }

  [data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child1 {
    background-color: var(--menu-bg);
    inset-block-start: 100% !important;
    box-shadow: 0.063rem 0.188rem 0.5rem var(--black-1);
    min-width: 12rem;
  }

  [data-nav-layout=horizontal] .app-sidebar .slide {
    padding: 0;
  }

  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 {
    border-radius: 0 0 0.25rem 0.25rem;
  }

  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .slide.has-sub,
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .slide {
    width: 100%;
    display: flex;
    padding: 0 0.1875rem;
  }

  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child2,
  [data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 {
    border-radius: 0.25rem;
  }

  [data-nav-layout=horizontal] .app-sidebar .slide,
  [data-nav-layout=horizontal] .app-sidebar .slide.has-sub {
    position: static;
  }

  [data-nav-layout=horizontal] .app-sidebar .main-menu {
    margin-block-end: 0;
    padding: 0;
  }

  [data-nav-layout=horizontal] .main-sidebar-header {
    display: none !important;
  }

  [data-nav-layout=horizontal] .main-sidebar {
    margin-block-start: 0;
    padding-block-end: 3rem;
  }

  [data-nav-layout=horizontal] .slide__category {
    display: none;
  }

  [data-nav-layout=horizontal] .main-menu-container .slide-left {
    inset-inline-start: 1.438rem;
  }

  [data-nav-layout=horizontal] .main-menu-container .slide-left,
  [data-nav-layout=horizontal] .main-menu-container .slide-right {
    position: absolute;
    top: 0.563rem;
    padding: 0.375rem;
    color: var(--custom-white);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
    border: 1px solid var(--default-border);
    border-radius: 3.125rem;
  }

  [data-nav-layout=horizontal] .main-menu-container .slide-left,
  [data-nav-layout=horizontal] .main-menu-container .slide-right {
    position: absolute;
    top: 0.8rem;
    padding: 0.375rem;
    color: var(--custom-white);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
    border: 1px solid var(--default-border);
    border-radius: 3.125rem;
    background: var(--custom-white);
  }

  [data-nav-layout=horizontal] .main-menu-container .slide-right {
    inset-inline-end: -2%;
  }

  [data-nav-layout=horizontal] .main-menu-container .slide-left {
    inset-inline-start: -2%;
  }

  [data-nav-layout=horizontal][page-style=classic] .app-sidebar {
    border-block-end: 1px solid var(--default-border);
  }

  [data-nav-layout=horizontal][dir=rtl] .main-menu-container .slide-right,
  [data-nav-layout=horizontal][dir=rtl] .main-menu-container .slide-left {
    transform: rotate(180deg);
  }
}

@media (min-width: 992px) {

  .icon-click .app-sidebar,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar {
    width: 5rem;
  }

  .icon-click .app-sidebar .main-sidebar,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .main-sidebar,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar {
    overflow: visible;
    height: 90%;
  }

  .icon-click .app-sidebar .main-sidebar-header,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .main-sidebar-header,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header {
    width: 5rem;
  }

  .icon-click .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
    display: block;
  }

  .icon-click .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  .icon-click .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  .icon-click .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
  .icon-click .app-sidebar .main-sidebar-header .header-logo .desktop-white,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
  .icon-click .app-sidebar .main-sidebar-header .header-logo .toggle-white,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .main-sidebar-header .header-logo .toggle-white,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
    display: none;
  }

  .icon-click .app-sidebar .category-name,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .category-name,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .category-name,
  .icon-click .app-sidebar .side-menu__label,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .side-menu__label,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .side-menu__label,
  .icon-click .app-sidebar .side-menu__angle,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .side-menu__angle,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .side-menu__angle {
    display: none;
  }

  .icon-click .app-sidebar .side-menu__icon,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .side-menu__icon,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .side-menu__icon {
    margin-inline-end: 0;
    color: var(--menu-prime-color);
    fill: var(--menu-prime-color);
  }

  .icon-click .app-sidebar .slide__category,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .slide__category,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide__category {
    padding: 1.2rem 1.65rem;
    position: relative;
  }

  .icon-click .app-sidebar .slide__category:before,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .slide__category:before,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide__category:before {
    content: "";
    position: absolute;
    inset-inline-start: 2.25rem;
    inset-inline-end: 0;
    inset-block-start: 1.25rem;
    inset-block-end: 0;
    width: 0.35rem;
    border-radius: 3.125rem;
    height: 0.35rem;
    border: 1px solid var(--menu-prime-color);
    opacity: 1;
  }

  .icon-click .app-sidebar .simplebar-content-wrapper,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .simplebar-content-wrapper,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .simplebar-content-wrapper {
    position: initial;
  }

  .icon-click .app-sidebar .simplebar-mask,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .simplebar-mask,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .simplebar-mask {
    position: inherit;
  }

  .icon-click .app-sidebar .simplebar-placeholder,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-sidebar .simplebar-placeholder,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .simplebar-placeholder {
    height: auto !important;
  }

  .icon-click .app-header,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-header,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-header {
    padding-inline-start: 5rem;
  }

  .icon-click .app-content,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .app-content,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .app-content {
    margin-inline-start: 5rem;
  }

  .icon-click .slide.side-menu__label1,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .slide.side-menu__label1,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide.side-menu__label1 {
    display: block;
    padding: 0.5rem 1rem !important;
    border-block-end: 1px solid var(--default-border);
  }

  .icon-click .slide.has-sub .slide-menu,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .slide.has-sub .slide-menu,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide.has-sub .slide-menu {
    position: absolute !important;
    inset-inline-start: 5rem !important;
    background: var(--menu-bg);
    inset-block-start: auto !important;
    box-shadow: 0.125rem 0.063rem 0.5rem var(--black-1);
    transition: none !important;
  }

  .icon-click .slide.has-sub .slide-menu.child2,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .slide.has-sub .slide-menu.child2,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide.has-sub .slide-menu.child2,
  .icon-click .slide.has-sub .slide-menu.child3,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .slide.has-sub .slide-menu.child3,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide.has-sub .slide-menu.child3 {
    inset-inline-start: 11.91rem !important;
  }

  .icon-click .slide-menu.child1,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child1,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child1,
  .icon-click .slide-menu.child2,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child2,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child2,
  .icon-click .slide-menu.child3,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child3,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child3 {
    min-width: 12rem;
  }

  .icon-click .slide-menu.child1 .slide .side-menu__item,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child1 .slide .side-menu__item,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child1 .slide .side-menu__item,
  .icon-click .slide-menu.child2 .slide .side-menu__item,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child2 .slide .side-menu__item,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child2 .slide .side-menu__item,
  .icon-click .slide-menu.child3 .slide .side-menu__item,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child3 .slide .side-menu__item,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child3 .slide .side-menu__item {
    text-align: start;
  }

  .icon-click .slide-menu.child1 .slide .side-menu__item:before,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child1 .slide .side-menu__item:before,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child1 .slide .side-menu__item:before,
  .icon-click .slide-menu.child2 .slide .side-menu__item:before,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child2 .slide .side-menu__item:before,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child2 .slide .side-menu__item:before,
  .icon-click .slide-menu.child3 .slide .side-menu__item:before,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child3 .slide .side-menu__item:before,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child3 .slide .side-menu__item:before {
    inset-block-start: 0.6rem;
    inset-inline-start: 0.75rem;
  }

  .icon-click .slide-menu.child1 .side-menu__angle,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child1 .side-menu__angle,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child1 .side-menu__angle,
  .icon-click .slide-menu.child2 .side-menu__angle,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child2 .side-menu__angle,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child2 .side-menu__angle,
  .icon-click .slide-menu.child3 .side-menu__angle,
  [data-nav-style=icon-click][data-nav-layout=horizontal] .slide-menu.child3 .side-menu__angle,
  [data-nav-style=icon-click][data-toggled=icon-click-closed] .slide-menu.child3 .side-menu__angle {
    display: block;
    inset-inline-end: 1rem;
    inset-block-start: 0.65rem;
  }
}

[data-nav-layout=horizontal][data-nav-style=icon-click] .mega-menu {
  -moz-columns: 1;
  columns: 1;
}

@media (min-width: 992px) {
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-menu {
    padding: 0;
  }

  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .main-menu>.slide>.side-menu__item {
    justify-content: center;
  }

  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide .slide-menu.child1,
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide .slide-menu.child2,
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide .slide-menu.child3 {
    border-radius: 0 0.5rem 0.5rem 0;
  }

  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][dir=rtl] .app-sidebar .slide .slide-menu.child1,
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][dir=rtl] .app-sidebar .slide .slide-menu.child2,
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][dir=rtl] .app-sidebar .slide .slide-menu.child3 {
    border-radius: 0.5rem 0 0 0.5rem;
  }

  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
    display: block;
  }

  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
    display: none;
  }

  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar {
    position: absolute;
  }

  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide-menu.child1 li.slide,
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide-menu.child2 li.slide,
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide-menu.child3 li.slide {
    padding-inline-start: 0;
  }

  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide-menu.child1 li.slide a,
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide-menu.child2 li.slide a,
  [data-nav-layout=vertical][data-nav-style=icon-click][data-toggled=icon-click-closed] .app-sidebar .slide-menu.child3 li.slide a {
    border-radius: 0;
  }
}

@media (min-width: 992px) {

  .icon-hover .app-sidebar,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar {
    width: 5rem;
  }

  .icon-hover .app-sidebar .main-sidebar,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .main-sidebar,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar {
    overflow: visible;
    height: 90%;
  }

  .icon-hover .app-sidebar .main-sidebar-header,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .main-sidebar-header,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header {
    width: 5rem;
  }

  .icon-hover .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
    display: block;
  }

  .icon-hover .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  .icon-hover .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  .icon-hover .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
  .icon-hover .app-sidebar .main-sidebar-header .header-logo .desktop-white,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
  .icon-hover .app-sidebar .main-sidebar-header .header-logo .toggle-white,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .main-sidebar-header .header-logo .toggle-white,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
    display: none;
  }

  .icon-hover .app-sidebar .category-name,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .category-name,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .category-name,
  .icon-hover .app-sidebar .side-menu__label,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .side-menu__label,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .side-menu__label,
  .icon-hover .app-sidebar .side-menu__angle,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .side-menu__angle,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .side-menu__angle {
    display: none;
  }

  .icon-hover .app-sidebar .side-menu__icon,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .side-menu__icon,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .side-menu__icon {
    margin-inline-end: 0;
    color: var(--menu-prime-color);
    fill: var(--menu-prime-color);
  }

  .icon-hover .app-sidebar .slide__category,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .slide__category,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide__category {
    padding: 1.2rem 1.65rem;
    position: relative;
  }

  .icon-hover .app-sidebar .slide__category:before,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .slide__category:before,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide__category:before {
    content: "";
    position: absolute;
    inset-inline-start: 2.25rem;
    inset-inline-end: 0;
    inset-block-start: 1.25rem;
    inset-block-end: 0;
    width: 0.35rem;
    border-radius: 3.125rem;
    height: 0.35rem;
    border: 1px solid var(--menu-prime-color);
    opacity: 1;
  }

  .icon-hover .app-sidebar .simplebar-content-wrapper,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .simplebar-content-wrapper,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .simplebar-content-wrapper {
    position: initial;
  }

  .icon-hover .app-sidebar .simplebar-mask,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .simplebar-mask,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .simplebar-mask {
    position: inherit;
  }

  .icon-hover .app-sidebar .simplebar-placeholder,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-sidebar .simplebar-placeholder,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .simplebar-placeholder {
    height: auto !important;
  }

  .icon-hover .app-header,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-header,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-header {
    padding-inline-start: 5rem;
  }

  .icon-hover .app-content,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .app-content,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-content {
    margin-inline-start: 5rem;
  }

  .icon-hover .slide.side-menu__label1,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide.side-menu__label1,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide.side-menu__label1 {
    display: block;
    padding: 0.5rem 1rem !important;
    border-block-end: 1px solid var(--default-border);
  }

  .icon-hover .slide.has-sub .slide-menu,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide.has-sub .slide-menu,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide.has-sub .slide-menu {
    position: absolute !important;
    inset-inline-start: 5rem !important;
    background: var(--menu-bg);
    inset-block-start: auto !important;
    box-shadow: 0.125rem 0.063rem 0.5rem var(--black-1);
    transition: none !important;
  }

  .icon-hover .slide.has-sub .slide-menu.child2,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide.has-sub .slide-menu.child2,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide.has-sub .slide-menu.child2,
  .icon-hover .slide.has-sub .slide-menu.child3,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide.has-sub .slide-menu.child3,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide.has-sub .slide-menu.child3 {
    inset-inline-start: 11.87rem !important;
  }

  .icon-hover .slide-menu.child1,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child1,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child1,
  .icon-hover .slide-menu.child2,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child2,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child2,
  .icon-hover .slide-menu.child3,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child3,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child3 {
    min-width: 12rem;
  }

  .icon-hover .slide-menu.child1 .slide .side-menu__item,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child1 .slide .side-menu__item,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child1 .slide .side-menu__item,
  .icon-hover .slide-menu.child2 .slide .side-menu__item,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child2 .slide .side-menu__item,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child2 .slide .side-menu__item,
  .icon-hover .slide-menu.child3 .slide .side-menu__item,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child3 .slide .side-menu__item,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child3 .slide .side-menu__item {
    text-align: start;
  }

  .icon-hover .slide-menu.child1 .slide .side-menu__item:before,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child1 .slide .side-menu__item:before,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child1 .slide .side-menu__item:before,
  .icon-hover .slide-menu.child2 .slide .side-menu__item:before,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child2 .slide .side-menu__item:before,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child2 .slide .side-menu__item:before,
  .icon-hover .slide-menu.child3 .slide .side-menu__item:before,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child3 .slide .side-menu__item:before,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child3 .slide .side-menu__item:before {
    inset-block-start: 0.6rem;
    inset-inline-start: 0.75rem;
  }

  .icon-hover .slide-menu.child1 .side-menu__angle,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child1 .side-menu__angle,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child1 .side-menu__angle,
  .icon-hover .slide-menu.child2 .side-menu__angle,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child2 .side-menu__angle,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child2 .side-menu__angle,
  .icon-hover .slide-menu.child3 .side-menu__angle,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide-menu.child3 .side-menu__angle,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide-menu.child3 .side-menu__angle {
    display: block;
    inset-inline-end: 1rem;
    inset-block-start: 0.65rem;
  }

  .icon-hover .slide.has-sub:hover .slide-menu.child1,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide.has-sub:hover .slide-menu.child1,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide.has-sub:hover .slide-menu.child1 {
    display: block !important;
  }

  .icon-hover .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2 {
    display: block !important;
  }

  .icon-hover .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2 .slide.has-sub:hover .slide-menu.child3,
  [data-nav-layout=horizontal][data-nav-style=icon-hover] .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2 .slide.has-sub:hover .slide-menu.child3,
  [data-nav-style=icon-hover][data-toggled=icon-hover-closed] .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2 .slide.has-sub:hover .slide-menu.child3 {
    display: block !important;
  }
}

[data-nav-layout=horizontal][data-nav-style=icon-hover] .mega-menu {
  -moz-columns: 1;
  columns: 1;
}

@media (min-width: 992px) {
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-menu {
    padding: 0;
  }

  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .main-menu>.slide>.side-menu__item {
    justify-content: center;
  }

  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide .slide-menu.child1,
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide .slide-menu.child2,
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide .slide-menu.child3 {
    border-radius: 0 0.5rem 0.5rem 0;
  }

  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][dir=rtl] .app-sidebar .slide .slide-menu.child1,
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][dir=rtl] .app-sidebar .slide .slide-menu.child2,
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][dir=rtl] .app-sidebar .slide .slide-menu.child3 {
    border-radius: 0.5rem 0 0 0.5rem;
  }

  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
    display: block;
  }

  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed][data-theme-mode=dark] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
    display: none;
  }

  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar {
    position: absolute;
  }

  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide-menu.child1,
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide-menu.child2,
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide-menu.child3 {
    padding: 0.1875rem;
  }

  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide-menu.child1 li.slide,
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide-menu.child2 li.slide,
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide-menu.child3 li.slide {
    padding-inline-start: 0;
  }

  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide-menu.child1 li.slide a,
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide-menu.child2 li.slide a,
  [data-nav-layout=vertical][data-nav-style=icon-hover][data-toggled=icon-hover-closed] .app-sidebar .slide-menu.child3 li.slide a {
    border-radius: 0;
  }
}

@media (min-width: 992px) {
  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar {
    width: 5rem;
  }

  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .main-sidebar-header {
    width: 5rem;
  }

  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
    display: block;
  }

  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .main-sidebar-header .header-logo .desktop-logo,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .main-sidebar-header .header-logo .desktop-white,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .main-sidebar-header .header-logo .toggle-white {
    display: none;
  }

  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .category-name,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .side-menu__label,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .side-menu__angle {
    display: none;
  }

  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .slide.has-sub.open .slide-menu {
    display: none !important;
  }

  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .side-menu__icon {
    margin-inline-end: 0;
    color: var(--menu-prime-color);
    fill: var(--menu-prime-color);
  }

  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .slide__category {
    display: none;
  }

  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .app-sidebar .side-menu__item .badge {
    display: none;
  }

  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .side-menu__item {
    justify-content: center;
  }

  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .side-menu__item.active .side-menu__icon,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close]:not([data-icon-overlay=open]) .side-menu__item:hover .side-menu__icon {
    color: var(--primary-color);
    fill: var(--primary-color);
  }

  [data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-header {
    padding-inline-start: 5rem;
  }

  [data-vertical-style=overlay][data-toggled=icon-overlay-close] .app-content {
    margin-inline-start: 5rem;
  }

  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar {
    width: 17rem;
  }

  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header {
    width: 17rem;
  }

  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-logo {
    display: block;
  }

  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-dark,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-logo,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-dark,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .desktop-white,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .main-sidebar-header .header-logo .toggle-white {
    display: none;
  }

  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .side-menu__item .badge {
    display: inline;
  }

  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .side-menu__item {
    justify-content: flex-start;
  }

  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .side-menu__icon {
    margin-inline-end: 0.625rem;
  }

  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .slide__category {
    padding: 0.75rem 1.65rem;
  }

  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-icon-overlay=open] .app-sidebar .slide__category:before {
    display: none;
  }

  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark] .main-sidebar-header .header-logo .toggle-dark {
    display: block;
  }

  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark] .main-sidebar-header .header-logo .desktop-dark,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark] .main-sidebar-header .header-logo .desktop-logo,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark] .main-sidebar-header .header-logo .toggle-logo,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark] .main-sidebar-header .header-logo .desktop-white,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark] .main-sidebar-header .header-logo .toggle-white {
    display: none;
  }

  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark][data-icon-overlay=open] .main-sidebar-header .header-logo .desktop-dark {
    display: block;
  }

  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark][data-icon-overlay=open] .main-sidebar-header .header-logo .toggle-dark,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark][data-icon-overlay=open] .main-sidebar-header .header-logo .desktop-logo,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark][data-icon-overlay=open] .main-sidebar-header .header-logo .toggle-logo,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark][data-icon-overlay=open] .main-sidebar-header .header-logo .desktop-white,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark][data-icon-overlay=open] .main-sidebar-header .header-logo .toggle-white {
    display: none;
  }

  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark][data-icon-overlay=open][data-menu-styles=light] .main-sidebar-header .header-logo .desktop-logo {
    display: block;
  }

  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark][data-icon-overlay=open][data-menu-styles=light] .main-sidebar-header .header-logo .toggle-dark,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark][data-icon-overlay=open][data-menu-styles=light] .main-sidebar-header .header-logo .desktop-white,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark][data-icon-overlay=open][data-menu-styles=light] .main-sidebar-header .header-logo .toggle-logo,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark][data-icon-overlay=open][data-menu-styles=light] .main-sidebar-header .header-logo .desktop-dark,
  [data-vertical-style=overlay][data-toggled=icon-overlay-close][data-theme-mode=dark][data-icon-overlay=open][data-menu-styles=light] .main-sidebar-header .header-logo .toggle-white {
    display: none;
  }
}

@media (min-width: 992px) {
  [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar {
    position: absolute;
    width: 9rem;
  }

  [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar .main-menu {
    padding: 0;
  }

  [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar .main-sidebar {
    overflow: visible;
  }

  [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar .main-sidebar-header {
    width: 9rem;
  }

  [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar .side-menu__icon {
    margin-inline-end: 0;
    margin-block-end: 0.5rem;
  }

  [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar .slide {
    padding: 0;
  }

  [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar .slide__category,
  [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar .side-menu__angle {
    display: none;
  }

  [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar .slide.has-sub.open .slide-menu {
    display: none !important;
  }

  [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar .side-menu__item {
    border-radius: 0;
  }

  [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar .side-menu__item,
  [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-sidebar .side-menu__label {
    display: block;
    text-align: center;
  }

  [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open])[data-menu-position=fixed] .app-sidebar {
    position: fixed;
  }

  [data-vertical-style=icontext][data-toggled=icon-text-close]:not([data-icon-text=open]) .app-header {
    padding-inline-start: 9rem;
  }

  [data-vertical-style=icontext][data-toggled=icon-text-close] .app-content {
    margin-inline-start: 9rem;
  }

  [data-vertical-style=icontext][data-toggled=icon-text-close][data-icon-text=open] .app-sidebar {
    width: 17rem;
  }

  [data-vertical-style=icontext][data-toggled=icon-text-close][data-icon-text=open] .app-sidebar .main-sidebar-header {
    width: 17rem;
  }

  [data-vertical-style=icontext][data-toggled=icon-text-close][data-icon-text=open] .app-sidebar .slide-menu.child1 li,
  [data-vertical-style=icontext][data-toggled=icon-text-close][data-icon-text=open] .app-sidebar .slide-menu .child2 li,
  [data-vertical-style=icontext][data-toggled=icon-text-close][data-icon-text=open] .app-sidebar .slide-menu .child3 li {
    padding: 0;
    padding-inline-start: 1.5rem;
  }
}

@media (min-width: 992px) {
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .side-menu__item {
    display: flex !important;
    padding-top: 0.93rem;
    padding-inline-end: 1.75rem;
    padding-bottom: 0.93rem;
    padding-inline-start: 0.75rem;
  }

  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .side-menu__icon {
    margin-block-end: 0 !important;
    margin-inline-end: 0.35rem !important;
  }

  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .side-menu__angle {
    display: block !important;
  }

  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu {
    inset-inline-start: auto !important;
  }

  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.active {
    inset-inline-start: auto !important;
    inset-inline-end: auto !important;
  }

  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child1 {
    inset-block-start: 100% !important;
  }

  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-header {
    padding-inline-start: 9.5rem;
  }
}

[data-nav-style=menu-click][data-toggled=menu-click-closed] .app-header {
  padding-inline-start: 9.5rem;
}

@media (min-width: 992px) {
  [data-nav-style=menu-click][data-nav-layout=vertical][data-toggled=menu-click-closed] .app-sidebar {
    position: absolute;
  }

  [data-nav-style=menu-click][data-nav-layout=vertical][data-toggled=menu-click-closed] .app-sidebar .main-menu {
    padding: 0;
  }

  [data-nav-style=menu-click][data-nav-layout=vertical][data-toggled=menu-click-closed] .app-sidebar .side-menu__item {
    border-radius: 0.5rem;
    margin: 0.25rem 0.25rem 0 0.25rem !important;
  }

  [data-nav-style=menu-click][data-nav-layout=vertical][data-toggled=menu-click-closed] .app-sidebar .side-menu__item:last-child {
    margin: 0.25rem !important;
  }

  [data-nav-style=menu-click][data-nav-layout=vertical][data-toggled=menu-click-closed] .app-sidebar .slide .slide-menu.child1,
  [data-nav-style=menu-click][data-nav-layout=vertical][data-toggled=menu-click-closed] .app-sidebar .slide .slide-menu.child2,
  [data-nav-style=menu-click][data-nav-layout=vertical][data-toggled=menu-click-closed] .app-sidebar .slide .slide-menu.child3 {
    border-radius: 0 0.5rem 0.5rem 0;
  }

  [data-nav-style=menu-click][data-nav-layout=vertical][data-toggled=menu-click-closed][dir=rtl] .app-sidebar .slide .slide-menu.child1,
  [data-nav-style=menu-click][data-nav-layout=vertical][data-toggled=menu-click-closed][dir=rtl] .app-sidebar .slide .slide-menu.child2,
  [data-nav-style=menu-click][data-nav-layout=vertical][data-toggled=menu-click-closed][dir=rtl] .app-sidebar .slide .slide-menu.child3 {
    border-radius: 0.5rem 0 0 0.5rem;
  }
}

@media (min-width: 992px) {

  .menu-click .app-sidebar,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar {
    width: 9.5rem;
  }

  .menu-click .app-sidebar .main-sidebar,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .main-sidebar,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar {
    overflow: visible;
    height: 90%;
  }

  .menu-click .app-sidebar .main-sidebar-header,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .main-sidebar-header,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .main-sidebar-header {
    width: 9.5rem;
  }

  .menu-click .app-sidebar .side-menu__icon,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .side-menu__icon,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .side-menu__icon {
    margin-inline-end: 0;
    margin-block-end: 0.5rem;
  }

  .menu-click .app-sidebar .slide,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide {
    padding: 0;
  }

  .menu-click .app-sidebar .slide-menu.child1,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child1,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child1,
  .menu-click .app-sidebar .slide-menu.child2,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child2,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child2,
  .menu-click .app-sidebar .slide-menu.child3,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child3,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child3 {
    min-width: 12rem;
  }

  .menu-click .app-sidebar .slide-menu.child1 .slide .side-menu__item,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .slide .side-menu__item,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child1 .slide .side-menu__item,
  .menu-click .app-sidebar .slide-menu.child2 .slide .side-menu__item,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 .slide .side-menu__item,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child2 .slide .side-menu__item,
  .menu-click .app-sidebar .slide-menu.child3 .slide .side-menu__item,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 .slide .side-menu__item,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child3 .slide .side-menu__item {
    text-align: start;
  }

  .menu-click .app-sidebar .slide-menu.child1 .slide .side-menu__item:before,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .slide .side-menu__item:before,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child1 .slide .side-menu__item:before,
  .menu-click .app-sidebar .slide-menu.child2 .slide .side-menu__item:before,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 .slide .side-menu__item:before,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child2 .slide .side-menu__item:before,
  .menu-click .app-sidebar .slide-menu.child3 .slide .side-menu__item:before,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 .slide .side-menu__item:before,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child3 .slide .side-menu__item:before {
    inset-block-start: 0.6rem;
    inset-inline-start: 0.75rem;
  }

  .menu-click .app-sidebar .slide-menu.child1 .side-menu__angle,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .side-menu__angle,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child1 .side-menu__angle,
  .menu-click .app-sidebar .slide-menu.child2 .side-menu__angle,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 .side-menu__angle,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child2 .side-menu__angle,
  .menu-click .app-sidebar .slide-menu.child3 .side-menu__angle,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 .side-menu__angle,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child3 .side-menu__angle {
    display: block;
    inset-inline-end: 1rem;
    inset-block-start: 0.65rem;
  }

  .menu-click .app-sidebar .slide-menu.child1 .slide.has-sub.side-menu__label1,
  .menu-click .app-sidebar .slide-menu.child1 .slide.side-menu__label1,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .slide.side-menu__label1,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child1 .slide.side-menu__label1,
  .menu-click .app-sidebar .slide-menu.child2 .slide.has-sub.side-menu__label1,
  .menu-click .app-sidebar .slide-menu.child2 .slide.side-menu__label1,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 .slide.side-menu__label1,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child2 .slide.side-menu__label1,
  .menu-click .app-sidebar .slide-menu.child3 .slide.has-sub.side-menu__label1,
  .menu-click .app-sidebar .slide-menu.child3 .slide.side-menu__label1,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 .slide.side-menu__label1,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide-menu.child3 .slide.side-menu__label1 {
    display: none;
  }

  .menu-click .app-sidebar .slide__category,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide__category,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide__category,
  .menu-click .app-sidebar .side-menu__angle,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .side-menu__angle,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .side-menu__angle {
    display: none;
  }

  .menu-click .app-sidebar .side-menu__item,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .side-menu__item,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .side-menu__item,
  .menu-click .app-sidebar .side-menu__label,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .side-menu__label,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .side-menu__label {
    display: block;
    text-align: center;
  }

  .menu-click .app-sidebar .slide.has-sub .slide-menu,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu {
    position: absolute !important;
    inset-inline-start: 9.5rem !important;
    background: var(--menu-bg);
    inset-block-start: auto !important;
    box-shadow: 0.125rem 0.063rem 0.5rem var(--black-1);
    transition: none !important;
  }

  .menu-click .app-sidebar .slide.has-sub .slide-menu.child2,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child2,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
  .menu-click .app-sidebar .slide.has-sub .slide-menu.child3,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child3,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .slide.has-sub .slide-menu.child3 {
    inset-inline-start: 11.92rem !important;
  }

  .menu-click .app-sidebar .simplebar-content-wrapper,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .simplebar-content-wrapper,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .simplebar-content-wrapper {
    position: initial;
  }

  .menu-click .app-sidebar .simplebar-mask,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .simplebar-mask,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .simplebar-mask {
    position: inherit;
  }

  .menu-click .app-sidebar .simplebar-placeholder,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar .simplebar-placeholder,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar .simplebar-placeholder {
    height: auto !important;
  }

  .menu-click .app-content,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-content,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-content {
    margin-inline-start: 9.5rem;
  }
}

@media (max-width: 991.98px) {

  .menu-click .app-sidebar,
  [data-nav-style=menu-click][data-nav-layout=horizontal] .app-sidebar,
  [data-nav-style=menu-click][data-toggled=menu-click-closed] .app-sidebar {
    width: 17rem;
  }
}

@media (min-width: 992px) {
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .side-menu__item {
    display: flex !important;
    padding-top: 0.93rem;
    padding-inline-end: 1.75rem;
    padding-bottom: 0.93rem;
    padding-inline-start: 0.75rem;
  }

  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .side-menu__icon {
    margin-block-end: 0 !important;
    margin-inline-end: 0.35rem !important;
  }

  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .side-menu__angle {
    display: block !important;
  }

  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.active {
    inset-inline-start: auto !important;
    inset-inline-end: auto !important;
  }

  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child1 {
    inset-block-start: 100% !important;
  }
}

@media (min-width: 992px) {
  [data-nav-style=menu-hover][data-nav-layout=vertical][data-toggled=menu-hover-closed] .app-sidebar {
    position: absolute;
  }

  [data-nav-style=menu-hover][data-nav-layout=vertical][data-toggled=menu-hover-closed] .app-sidebar .main-menu {
    padding: 0;
  }

  [data-nav-style=menu-hover][data-nav-layout=vertical][data-toggled=menu-hover-closed] .app-sidebar .side-menu__item {
    border-radius: 0.5rem;
    margin: 0.25rem 0.25rem 0 0.25rem !important;
  }

  [data-nav-style=menu-hover][data-nav-layout=vertical][data-toggled=menu-hover-closed] .app-sidebar .side-menu__item:last-child {
    margin: 0.25rem !important;
  }

  [data-nav-style=menu-hover][data-nav-layout=vertical][data-toggled=menu-hover-closed] .app-sidebar .slide .slide-menu.child1,
  [data-nav-style=menu-hover][data-nav-layout=vertical][data-toggled=menu-hover-closed] .app-sidebar .slide .slide-menu.child2,
  [data-nav-style=menu-hover][data-nav-layout=vertical][data-toggled=menu-hover-closed] .app-sidebar .slide .slide-menu.child3 {
    border-radius: 0 0.5rem 0.5rem 0;
  }

  [data-nav-style=menu-hover][data-nav-layout=vertical][data-toggled=menu-hover-closed][dir=rtl] .app-sidebar .slide .slide-menu.child1,
  [data-nav-style=menu-hover][data-nav-layout=vertical][data-toggled=menu-hover-closed][dir=rtl] .app-sidebar .slide .slide-menu.child2,
  [data-nav-style=menu-hover][data-nav-layout=vertical][data-toggled=menu-hover-closed][dir=rtl] .app-sidebar .slide .slide-menu.child3 {
    border-radius: 0.5rem 0 0 0.5rem;
  }
}

@media (min-width: 992px) {

  .menu-hover .app-sidebar,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar {
    width: 9.5rem;
  }

  .menu-hover .app-sidebar .main-sidebar,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .main-sidebar,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar {
    overflow: visible;
    height: 90%;
  }

  .menu-hover .app-sidebar .main-sidebar-header,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .main-sidebar-header,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .main-sidebar-header {
    width: 9.5rem;
  }

  .menu-hover .app-sidebar .side-menu__icon,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .side-menu__icon,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .side-menu__icon {
    margin-inline-end: 0;
    margin-block-end: 0.5rem;
  }

  .menu-hover .app-sidebar .slide,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide {
    padding: 0;
  }

  .menu-hover .app-sidebar .slide-menu.child1,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child1,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child1,
  .menu-hover .app-sidebar .slide-menu.child2,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child2,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child2,
  .menu-hover .app-sidebar .slide-menu.child3,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child3,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child3 {
    min-width: 12rem;
    display: none !important;
  }

  .menu-hover .app-sidebar .slide-menu.child1 .slide .side-menu__item,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .slide .side-menu__item,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child1 .slide .side-menu__item,
  .menu-hover .app-sidebar .slide-menu.child2 .slide .side-menu__item,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 .slide .side-menu__item,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child2 .slide .side-menu__item,
  .menu-hover .app-sidebar .slide-menu.child3 .slide .side-menu__item,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 .slide .side-menu__item,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child3 .slide .side-menu__item {
    text-align: start;
  }

  .menu-hover .app-sidebar .slide-menu.child1 .slide .side-menu__item:before,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .slide .side-menu__item:before,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child1 .slide .side-menu__item:before,
  .menu-hover .app-sidebar .slide-menu.child2 .slide .side-menu__item:before,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 .slide .side-menu__item:before,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child2 .slide .side-menu__item:before,
  .menu-hover .app-sidebar .slide-menu.child3 .slide .side-menu__item:before,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 .slide .side-menu__item:before,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child3 .slide .side-menu__item:before {
    inset-block-start: 0.6rem;
    inset-inline-start: 0.75rem;
  }

  .menu-hover .app-sidebar .slide-menu.child1 .slide.has-sub.side-menu__label1,
  .menu-hover .app-sidebar .slide-menu.child1 .slide.side-menu__label1,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .slide.side-menu__label1,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child1 .slide.side-menu__label1,
  .menu-hover .app-sidebar .slide-menu.child2 .slide.has-sub.side-menu__label1,
  .menu-hover .app-sidebar .slide-menu.child2 .slide.side-menu__label1,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 .slide.side-menu__label1,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child2 .slide.side-menu__label1,
  .menu-hover .app-sidebar .slide-menu.child3 .slide.has-sub.side-menu__label1,
  .menu-hover .app-sidebar .slide-menu.child3 .slide.side-menu__label1,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 .slide.side-menu__label1,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child3 .slide.side-menu__label1 {
    display: none;
  }

  .menu-hover .app-sidebar .slide.has-sub:hover .slide-menu.child1,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub:hover .slide-menu.child1,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub:hover .slide-menu.child1 {
    display: block !important;
  }

  .menu-hover .app-sidebar .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2 {
    display: block !important;
  }

  .menu-hover .app-sidebar .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2 .slide.has-sub:hover .slide-menu.child3,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2 .slide.has-sub:hover .slide-menu.child3,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub:hover .slide-menu.child1 .slide.has-sub:hover .slide-menu.child2 .slide.has-sub:hover .slide-menu.child3 {
    display: block !important;
  }

  .menu-hover .app-sidebar .slide__category,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide__category,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide__category,
  .menu-hover .app-sidebar .side-menu__angle,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .side-menu__angle,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .side-menu__angle {
    display: none;
  }

  .menu-hover .app-sidebar .slide-menu.child1 .side-menu__angle,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child1 .side-menu__angle,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child1 .side-menu__angle,
  .menu-hover .app-sidebar .slide-menu.child2 .side-menu__angle,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child2 .side-menu__angle,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child2 .side-menu__angle,
  .menu-hover .app-sidebar .slide-menu.child3 .side-menu__angle,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide-menu.child3 .side-menu__angle,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide-menu.child3 .side-menu__angle {
    display: block;
    inset-inline-end: 1rem;
    inset-block-start: 0.65rem;
  }

  .menu-hover .app-sidebar .side-menu__item,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .side-menu__item,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .side-menu__item,
  .menu-hover .app-sidebar .side-menu__label,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .side-menu__label,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .side-menu__label {
    display: block;
    text-align: center;
  }

  .menu-hover .app-sidebar .slide.has-sub .slide-menu,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu {
    position: absolute !important;
    inset-inline-start: 9.5rem !important;
    background: var(--menu-bg);
    inset-block-start: auto !important;
    box-shadow: 0.125rem 0.063rem 0.5rem var(--black-1);
    transition: none !important;
  }

  .menu-hover .app-sidebar .slide.has-sub .slide-menu.child2,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child2,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child2,
  .menu-hover .app-sidebar .slide.has-sub .slide-menu.child3,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .slide.has-sub .slide-menu.child3,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .slide.has-sub .slide-menu.child3 {
    inset-inline-start: 11.92rem !important;
  }

  .menu-hover .app-sidebar .simplebar-content-wrapper,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .simplebar-content-wrapper,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .simplebar-content-wrapper {
    position: initial;
  }

  .menu-hover .app-sidebar .simplebar-mask,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .simplebar-mask,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .simplebar-mask {
    position: inherit;
  }

  .menu-hover .app-sidebar .simplebar-placeholder,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-sidebar .simplebar-placeholder,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-sidebar .simplebar-placeholder {
    height: auto !important;
  }

  .menu-hover .app-header,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-header,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-header {
    padding-inline-start: 9.5rem;
  }

  .menu-hover .app-content,
  [data-nav-style=menu-hover][data-nav-layout=horizontal] .app-content,
  [data-nav-style=menu-hover][data-toggled=menu-hover-closed] .app-content {
    margin-inline-start: 9.5rem;
  }
}

.main-sidebar {
  margin-block-start: 4.2rem;
  padding-block-start: 0.5rem;
  padding-block-end: 5rem;
  height: 100vh;
  position: relative;
  overflow: auto;
}

[dir=rtl] .app-sidebar .fe-chevron-right {
  transform: rotate(180deg);
}

[dir=rtl] .sidemenu-toggle .header-link .header-link-icon {
  transform: rotate(180deg);
}

.app-sidebar {
  width: 17rem;
  height: 100%;
  background: var(--menu-bg);
  border-inline-end: 1px solid var(--menu-border-color);
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  z-index: 103;
  transition: left 50ms ease, width 50ms ease;
  box-shadow: 0 8px 24px var(--shadow-color);
}

.app-sidebar .main-menu {
  padding: 0 10px 0 10px;
}

.app-sidebar .main-sidebar-header {
  width: 17rem;
  position: fixed;
  display: flex;
  background: var(--menu-bg);
  z-index: 9;
  align-items: center;
  justify-content: center;
  padding: 19px 17px;
  border-inline-end: 1px solid var(--menu-border-color);
  border-block-end: 1px solid var(--menu-border-color);
  transition: left 50ms ease, width 50ms ease;
}

.app-sidebar .main-sidebar-header .header-logo img {
  line-height: 2rem;
}

.app-sidebar .main-sidebar-header .header-logo .desktop-logo {
  display: block;
}

.app-sidebar .main-sidebar-header .header-logo .desktop-dark,
.app-sidebar .main-sidebar-header .header-logo .toggle-logo,
.app-sidebar .main-sidebar-header .header-logo .toggle-dark,
.app-sidebar .main-sidebar-header .header-logo .desktop-white,
.app-sidebar .main-sidebar-header .header-logo .toggle-white {
  display: none;
}

.app-sidebar .slide__category {
  color: var(--menu-prime-color);
  padding-block-start: 12px;
  padding-inline-end: 30px;
  padding-block-end: 2px;
  font-size: 11px;
  font-weight: 600;
  padding-inline-start: 20px;
  margin-block-end: 0;
  white-space: nowrap;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  opacity: 0.6;
}

.app-sidebar .slide__category:not(:first-child) {
  margin-block-start: 0.75rem;
}

.app-sidebar .slide {
  margin: 0;
}

.app-sidebar .side-menu__item {
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 400;
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--menu-prime-color);
  white-space: nowrap;
}

.app-sidebar .side-menu__item.active,
.app-sidebar .side-menu__item:hover {
  color: var(--primary-color);
  background-color: transparent;
}

.app-sidebar .side-menu__item.active .side-menu__label,
.app-sidebar .side-menu__item.active .side-menu__angle,
.app-sidebar .side-menu__item:hover .side-menu__label,
.app-sidebar .side-menu__item:hover .side-menu__angle {
  color: var(--primary-color);
}

.app-sidebar .side-menu__item.active .side-menu__icon,
.app-sidebar .side-menu__item:hover .side-menu__icon {
  fill: var(--primary-color) !important;
  color: var(--primary-color) !important;
}

.app-sidebar .slide-menu.child1 .side-menu__item.active,
.app-sidebar .slide-menu.child1 .side-menu__item:hover,
.app-sidebar .slide-menu.child2 .side-menu__item.active,
.app-sidebar .slide-menu.child2 .side-menu__item:hover,
.app-sidebar .slide-menu.child3 .side-menu__item.active,
.app-sidebar .slide-menu.child3 .side-menu__item:hover {
  background-color: transparent;
  opacity: 1;
}

.app-sidebar .slide-menu {
  padding: 0rem;
}

.app-sidebar .slide-menu.child1 .side-menu__item,
.app-sidebar .slide-menu.child2 .side-menu__item {
  padding: 0.45rem 1.6rem;
  display: flex;
  align-items: center;
  font-size: 13.3px;
  white-space: nowrap;
  opacity: 0.8;
}

.app-sidebar .slide-menu.child1 .side-menu__item:before,
.app-sidebar .slide-menu.child2 .side-menu__item:before,
.app-sidebar .slide-menu.child3 .side-menu__item:before {
  position: absolute;
  content: "\e933";
  font-family: feather !important;
  font-size: 10px;
  inset-inline-start: 0.5rem;
  opacity: 0.8;
}

.app-sidebar .slide-menu.child1 .side-menu__item:hover,
.app-sidebar .slide-menu.child2 .side-menu__item:hover,
.app-sidebar .slide-menu.child3 .side-menu__item:hover {
  color: var(--primary-color);
}

.app-sidebar .slide-menu.child1 .side-menu__item:hover:before,
.app-sidebar .slide-menu.child2 .side-menu__item:hover:before,
.app-sidebar .slide-menu.child3 .side-menu__item:hover:before {
  color: var(--primary-color);
  opacity: 1;
}

.app-sidebar .slide-menu.child1 .side-menu__item.active:before,
.app-sidebar .slide-menu.child2 .side-menu__item.active:before,
.app-sidebar .slide-menu.child3 .side-menu__item.active:before {
  color: var(--primary-color);
  opacity: 1;
}

.app-sidebar .slide-menu.child1 li,
.app-sidebar .slide-menu.child2 li,
.app-sidebar .slide-menu.child3 li {
  padding: 0;
  padding-inline-start: 1.5rem;
  position: relative;
}

.app-sidebar .side-menu__label {
  white-space: nowrap;
  flex: 1 1 auto;
  margin-block-start: 3px;
}

.app-sidebar .side-menu__icon {
  margin-inline-end: 8px;
  font-size: 1.15rem;
  text-align: center;
  color: var(--primary-color);
  fill: var(--primary-color);
  border-radius: 7px;
  line-height: 30px;
}

.app-sidebar .side-menu__angle {
  transform-origin: center;
  position: absolute;
  inset-inline-end: 1.5rem;
  font-size: 11px;
  color: var(--menu-prime-color);
  transition: left 50ms ease, width 50ms ease;
}

.app-sidebar .slide.side-menu__label1 {
  display: none;
}

.horizontal-logo {
  padding: 0.85rem 0rem;
}

.slide.has-sub .slide-menu {
  transform: translate(0, 0) !important;
  visibility: visible !important;
}

.nav ul li {
  list-style-type: none;
}

.nav>ul {
  padding-inline-start: 0px;
}

.slide-menu {
  display: none;
}

.slide.has-sub {
  display: grid;
}

.slide.has-sub.open>.side-menu__item .side-menu__angle {
  transform: rotate(90deg);
}

@media (min-width: 992px) {
  [data-toggled=open] .page {
    position: absolute;
  }

  [data-toggled=menu-click-closed]:not([data-nav-layout=horizontal]) .app-content,
  [data-toggled=menu-hover-closed]:not([data-nav-layout=horizontal]) .app-content,
  [data-toggled=icon-click-closed]:not([data-nav-layout=horizontal]) .app-content,
  [data-toggled=icon-hover-closed]:not([data-nav-layout=horizontal]) .app-content,
  [data-toggled=icon-text-close]:not([data-nav-layout=horizontal]) .app-content {
    min-height: calc(100vh + 50rem);
  }
}

/* Responsive Styles Start */
@media (max-width: 991.98px) {

  .horizontal-logo .header-logo .desktop-logo,
  .horizontal-logo .header-logo .desktop-dark,
  .horizontal-logo .header-logo .toggle-dark,
  .horizontal-logo .header-logo .desktop-white,
  .horizontal-logo .header-logo .toggle-white {
    display: none;
  }

  .horizontal-logo .header-logo .toggle-logo {
    display: block;
  }

  .main-content {
    padding-block-start: 3.75rem;
  }

  .main-sidebar-header {
    display: none !important;
  }

  .main-sidebar {
    margin-block-start: 0;
  }

  .app-sidebar {
    inset-block-start: 0;
  }

  .main-menu {
    margin: 0 !important;
  }
}

.slide-left,
.slide-right {
  display: none;
}

[data-nav-layout=vertical] .slide.has-sub.active>.side-menu__item {
  margin-block: 0.25rem;
}

@media (min-width: 992px) {
  .horizontal-logo {
    display: none;
  }
}

/* Responsive Styles End */
/* Responsive Overlay Start */
#responsive-overlay {
  visibility: hidden;
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 101;
  background-color: rgba(15, 23, 42, 0.5);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 100ms;
}

#responsive-overlay.active {
  visibility: visible;
}

/* Responsive Overlay End */
/* PAGES */
.main-chart-wrapper {
  position: relative;
  overflow: hidden;
}

.main-chart-wrapper .chat-info,
.main-chart-wrapper .main-chat-area {
  background-color: var(--custom-white);
  height: calc(100vh - 17.5rem);
  border-radius: 7px;
}

.main-chart-wrapper .chat-user-details {
  background-color: var(--custom-white);
  height: calc(100vh - 3.5rem);
  border-radius: 7px;
}

.main-chart-wrapper .chat-users-tab,
.main-chart-wrapper .chat-groups-tab,
.main-chart-wrapper .chat-calls-tab {
  max-height: calc(100vh - 27rem);
}

.main-chart-wrapper .chat-content {
  max-height: calc(100vh - 28.5rem);
}

.main-chart-wrapper .chat-content .simplebar-content-wrapper .simplebar-content {
  margin-top: auto;
}

.main-chart-wrapper .chat-content ul li {
  margin-block-end: 1rem;
}

.main-chart-wrapper .chat-content ul li:last-child {
  margin-block-end: 0;
}

.main-chart-wrapper .responsive-chat-close,
.main-chart-wrapper button.responsive-userinfo-open {
  display: none;
}

.main-chart-wrapper .chat-info {
  position: relative;
  overflow: hidden;
}

.main-chart-wrapper .chat-info .chat-add-icon {
  position: absolute;
  inset-block-end: 0.75rem;
  inset-inline-end: 0.8rem;
  z-index: 1;
  box-shadow: 0 0 0.5rem rgba(var(--secondary-rgb), 0.5);
}

.main-chart-wrapper .chat-info .nav-link {
  color: var(--text-muted);
  border-radius: 0;
}

.main-chart-wrapper .chat-info .tab-pane {
  padding: 0;
}

.main-chart-wrapper .chat-info .chat-groups-tab li {
  padding: 0.625rem 1.25rem;
}

.main-chart-wrapper .chat-info .chat-groups-tab .group-indivudial {
  color: var(--primary-color);
  font-weight: 600;
}

.main-chart-wrapper .chat-info .chat-calls-tab li {
  padding: 0.625rem 1.25rem;
}

.main-chart-wrapper .chat-info .chat-calls-tab .incoming-call-success i,
.main-chart-wrapper .chat-info .chat-calls-tab .outgoing-call-success i {
  color: rgb(var(--success-rgb));
  font-size: 0.875rem;
}

.main-chart-wrapper .chat-info .chat-calls-tab .incoming-call-failed i,
.main-chart-wrapper .chat-info .chat-calls-tab .outgoing-call-failed i {
  color: rgb(var(--danger-rgb));
  font-size: 0.875rem;
}

.main-chart-wrapper .chat-info .chat-users-tab li,
.main-chart-wrapper .chat-info .chat-groups-tab li {
  padding: 0.625rem 1.25rem;
}

.main-chart-wrapper .chat-info .chat-users-tab li .chat-msg,
.main-chart-wrapper .chat-info .chat-groups-tab li .chat-msg {
  color: var(--text-muted);
  max-width: 11.25rem;
  display: inline-block;
}

.main-chart-wrapper .chat-info .chat-users-tab li .chat-msg-typing .chat-msg,
.main-chart-wrapper .chat-info .chat-groups-tab li .chat-msg-typing .chat-msg {
  color: rgb(var(--success-rgb));
}

.main-chart-wrapper .chat-info .chat-users-tab li .chat-msg-typing .chat-read-icon,
.main-chart-wrapper .chat-info .chat-groups-tab li .chat-msg-typing .chat-read-icon {
  display: none;
}

.main-chart-wrapper .chat-info .chat-users-tab li .chat-read-icon,
.main-chart-wrapper .chat-info .chat-groups-tab li .chat-read-icon {
  line-height: 1;
}

.main-chart-wrapper .chat-info .chat-users-tab li .chat-read-icon i,
.main-chart-wrapper .chat-info .chat-groups-tab li .chat-read-icon i {
  font-size: 1rem;
  color: rgb(var(--success-rgb));
}

.main-chart-wrapper .chat-info .chat-users-tab li.chat-msg-unread,
.main-chart-wrapper .chat-info .chat-groups-tab li.chat-msg-unread {
  background-color: var(--default-background);
  color: var(--default-text-color);
}

.main-chart-wrapper .chat-info .chat-users-tab li.chat-msg-unread .chat-msg,
.main-chart-wrapper .chat-info .chat-groups-tab li.chat-msg-unread .chat-msg {
  color: var(--default-text-color);
}

.main-chart-wrapper .chat-info .chat-users-tab li.chat-msg-unread .chat-read-icon i,
.main-chart-wrapper .chat-info .chat-groups-tab li.chat-msg-unread .chat-read-icon i {
  color: var(--text-muted);
}

.main-chart-wrapper .chat-info .chat-users-tab li.chat-inactive .chat-read-icon,
.main-chart-wrapper .chat-info .chat-groups-tab li.chat-inactive .chat-read-icon {
  display: none;
}

.main-chart-wrapper .chat-user-details {
  padding: 1.5rem;
}

.main-chart-wrapper .chat-user-details .avatar {
  outline: 0.25rem solid var(--primary02);
}

.main-chart-wrapper .chat-user-details .shared-files li {
  margin-block-end: 1rem;
}

.main-chart-wrapper .chat-user-details .shared-files li:last-child {
  margin-block-end: 0;
}

.main-chart-wrapper .chat-user-details .shared-files .shared-file-icon i {
  line-height: 1;
  border-radius: 0.3rem;
  border: 1px solid var(--default-border);
  display: flex;
  align-items: center;
  padding: 0.5rem;
  justify-content: center;
  font-size: 1.125rem;
  color: var(--text-muted);
}

.main-chart-wrapper .chat-user-details .chat-media img {
  width: 4.5rem;
  height: 3.5rem;
  border-radius: 7px;
  margin-bottom: 1.05rem;
}

.main-chart-wrapper .main-chat-area {
  position: relative;
}

.main-chart-wrapper .main-chat-area .chat-content {
  padding: 2.5rem;
}

.main-chart-wrapper .main-chat-area .chat-content .chatting-user-info {
  color: var(--default-text-color);
  font-weight: 600;
  font-size: 0.813rem;
}

.main-chart-wrapper .main-chat-area .chat-content .chatting-user-info .msg-sent-time {
  color: var(--text-muted);
  font-size: 0.75rem;
  font-weight: 500;
}

.main-chart-wrapper .main-chat-area .chat-content .chatting-user-info .msg-sent-time .chat-read-mark i {
  color: rgb(var(--success-rgb));
  margin-inline-end: 0.3rem;
}

.main-chart-wrapper .main-chat-area .chat-content .main-chat-msg div {
  margin-block-start: 0.4rem;
  padding: 0.75rem;
  width: -moz-fit-content;
  width: fit-content;
}

.main-chart-wrapper .main-chat-area .chat-content .main-chat-msg div p {
  font-size: 0.813rem;
}

.main-chart-wrapper .main-chat-area .chat-content .main-chat-msg div .chat-media-image {
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 7px;
}

.main-chart-wrapper .main-chat-area .chat-content .chat-item-start .main-chat-msg div {
  background-color: var(--primary02);
  color: var(--default-text-color);
  border-start-start-radius: 0px;
  border-start-end-radius: 0.3rem;
  border-end-end-radius: 0.3rem;
  border-end-start-radius: 0.3rem;
}

.main-chart-wrapper .main-chat-area .chat-content .chat-item-start .msg-sent-time {
  margin-inline-start: 0.25rem;
}

.main-chart-wrapper .main-chat-area .chat-content .chat-item-end {
  justify-content: end;
  text-align: end;
}

.main-chart-wrapper .main-chat-area .chat-content .chat-item-end .main-chat-msg div {
  background-color: var(--primary-color);
  color: #fff;
  border-start-start-radius: 0.3rem;
  border-start-end-radius: 0px;
  border-end-end-radius: 0.3rem;
  border-end-start-radius: 0.3rem;
}

.main-chart-wrapper .main-chat-area .chat-content .chat-item-end .msg-sent-time {
  margin-inline-end: 0.25rem;
}

.main-chart-wrapper .main-chat-area .chat-content .chat-item-start,
.main-chart-wrapper .main-chat-area .chat-content .chat-item-end {
  display: flex;
}

.main-chart-wrapper .main-chat-area .chat-content .chat-item-start .chat-list-inner,
.main-chart-wrapper .main-chat-area .chat-content .chat-item-end .chat-list-inner {
  display: flex;
  align-items: top;
  max-width: 75%;
}

.main-chart-wrapper .main-chat-area .chat-footer {
  width: 100%;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.1);
}

.main-chart-wrapper .main-chat-area .chat-footer {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 4.75rem;
  padding: 0 1.5rem;
  border-top: 1px solid var(--default-border);
  background-color: var(--custom-white);
  position: absolute;
  inset-inline-start: auto;
  inset-inline-end: auto;
  inset-block-end: 0;
}

.main-chart-wrapper .main-chat-area .chat-day-label {
  text-align: center;
  color: var(--text-muted);
  margin-block-end: 2rem;
  opacity: 0.6;
  position: relative;
}

.main-chart-wrapper .main-chat-area .chat-day-label span {
  padding: 0.188rem 0.5rem;
  font-size: 0.7rem;
  background-color: var(--primary02);
  border-radius: 0.3rem;
  color: var(--primary-color);
}

@media (min-width: 1200px) {
  .main-chart-wrapper .chat-info {
    min-width: 23.875rem;
    max-width: 23.875rem;
  }
}

.main-chart-wrapper .main-chat-area {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

@media (max-width: 1275.98px) and (min-width: 1200px) {
  .main-chart-wrapper .chat-info {
    min-width: 21.875rem;
    max-width: 21.875rem;
  }

  .main-chart-wrapper .main-chat-area {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  }

  .main-chart-wrapper .chat-user-details {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .main-chart-wrapper .chat-info {
    width: 100%;
  }

  .main-chart-wrapper .main-chat-area {
    display: none;
    min-width: 100%;
    max-width: 100%;
  }

  .main-chart-wrapper .responsive-chat-close {
    display: block;
  }
}

@media (max-width: 1400px) {
  .main-chart-wrapper .chat-user-details {
    display: none;
    width: 100%;
  }

  .main-chart-wrapper .chat-user-details.open {
    display: block;
    inset-inline-end: 0;
    inset-block-start: 0.5rem;
    box-shadow: 0 0.125rem 0 rgba(10, 10, 10, 0.04);
    border-inline-start: 1px solid var(--default-border);
  }

  .main-chart-wrapper button.responsive-userinfo-open {
    display: block;
  }
}

@media (max-width: 1199.98px) {
  .main-chart-wrapper.responsive-chat-open .chat-info {
    display: none;
  }

  .main-chart-wrapper.responsive-chat-open .main-chat-area {
    display: block;
  }
}

@media (max-width: 767.98px) {
  .main-chart-wrapper .main-chat-area .chat-content .main-chat-msg div .chat-media-image {
    width: 2.5rem;
    height: 2.5rem;
  }
}

/* Start:: Products */
.product-icons {
  display: none;
}

.product-image img {
  background-color: rgb(var(--light-rgb));
}

.product-card {
  position: relative;
}

.product-card:hover .product-icons {
  display: block;
}

.product-card:hover .product-icons .wishlist,
.product-card:hover .product-icons .cart,
.product-card:hover .product-icons .view {
  position: absolute;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-card:hover .product-icons .wishlist i,
.product-card:hover .product-icons .cart i,
.product-card:hover .product-icons .view i {
  font-size: 0.9rem;
}

.product-card:hover .product-icons .wishlist {
  background-color: rgb(var(--danger-rgb));
  color: #fff;
  inset-block-start: 1.75rem;
  inset-inline-end: 1.75rem;
}

.product-card:hover .product-icons .cart {
  background-color: var(--primary-color);
  color: #fff;
  inset-block-start: 4rem;
  inset-inline-end: 1.75rem;
}

.product-card:hover .product-icons .view {
  background-color: rgb(var(--success-rgb));
  color: #fff;
  inset-block-start: 6.25rem;
  inset-inline-end: 1.75rem;
}

.ecommerce-more-link {
  position: relative;
  padding: 0.3rem 1.5rem 0.3rem 0.75rem;
  background-color: var(--primary01);
  color: var(--primary-color);
  border-radius: 7px;
  font-weight: 500;
  font-size: 0.625rem;
}

.ecommerce-more-link:hover {
  color: var(--primary-color);
}

.ecommerce-more-link:before {
  position: absolute;
  content: "\f64d";
  inset-inline-end: 0.5rem;
  font-family: bootstrap-icons !important;
  inset-block-start: 0.313rem;
  font-weight: bold;
}

.ecommerce-more-link:after {
  position: absolute;
  content: "\f2ea";
  inset-inline-end: 0.5rem;
  font-family: bootstrap-icons !important;
  inset-block-start: 0.313rem;
  font-weight: bold;
  display: none;
}

[dir=rtl] .ecommerce-more-link {
  padding: 0.3rem 0.75rem 0.3rem 1.5rem;
}

[dir=rtl] .ecommerce-more-link::before,
[dir=rtl] .ecommerce-more-link:after {
  inset-inline-end: -0.5rem;
}

.ecommerce-more-link[aria-expanded=true]:after {
  display: block;
}

.ecommerce-more-link[aria-expanded=true]:before {
  display: none;
}

.products-navigation-card .form-check-label {
  font-size: 0.813rem;
  font-weight: 500;
}

/* End:: Products */
/* Start:: Product Details */
.swiper-view-details .swiper-slide {
  background-color: var(--custom-white) !important;
  border: 1px solid var(--default-border);
  border-radius: 7px !important;
}

.swiper-view-details .swiper-slide.swiper-slide-thumb-active {
  background-color: rgb(var(--light-rgb)) !important;
}

.swiper-preview-details .swiper-button-next {
  background-color: var(--black-1);
  color: var(--default-text-color) !important;
}

.swiper-preview-details .swiper-button-prev {
  background-color: var(--black-1);
  color: var(--default-text-color) !important;
}

.product-colors {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3.125rem;
  border: 1px solid var(--default-border);
  margin-inline-end: 0.5rem;
  background-color: rgb(var(--light-rgb));
}

.product-colors i {
  font-size: 1.5rem;
}

.product-colors.color-2.selected {
  border: 0.125rem solid rgb(var(--purple-rgb));
}

.product-colors.color-2 i {
  color: rgb(var(--purple-rgb));
}

.product-colors.color-4.selected {
  border: 0.125rem solid rgb(var(--warning-rgb));
}

.product-colors.color-4 i {
  color: rgb(var(--warning-rgb));
}

.product-colors.color-1.selected {
  border: 0.125rem solid rgb(var(--danger-rgb));
}

.product-colors.color-1 i {
  color: rgb(var(--danger-rgb));
}

.product-colors.color-3.selected {
  border: 0.125rem solid rgb(var(--info-rgb));
}

.product-colors.color-3 i {
  color: rgb(var(--info-rgb));
}

.product-colors.color-5.selected {
  border: 0.125rem solid rgb(var(--secondary-rgb));
}

.product-colors.color-5 i {
  color: rgb(var(--secondary-rgb));
}

.product-sizes {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3.125rem;
  border: 1px solid var(--default-border);
  margin-inline-end: 0.5rem;
  font-size: 0.75rem;
  background-color: rgb(var(--light-rgb));
  color: var(--text-muted);
}

.product-sizes.selected {
  background-color: rgba(var(--success-rgb), 0.1);
  color: rgb(var(--success-rgb));
}

.offer-promotion {
  position: relative;
  padding: 0.5rem;
  background-color: rgba(var(--warning-rgb), 0.1);
  border: 1px solid var(--default-border);
  border-radius: 7px;
}

.offer-promotion .promotion-code {
  color: var(--default-text-color);
}

.offer-promotion .offer-details {
  position: absolulte;
  border: 1px dashed rgb(var(--warning-rgb));
  padding: 0.25rem 0.5rem;
  border-radius: 7px;
}

.ecommerce-assurance {
  border: 1px dashed rgb(var(--success-rgb));
  border-radius: 7px;
  text-align: center;
  padding: 1rem 1.5rem;
}

.ecommerce-assurance svg {
  width: 3rem;
  height: 3rem;
}

.product-images {
  padding-inline-start: 1.875rem;
}

.product-images .products-review-images img {
  width: 3.125rem;
  height: 3.125rem;
  margin-inline-end: 0.25rem;
  border-radius: 7px;
  background-color: rgb(var(--light-rgb));
}

.similar-products-image img {
  width: 5rem;
  height: 5rem;
  border-radius: 7px;
  background-color: rgb(var(--light-rgb));
  padding: 8px;
}

.similar-product-name {
  max-width: 80%;
}

/* End:: Product Details */
/* Start:: Cart */
.product-quantity-container {
  width: 8.75rem;
}

.product-quantity-container .input-group input.form-control:focus {
  box-shadow: none;
  border: 0;
}

.product-quantity-container .input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 0;
}

.cart-empty svg {
  width: 6.25rem;
  height: 6.25rem;
  margin-block-end: 1.25rem;
  fill: var(--default-text-color);
}

/* End:: Cart */
/* Start:: Checkout */
.product-checkout .tab-style-2 .nav-item .nav-link {
  padding: 0.85rem 2rem;
}

.product-checkout .tab-style-2 .nav-item .nav-link i {
  line-height: 1.2;
}

.product-checkout .form-floating input,
.product-checkout .form-floating textarea {
  font-size: 0.813rem;
  font-weight: 600;
}

.product-checkout .shipping-method-container,
.product-checkout .payment-card-container {
  position: relative;
  padding: 0.625rem;
  border: 1px solid var(--input-border);
  border-radius: 7px;
}

.product-checkout .shipping-method-container .form-check-input,
.product-checkout .payment-card-container .form-check-input {
  position: absolute;
  inset-inline-end: 0.75rem;
  inset-block-start: 1.2rem;
}

@media (min-width: 576px) {

  .product-checkout .shipping-method-container .shipping-partner-details,
  .product-checkout .shipping-method-container .saved-card-details,
  .product-checkout .payment-card-container .shipping-partner-details,
  .product-checkout .payment-card-container .saved-card-details {
    width: 12.5rem;
  }
}

.checkout-payment-success {
  text-align: center;
}

.checkout-payment-success img {
  width: 200px;
  height: 200px;
}

/* End:: Checkout */
/* Start:: Orders */
.orders-delivery-address {
  width: 75%;
}

.delivery-date {
  width: 60px;
  height: 60px;
  padding: 10px;
  background-color: var(--primary005);
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border-radius: 0.3rem;
  animation-fill-mode: backwards;
}

/* End:: Orders */
/* Start:: Order Details */
.order-track {
  position: relative;
}

.order-track .avatar.avatar-md {
  font-size: 1.2rem;
}

.order-track:before {
  content: "";
  inset-inline-start: 20px;
  inset-block-start: 0;
  inset-inline-end: 0;
  inset-block-end: 0;
  position: absolute;
  width: 1px;
  border-inline-start: 1px dashed var(--primary-color);
  height: 100%;
  opacity: 0.2;
}

.order-details-list li {
  margin-block-end: 0.85rem;
}

.order-details-list li:last-child {
  margin-block-end: 0;
}

.order-details-list li .add-products .tab-style-2 .nav-item .nav-link {
  padding: 0.85rem 2rem;
}

.color-selection .choices__input {
  width: 6.25rem;
}

.product-image-default {
  width: 100%;
  height: 16.25rem;
}

.productupload-image-container .filepond--root[data-style-panel-layout~=circle],
.productupload-image-container1 .filepond--root[data-style-panel-layout~=circle],
.productupload-image-container2 .filepond--root[data-style-panel-layout~=circle] {
  border-radius: 7px;
  width: 100%;
}

.productupload-image-container .filepond--drop-label.filepond--drop-label label,
.productupload-image-container1 .filepond--drop-label.filepond--drop-label label,
.productupload-image-container2 .filepond--drop-label.filepond--drop-label label {
  padding: 2em;
}

.productupload-image-container .filepond--drop-label,
.productupload-image-container1 .filepond--drop-label,
.productupload-image-container2 .filepond--drop-label {
  color: var(--text-muted) !important;
}

.productupload-image-container .filepond--panel-root,
.productupload-image-container1 .filepond--panel-root,
.productupload-image-container2 .filepond--panel-root {
  border: 0.125rem dashed var(--input-border) !important;
}

.productupload-image-container .filepond--root[data-style-panel-layout~=circle] .filepond--image-preview-wrapper,
.productupload-image-container1 .filepond--root[data-style-panel-layout~=circle] .filepond--image-preview-wrapper,
.productupload-image-container2 .filepond--root[data-style-panel-layout~=circle] .filepond--image-preview-wrapper {
  border-radius: 0.3rem;
}

.productupload-image-container .filepond--root[data-style-panel-layout~=circle] {
  height: 16.25rem !important;
}

.productupload-image-container .filepond--drop-label label {
  font-size: 0.75rem;
}

.productupload-image-container .filepond--root[data-style-panel-layout~=circle] .filepond--image-preview-wrapper,
.productupload-image-container .filepond--file {
  height: 16.25rem;
}

.productupload-image-container1 .filepond--root[data-style-panel-layout~=circle],
.productupload-image-container2 .filepond--root[data-style-panel-layout~=circle] {
  height: 7.375rem !important;
}

.productupload-image-container1 .filepond--drop-label label,
.productupload-image-container2 .filepond--drop-label label {
  font-size: 0.75rem;
}

.productupload-image-container1 .filepond--root[data-style-panel-layout~=circle] .filepond--image-preview-wrapper,
.productupload-image-container1 .filepond--file,
.productupload-image-container2 .filepond--root[data-style-panel-layout~=circle] .filepond--image-preview-wrapper,
.productupload-image-container2 .filepond--file {
  height: 7.375rem !important;
}

.productupload-image-container1 .filepond--file [data-align*=bottom],
.productupload-image-container2 .filepond--file [data-align*=bottom] {
  bottom: 9.125em;
}

.product-img-icon {
  font-size: 1.625rem !important;
  opacity: 0.5;
}

.product-documents-container .filepond--root {
  width: 100%;
}

.product-documents-container .filepond--panel-root {
  border-color: var(--input-border) !important;
  border-radius: 7px;
}

.product-documents-container .filepond--root .filepond--drop-label label {
  color: var(--text-muted);
}

#product-features {
  max-height: 12.5rem;
  overflow-y: scroll;
}

/* End:: Add Products & Edit Products */
.open-file {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  inset-inline-end: 0;
  inset-inline-start: 0;
  inset-block-start: 0;
}

.file-manager-image img {
  background: rgba(var(--warning-rgb), 0.2);
  border-radius: 50px;
  padding: 6px;
  width: 75px;
}

.file-img {
  padding: 0.75rem;
  border-radius: 50%;
  vertical-align: top;
}

.file-img svg {
  width: 24px;
  height: 24px;
}

.file-dropdown {
  position: relative;
  z-index: 2;
}

@media screen and (min-width: 1400px) {
  .file-manager-list {
    height: 133px;
  }
}

@media (min-width: 992px) {
  .flex-none {
    flex: none;
  }
}

.landing-body {
  background-color: var(--custom-white);
}

.landing-body .landing-main {
  padding-inline-start: 0;
  padding-inline-end: 0;
}

.landing-body .switcher-icon {
  padding: 11px;
  text-align: center;
  height: 2.5rem;
  font-size: 1.2rem;
  position: relative;
  min-width: 2rem;
  transition: 0.3s color;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--primary-color);
}

.landing-body .switcher-icon i {
  font-size: 20px;
  position: relative;
  animation-name: spin;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.landing-body .app-sidebar .side-menu__item {
  padding: 0.8rem 1rem;
}

.landing-body .app-sidebar .side-menu__item.active,
.landing-body .app-sidebar .side-menu__item:hover {
  color: var(--primary-color) !important;
}

.landing-body .app-sidebar.sticky.sticky-pin .side-menu__item.active {
  font-weight: normal;
}

.landing-body .app-sidebar.sticky.sticky-pin .side-menu__item.active .side-menu__label {
  color: var(--primary-color) !important;
}

.landing-body .accordion.accordion-primary .accordion-button.collapsed:after {
  background-color: var(--primary01);
  color: var(--primary-color);
}

.landing-body #contact {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  inset-block-start: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  position: relative;
}

.landing-body #contact::before {
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  content: "";
  inset-inline-start: -19%;
  inset-inline-end: 0;
  width: 35%;
  height: 80%;
}

.landing-body .featur-icon {
  width: 90px;
  height: 90px;
  padding: 20px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: 0px 4px 16px var(--black-1);
  margin: 0 auto;
}

.landing-body .feature-logos {
  display: flex;
  justify-content: center;
}

.landing-body .landing-Features {
  position: relative;
  width: 100%;
  height: 100%;
  inset-block-start: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 9;
}

.landing-body .landing-Features:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, var(--primary04) 0%, rgba(var(--secondary-rgb), 0.49) 90%);
  top: 0px;
  z-index: -1;
}

.landing-body .app-sidebar .slide-menu.child1 .side-menu__item:before {
  inset-block-start: 10px !important;
}

@media (min-width: 992px) {
  .landing-body .app-sidebar {
    inset-block-start: 0;
    padding: 0.5rem 0;
    height: auto !important;
    background-color: transparent;
    box-shadow: none;
    border-inline-end: 0;
  }

  .landing-body .app-sidebar .main-sidebar {
    height: auto !important;
    width: 100%;
    margin: 0;
  }

  .landing-body .app-sidebar.sticky.sticky-pin {
    background-color: var(--custom-white);
    box-shadow: 0 0.25rem 1rem var(--black-1);
  }

  .landing-body .app-sidebar.sticky.sticky-pin .side-menu__item .side-menu__angle {
    color: var(--menu-prime-color);
  }

  .landing-body .app-sidebar.sticky.sticky-pin .side-menu__item:hover .side-menu__angle {
    color: var(--primary-color) !important;
  }

  .landing-body .app-sidebar.sticky.sticky-pin.app-sidebar .side-menu__label {
    color: var(--custom-black);
  }

  .landing-body .app-sidebar.sticky.sticky-pin .landing-logo-container .horizontal-logo .desktop-white {
    display: none;
  }

  .landing-body .app-sidebar.sticky.sticky-pin .landing-logo-container .horizontal-logo .desktop-logo {
    display: block;
  }

  .landing-body .app-sidebar.sticky.sticky-pin.app-sidebar .side-menu__item:hover .side-menu__label {
    color: var(--primary-color) !important;
  }

  .landing-body .app-sidebar.app-sidebar .slide.has-sub.open .slide-menu.child1::before {
    border-block-start-color: var(--default-border) !important;
    border-inline-start-color: var(--default-border) !important;
  }

  .landing-body .app-header {
    display: none;
  }

  .landing-body .main-sidebar-header {
    display: block !important;
  }

  .landing-body .main-menu-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .landing-body .main-menu-container .slide-left,
  .landing-body .main-menu-container .slide-right {
    display: none;
  }

  .landing-body .main-content {
    padding: 0;
  }

  .landing-body .landing-logo-container .horizontal-logo .header-logo .desktop-logo {
    display: block;
  }

  .landing-body .landing-logo-container .horizontal-logo .header-logo .desktop-white {
    display: none;
  }
}

@media (max-width: 991.98px) {

  .landing-body .app-sidebar .side-menu__item.active,
  .landing-body .app-sidebar .side-menu__item:hover {
    background-color: transparent;
  }

  .landing-body .main-menu-container .main-menu {
    padding-inline-start: 0px !important;
    padding-inline-end: 0px !important;
    width: 100%;
  }

  .landing-body .main-menu-container .main-menu .slide-menu {
    padding-inline-start: 2rem;
  }

  .landing-body .app-sidebar .slide {
    padding: 0;
  }

  .landing-body .landing-logo-container .horizontal-logo .header-logo .desktop-logo {
    display: none !important;
  }

  .landing-body .landing-logo-container .horizontal-logo .header-logo .desktop-white {
    display: none !important;
  }
}

.landing-body .main-menu-container .main-menu {
  padding-inline-start: 0;
}

.landing-body .app-content {
  margin-block-start: 4rem;
}

.landing-body .section {
  padding: 3.375rem 0;
  background-size: cover;
  position: relative;
}

.landing-body .landing-banner {
  position: relative;
  width: 100%;
  inset-block-start: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.landing-body .landing-banner:before {
  position: absolute;
  content: "";
  inset-inline-start: -19%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  inset-inline-end: 0;
  width: 35%;
  height: 80%;
  overflow: hidden;
}

.landing-body .landing-banner .main-banner-container {
  padding: 4rem 3rem;
}

.landing-body .landing-banner .landing-banner-heading {
  line-height: 1.25;
  text-shadow: 1px 1px var(--black-1);
  font-size: 3rem;
  font-weight: 600;
  color: #fff;
}

@media (max-width: 767.98px) {
  .landing-body .landing-main-image {
    display: none;
  }

  .landing-body .landing-banner .main-banner-container {
    padding: 1rem;
  }
}

@media (max-width: 480px) {
  .landing-body .landing-banner .section {
    padding: 2.375rem 0;
  }
}

.landing-body .landing-main-image {
  position: relative;
  z-index: 10;
}

.landing-body .landing-main-image img {
  z-index: 11;
  position: relative;
  width: 27.063rem;
  height: 26.063rem;
  margin-block-start: -10px;
}

.landing-body .landing-main-image:before {
  content: "";
  position: absolute;
  width: 24.5rem;
  height: 24.5rem;
  background-color: var(--black-1);
  opacity: 0.3;
  inset-inline-start: 5rem;
  inset-block-start: 0rem;
  border-radius: 50%;
  box-shadow: 0 3rem 10rem var(--white-1) !important;
}

.landing-body .landing-main-image:after {
  content: "";
  position: absolute;
  width: 12.5rem;
  height: 12.5rem;
  background-color: transparent;
  border: 0.125rem dashed var(--primary05);
  opacity: 0.3;
  inset-inline-start: 5.1rem;
  inset-block-end: 4.4rem;
}

.landing-body .landing-page-wrapper {
  position: relative;
  min-height: calc(100vh - 3.4rem);
}

.landing-body .main-landing-image {
  width: 37.5rem;
  padding: 0.5rem;
  border-radius: 7px;
  background-color: var(--primary01);
}

.landing-body .brand-img-white {
  display: block;
}

.landing-body .brand-img-light {
  display: block;
}

.landing-body .app-sidebar .side-menu__label {
  margin-block-start: 0;
}

.landing-body .landing-section-heading {
  position: relative;
}

.landing-body .landing-title {
  background: linear-gradient(to right, transparent, var(--primary-color), transparent);
  height: 1px;
  margin: 0px auto;
  width: 70px !important;
  margin-block-end: 10px;
  display: flex;
}

.landing-body .landing-missions,
.landing-body .landing-missions-dark {
  position: relative;
  overflow: hidden;
  z-index: 1;
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05) !important;
}

.landing-body .landing-missions:before,
.landing-body .landing-missions-dark:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.15rem;
  inset-block-start: 0;
  inset-inline-start: 0;
  border-radius: 3.125rem;
}

.landing-body .landing-missions svg,
.landing-body .landing-missions-dark svg {
  width: 1.5rem;
  height: 1.5rem;
}

.landing-body .customize-image img {
  height: 25rem;
}

.landing-body .landing-footer {
  background-color: rgb(var(--light-rgb));
  padding-block-start: 150px;
  border-bottom: 1px solid var(--default-border);
}

.landing-body .landing-footer .landing-footer-list li {
  margin-bottom: 0.5rem;
}

.landing-body .landing-footer .landing-footer-list li:last-child {
  margin-bottom: 0;
}

.landing-body .landing-footer .logo-3 {
  display: none;
}

.landing-body .landing-footer .btn-list .btn-icon {
  border: 1px solid var(--default-border);
  color: var(--default-text-color);
  background-color: var(--custom-white);
}

.landing-body .landing-footer h6 {
  position: relative;
}

.landing-body .landing-footer h6::before {
  position: absolute;
  content: "";
  height: 8px;
  border: 2px solid var(--primary-color);
  margin: 0px auto;
  transform: rotate(45deg);
  width: 8px;
  inset-inline-start: -15px;
  margin-block-end: 10px;
  inset-block-start: 5px;
}

.landing-body .landing-main-footer {
  background-color: rgb(var(--light-rgb));
}

.landing-body .landing-testimonials .swiper-pagination-bullet {
  background: var(--primary-color) !important;
}

.landing-body .landing-testimonials .swiper-pagination-bullet:active {
  background: var(--primary-color) !important;
}

.landing-body .team-card {
  transition: box-shadow 0.2s ease-in;
}

.landing-body .team-card:hover {
  box-shadow: 0 0.25rem 1rem 0 rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.2s ease-in;
}

.landing-body .team-card .team-avatar {
  box-shadow: 0 0 0 0.5rem rgba(0, 0, 0, 0.05);
}

.landing-body .btn-close {
  padding: 1.5rem;
  right: -0.875rem;
}

.landing-body .section-bg {
  background-color: var(--gray-1);
}

[data-theme-mode=dark] .landing-body {
  background-color: var(--default-body-bg-color);
}

@media (min-width: 992px) {
  [data-theme-mode=dark] .landing-body .main-menu-container .landing-logo-container .horizontal-logo .header-logo .desktop-logo {
    display: none !important;
  }

  [data-theme-mode=dark] .landing-body .main-menu-container .landing-logo-container .horizontal-logo .header-logo .desktop-white {
    display: block !important;
  }
}

@media (max-width: 991.98px) {
  [data-theme-mode=dark] .landing-body .app-header .main-header-container .horizontal-logo .header-logo .toggle-logo {
    display: none;
  }

  [data-theme-mode=dark] .landing-body .app-header .main-header-container .horizontal-logo .header-logo .toggle-dark {
    display: block;
  }
}

[data-theme-mode=dark] .landing-body .landing-trusted-companies img {
  filter: imvert(1);
}

[data-theme-mode=dark] .landing-body .team-card {
  border: 1px solid var(--default-border);
}

[data-theme-mode=dark] .landing-body .team-card:hover {
  box-shadow: 0 0.25rem 1rem 0 rgba(255, 255, 255, 0.05);
}

[data-theme-mode=dark] .landing-body .team-card .team-avatar {
  box-shadow: 0 0 0 0.5rem rgba(255, 255, 255, 0.05);
}

[data-theme-mode=dark] .landing-body .app-sidebar.sticky.sticky-pin .landing-logo-container .horizontal-logo .desktop-white {
  display: block;
}

[data-theme-mode=dark] .landing-body .landing-footer .logo-3 {
  display: block;
}

[data-theme-mode=dark] .landing-body .landing-footer .logo-2 {
  display: none;
}


[data-theme-mode=dark] .brand-img-light {
  display: block;
}

[data-theme-mode=dark] .brand-img-white {
  display: none;
}

[data-theme-mode=dark] .section-bg {
  background-color: var(--black-1);
}

@media (max-width: 420px) {
  .landing-body .landing-banner .main-banner-container {
    padding: 1rem;
  }

  .landing-body .landing-banner .landing-banner-heading {
    font-size: 2rem;
  }
}

@media (max-width: 992px) {
  .landing-body .app-sidebar .side-menu__item {
    padding: 0.8rem 5rem 0.8rem 1rem;
  }

  .landing-body .app-sidebar .slide-menu.child1 li,
  .landing-body .app-sidebar .slide-menu.child2 li,
  .landing-body .app-sidebar .slide-menu.child3 li {
    padding: 0;
    padding-inline-start: 0;
    position: relative;
  }

  [dir=rtl] .landing-body .app-sidebar .side-menu__item {
    padding: 0.8rem 1rem 0.8rem 5rem;
  }
}

[dir=rtl] .landing-body .testimonial-card {
  direction: rtl;
}

.animate-heading {
  position: relative;
  color: var(--primary-bg-color);
  letter-spacing: 0px;
  text-transform: capitalize;
  font-size: 2rem;
  animation: text 3s steps(4) infinite;
  overflow: hidden;
  display: flex;
}

.animate-heading ::before {
  content: "";
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
  width: 2px;
  height: 100%;
  animation: cursorAnimate 0.8s steps(4) infinite;
}

@keyframes text {

  0%,
  90%,
  100% {
    width: 0;
  }

  30%,
  60% {
    width: 72.23px;
  }
}

#statistics .card {
  border: 0;
  box-shadow: none;
  border-radius: 50px 50px 0 50px;
}

#statistics .card .counter-icon {
  border-radius: 50px 50px 0 50px;
  margin-block-end: 1.5rem;
  margin-block-start: 14px !important;
  display: inline-flex;
  width: 4rem;
  height: 4rem;
  padding: 1.3rem 1.4rem;
  text-align: center;
}

#statistics .card .counter-icon i {
  font-size: 23px;
}

#statistics .card .counter-icon.icon-1 {
  box-shadow: 0 5px 10px var(--primary03);
  background: var(--primary01);
}

#statistics .card .counter-icon.icon-1 i {
  color: var(--primary-color);
}

#statistics .card .counter-icon.icon-2 {
  box-shadow: 0 5px 10px rgba(var(--secondary-rgb), 0.3);
  background: rgba(var(--secondary-rgb), 0.1);
}

#statistics .card .counter-icon.icon-2 i {
  color: rgb(var(--secondary-rgb));
}

#statistics .card .counter-icon.icon-3 {
  box-shadow: 0 5px 10px rgba(var(--success-rgb), 0.3);
  background: rgba(var(--success-rgb), 0.1);
}

#statistics .card .counter-icon.icon-3 i {
  color: rgb(var(--success-rgb));
}

#statistics .card .counter-icon.icon-4 {
  box-shadow: 0 5px 10px rgba(var(--danger-rgb), 0.3);
  background: rgba(var(--danger-rgb), 0.1);
}

#statistics .card .counter-icon.icon-4 i {
  color: rgb(var(--danger-rgb));
}

.landing-missions .avatar {
  border-radius: 0 40% 0 40% !important;
}

.services-statistics .counter-icon {
  border-radius: 0 40% 0 40%;
  margin-block-end: 1.5rem;
  margin-block-start: 14px !important;
  display: inline-flex;
  width: 4rem;
  height: 4rem;
  padding: 1.3rem 1.4rem;
  text-align: center;
}

.services-statistics .counter-icon i {
  font-size: 23px;
}

.services-statistics .counter-icon.icon-1 {
  box-shadow: 0 5px 10px var(--primary03);
  background: var(--primary01);
}

.services-statistics .counter-icon.icon-1 i {
  color: var(--primary-color);
}

.services-statistics .counter-icon.icon-2 {
  box-shadow: 0 5px 10px rgba(var(--secondary-rgb), 0.3);
  background: rgba(var(--secondary-rgb), 0.1);
}

.services-statistics .counter-icon.icon-2 i {
  color: rgb(var(--secondary-rgb));
}

.services-statistics .counter-icon.icon-3 {
  box-shadow: 0 5px 10px rgba(var(--success-rgb), 0.3);
  background: rgba(var(--success-rgb), 0.1);
}

.services-statistics .counter-icon.icon-3 i {
  color: rgb(var(--success-rgb));
}

.services-statistics .counter-icon.icon-4 {
  box-shadow: 0 5px 10px rgba(var(--danger-rgb), 0.3);
  background: rgba(var(--danger-rgb), 0.1);
}

.services-statistics .counter-icon.icon-4 i {
  color: rgb(var(--danger-rgb));
}

.reveal {
  transition: all 0.8s ease;
  transform: rotateY(90deg);
  opacity: 0;
}

.reveal.active {
  transform: rotateY(0deg);
  opacity: 1;
}

.buynow-landing {
  inset-block-start: 92px;
  border-radius: 25px;
  z-index: 1;
  box-shadow: 0px 16px 32px rgba(33, 33, 33, 0.15);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  inset-inline-start: 0;
  inset-inline-end: 0;
  position: relative;
}

.buynow-landing::before {
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 25px;
  content: "";
  inset-inline-start: 0;
  inset-inline-end: 0;
  inset-block-start: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, var(--primary04) 0%, rgba(var(--secondary-rgb), 0.49) 90%);
}

/* Start:: mail-app */
.main-mail-container {
  position: relative;
  overflow: hidden;
  margin: 0;
}

.mail-navigation,
.total-mails,
.mail-recepients {
  height: calc(100vh - 8rem);
}

.mail-navigation,
.total-mails,
.mails-information,
.mail-recepients {
  background-color: var(--custom-white);
  overflow: hidden;
  border-radius: 7px;
}

.mail-info-body {
  max-height: calc(100vh - 16.3rem);
}

@media (min-width: 1400px) {
  .total-mails .mail-msg span {
    display: inline-block;
  }
}

@media (max-width: 1599.98px) {
  .mail-action-icons {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .mail-navigation {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .mail-navigation {
    min-width: 20rem;
    max-width: 20rem;
  }
}

.total-mails {
  width: 100%;
}

.total-mails .mail-messages {
  max-height: calc(100vh - 12.65rem);
}

.total-mails .mail-messages li {
  padding: 1.1rem 1.25rem 0.625rem 1.25rem;
  border-bottom: 1px solid var(--default-border);
}

.total-mails .mail-messages li.active {
  background-color: rgb(var(--light-rgb));
}

.total-mails .mail-messages li:hover {
  background-color: rgb(var(--light-rgb));
  transition: none;
}

.total-mails .mail-messages li:last-child {
  border-bottom: 0;
}

.total-mails .mail-messages li .avatar.mail-msg-avatar {
  width: 1.8rem;
  height: 1.8rem;
}

.total-mails .mail-messages li .avatar.mail-msg-avatar.online:before,
.total-mails .mail-messages li .avatar.mail-msg-avatar.offline:before {
  width: 0.55rem;
  height: 0.55rem;
}

.total-mails .mail-messages li .mail-msg .mail-starred i {
  color: var(--text-muted);
  opacity: 0.5;
}

.total-mails .mail-messages li .mail-msg .mail-starred.true i {
  color: rgb(var(--warning-rgb));
  opacity: 1;
}

.mail-recepients {
  min-width: 4.4rem;
  max-width: 4.4rem;
}

.mail-recepients .total-mail-recepients {
  max-height: calc(100vh - 12.4rem);
}

.mail-recepients .mail-recepeint-person .avatar {
  width: 2rem;
  height: 2rem;
  margin-block-end: 1rem;
}

.mail-recepients .mail-recepeint-person .avatar.online:before,
.mail-recepients .mail-recepeint-person .avatar.offline:before {
  width: 0.55rem;
  height: 0.55rem;
}

.mail-recepients .mail-recepeint-person:last-child {
  margin-block-end: 0;
}

.mail-navigation ul.mail-main-nav {
  max-height: calc(100vh - 15rem);
  padding: 1rem;
  margin-block-end: 0;
}

.mail-navigation ul.mail-main-nav li {
  padding: 0.5rem;
  border-radius: var(--default-border);
  border-radius: 7px;
  margin-bottom: 0.25rem;
}

.mail-navigation ul.mail-main-nav li:last-child {
  margin-bottom: 0;
}

.mail-navigation ul.mail-main-nav li div {
  color: var(--text-muted);
}

.mail-navigation ul.mail-main-nav li.mail-nav-heading.active,
.mail-navigation ul.mail-main-nav li.mail-nav-heading:hover {
  background-color: transparent;
}

.mail-navigation ul.mail-main-nav li.active,
.mail-navigation ul.mail-main-nav li:hover {
  background-color: var(--primary01);
}

.mail-navigation ul.mail-main-nav li.active div,
.mail-navigation ul.mail-main-nav li:hover div {
  color: var(--primary-color);
}

.mails-information {
  width: 100%;
}

.mails-information .mail-info-header {
  padding: 0.75rem;
  border-block-end: 1px solid var(--default-border);
}

.mails-information .mail-info-footer {
  padding: 0.75rem;
  border-block-start: 1px solid var(--default-border);
}

.mails-information .mail-attachment {
  padding: 0.25rem;
  width: 12rem;
  height: 2.75rem;
  border: 1px solid var(--default-border);
  border-radius: 7px;
  display: flex;
  align-items: center;
}

.mails-information .mail-attachment .attachment-icon svg,
.mails-information .mail-attachment .attachment-icon i {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 2rem;
  margin-inline-end: 0.5rem;
}

.mails-information .mail-attachment .attachment-name {
  max-width: 7rem;
  display: inline-block;
  font-size: 0.75rem;
  font-weight: 500;
}

#mail-reply-editor .ck-content {
  min-height: 14rem !important;
}

/* End:: mail-app */
/* Start:: mail-settings */
.mail-notification-settings,
.mail-security-settings {
  width: 60%;
}

/* End:: mail-settings */
.main-mail-star {
  position: relative;
  bottom: auto;
  top: 6px;
  color: var(--text-muted);
  bottom: 10px;
  right: 0;
}

.main-mail-star i {
  font-size: 17px;
}

.icons-mail,
.mail-hover-icons {
  width: 7rem !important;
}

.total-mails .mail-messages li:hover .mail-date {
  display: none;
}

.mail-hover-icons a {
  display: none;
}

.total-mails .mail-messages li:hover .mail-hover-icons a {
  display: block !important;
  font-size: 14px !important;
  color: var(--text-muted);
}

.total-mails .mail-messages li:hover .mail-hover-icons a:not(:last-child) {
  margin-inline-end: 15px;
}

.main-mail-subject {
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
}

.main-mail-subject .text-truncate {
  display: inline;
}

.subject-container {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
}

.mail-option-read .vertical-phone i {
  font-size: 18px;
}

.mail-option-read .vertical-phone {
  list-style: none;
  display: inline-block;
  color: #7b8191;
  width: 2.2rem;
  height: 2.2rem;
  line-height: 2.5rem;
  margin: 4px;
  text-align: center;
  transition: 0.4s;
  font-size: 15px;
  border: 1px solid var(--default-border);
  border-radius: 7px;
}

@media screen and (max-width: 400px) {
  .mail-verification {
    display: block;
  }

  .mail-verification .btn {
    border-radius: 0.35rem !important;
    margin-bottom: 0.5rem;
  }

  .subject-container {
    overflow: auto;
    width: 4rem;
  }

  .icons-mail {
    width: 4rem !important;
  }

  #mail-messages ul {
    overflow: auto;
  }

  #mail-messages ul li {
    width: 20rem;
  }
}

#security .choices .choices__list--dropdown .choices__item--selectable {
  padding: 10px !important;
}

#security .choices .choices__list--dropdown .choices__item--selectable::after {
  display: none;
}

#security .choices .choices__inner {
  width: 6rem !important;
}

/* UTILITIES */
/* Start::avatar styles */
.avatar {
  position: relative;
  height: 2.625rem;
  width: 2.625rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  color: #fff;
  font-weight: 500;
}

.avatar a.badge:hover {
  color: #fff;
}

.avatar img {
  width: 100%;
  height: 100%;
  border-radius: 7px;
}

.avatar.avatar-rounded {
  border-radius: 50%;
}

.avatar.avatar-rounded img {
  border-radius: 50%;
}

.avatar.avatar-radius-0 {
  border-radius: 0;
}

.avatar.avatar-radius-0 img {
  border-radius: 0;
}

.avatar .avatar-badge {
  position: absolute;
  inset-block-start: -4%;
  inset-inline-end: -0.375rem;
  width: 1.4rem;
  height: 1.4rem;
  font-size: 0.625rem;
  border: 2px solid #ffffff;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar.online:before,
.avatar.offline:before {
  position: absolute;
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  inset-inline-end: 0;
  inset-block-end: 0;
  border: 2px solid var(--custom-white);
  z-index: 1;
}

.avatar.online:before {
  background-color: rgb(var(--success-rgb));
}

.avatar.offline:before {
  background-color: var(--gray-5);
}

.avatar.avatar-xs {
  width: 1.25rem;
  height: 1.25rem;
  line-height: 1.25rem;
  font-size: 0.65rem;
}

.avatar.avatar-xs .avatar-badge {
  padding: 0.25rem;
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  font-size: 0.5rem;
  inset-block-start: -25%;
  inset-inline-end: -0.5rem;
}

.avatar.avatar-sm {
  width: 1.75rem;
  height: 1.75rem;
  line-height: 1.75rem;
  font-size: 0.65rem;
}

.avatar.avatar-sm .avatar-badge {
  padding: 0.3rem;
  width: 1.1rem;
  height: 1.1rem;
  line-height: 1.1rem;
  font-size: 0.5rem;
  inset-block-start: -28%;
  inset-inline-end: -0.45rem;
}

.avatar.avatar-sm.online:before,
.avatar.avatar-sm.offline:before {
  width: 0.5rem;
  height: 0.5rem;
}

.avatar.avatar-md {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  font-size: 0.8rem;
}

.avatar.avatar-md .avatar-badge {
  padding: 0.4rem;
  width: 1.2rem;
  height: 1.2rem;
  line-height: 1.2rem;
  font-size: 0.65rem;
  inset-block-start: -6%;
  inset-inline-end: -13%;
}

.avatar.avatar-md.online:before,
.avatar.avatar-md.offline:before {
  width: 0.75rem;
  height: 0.75rem;
}

.avatar.avatar-md svg {
  width: 1.5rem;
  height: 1.5rem;
}

.avatar.avatar-lg {
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  font-size: 1rem;
}

.avatar.avatar-lg .avatar-badge {
  inset-block-start: -15%;
  inset-inline-end: -0.25%;
}

.avatar.avatar-lg.online:before,
.avatar.avatar-lg.offline:before {
  width: 0.8rem;
  height: 0.8rem;
}

.avatar.avatar-lg svg {
  width: 1.8rem;
  height: 1.8rem;
}

.avatar.avatar-xl {
  width: 4rem;
  height: 4rem;
  line-height: 4rem;
  font-size: 1.25rem;
}

.avatar.avatar-xl .avatar-badge {
  inset-block-start: -8%;
  inset-inline-end: -0.2%;
}

.avatar.avatar-xl.online:before,
.avatar.avatar-xl.offline:before {
  width: 0.95rem;
  height: 0.95rem;
}

.avatar.avatar-xxl {
  width: 5rem;
  height: 5rem;
  line-height: 5rem;
  font-size: 1.5rem;
}

.avatar.avatar-xxl .avatar-badge {
  inset-block-start: -4%;
  inset-inline-end: 0rem;
}

.avatar.avatar-xxl.online:before,
.avatar.avatar-xxl.offline:before {
  width: 1.05rem;
  height: 1.05rem;
  inset-block-end: 0.25rem;
}

.avatar-list-stacked {
  padding: 0;
}

.avatar-list-stacked .avatar {
  margin-inline-end: -0.65rem !important;
  border: 1px solid rgba(0, 0, 0, 0.05);
  vertical-align: middle;
  transition: transform ease 200ms;
}

.avatar-list-stacked .avatar:last-child {
  margin-inline-end: 0 !important;
}

.avatar-list-stacked .avatar:hover {
  z-index: 1;
  transform: translateY(-0.188rem);
}

[dir=rtl] .avatar-list-stacked .ri-arrow-right-s-line {
  transform: rotate(180deg);
}

/* End::avatar styles */
.color-container {
  width: 3rem;
  height: 3rem;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 3rem;
}

.text-container {
  padding: 0.25rem 0.5rem;
  border-radius: 7px;
  box-shadow: 0 0.125rem 0 rgba(10, 10, 10, 0.04);
}

/* Start::background color */
.bg-body {
  background-color: var(--default-body-bg-color) !important;
}

/* End::background color */
/* Start::background color */
.bg-primary {
  background-color: var(--primary-color) !important;
}

.bg-primary.bg-opacity-10 {
  background-color: var(--primary01) !important;
}

.bg-primary.bg-opacity-25 {
  background-color: var(--primary03) !important;
}

.bg-primary.bg-opacity-50 {
  background-color: var(--primary05) !important;
}

.bg-primary.bg-opacity-75 {
  background-color: var(--primary08) !important;
}

.bg-primary.bg-opacity-100 {
  background-color: var(--primary-color) !important;
}

.bg-secondary {
  background-color: rgb(var(--secondary-rgb)) !important;
}

.bg-secondary.bg-opacity-10 {
  background-color: rgba(var(--secondary-rgb), 0.1) !important;
}

.bg-secondary.bg-opacity-25 {
  background-color: rgba(var(--secondary-rgb), 0.25) !important;
}

.bg-secondary.bg-opacity-50 {
  background-color: rgba(var(--secondary-rgb), 0.5) !important;
}

.bg-secondary.bg-opacity-75 {
  background-color: rgba(var(--secondary-rgb), 0.75) !important;
}

.bg-secondary.bg-opacity-100 {
  background-color: rgba(var(--secondary-rgb), 1) !important;
}

.bg-info {
  background-color: rgb(var(--info-rgb)) !important;
}

.bg-info.bg-opacity-10 {
  background-color: rgba(var(--info-rgb), 0.1) !important;
}

.bg-info.bg-opacity-25 {
  background-color: rgba(var(--info-rgb), 0.25) !important;
}

.bg-info.bg-opacity-50 {
  background-color: rgba(var(--info-rgb), 0.5) !important;
}

.bg-info.bg-opacity-75 {
  background-color: rgba(var(--info-rgb), 0.75) !important;
}

.bg-info.bg-opacity-100 {
  background-color: rgba(var(--info-rgb), 1) !important;
}

.bg-success {
  background-color: rgb(var(--success-rgb)) !important;
}

.bg-success.bg-opacity-10 {
  background-color: rgba(var(--success-rgb), 0.1) !important;
}

.bg-success.bg-opacity-25 {
  background-color: rgba(var(--success-rgb), 0.25) !important;
}

.bg-success.bg-opacity-50 {
  background-color: rgba(var(--success-rgb), 0.5) !important;
}

.bg-success.bg-opacity-75 {
  background-color: rgba(var(--success-rgb), 0.75) !important;
}

.bg-success.bg-opacity-100 {
  background-color: rgba(var(--success-rgb), 1) !important;
}

.bg-warning {
  background-color: rgb(var(--warning-rgb)) !important;
}

.bg-warning.bg-opacity-10 {
  background-color: rgba(var(--warning-rgb), 0.1) !important;
}

.bg-warning.bg-opacity-25 {
  background-color: rgba(var(--warning-rgb), 0.25) !important;
}

.bg-warning.bg-opacity-50 {
  background-color: rgba(var(--warning-rgb), 0.5) !important;
}

.bg-warning.bg-opacity-75 {
  background-color: rgba(var(--warning-rgb), 0.75) !important;
}

.bg-warning.bg-opacity-100 {
  background-color: rgba(var(--warning-rgb), 1) !important;
}

.bg-danger {
  background-color: rgb(var(--danger-rgb)) !important;
}

.bg-danger.bg-opacity-10 {
  background-color: rgba(var(--danger-rgb), 0.1) !important;
}

.bg-danger.bg-opacity-25 {
  background-color: rgba(var(--danger-rgb), 0.25) !important;
}

.bg-danger.bg-opacity-50 {
  background-color: rgba(var(--danger-rgb), 0.5) !important;
}

.bg-danger.bg-opacity-75 {
  background-color: rgba(var(--danger-rgb), 0.75) !important;
}

.bg-danger.bg-opacity-100 {
  background-color: rgba(var(--danger-rgb), 1) !important;
}

.bg-dark {
  background-color: rgb(var(--dark-rgb)) !important;
}

.bg-dark.bg-opacity-10 {
  background-color: rgba(var(--dark-rgb), 0.1) !important;
}

.bg-dark.bg-opacity-25 {
  background-color: rgba(var(--dark-rgb), 0.25) !important;
}

.bg-dark.bg-opacity-50 {
  background-color: rgba(var(--dark-rgb), 0.5) !important;
}

.bg-dark.bg-opacity-75 {
  background-color: rgba(var(--dark-rgb), 0.75) !important;
}

.bg-dark.bg-opacity-100 {
  background-color: rgba(var(--dark-rgb), 1) !important;
}

.bg-light {
  background-color: rgb(var(--light-rgb)) !important;
}

.bg-light.bg-opacity-10 {
  background-color: rgba(var(--light-rgb), 0.1) !important;
}

.bg-light.bg-opacity-25 {
  background-color: rgba(var(--light-rgb), 0.25) !important;
}

.bg-light.bg-opacity-50 {
  background-color: rgba(var(--light-rgb), 0.5) !important;
}

.bg-light.bg-opacity-75 {
  background-color: rgba(var(--light-rgb), 0.75) !important;
}

.bg-light.bg-opacity-100 {
  background-color: rgba(var(--light-rgb), 1) !important;
}

.bg-white {
  background-color: var(--custom-white) !important;
}

.bg-pink {
  background-color: rgb(var(--pink-rgb)) !important;
}

.bg-orange {
  background-color: rgb(var(--orange-rgb)) !important;
}

.bg-purple {
  background-color: rgb(var(--purple-rgb)) !important;
}

.bg-green {
  background-color: #1dd871 !important;
}

.bg-teal {
  background-color: rgb(var(--teal-rgb)) !important;
}

.bg-blue {
  background-color: #2b3e65 !important;
}

.bg-indigo {
  background-color: #4d5ddb !important;
}

.bg-yellow {
  background-color: #ffc102 !important;
}

/* End::background color */
/* Start::gradient colors */
.bg-primary-gradient {
  background: linear-gradient(to right, var(--primary-color) 0%, #8e77fa 100%) !important;
  color: #fff;
}

.bg-secondary-gradient {
  background: linear-gradient(to right, rgb(var(--secondary-rgb)) 0%, #28b7f9 100%) !important;
  color: #fff;
}

.bg-warning-gradient {
  background: linear-gradient(to right, rgb(var(--warning-rgb)) 0%, #fbc434 100%) !important;
  color: #fff;
}

.bg-info-gradient {
  background: linear-gradient(to right, rgb(var(--info-rgb)) 0%, #00f2fe 100%) !important;
  color: #fff;
}

.bg-success-gradient {
  background: linear-gradient(to right, rgb(var(--success-rgb)) 0%, #5cf9e2 100%) !important;
  color: #fff;
}

.bg-danger-gradient {
  background: linear-gradient(to right, rgb(var(--danger-rgb)) 0%, #fd4a68 100%) !important;
  color: #fff;
}

.bg-orange-gradient {
  background: linear-gradient(to right, rgb(var(--orange-rgb)) 0%, #FBB034 100%) !important;
  color: #fff;
}

.bg-purple-gradient {
  background: linear-gradient(to right, rgb(var(--purple-rgb)) 0%, #9b25b7 100%) !important;
  color: #fff;
}

.bg-teal-gradient {
  background: linear-gradient(to right, rgb(var(--teal-rgb)) 0%, #0695DD 100%) !important;
  color: #fff;
}

.bg-light-gradient {
  background: linear-gradient(to right, rgb(var(--light-rgb)) 0%, #D1D6DE 100%) !important;
  color: #000;
}

.bg-dark-gradient {
  background: linear-gradient(to right, rgb(var(--dark-rgb)) 0%, #54505D 100%) !important;
  color: #fff;
}

/* End::gradient colors */
/* Start:: outline colors */
.bg-outline-primary {
  background-color: var(--custom-white);
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.bg-outline-secondary {
  background-color: var(--custom-white);
  border: 1px solid rgb(var(--secondary-rgb));
  color: rgb(var(--secondary-rgb));
}

.bg-outline-warning {
  background-color: var(--custom-white);
  border: 1px solid rgb(var(--warning-rgb));
  color: rgb(var(--warning-rgb));
}

.bg-outline-info {
  background-color: var(--custom-white);
  border: 1px solid rgb(var(--info-rgb));
  color: rgb(var(--info-rgb));
}

.bg-outline-success {
  background-color: var(--custom-white);
  border: 1px solid rgb(var(--success-rgb));
  color: rgb(var(--success-rgb));
}

.bg-outline-danger {
  background-color: var(--custom-white);
  border: 1px solid rgb(var(--danger-rgb));
  color: rgb(var(--danger-rgb));
}

.bg-outline-dark {
  background-color: var(--custom-white);
  border: 1px solid rgb(var(--dark-rgb));
  color: rgb(var(--dark-rgb));
}

.bg-outline-light {
  background-color: var(--custom-white);
  border: 1px solid rgb(var(--light-rgb));
  color: var(--custom-black);
}

/* End:: outline colors */
/* Start::gray set */
.bg-gray-100 {
  background-color: var(--gray-1);
}

.bg-gray-200 {
  background-color: var(--gray-2);
}

.bg-gray-300 {
  background-color: var(--gray-3);
}

.bg-gray-400 {
  background-color: var(--gray-4);
}

.bg-gray-500 {
  background-color: var(--gray-5);
}

.bg-gray-600 {
  background-color: var(--gray-6);
}

.bg-gray-700 {
  background-color: var(--gray-7);
}

.bg-gray-800 {
  background-color: var(--gray-8);
}

.bg-gray-900 {
  background-color: var(--gray-9);
}

/* End::gray set */
/* Start::background transparent */
.bg-primary-transparent {
  background-color: var(--primary01) !important;
  color: var(--primary-color) !important;
}

.bg-primary-transparent:hover {
  background-color: var(--primary01) !important;
  color: var(--primary-color) !important;
}

.bg-secondary-transparent {
  background-color: rgba(var(--secondary-rgb), 0.1) !important;
  color: rgb(var(--secondary-rgb)) !important;
}

.bg-secondary-transparent:hover {
  background-color: rgba(var(--secondary-rgb), 0.1) !important;
  color: rgb(var(--secondary-rgb)) !important;
}

.bg-info-transparent {
  background-color: rgba(var(--info-rgb), 0.1) !important;
  color: rgb(var(--info-rgb)) !important;
}

.bg-info-transparent:hover {
  background-color: rgba(var(--info-rgb), 0.1) !important;
  color: rgb(var(--info-rgb)) !important;
}

.bg-success-transparent {
  background-color: rgba(var(--success-rgb), 0.1) !important;
  color: rgb(var(--success-rgb)) !important;
}

.bg-success-transparent:hover {
  background-color: rgba(var(--success-rgb), 0.1) !important;
  color: rgb(var(--success-rgb)) !important;
}

.bg-warning-transparent {
  background-color: rgba(var(--warning-rgb), 0.1) !important;
  color: rgb(var(--warning-rgb)) !important;
}

.bg-warning-transparent:hover {
  background-color: rgba(var(--warning-rgb), 0.1) !important;
  color: rgb(var(--warning-rgb)) !important;
}

.bg-danger-transparent {
  background-color: rgba(var(--danger-rgb), 0.1) !important;
  color: rgb(var(--danger-rgb)) !important;
}

.bg-danger-transparent:hover {
  background-color: rgba(var(--danger-rgb), 0.1) !important;
  color: rgb(var(--danger-rgb)) !important;
}

.bg-light-transparent {
  background-color: rgba(var(--light-rgb), 0.5) !important;
  color: rgb(var(--light-rgb)) !important;
}

.bg-light-transparent:hover {
  background-color: rgba(var(--light-rgb), 0.5) !important;
  color: var(--default-text-color) !important;
}

.bg-dark-transparent {
  background-color: rgba(var(--dark-rgb), 0.1) !important;
  color: rgb(var(--dark-rgb)) !important;
}

.bg-dark-transparent:hover {
  background-color: rgba(var(--dark-rgb), 0.1) !important;
  color: rgb(var(--dark-rgb)) !important;
}

.bg-pink-transparent {
  background-color: rgba(var(--pink-rgb), 0.1) !important;
  color: rgb(var(--pink-rgb)) !important;
}

.bg-pink-transparent:hover {
  background-color: rgba(var(--pink-rgb), 0.1) !important;
  color: rgb(var(--pink-rgb)) !important;
}

.bg-orange-transparent {
  background-color: rgba(var(--orange-rgb), 0.1) !important;
  color: rgb(var(--orange-rgb)) !important;
}

.bg-orange-transparent:hover {
  background-color: rgba(var(--orange-rgb), 0.1) !important;
  color: rgb(var(--orange-rgb)) !important;
}

.bg-purple-transparent {
  background-color: rgba(var(--purple-rgb), 0.1) !important;
  color: rgb(var(--purple-rgb)) !important;
}

.bg-purple-transparent:hover {
  background-color: rgba(var(--purple-rgb), 0.1) !important;
  color: rgb(var(--purple-rgb)) !important;
}

.bg-teal-transparent {
  background-color: rgba(var(--teal-rgb), 0.1) !important;
  color: rgb(var(--teal-rgb)) !important;
}

.bg-teal-transparent:hover {
  background-color: rgba(var(--teal-rgb), 0.1) !important;
  color: rgb(var(--teal-rgb)) !important;
}

.bg-green-transparent {
  background-color: rgba(29, 216, 113, 0.1) !important;
  color: #1dd871 !important;
}

.bg-green-transparent:hover {
  background-color: rgba(29, 216, 113, 0.1) !important;
  color: #1dd871 !important;
}

.bg-indigo-transparent {
  background-color: rgba(77, 93, 219, 0.1) !important;
  color: #4d5ddb !important;
}

.bg-indigo-transparent:hover {
  background-color: rgba(77, 93, 219, 0.1) !important;
  color: #4d5ddb !important;
}

.bg-yellow-transparent {
  background-color: rgba(255, 193, 2, 0.1) !important;
  color: #ffc102 !important;
}

.bg-yellow-transparent:hover {
  background-color: rgba(255, 193, 2, 0.1) !important;
  color: #ffc102 !important;
}

.bg-blue-transparent {
  background-color: rgba(43, 62, 101, 0.1) !important;
  color: #2b3e65 !important;
}

.bg-blue-transparent:hover {
  background-color: rgba(43, 62, 101, 0.1) !important;
  color: #2b3e65 !important;
}

/* End::background transparent */
/* Start::backgrounds with colors */
.text-bg-primary {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}

.text-bg-secondary {
  background-color: rgb(var(--secondary-rgb)) !important;
  color: #fff !important;
}

.text-bg-warning {
  background-color: rgb(var(--warning-rgb)) !important;
  color: #fff !important;
}

.text-bg-info {
  background-color: rgb(var(--info-rgb)) !important;
  color: #fff !important;
}

.text-bg-success {
  background-color: rgb(var(--success-rgb)) !important;
  color: #fff !important;
}

.text-bg-danger {
  background-color: rgb(var(--danger-rgb)) !important;
  color: #fff !important;
}

.text-bg-light {
  background-color: rgb(var(--light-rgb)) !important;
  color: #000 !important;
}

.text-bg-dark {
  background-color: rgb(var(--dark-rgb)) !important;
  color: #fff !important;
}

/* End::backgrounds with colors */
/* Start::svg colors */
.svg-primary {
  fill: var(--primary-color);
}

.svg-secondary {
  fill: rgb(var(--secondary-rgb));
}

.svg-success {
  fill: rgb(var(--success-rgb));
}

.svg-danger {
  fill: rgb(var(--danger-rgb));
}

.svg-warning {
  fill: rgb(var(--warning-rgb));
}

.svg-white {
  fill: #fff;
}

.svg-black {
  fill: #000;
}

.svg-pink {
  fill: rgb(var(--pink-rgb));
}

.svg-orange {
  fill: rgb(var(--orange-rgb));
}

.svg-purple {
  fill: rgb(var(--purple-rgb));
}

.svg-indigo {
  fill: #4d5ddb;
}

.svg-info {
  fill: rgb(var(--info-rgb));
}

.svg-yellow {
  fill: #ffc102;
}

.svg-light {
  fill: rgb(var(--light-rgb));
}

.svg-dark {
  fill: rgb(var(--dark-rgb));
}

.svg-teal {
  fill: rgb(var(--teal-rgb));
}

.svg-default {
  fill: var(--default-text-color);
}

/* End::svg colors */
/* Start::Colors */
/* Start::blue set */
.bd-blue-100 {
  background-color: rgba(43, 62, 101, 0.1);
  color: var(--default-text-color);
}

.bd-blue-200 {
  background-color: rgba(43, 62, 101, 0.2);
  color: var(--default-text-color);
}

.bd-blue-300 {
  background-color: rgba(43, 62, 101, 0.3);
  color: var(--default-text-color);
}

.bd-blue-400 {
  background-color: rgba(43, 62, 101, 0.4);
  color: var(--default-text-color);
}

.bd-blue-500 {
  background-color: rgba(43, 62, 101, 0.5);
  color: var(--default-text-color);
}

.bd-blue-600 {
  background-color: rgba(43, 62, 101, 0.6);
  color: #fff;
}

.bd-blue-700 {
  background-color: rgba(43, 62, 101, 0.7);
  color: #fff;
}

.bd-blue-800 {
  background-color: rgba(43, 62, 101, 0.8);
  color: #fff;
}

.bd-blue-900 {
  background-color: rgba(43, 62, 101, 0.9);
  color: #fff;
}

.bd-blue {
  background-color: #2b3e65;
  color: #fff;
}

/* Start::blue set */
/* Start::indigo set */
.bd-indigo-100 {
  background-color: rgba(77, 93, 219, 0.1);
  color: var(--default-text-color);
}

.bd-indigo-200 {
  background-color: rgba(77, 93, 219, 0.2);
  color: var(--default-text-color);
}

.bd-indigo-300 {
  background-color: rgba(77, 93, 219, 0.3);
  color: var(--default-text-color);
}

.bd-indigo-400 {
  background-color: rgba(77, 93, 219, 0.4);
  color: var(--default-text-color);
}

.bd-indigo-500 {
  background-color: rgba(77, 93, 219, 0.5);
  color: var(--default-text-color);
}

.bd-indigo-600 {
  background-color: rgba(77, 93, 219, 0.6);
  color: #fff;
}

.bd-indigo-700 {
  background-color: rgba(77, 93, 219, 0.7);
  color: #fff;
}

.bd-indigo-800 {
  background-color: rgba(77, 93, 219, 0.8);
  color: #fff;
}

.bd-indigo-900 {
  background-color: rgba(77, 93, 219, 0.9);
  color: #fff;
}

.bd-indigo {
  background-color: #4d5ddb;
  color: #fff;
}

/* Start::indigo set */
/* Start::purple set */
.bd-purple-100 {
  background-color: rgba(var(--purple-rgb), 0.1);
  color: var(--default-text-color);
}

.bd-purple-200 {
  background-color: rgba(var(--purple-rgb), 0.2);
  color: var(--default-text-color);
}

.bd-purple-300 {
  background-color: rgba(var(--purple-rgb), 0.3);
  color: var(--default-text-color);
}

.bd-purple-400 {
  background-color: rgba(var(--purple-rgb), 0.4);
  color: var(--default-text-color);
}

.bd-purple-500 {
  background-color: rgba(var(--purple-rgb), 0.5);
  color: var(--default-text-color);
}

.bd-purple-600 {
  background-color: rgba(var(--purple-rgb), 0.6);
  color: #fff;
}

.bd-purple-700 {
  background-color: rgba(var(--purple-rgb), 0.7);
  color: #fff;
}

.bd-purple-800 {
  background-color: rgba(var(--purple-rgb), 0.8);
  color: #fff;
}

.bd-purple-900 {
  background-color: rgba(var(--purple-rgb), 0.9);
  color: #fff;
}

.bd-purple {
  background-color: rgb(var(--purple-rgb));
  color: #fff;
}

/* Start::purple set */
/* Start::pink set */
.bd-pink-100 {
  background-color: rgba(var(--pink-rgb), 0.1);
  color: var(--default-text-color);
}

.bd-pink-200 {
  background-color: rgba(var(--pink-rgb), 0.2);
  color: var(--default-text-color);
}

.bd-pink-300 {
  background-color: rgba(var(--pink-rgb), 0.3);
  color: var(--default-text-color);
}

.bd-pink-400 {
  background-color: rgba(var(--pink-rgb), 0.4);
  color: var(--default-text-color);
}

.bd-pink-500 {
  background-color: rgba(var(--pink-rgb), 0.5);
  color: var(--default-text-color);
}

.bd-pink-600 {
  background-color: rgba(var(--pink-rgb), 0.6);
  color: #fff;
}

.bd-pink-700 {
  background-color: rgba(var(--pink-rgb), 0.7);
  color: #fff;
}

.bd-pink-800 {
  background-color: rgba(var(--pink-rgb), 0.8);
  color: #fff;
}

.bd-pink-900 {
  background-color: rgba(var(--pink-rgb), 0.9);
  color: #fff;
}

.bd-pink {
  background-color: rgb(var(--pink-rgb));
  color: #fff;
}

/* Start::pink set */
/* Start::red set */
.bd-red-100 {
  background-color: rgba(208, 61, 70, 0.1);
  color: var(--default-text-color);
}

.bd-red-200 {
  background-color: rgba(208, 61, 70, 0.2);
  color: var(--default-text-color);
}

.bd-red-300 {
  background-color: rgba(208, 61, 70, 0.3);
  color: var(--default-text-color);
}

.bd-red-400 {
  background-color: rgba(208, 61, 70, 0.4);
  color: var(--default-text-color);
}

.bd-red-500 {
  background-color: rgba(208, 61, 70, 0.5);
  color: var(--default-text-color);
}

.bd-red-600 {
  background-color: rgba(208, 61, 70, 0.6);
  color: #fff;
}

.bd-red-700 {
  background-color: rgba(208, 61, 70, 0.7);
  color: #fff;
}

.bd-red-800 {
  background-color: rgba(208, 61, 70, 0.8);
  color: #fff;
}

.bd-red-900 {
  background-color: rgba(208, 61, 70, 0.9);
  color: #fff;
}

.bd-red {
  background-color: #d03d46;
  color: #fff;
}

/* Start::red set */
/* Start::orange set */
.bd-orange-100 {
  background-color: rgba(var(--orange-rgb), 0.1);
  color: var(--default-text-color);
}

.bd-orange-200 {
  background-color: rgba(var(--orange-rgb), 0.2);
  color: var(--default-text-color);
}

.bd-orange-300 {
  background-color: rgba(var(--orange-rgb), 0.3);
  color: var(--default-text-color);
}

.bd-orange-400 {
  background-color: rgba(var(--orange-rgb), 0.4);
  color: var(--default-text-color);
}

.bd-orange-500 {
  background-color: rgba(var(--orange-rgb), 0.5);
  color: var(--default-text-color);
}

.bd-orange-600 {
  background-color: rgba(var(--orange-rgb), 0.6);
  color: #fff;
}

.bd-orange-700 {
  background-color: rgba(var(--orange-rgb), 0.7);
  color: #fff;
}

.bd-orange-800 {
  background-color: rgba(var(--orange-rgb), 0.8);
  color: #fff;
}

.bd-orange-900 {
  background-color: rgba(var(--orange-rgb), 0.9);
  color: #fff;
}

.bd-orange {
  background-color: rgb(var(--orange-rgb));
  color: #fff;
}

/* Start::orange set */
/* Start::yellow set */
.bd-yellow-100 {
  background-color: rgba(255, 193, 2, 0.1);
  color: var(--default-text-color);
}

.bd-yellow-200 {
  background-color: rgba(255, 193, 2, 0.2);
  color: var(--default-text-color);
}

.bd-yellow-300 {
  background-color: rgba(255, 193, 2, 0.3);
  color: var(--default-text-color);
}

.bd-yellow-400 {
  background-color: rgba(255, 193, 2, 0.4);
  color: var(--default-text-color);
}

.bd-yellow-500 {
  background-color: rgba(255, 193, 2, 0.5);
  color: var(--default-text-color);
}

.bd-yellow-600 {
  background-color: rgba(255, 193, 2, 0.6);
  color: #fff;
}

.bd-yellow-700 {
  background-color: rgba(255, 193, 2, 0.7);
  color: #fff;
}

.bd-yellow-800 {
  background-color: rgba(255, 193, 2, 0.8);
  color: #fff;
}

.bd-yellow-900 {
  background-color: rgba(255, 193, 2, 0.9);
  color: #fff;
}

.bd-yellow {
  background-color: #ffc102;
  color: #fff;
}

/* Start::yellow set */
/* Start::green set */
.bd-green-100 {
  background-color: rgba(29, 216, 113, 0.1);
  color: var(--default-text-color);
}

.bd-green-200 {
  background-color: rgba(29, 216, 113, 0.2);
  color: var(--default-text-color);
}

.bd-green-300 {
  background-color: rgba(29, 216, 113, 0.3);
  color: var(--default-text-color);
}

.bd-green-400 {
  background-color: rgba(29, 216, 113, 0.4);
  color: var(--default-text-color);
}

.bd-green-500 {
  background-color: rgba(29, 216, 113, 0.5);
  color: var(--default-text-color);
}

.bd-green-600 {
  background-color: rgba(29, 216, 113, 0.6);
  color: #fff;
}

.bd-green-700 {
  background-color: rgba(29, 216, 113, 0.7);
  color: #fff;
}

.bd-green-800 {
  background-color: rgba(29, 216, 113, 0.8);
  color: #fff;
}

.bd-green-900 {
  background-color: rgba(29, 216, 113, 0.9);
  color: #fff;
}

.bd-green {
  background-color: #1dd871;
  color: #fff;
}

/* Start::green set */
/* Start::teal set */
.bd-teal-100 {
  background-color: rgba(var(--teal-rgb), 0.1);
  color: var(--default-text-color);
}

.bd-teal-200 {
  background-color: rgba(var(--teal-rgb), 0.2);
  color: var(--default-text-color);
}

.bd-teal-300 {
  background-color: rgba(var(--teal-rgb), 0.3);
  color: var(--default-text-color);
}

.bd-teal-400 {
  background-color: rgba(var(--teal-rgb), 0.4);
  color: var(--default-text-color);
}

.bd-teal-500 {
  background-color: rgba(var(--teal-rgb), 0.5);
  color: var(--default-text-color);
}

.bd-teal-600 {
  background-color: rgba(var(--teal-rgb), 0.6);
  color: #fff;
}

.bd-teal-700 {
  background-color: rgba(var(--teal-rgb), 0.7);
  color: #fff;
}

.bd-teal-800 {
  background-color: rgba(var(--teal-rgb), 0.8);
  color: #fff;
}

.bd-teal-900 {
  background-color: rgba(var(--teal-rgb), 0.9);
  color: #fff;
}

.bd-teal {
  background-color: rgb(var(--teal-rgb));
  color: #fff;
}

/* Start::teal set */
/* Start::cyan set */
.bd-cyan-100 {
  background-color: rgba(0, 209, 209, 0.1);
  color: var(--default-text-color);
}

.bd-cyan-200 {
  background-color: rgba(0, 209, 209, 0.2);
  color: var(--default-text-color);
}

.bd-cyan-300 {
  background-color: rgba(0, 209, 209, 0.3);
  color: var(--default-text-color);
}

.bd-cyan-400 {
  background-color: rgba(0, 209, 209, 0.4);
  color: var(--default-text-color);
}

.bd-cyan-500 {
  background-color: rgba(0, 209, 209, 0.5);
  color: var(--default-text-color);
}

.bd-cyan-600 {
  background-color: rgba(0, 209, 209, 0.6);
  color: #fff;
}

.bd-cyan-700 {
  background-color: rgba(0, 209, 209, 0.7);
  color: #fff;
}

.bd-cyan-800 {
  background-color: rgba(0, 209, 209, 0.8);
  color: #fff;
}

.bd-cyan-900 {
  background-color: rgba(0, 209, 209, 0.9);
  color: #fff;
}

.bd-cyan {
  background-color: #00D1D1;
  color: #fff;
}

/* Start::cyan set */
/* Start::gray set */
.bd-gray-100 {
  background-color: rgba(134, 153, 163, 0.1);
  color: var(--default-text-color);
}

.bd-gray-200 {
  background-color: rgba(134, 153, 163, 0.2);
  color: var(--default-text-color);
}

.bd-gray-300 {
  background-color: rgba(134, 153, 163, 0.3);
  color: var(--default-text-color);
}

.bd-gray-400 {
  background-color: rgba(134, 153, 163, 0.4);
  color: var(--default-text-color);
}

.bd-gray-500 {
  background-color: rgba(134, 153, 163, 0.5);
  color: var(--default-text-color);
}

.bd-gray-600 {
  background-color: rgba(134, 153, 163, 0.6);
  color: #fff;
}

.bd-gray-700 {
  background-color: rgba(134, 153, 163, 0.7);
  color: #fff;
}

.bd-gray-800 {
  background-color: rgba(134, 153, 163, 0.8);
  color: #fff;
}

.bd-gray-900 {
  background-color: rgba(134, 153, 163, 0.9);
  color: #fff;
}

.bd-gray {
  background-color: #8699A3;
  color: #fff;
}

/* Start::gray set */
/* Start::Colors */
/* Start::border */
.border-container {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  margin: 0.25rem;
  background-color: rgb(var(--light-rgb));
}

/*Start::border */
.border {
  border: 1px solid var(--default-border) !important;
  /* Start:: Border Style */
  /* End:: Border Style */
  /* Start::Border Colors */
  /* End::Border Colors */
  /* Start::border opacity */
  /* End::border opacity */
  /* Start::Border Widths */
  /* End::Border Widths */
  /* Start::Border Styles */
  /* End::Border Styles */
  /* Start::subtractive border */
  /* End::subtractive border */
}

.border.border-dashed {
  border-style: dashed !important;
}

.border.border-dotted {
  border-style: dotted !important;
}

.border.border-primary {
  border: 1px solid var(--primary-color) !important;
  opacity: 1;
}

.border.border-primary.border-opacity-10 {
  border-color: var(--primary01) !important;
}

.border.border-primary.border-opacity-25 {
  border-color: var(--primary03) !important;
}

.border.border-primary.border-opacity-50 {
  border-color: var(--primary05) !important;
}

.border.border-primary.border-opacity-75 {
  border-color: var(--primary08) !important;
}

.border.border-primary.border-opacity-100 {
  border-color: var(--primary-color) !important;
}

.border.border-secondary {
  border: 1px solid rgb(var(--secondary-rgb)) !important;
  opacity: 1;
}

.border.border-secondary.border-opacity-10 {
  border-color: rgba(var(--secondary-rgb), 0.1) !important;
}

.border.border-secondary.border-opacity-25 {
  border-color: rgba(var(--secondary-rgb), 0.25) !important;
}

.border.border-secondary.border-opacity-50 {
  border-color: rgba(var(--secondary-rgb), 0.5) !important;
}

.border.border-secondary.border-opacity-75 {
  border-color: rgba(var(--secondary-rgb), 0.75) !important;
}

.border.border-secondary.border-opacity-100 {
  border-color: rgba(var(--secondary-rgb), 1) !important;
}

.border.border-info {
  border: 1px solid rgb(var(--info-rgb)) !important;
  opacity: 1;
}

.border.border-info.border-opacity-10 {
  border-color: rgba(var(--info-rgb), 0.1) !important;
}

.border.border-info.border-opacity-25 {
  border-color: rgba(var(--info-rgb), 0.25) !important;
}

.border.border-info.border-opacity-50 {
  border-color: rgba(var(--info-rgb), 0.5) !important;
}

.border.border-info.border-opacity-75 {
  border-color: rgba(var(--info-rgb), 0.75) !important;
}

.border.border-info.border-opacity-100 {
  border-color: rgba(var(--info-rgb), 1) !important;
}

.border.border-success {
  border: 1px solid rgb(var(--success-rgb)) !important;
  opacity: 1;
}

.border.border-success.border-opacity-10 {
  border-color: rgba(var(--success-rgb), 0.1) !important;
}

.border.border-success.border-opacity-25 {
  border-color: rgba(var(--success-rgb), 0.25) !important;
}

.border.border-success.border-opacity-50 {
  border-color: rgba(var(--success-rgb), 0.5) !important;
}

.border.border-success.border-opacity-75 {
  border-color: rgba(var(--success-rgb), 0.75) !important;
}

.border.border-success.border-opacity-100 {
  border-color: rgba(var(--success-rgb), 1) !important;
}

.border.border-warning {
  border: 1px solid rgb(var(--warning-rgb)) !important;
  opacity: 1;
}

.border.border-warning.border-opacity-10 {
  border-color: rgba(var(--warning-rgb), 0.1) !important;
}

.border.border-warning.border-opacity-25 {
  border-color: rgba(var(--warning-rgb), 0.25) !important;
}

.border.border-warning.border-opacity-50 {
  border-color: rgba(var(--warning-rgb), 0.5) !important;
}

.border.border-warning.border-opacity-75 {
  border-color: rgba(var(--warning-rgb), 0.75) !important;
}

.border.border-warning.border-opacity-100 {
  border-color: rgba(var(--warning-rgb), 1) !important;
}

.border.border-danger {
  border: 1px solid rgb(var(--danger-rgb)) !important;
  opacity: 1;
}

.border.border-danger.border-opacity-10 {
  border-color: rgba(var(--danger-rgb), 0.1) !important;
}

.border.border-danger.border-opacity-25 {
  border-color: rgba(var(--danger-rgb), 0.25) !important;
}

.border.border-danger.border-opacity-50 {
  border-color: rgba(var(--danger-rgb), 0.5) !important;
}

.border.border-danger.border-opacity-75 {
  border-color: rgba(var(--danger-rgb), 0.75) !important;
}

.border.border-danger.border-opacity-100 {
  border-color: rgba(var(--danger-rgb), 1) !important;
}

.border.border-white {
  border: 1px solid #fff !important;
  opacity: 1;
}

.border.border-white.border-opacity-10 {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.border.border-white.border-opacity-25 {
  border-color: rgba(255, 255, 255, 0.25) !important;
}

.border.border-white.border-opacity-50 {
  border-color: rgba(255, 255, 255, 0.5) !important;
}

.border.border-white.border-opacity-75 {
  border-color: rgba(255, 255, 255, 0.75) !important;
}

.border.border-white.border-opacity-100 {
  border-color: white !important;
}

.border.border-light {
  border: 1px solid rgb(var(--light-rgb)) !important;
  opacity: 1;
}

.border.border-light.border-opacity-10 {
  border-color: rgba(var(--light-rgb), 0.1) !important;
}

.border.border-light.border-opacity-25 {
  border-color: rgba(var(--light-rgb), 0.25) !important;
}

.border.border-light.border-opacity-50 {
  border-color: rgba(var(--light-rgb), 0.5) !important;
}

.border.border-light.border-opacity-75 {
  border-color: rgba(var(--light-rgb), 0.75) !important;
}

.border.border-light.border-opacity-100 {
  border-color: rgba(var(--light-rgb), 1) !important;
}

.border.border-dark {
  border: 1px solid rgb(var(--dark-rgb)) !important;
  opacity: 1;
}

.border.border-dark.border-opacity-10 {
  border-color: rgba(var(--dark-rgb), 0.1) !important;
}

.border.border-dark.border-opacity-25 {
  border-color: rgba(var(--dark-rgb), 0.25) !important;
}

.border.border-dark.border-opacity-50 {
  border-color: rgba(var(--dark-rgb), 0.5) !important;
}

.border.border-dark.border-opacity-75 {
  border-color: rgba(var(--dark-rgb), 0.75) !important;
}

.border.border-dark.border-opacity-100 {
  border-color: rgba(var(--dark-rgb), 1) !important;
}

.border.border-orange {
  border: 1px solid rgb(var(--orange-rgb)) !important;
  opacity: 1;
}

.border.border-orange.border-opacity-10 {
  border-color: rgba(var(--orange-rgb), 0.1) !important;
}

.border.border-orange.border-opacity-25 {
  border-color: rgba(var(--orange-rgb), 0.25) !important;
}

.border.border-orange.border-opacity-50 {
  border-color: rgba(var(--orange-rgb), 0.5) !important;
}

.border.border-orange.border-opacity-75 {
  border-color: rgba(var(--orange-rgb), 0.75) !important;
}

.border.border-orange.border-opacity-100 {
  border-color: rgba(var(--orange-rgb), 1) !important;
}

.border.border-opacity-10 {
  --bs-border-opacity: 0.1 !important;
}

.border.border-opacity-25 {
  --bs-border-opacity: 0.25 !important;
}

.border.border-opacity-50 {
  --bs-border-opacity: 0.5 !important;
}

.border.border-opacity-75 {
  --bs-border-opacity: 0.75 !important;
}

.border.border-opacity-100 {
  --bs-border-opacity: 1 !important;
}

.border.border-1 {
  border-width: 1px !important;
}

.border.border-2 {
  border-width: 2px !important;
}

.border.border-3 {
  border-width: 3px !important;
}

.border.border-4 {
  border-width: 4px !important;
}

.border.border-5 {
  border-width: 5px !important;
}

.border.border-block-start-dashed {
  border-top-style: dashed;
}

.border.border-block-start-dotted {
  border-top-style: dotted;
}

.border.border-inline-end-dashed {
  border-inline-end-style: dashed;
}

.border.border-inline-end-dotted {
  border-inline-end-style: dotted;
}

.border.border-block-end-dashed {
  border-bottom-style: dashed;
}

.border.border-block-end-dotted {
  border-bottom-style: dotted;
}

.border.border-inline-start-dashed {
  border-inline-start-style: dashed;
}

.border.border-inline-start-dotted {
  border-inline-start-style: dotted;
}

.border.border-0 {
  border: 0 !important;
}

.border.border-top-0 {
  border-block-start: 0 !important;
}

.border.border-end-0 {
  border-inline-end: 0 !important;
}

.border.border-bottom-0 {
  border-block-end: 0 !important;
}

.border.border-start-0 {
  border-inline-start: 0 !important;
}

.border-top {
  border-top: 1px solid var(--default-border) !important;
}

.border-top.border-block-start-dashed {
  border-top-style: dashed !important;
}

.border-top.border-block-start-dotted {
  border-top-style: dotted !important;
}

.border-top.border-primary {
  border-color: var(--primary-color) !important;
}

.border-top.border-secondary {
  border-color: rgb(var(--secondary-rgb)) !important;
}

.border-top.border-warning {
  border-color: rgb(var(--warning-rgb)) !important;
}

.border-top.border-info {
  border-color: rgb(var(--info-rgb)) !important;
}

.border-top.border-success {
  border-color: rgb(var(--success-rgb)) !important;
}

.border-top.border-danger {
  border-color: rgb(var(--danger-rgb)) !important;
}

.border-top.border-light {
  border-color: rgb(var(--light-rgb)) !important;
}

.border-top.border-dark {
  border-color: rgb(var(--dark-rgb)) !important;
}

.border-end {
  border-inline-end: 1px solid var(--default-border) !important;
}

.border-end.border-inline-end-dashed {
  border-inline-end-style: dashed !important;
}

.border-end.border-inline-end-dotted {
  border-inline-end-style: dotted !important;
}

.border-end.border-primary {
  border-color: var(--primary-color) !important;
}

.border-end.border-secondary {
  border-color: rgb(var(--secondary-rgb)) !important;
}

.border-end.border-warning {
  border-color: rgb(var(--warning-rgb)) !important;
}

.border-end.border-info {
  border-color: rgb(var(--info-rgb)) !important;
}

.border-end.border-success {
  border-color: rgb(var(--success-rgb)) !important;
}

.border-end.border-danger {
  border-color: rgb(var(--danger-rgb)) !important;
}

.border-end.border-light {
  border-color: rgb(var(--light-rgb)) !important;
}

.border-end.border-dark {
  border-color: rgb(var(--dark-rgb)) !important;
}

.border-bottom {
  border-bottom: 1px solid var(--default-border) !important;
}

.border-bottom.border-block-end-dashed {
  border-bottom-style: dashed !important;
}

.border-bottom.border-block-end-dotted {
  border-bottom-style: dotted !important;
}

.border-bottom.border-primary {
  border-color: var(--primary-color) !important;
}

.border-bottom.border-secondary {
  border-color: rgb(var(--secondary-rgb)) !important;
}

.border-bottom.border-warning {
  border-color: rgb(var(--warning-rgb)) !important;
}

.border-bottom.border-info {
  border-color: rgb(var(--info-rgb)) !important;
}

.border-bottom.border-success {
  border-color: rgb(var(--success-rgb)) !important;
}

.border-bottom.border-danger {
  border-color: rgb(var(--danger-rgb)) !important;
}

.border-bottom.border-light {
  border-color: rgb(var(--light-rgb)) !important;
}

.border-bottom.border-dark {
  border-color: rgb(var(--dark-rgb)) !important;
}

.border-start {
  border-inline-start: 1px solid var(--default-border) !important;
}

.border-start.border-inline-start-dashed {
  border-inline-start-style: dashed !important;
}

.border-start.border-inline-start-dotted {
  border-inline-start-style: dotted !important;
}

.border-start.border-primary {
  border-color: var(--primary-color) !important;
}

.border-start.border-secondary {
  border-color: rgb(var(--secondary-rgb)) !important;
}

.border-start.border-warning {
  border-color: rgb(var(--warning-rgb)) !important;
}

.border-start.border-info {
  border-color: rgb(var(--info-rgb)) !important;
}

.border-start.border-success {
  border-color: rgb(var(--success-rgb)) !important;
}

.border-start.border-danger {
  border-color: rgb(var(--danger-rgb)) !important;
}

.border-start.border-light {
  border-color: rgb(var(--light-rgb)) !important;
}

.border-start.border-dark {
  border-color: rgb(var(--dark-rgb)) !important;
}

/*End::additive border */
/* Start::border radius */
.br-1 {
  border-radius: 0.0625rem;
}

.br-2 {
  border-radius: 0.125rem;
}

.br-3 {
  border-radius: 0.1875rem;
}

.br-4 {
  border-radius: 0.25rem;
}

.br-5 {
  border-radius: 0.3125rem;
}

.br-6 {
  border-radius: 0.375rem;
}

.br-7 {
  border-radius: 0.4375rem;
}

.br-8 {
  border-radius: 0.5rem;
}

.br-9 {
  border-radius: 0.5625rem;
}

.br-10 {
  border-radius: 0.625rem;
}

.br-15 {
  border-radius: 0.9375rem;
}

.br-20 {
  border-radius: 1.25rem;
}

.rounded {
  border-radius: 7px !important;
}

/* End::border radius */
/* Start::opacity */
.op-1-1 {
  opacity: 1;
}

.op-0 {
  opacity: 0;
}

.op-1 {
  opacity: 0.1;
}

.op-2 {
  opacity: 0.2;
}

.op-3 {
  opacity: 0.3;
}

.op-4 {
  opacity: 0.4;
}

.op-5 {
  opacity: 0.5;
}

.op-6 {
  opacity: 0.6;
}

.op-7 {
  opacity: 0.7;
}

.op-8 {
  opacity: 0.8;
}

.op-9 {
  opacity: 0.9;
}

/* End::opacity */
/* Start::Heading sizes */
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-block-end: 0.66em;
  line-height: 1.1;
}

h1,
.h1 {
  font-size: 2rem;
}

h2,
.h2 {
  font-size: 1.75rem;
}

h3,
.h3 {
  font-size: 1.5rem;
}

h4,
.h4 {
  font-size: 1.125rem;
}

h5,
.h5 {
  font-size: 1rem;
}

h6,
.h6 {
  font-size: 0.875rem;
}

/* End::Heading sizes */
/* Start::basic sizes */
.fs-sm {
  font-size: 0.6875rem;
}

.fs-base {
  font-size: 0.875rem;
}

.fs-lg {
  font-size: 1.125rem;
}

/* End::basic sizes */
/* Start::font sizes */
.fs-1 {
  font-size: 0.0625rem;
}

.fs-2 {
  font-size: 0.125rem;
}

.fs-3 {
  font-size: 0.1875rem;
}

.fs-4 {
  font-size: 0.25rem;
}

.fs-5 {
  font-size: 0.3125rem;
}

.fs-6 {
  font-size: 0.375rem;
}

.fs-7 {
  font-size: 0.4375rem;
}

.fs-8 {
  font-size: 0.5rem;
}

.fs-9 {
  font-size: 0.5625rem;
}

.fs-10 {
  font-size: 0.625rem;
}

.fs-11 {
  font-size: 0.6875rem;
}

.fs-12 {
  font-size: 0.75rem;
}

.fs-13 {
  font-size: 0.8125rem;
}

.fs-14 {
  font-size: 0.875rem;
}

.fs-15 {
  font-size: 0.9375rem;
}

.fs-16 {
  font-size: 1rem;
}

.fs-17 {
  font-size: 1.0625rem;
}

.fs-18 {
  font-size: 1.125rem;
}

.fs-19 {
  font-size: 1.1875rem;
}

.fs-20 {
  font-size: 1.25rem;
}

.fs-21 {
  font-size: 1.3125rem;
}

.fs-22 {
  font-size: 1.375rem;
}

.fs-23 {
  font-size: 1.4375rem;
}

.fs-24 {
  font-size: 1.5rem;
}

.fs-25 {
  font-size: 1.5625rem;
}

.fs-26 {
  font-size: 1.625rem;
}

.fs-27 {
  font-size: 1.6875rem;
}

.fs-28 {
  font-size: 1.75rem;
}

.fs-29 {
  font-size: 1.8125rem;
}

.fs-30 {
  font-size: 1.875rem;
}

.fs-32 {
  font-size: 2rem;
}

.fs-34 {
  font-size: 2.125rem;
}

.fs-36 {
  font-size: 2.25rem;
}

.fs-38 {
  font-size: 2.375rem;
}

.fs-40 {
  font-size: 2.5rem;
}

/* End::font sizes */
/* Start::text colors */
.text-default {
  color: var(--default-text-color) !important;
}

.text-muted {
  color: var(--text-muted) !important;
}

.text-primary {
  color: var(--primary-color) !important;
  opacity: 1;
}

.text-primary.text-opacity-75 {
  color: var(--primary08) !important;
}

.text-primary.text-opacity-25 {
  color: var(--primary03) !important;
}

.text-primary.text-opacity-50 {
  color: var(--primary05) !important;
}

.text-secondary {
  color: rgb(var(--secondary-rgb)) !important;
  opacity: 1;
}

.text-secondary.text-opacity-75 {
  color: rgba(var(--secondary-rgb), 0.75) !important;
}

.text-secondary.text-opacity-25 {
  color: rgba(var(--secondary-rgb), 0.25) !important;
}

.text-secondary.text-opacity-50 {
  color: rgba(var(--secondary-rgb), 0.5) !important;
}

.text-info {
  color: rgb(var(--info-rgb)) !important;
  opacity: 1;
}

.text-info.text-opacity-75 {
  color: rgba(var(--info-rgb), 0.75) !important;
}

.text-info.text-opacity-25 {
  color: rgba(var(--info-rgb), 0.25) !important;
}

.text-info.text-opacity-50 {
  color: rgba(var(--info-rgb), 0.5) !important;
}

.text-success {
  color: rgb(var(--success-rgb)) !important;
  opacity: 1;
}

.text-success.text-opacity-75 {
  color: rgba(var(--success-rgb), 0.75) !important;
}

.text-success.text-opacity-25 {
  color: rgba(var(--success-rgb), 0.25) !important;
}

.text-success.text-opacity-50 {
  color: rgba(var(--success-rgb), 0.5) !important;
}

.text-warning {
  color: rgb(var(--warning-rgb)) !important;
  opacity: 1;
}

.text-warning.text-opacity-75 {
  color: rgba(var(--warning-rgb), 0.75) !important;
}

.text-warning.text-opacity-25 {
  color: rgba(var(--warning-rgb), 0.25) !important;
}

.text-warning.text-opacity-50 {
  color: rgba(var(--warning-rgb), 0.5) !important;
}

.text-danger {
  color: rgb(var(--danger-rgb)) !important;
  opacity: 1;
}

.text-danger.text-opacity-75 {
  color: rgba(var(--danger-rgb), 0.75) !important;
}

.text-danger.text-opacity-25 {
  color: rgba(var(--danger-rgb), 0.25) !important;
}

.text-danger.text-opacity-50 {
  color: rgba(var(--danger-rgb), 0.5) !important;
}

.text-blue {
  color: #2b3e65 !important;
}

.text-green {
  color: #1dd871 !important;
}

.text-pink {
  color: rgb(var(--pink-rgb)) !important;
}

.text-teal {
  color: rgb(var(--teal-rgb)) !important;
}

.text-orange {
  color: rgb(var(--orange-rgb)) !important;
}

.text-indigo {
  color: #4d5ddb !important;
}

.text-purple {
  color: rgb(var(--purple-rgb)) !important;
}

.text-dark {
  color: rgb(var(--dark-rgb)) !important;
}

.text-light {
  color: rgb(var(--light-rgb)) !important;
}

.text-gray {
  color: var(--gray-5) !important;
}

.text-black {
  color: var(--custom-black) !important;
}

.text-white {
  color: var(--custom-white) !important;
}

.text-fixed-white {
  color: #fff !important;
}

.text-fixed-black {
  color: #000 !important;
}

.text-gray-1 {
  color: var(--gray-1);
}

.text-gray-2 {
  color: var(--gray-2);
}

.text-gray-3 {
  color: var(--gray-3);
}

.text-gray-4 {
  color: var(--gray-4);
}

.text-gray-5 {
  color: var(--gray-5);
}

.text-gray-6 {
  color: var(--gray-6);
}

.text-gray-7 {
  color: var(--gray-7);
}

.text-gray-8 {
  color: var(--gray-8);
}

.text-gray-9 {
  color: var(--gray-9);
}

/* End::text colors */
/* Start::links */
a {
  text-decoration: none;
  color: var(--default-text-color);
}

a:hover {
  --bs-text-opacity: 1;
  opacity: 1;
  color: var(--primary-color);
}

/* End::links */
/* Start::Colored Links */
.link-primary {
  color: var(--primary-color) !important;
}

.link-primary:hover,
.link-primary:focus,
.link-primary:active {
  color: var(--primary-color) !important;
}

.link-secondary {
  color: rgb(var(--secondary-rgb)) !important;
}

.link-secondary:hover,
.link-secondary:focus,
.link-secondary:active {
  color: rgb(var(--secondary-rgb)) !important;
}

.link-success {
  color: rgb(var(--success-rgb)) !important;
}

.link-success:hover,
.link-success:focus,
.link-success:active {
  color: rgb(var(--success-rgb)) !important;
}

.link-danger {
  color: rgb(var(--danger-rgb)) !important;
}

.link-danger:hover,
.link-danger:focus,
.link-danger:active {
  color: rgb(var(--danger-rgb)) !important;
}

.link-warning {
  color: rgb(var(--warning-rgb)) !important;
}

.link-warning:hover,
.link-warning:focus,
.link-warning:active {
  color: rgb(var(--warning-rgb)) !important;
}

.link-info {
  color: rgb(var(--info-rgb)) !important;
}

.link-info:hover,
.link-info:focus,
.link-info:active {
  color: rgb(var(--info-rgb)) !important;
}

.link-light {
  color: rgb(var(--light-rgb)) !important;
}

.link-light:hover,
.link-light:focus,
.link-light:active {
  color: rgb(var(--light-rgb)) !important;
}

.link-dark {
  color: rgb(var(--dark-rgb)) !important;
}

.link-dark:hover,
.link-dark:focus,
.link-dark:active {
  color: rgb(var(--dark-rgb)) !important;
}

/* End::Colored Links */
/* Start::Blockquote */
.blockquote-container {
  padding: 1.25rem;
  border: 1px solid var(--default-border);
  border-block-start: 4px solid var(--default-border);
  border-radius: 7px;
  position: relative;
}

.blockquote-container:before {
  position: absolute;
  content: "\f6b0";
  font-family: bootstrap-icons !important;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0.813rem;
  font-weight: 600;
  border: 1px solid var(--default-border);
  background-color: var(--custom-white);
  top: -0.875rem;
  align-items: center;
  display: flex;
  padding: 0px;
  justify-content: center;
  border-radius: 3.125rem;
  inset-inline-start: 50%;
  box-shadow: 0px 0.25rem 1rem rgba(0, 0, 0, 0.1);
}

.blockquote.custom-blockquote {
  font-size: 0.85rem;
  font-weight: 600;
  padding: 1rem;
  border-radius: 7px;
  position: relative;
}

.blockquote.custom-blockquote:before {
  content: "\f6b0";
  font-family: bootstrap-icons !important;
  z-index: 0;
  font-size: 4rem;
  position: absolute;
  inset-block-start: -1.5rem;
  inset-inline-start: -0.25rem;
}

.blockquote.custom-blockquote .quote-icon {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3.125rem;
  position: absolute;
  inset-block-start: -1rem;
  inset-inline-end: -1rem;
  background-color: var(--custom-white);
}

.blockquote.custom-blockquote .quote-icon i {
  font-size: 1.75rem;
  font-weight: 500;
}

.blockquote.custom-blockquote.primary {
  background-color: var(--primary01);
  border-inline-start: 2px solid var(--primary-color);
  border-block-start: 2px solid var(--primary-color);
}

.blockquote.custom-blockquote.primary .quote-icon i {
  color: var(--primary-color);
}

.blockquote.custom-blockquote.primary:before {
  color: var(--primary01);
}

.blockquote.custom-blockquote.secondary {
  background-color: rgba(var(--secondary-rgb), 0.1);
  border-inline-start: 2px solid rgb(var(--secondary-rgb));
  border-block-start: 2px solid rgb(var(--secondary-rgb));
}

.blockquote.custom-blockquote.secondary .quote-icon i {
  color: rgb(var(--secondary-rgb));
}

.blockquote.custom-blockquote.secondary:before {
  color: rgba(var(--secondary-rgb), 0.1);
}

.blockquote.custom-blockquote.info {
  background-color: rgba(var(--info-rgb), 0.1);
  border-inline-start: 2px solid rgb(var(--info-rgb));
  border-block-start: 2px solid rgb(var(--info-rgb));
}

.blockquote.custom-blockquote.info .quote-icon i {
  color: rgb(var(--info-rgb));
}

.blockquote.custom-blockquote.info:before {
  color: rgba(var(--info-rgb), 0.1);
}

.blockquote.custom-blockquote.warning {
  background-color: rgba(var(--warning-rgb), 0.1);
  border-inline-start: 2px solid rgb(var(--warning-rgb));
  border-block-start: 2px solid rgb(var(--warning-rgb));
}

.blockquote.custom-blockquote.warning .quote-icon i {
  color: rgb(var(--warning-rgb));
}

.blockquote.custom-blockquote.warning:before {
  color: rgba(var(--warning-rgb), 0.1);
}

.blockquote.custom-blockquote.success {
  background-color: rgba(var(--success-rgb), 0.1);
  border-inline-start: 2px solid rgb(var(--success-rgb));
  border-block-start: 2px solid rgb(var(--success-rgb));
}

.blockquote.custom-blockquote.success .quote-icon i {
  color: rgb(var(--success-rgb));
}

.blockquote.custom-blockquote.success:before {
  color: rgba(var(--success-rgb), 0.1);
}

.blockquote.custom-blockquote.danger {
  background-color: rgba(var(--danger-rgb), 0.1);
  border-inline-start: 2px solid rgb(var(--danger-rgb));
  border-block-start: 2px solid rgb(var(--danger-rgb));
}

.blockquote.custom-blockquote.danger .quote-icon i {
  color: rgb(var(--danger-rgb));
}

.blockquote.custom-blockquote.danger:before {
  color: rgba(var(--danger-rgb), 0.1);
}

/* End::Blockquote */
/* Start::Shadows */
[data-theme-mode=dark] .shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(33, 37, 41, 0.3) !important;
}

[data-theme-mode=dark] .shadow {
  box-shadow: 0 0.5rem 1rem rgba(33, 37, 41, 0.3) !important;
}

[data-theme-mode=dark] .shadow-lg {
  box-shadow: 0 1rem 3rem rgba(33, 37, 41, 0.3) !important;
}

/* End::Shadows */
@media screen and (min-width: 576px) {
  .w-sm-auto {
    width: auto;
  }
}

/* Start::Text Break */
[dir=rtl] .text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* End::Text Break */
/* Start::Transform */
.transform-none {
  transform: none !important;
}

/* End::Transform */
/* Start::Width */
.w-9 {
  width: 9px;
}

/* End::Width */
/* Start::height */
.h-9 {
  height: 9px;
}

/* End::Width */