$forgotpass-color: #99A1B7;
$background-color: #282c34;
$link-color: #61dafb;
$center: center;
$none: none;
$block: block;
$auto: auto;
$break-word: break-word;
$object-fit-cover: cover;
$display-flex: flex;
$cursor-pointer: pointer;
$font-weight-500: 500;
$primary-color: #007bff;
$light-gray: #f5f5f5;
$swiper-bg: #EEEEEE;
$swiper-btn-bg: rgba(255, 255, 255, 0.7);
$swiper-btn-size: 30px;
$font-size-small: 8px;
$font-size-large: 25px;
$text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
$overlay-gradient: radial-gradient(circle, transparent 20%, rgba(0, 0, 0, 0.6) 80%);
$gradient-color-1: rgba(255, 255, 255, 0);
$gradient-color-2: rgba(70, 57, 59, 1);
$gradient-overlay: linear-gradient(324deg, $gradient-color-1 70%, $gradient-color-2 100%);
$gradient-overlay-small: linear-gradient(308deg, $gradient-color-1 48%, rgb(70, 57, 59) 131%);
$border-radius: 10px;
$border-transparent: 2px solid transparent;
$loading-skeleton-bg: rgb(128, 128, 128);
$skeleton-border-color: #eee;
$skeleton-loading-bg: linear-gradient(90deg, #dfdbdb 25%, #dfdbdb 50%, #dfdbdb 75%);
$card-img-width: 300px;
$card-img-height: 200px;
$btn-padding: 0.375rem 0.75rem;
$btn-font-size: 1rem;
$btn-border-radius-left: 0.25rem;
$transition-timing: 0.15s ease-in-out;
$dropdown-bg: #fff;
$dropdown-border: 1px solid #ccc;
$navbar-border: 1px solid rgba(136, 136, 136, 0.685);
$black-color: black !important;
$tooltip-bg: rgba(0, 0, 0, 0.8);
$tooltip-padding: 10px;
$outline-color: #007bff;
$font-size: 22px;
$font-size-12px: 12px;
$bg-transparent: transparent !important;
$filter-blur: blur(4px) !important;
$white-bg: #fff !important;
$font-size-16: 16px !important;
$padding-small: 2px 5px;
$full-width: 100% !important;
$height-520px: 520px !important;
$height-250px: 250px !important;
$background-size-full: 100% 100% !important;
$height-100vh: 100vh !important;
$position-absolute: absolute;
$position-relative: relative;
$top-123px: 123px !important;
$left-170px: 170px !important;
$background-repeat-no: no-repeat !important;
$background-position-center: center !important;
$background-size-100-60: 100% 60% !important;
$height-100vh: 100vh !important;
$full-width: 100% !important;
$background-no-repeat: no-repeat !important;
$background-size-full: 100% 100% !important;
$white-color: #ffffff;
$border-radius-25px: 25px;
$padding-top-35px: 35px;
$bottom-20px: 20px !important;
$left-0px: 0px !important;
$top-0px: 0px !important;
$z-index-1: 1;
$z-index-1000: 1000;
$height-450px: 450px !important;
$height-400px: 400px !important;
$width-139px: 139px;
$height-33px: 33px;
$border-radius-6px: 6px;
$margin-top-20px: 20px;
$background-700px: url(../../public/images/Select-Room-700px.png) !important;
$background-homepage: url(../../public/images/homepageanimation_700px.gif) !important;
$height-280px: 280px;
$bottom--20px: -20px;
$top-300px: 300px !important;
$left-330px: 330px !important;
$z-index-top: 1000;
$width-700px: 700px !important;
$margin-left-28pc: 28% !important;
$top-200px: 200px !important;
$left-300px: 300px !important;
$z-index-backdrop: 1050;
$background-color-white: #fff !important;
$background-color-grey: #ccc;
$background-color-blue: blue;
$background-color-black: black;
$color-white: white;
$border-radius-50pc: 50%;
$border-2px: 2px solid black;
$border-right-none: none;
$border-radius-50pc: 50%;
$border-color-black: #000000;
$background-color-white: white;
$background-color-primary: #0250f8;
$background-color-secondary: #2469ff;
$background-color-danger: #E82646;
$font-size-large: 17px;
$font-size-small: 13px;
$transition-duration: 0.3s;
$transition-ease: ease;
$skeleton-bg: #ededed;
$skeleton-shine: white;
$gutter-x: 2.1rem !important;
$border-color-dark: #5d5c5c;
$border-color-light: #ced4da;
$bg-light: #f8f9fa;

/*-------------side bar*/
$side-button-bg: #fffce1;
$side-button-font-size: 0.8em;
$side-button-font-weight: 800;
$border-radius-10px: 10px;
$background-color-active: #c94e50;

$bm-burger-width: 23px;
$bm-burger-height: 23px;
$bm-burger-left: 36px;
$bm-burger-top: 21px;

$bm-burger-background: #373a47;
$bm-menu-bg: #ffffff;

$main-div-height: 160px;
$main-div-bg-color: #2469ff;
$border-radius-100pc: 100%;

$company-logo-height: 150px;
$company-logo-width-340px: 340px;
$company-logo-width-304px: 304px;
$company-logo-width-400px: 400px;
$company-logo-bg-color: #ffffff;
$company-logo-margin-top-1: -89px;
$company-logo-margin-top-2: -95px;
$company-logo-margin-top-3: -80px;
$company-logo-box-shadow: 0px 9px 36px rgba(0, 0, 0, 0.15);

$visiter-email-bg-color: #F7F7F7;
$visiter-email-border-color: rgb(204, 204, 204);
$visiter-email-height: 45px;
$visiter-email-width-1: 335px;
$visiter-email-width-2: 350px;
$visiter-email-margin-top: 25px;
$margin-auto: auto;

$count-box-width: 160px;
$count-box-height: 100px;

$view-button-width: 48px;
$view-button-height: 23px;
$view-button-bg-color: #ffffff;
$view-button-font-size: 13px;
$view-button-font-weight: 600;

$bm-cross-bg-color: #ffffff;
$bm-cross-width: 3.5px;
$bm-cross-height: 16px;

$visitor-profile-font-size: 14px;

$form-check-input-checked-bg: #ffffff;

$bs-gutter-x: 0px;
$bs-gutter-y: 0px;
$border-radius-4px: 4px;
$border-radius-5px: 5px;
$border-radius-2: 2.5px;
$border-radius-8px: 8px;
