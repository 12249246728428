.see-catalog-header {
    position: absolute !important;
    top: 2% !important;
    width: 100% !important;
    z-index: 1 !important;
    margin-top: 10px !important;
}

.services {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    width: 100% !important;
    height: 100vh !important;
    padding-top: 40px !important;
}

.service-box {
    width: 100%;
    // max-width: 272px;
    height: 550px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    object-fit: cover;
    border-left: 3px solid black;
    border-right: 3px solid black;
    border-top: 5px solid black;
    border-bottom: 5px solid black;
    margin: 35px 30px !important;
    // box-shadow: 0px 0px 2px 1px rgba(255, 255, 255, 0.8);
    background-color: #222 !important;
    overflow: hidden !important;
    position: relative !important;
}

.swiper-holder {
    width: 100%;
    position: relative;
    padding: 0px 0;
    min-height: 60vh;
    max-height: 80vh;
    margin: 0 0 0 0;
}

.swiper-holder::before,
.swiper-holder::after {
    content: "";
    width: 100%;
    height: 28px;
    position: absolute;
    left: 0;
    background-repeat: repeat-x;
    background-size: auto 100%;
}

.swiper-holder::before {
    background-image: url(../../public/images/top-line.png);
    margin-top: 13px !important;
}

.swiper-holder::after {
    background-image: url(../../public/images/bottom-line.png);
    bottom: 5% !important;
}

.tile-details-box {
    position: absolute;
    top: 0;
    background: #ffffff;
    border-bottom: 1px solid #ddd;
    text-align: left;
    font-size: 12px !important;
    // color: #000000;
    width: 100%;
    padding: 5px 10px;
}

.tile-details-box img {
    width: 100% !important;
    height: auto !important;
    max-height: 500px !important;
    object-fit: cover !important;
    border-radius: 5px !important;
}

.custom-search::placeholder {
    color: white !important;
    opacity: 1;
    font-size: 14px !important;
}


.swiper-slide .swiper-slide-visible .swiper-slide-fully-visible {
    width: auto !important;
}

.swiper-slide img {
    border: none !important;
}

.details-image {
    width: 100% !important;
    height: auto !important;
    max-height: 400px !important;
    object-fit: cover !important;
    border-radius: 5px !important;
}


.details-header {
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #fff !important;
}

//////////////////catalog screen ////////////////

.catalog-screen {
    margin: 0 !important;
    padding: 0 !important;
    // background-image: url('../../public/images/Black-background-screen.jpg') !important;
    background: rgb(90,85,85) !important;
    background: radial-gradient(circle, rgba(90,85,85,1) 0%, rgba(0,0,0,1) 100%) !important;
    width: 100vw !important;
    height: 100% !important;
    position: relative !important;
    z-index: 999 !important;
}

.catalog-screen-box {
    position: absolute !important;
    bottom: 0px;
    width: 100% !important;
}

.screen-box-1 {
    width: 360px !important;
    height: 330px !important;
    background-image: url('../../public/images/Black-background-box.png');
    background-size: cover;
    background-position: center;
    position: relative;
}

.box-image {
    position: absolute;
    top: 2.5%;
    left: 15%;
    border-radius: 5px;
    height: 173px;
    width: 250px;
    cursor: pointer;
}

////////////////// details //////////////// 

.btn-gold {
    background: #DAA520 !important;
}
.custom-container-see-catalog {
    margin: 0 !important;
    padding: 0 !important;
    // background-image: url(../../public/images/back2.jpg) !important;
    background: rgb(90,85,85);
    background: radial-gradient(circle, rgba(90,85,85,1) 0%, rgba(0,0,0,1) 100%);
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    height: 100vh !important;
    overflow-y: auto !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.custom-container-see-catalog::-webkit-scrollbar {
    display: none;
}

.custom-container-details {
    margin-top: 130px;
    z-index: 1000 !important;
}

.service-box-details {
    width: 100%;
    max-width: 300px;
    height: 520px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    object-fit: cover;
    border-left: 3px solid black;
    border-right: 3px solid black;
    border-top: 5px solid black;
    border-bottom: 5px solid black;
    box-shadow: 0px 0px 2px 1px rgba(255, 255, 255, 0.8);
    background-color: #222 !important;
    overflow: hidden !important;
    position: relative !important;
}

.service-box-details img {
    height: 520px !important;
    object-fit: cover !important;
    max-width: 300px !important;
    width: 100% !important;
}

.details-header {
    color: white !important;
}

.details-title {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #fff !important;
}

.custom-width {
    width: 50%;
}
.header-logo-catalog{
    height: 80px !important;
    width: 80px !important;
    margin-right: 40px !important;
}


.skeleton-catalog{
    width: 120px;
    height: 40px;
    background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
    background-size: 200% 100%;
}

//////////// media queries ////////////////



/* Extra Small Devices (Phones) */
@media (max-width: 576px) {
    .service-box {
        max-width: 242px;
    }

    .swiper-holder::after {
        bottom: 4% !important;
    }

    .tile-details-box {
        font-size: 10px !important;
    }

    .service-box-details {
        max-width: 280px;
        height: 420px;
    }
    .swiper-holder {
        min-height: 60vh;
        max-height: 70vh;
        margin-top: 130px;
    }
    .custom-width {
        width: 100%;
    }
    .header-logo-catalog{
        margin-right: -20px !important;
    }
}

/* Small Devices (Tablets) */
@media (min-width: 577px) and (max-width: 768px) {
    .service-box {
        max-width: 220px !important;
    }

    .swiper-holder::after {
        bottom: 4% !important;
    }

    .tile-details-box {
        font-size: 11px;
    }

    .screen-box-1 {
        width: 180px !important;
        height: 160px !important;
    }
    .swiper-holder {
        min-height: 60vh;
        max-height: 70vh;
        margin-top: 100px;
    }
    .custom-width {
        width: 100%;
    }
    .header-logo-catalog{
        margin-right: 15px !important;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .swiper-holder::after {
        bottom: 3.1% !important;
    }

    .tile-details-box {
        font-size: 8px !important;
    }

    .tile-details-box {
        font-size: 12px;
    }

    .screen-box-1 {
        width: 220px !important;
        height: 203px !important;
    }
    .swiper-holder {
        min-height: 60vh;
        max-height: 80vh;
        margin-top: 100px;
    }
    .header-logo-catalog{
        margin-right: 30px !important;
    }
}

/* Large Devices (Desktops) */
@media (min-width: 1025px) and (max-width: 1599px) {
    .service-box {
        max-width: 252px !important;
    }

    .swiper-holder::after {
        bottom: 4.5% !important;
    }

    .tile-details-box {
        font-size: 14px;
    }

    .screen-box-1 {
        width: 270px !important;
        height: 248px !important;
    }
    .swiper-holder {
        min-height: 80vh;
        max-height: 80vh;
        // margin-top: 100px;
    }
}

@media (min-width: 1600px) {
    .service-box {
        max-width: 372px;
    }

    .swiper-holder::after {
        bottom: 6.5% !important;
    }
}