@import "./Variables.scss";

.App {
  text-align: $center !important;
}

.add-to-favorite {
  position: $position-absolute !important;
  padding-top: 5px !important;
  padding-left: 20px;
  font-size: 8px !important;
  cursor: $cursor-pointer !important;
  z-index: 1;
}

.add-to-favorite-like {
  position: $position-absolute !important;
  padding-top: 19px !important;
  padding-left: 20px;
  font-size: 8px !important;
  cursor: $cursor-pointer !important;
  z-index: 1;
}

.swiper-background {
  background: $swiper-bg;
}

.swiperimg::after {
  content: "";
  position: $position-absolute !important;
  top: 0;
  left: 0;
  height: 100%;
  background: $overlay-gradient;
  pointer-events: $none !important;
  mix-blend-mode: overlay;
}

.norecord {
  padding: 21.5px 0;
}

.skeleton-loading {
  display: $display-flex !important;
  height: $auto !important;
  flex-direction: column;
  align-items: $center;
}

.skeleton-loading-item {
  width: 100%;
  height: 20px;
  margin-bottom: 10px;
  background: $skeleton-loading-bg;
  background-size: 200% 100%;
  animation: loading 1.5ms infinite;
}

.skeleton-loading-header {
  height: $auto !important;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

.card-img,
.card-img-top {
  width: $card-img-width;
  height: $card-img-height;
  object-fit: contain;
}

.image-container {
  position: $position-absolute;
  width: 100%;
  top: 70% !important;
  left: 50%;
  transform: translate(-50%, -50%);
}

//============= 1 ==============

.image-slider-container .slider__wrapper .slider__stick .slider__handle path {
  stroke: $black-color;
  fill: $black-color;
}

area {
  height: 10px;
  width: 10px;
  background-color: $black-color;
}

.container {
  position: $position-relative;
}

.selected-tile {
  outline: #007bff solid 2px !important;
}

.selected-Aiimage {
  outline: #007bff solid 2px !important;
  box-shadow: 0px 25px 50px -12px #00000040 !important;
}

.sigin-dialog {
  margin-top: 227px !important;
  background-color: $white-bg;
  filter: $none !important;
}

.modal-backdrop.show {
  opacity: 1 !important;
  background: $bg-transparent;
  filter: $filter-blur;
  align-items: $center !important;
  margin: $auto !important;
}

.intro-silder {
  height: $height-100vh;
  width: $full-width;
  position: $position-absolute;
  z-index: $z-index-top;
}

.slick-next:before,
.slick-prev:before {
  display: $none !important;
}

.slider-main {
  height: $full-width;
  // width: $full-width;
  width: 100% !important;
  position: relative;
}

.text-div {
  height: $height-280px;
  width: $full-width;
  position: $position-absolute;
  bottom: $bottom--20px;
  left: $left-0px;
  background-color: $white-color;
  text-align: $center !important;
  border-top-left-radius: $border-radius-25px;
  border-top-right-radius: $border-radius-25px;
  padding-top: $padding-top-35px;
}

.next-button {
  width: $width-139px;
  height: $height-33px;
  border-radius: $border-radius-6px;
  margin-top: $margin-top-20px;
  background-color: $background-color-secondary !important;
  color: #ffffff !important;
  font-size: 13px !important;
}

.slick-dots {
  position: $position-absolute !important;
  bottom: $bottom-20px;
  display: block !important;
  width: $full-width;
  padding: 0 !important;
  margin: 0 !important;
  list-style: $none !important;
  text-align: $center !important;
}

.space-ai-container {
  position: fixed;
  left: 50%;
  align-items: $center !important;
  transform: translateX(-50%);
  width: calc(100% - 0px);
  max-width: 700px;
}

.product-logo {
  margin: $auto !important;
  display: $display-flex !important;
  justify-content: $center !important;
  align-items: $center !important;
}

.profile-dropdown {
  background-color: #FFFFFF !important;
  min-width: 200px !important;
  max-width: 200px !important;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.23) !important;
  border-radius: 0.375rem !important;
  padding: 1rem 0.9375rem !important;
  font-weight: 500 !important;
  white-space: nowrap !important;
  font-size: 1rem !important;
  margin-top: 6px !important;
}

.nav-font {
  font-weight: 600 !important;
  font-size: 1rem !important;
}

.Kiosk-Profile {
  width: 35px !important;
  height: 35px !important;
  border: 1px solid #2e2e2e !important;
  border-radius: 50%;
  font-size: 1.5rem !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.card .card-body {
  margin: 0px;
  padding: 10px;
}

[data-rsbs-content] {
  overflow: $none !important;
}

.view-app-container {
  display: $display-flex !important;
  flex-direction: column;
  height: 100% !important;
  transition: height 2s !important;
  padding: 0px !important;
}

.checkbox-radio {
  display: $none !important;
}

.checkbox-radio+label {
  display: inline-block;
  cursor: $cursor-pointer !important;
  position: relative;
  padding-left: 25px;
}

.checkbox-radio+label:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid $border-color-black;
  background-color: $background-color-white;
  border-radius: $border-radius-50pc;
  position: $position-absolute;
  left: 5px;
  top: 5.5px;
}

.checkbox-radio:checked+label:before {
  background-color: $background-color-primary;
  border-color: $background-color-primary;
}

.checkbox-radio:not(:checked)+label:before {
  border-width: 4px;
  color: rgba(0, 0, 0, 0);
}

.checkbox-radio:checked+label:after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: $border-radius-50pc;
  background-color: $background-color-white;
  position: $position-absolute;
  left: 9px;
  top: 9.5px;
}

.modal-horizontal {
  display: $none !important;
}

.blur-modal {
  filter: blur(4px);
}

.confirmation-modal {
  width: 320px !important;
}

.confirmation-modal .modal-content {
  box-shadow: 0px 0px 15px #0000005b !important;
}

.modal-open .modal-backdrop {
  backdrop-filter: blur(4px);
}

.hYFJMi {
  display: $none !important;
}

.cataloglist:hover {
  border: $background-color-primary;
}

.createcatalogmodel {
  width: 350px !important;
}

.card.mb-4.border-0:hover {
  border: 1px solid $background-color-primary !important;
}

.transition {
  transition: bottom $transition-duration $transition-ease;
}

.btn-bg-uniform {
  background-color: $background-color-secondary !important;
}

.navindex {
  z-index: 1 !important;
}
.font-14-fw-500{
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #99A1B7 !important;
}
.uniform-btn {
  background-color: #387efe !important;
  color: #ffffff !important;
  font-size: 13px !important;
}

.uniform-edit-btn {
  background-color: #00b196 !important;
  color: #ffffff !important;
  font-size: 16px !important;
}

.uniform-success-btn {
  background-color: #00b196 !important;
  color: #ffffff !important;
  font-size: 13px !important;
}

.uniform-delete-btn {
  background-color: #e82646 !important;
  color: #ffffff !important;
  font-size: 13px !important;
}

.uniform-back-btn {
  background-color: #99a1b7 !important;
  color: #ffffff !important;
  font-size: 16px !important;
}
.custom-back-btn{
  background-color: #99a1b7 !important;
  color: #ffffff !important;
  margin-left: 3% !important;
}

.uniform-submit-btn {
  background-color: #387efe !important;
  color: #ffffff !important;
  font-size: 16px !important;
}

.row {
  --bs-gutter-x: 2.1rem !important;
}

.image-container,
.product-details {
  text-align: $center !important;
}

.custom-modal-container {
  padding: 20px;
}

.custom-modal-container input[type="email"],
.custom-modal-container input[type="tel"] {
  border: 1px solid #5d5c5c;
}

.or {
  overflow: hidden;
  text-align: $center !important;
  color: #5d5c5c;
}

.or:before,
.or:after {
  background-color: #5d5c5c;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.or:before {
  right: 0.5em;
  margin-left: -50%;
}

.or:after {
  left: 0.5em;
  margin-right: -50%;
}

.image-container {
  max-height: 97vh;
  height: 99vh;
}

.ai-image-section-browser-view {
  height: 100vh !important;
  max-height: 100% !important;
  background-color: red !important;
}

.box-container {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 20px;
  margin: 20px $auto !important;

  @media (min-width: 576px) {
    .box-container {
      max-width: 500px;
    }
  }
}

.checkbox-container {
  position: relative;
  display: inline-block;
}

.checkbox-radio {
  position: $position-absolute;
}

.checkbox-label {
  position: $position-absolute;
  display: block;
  padding: 4px;
  background-color: #ffffff;
  border-radius: 10px !important;
  box-shadow: 0px 0px 10px #00000054;
}

.browserView {
  position: relative !important;
  height: 100vh !important;
  max-height: 100% !important;
  width: 100% !important;
  max-width: 100vw !important;
}

.silder-container {
  height: 100vh !important;
  min-height: 100% !important;
}


.botton-menubar {
  position: absolute !important;
  background-color: #ffffff !important;
  right: 0px !important;
  bottom: 9.3% !important;
  width: 100% !important;
  height: 70px !important;
  left: 0px !important;
  z-index: 1000 !important;
  display: flex !important;
  justify-content: space-evenly !important;
  align-items: center !important;
  gap: 5px !important;
  overflow-x: auto !important;
  overflow-y: hidden !important;
  white-space: nowrap !important;
  padding: 0 10px !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}

.botton-menubar::-webkit-scrollbar {
  display: none !important;
}

.product-details-btn {
  color: black !important;
  font-size: 10px !important;
  font-weight: 400 !important;
  font-family: "Inter", sans-serif;
  text-align: center !important;
  flex-shrink: 0 !important;
  width: auto !important;
  min-width: 60px !important;
  padding: 5px !important;
}

.bottom-menubar-icon {
  width: 14.43px !important;
  height: 13px !important;
  margin-bottom: 5px !important;
}


/* ------------- */

.vertical-slider-container {
  margin-top: 2px !important;
  height: 100% !important;
}

.mySwiper {
  height: 94% !important;
}

.aiimage {
  object-fit: cover !important;
  max-height: 100vh !important;
}

.signin-image {
  width: $auto !important;
  object-fit: contain !important;
  height: 100vh !important;
  float: right !important;
}

.signin-form-wrapper {
  width: 60% !important;
  margin: 0 $auto !important;
}

.forgotpass {
  color: $forgotpass-color !important;

  &:hover {
    color: $primary-color !important;
  }
}

.table-borderless td {
  padding: 15px 15px;
}

.password-toggle {
  position: $position-absolute !important;
  top: 50% !important;
  right: 0.5rem !important;
  transform: translateY(-50%) !important;
  cursor: pointer !important;
}

.input-search-field {
  max-width: 300px !important;
}

.campaign-list-card {
  border: solid 1px #f1f1f2 !important;
  padding: 10px;
  cursor: pointer;

  &:hover {
    border: solid 1px #cbdafd !important;
    background-color: #f1f5ff !important;
  }

  &:active {
    background-color: #f1f5ff !important;
  }
}

.campaignlist-card {
  border: solid 1px #f1f1f2 !important;
  padding: 10px;
  cursor: pointer;
  background-color: #f1f5ff !important;


  &:hover {
    border: solid 1px #91b1fc !important;
  }

  &:active {
    background-color: #f1f5ff !important;
  }
}

.width-for-fvt-product-btn {
  width: 170px !important;
}

.w-99pr {
  width: 99% !important;
}

.visitor-details-table {
  border: #f1f1f2 solid 1px !important;
  background-color: #ffffff !important;
}

.favorite-products-perent-div {
  background-color: $dropdown-bg !important;
  padding: 10px !important;
  height: 320px !important;
  overflow: hidden !important;
}

.favorite-products-div {
  height: 240px !important;
  align-items: center !important;
  margin-bottom: 10px !important;
}

.favorite-products-img {
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: contain !important;
}

.badge-green {
  color: #19c653 !important;
  background-color: #e0ffeb !important;
}

.badge-red {
  color: #e82646 !important;
  background-color: #ffe7eb !important;
}

.visitor-details-label {
  color: #99a1b7 !important;
}

.custom-close-icon {
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.custom-close-icon:hover {
  transform: scale(1.1);
  content: url("../../public/icon/closeiconblue.svg");
}

.kiosk-body {
  background-color: #fefefe;
}

.qr-box {
  background-color: #ffffff;
}

.kiosk-container {
  width: 93% !important;
  margin: 0 $auto;

  @media (max-width: 767px) {
    width: 99% !important;
  }

  cursor: $cursor-pointer !important;
}

.button-container-browser-view {
  flex-wrap: nowrap !important;
  width: 100% !important;
  display: flex !important;
  justify-content: space-evenly !important;
  align-items: center !important;
  white-space: nowrap !important;
  padding: 2% 0 !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}

.change-room {
  background: #00b196 !important;
  color: $white-color !important;
  border-radius: 3px !important;
  font-size: $font-size-12px !important;
  font-weight: $font-weight-500 !important;
  word-wrap: $break-word !important;
  padding: 8px 5px !important;
}

.button-wall-folor {
  color: $white-color !important;
  font-size: $font-size-12px !important;
  font-weight: $font-weight-500 !important;
  word-wrap: $break-word !important;
}

.button-catalog {
  background: #292929 !important;
  color: $white-color !important;
  font-size: $font-size-12px !important;
  font-weight: $font-weight-500 !important;
  word-wrap: $break-word !important;
  padding: 8px 5px !important;
}

.search-wall-floor-btn {
  width: 85px !important;
  height: 32px !important;
  color: $white-color !important;
  font-size: $font-size-12px !important;
  font-weight: $font-weight-500 !important;
  word-wrap: $break-word !important;
}

.search-filter-btn {
  width: 79% !important;
  height: 32px !important;
  color: $white-color !important;
  font-size: $font-size-12px !important;
  font-weight: $font-weight-500 !important;
  word-wrap: $break-word !important;
  background-color: #007bff !important;
}

.search-close-btn {
  width: 19% !important;
  height: 32px !important;
  color: $white-color !important;
  font-size: $font-size-12px !important;
  font-weight: $font-weight-500 !important;
  word-wrap: $break-word !important;
  background-color: #e82646 !important;
}



.search-checkbox {
  height: 17px !important;
  width: 17px !important;
  margin: 0px 5px 0px -10px !important;
  border-radius: 4px !important;
}

.cancel-uploading {
  color: $white-color !important;
  font-size: 17px !important;
  text-align: $center !important;
  margin-left: -4px !important;
}

.skip-btn {
  letter-spacing: 1px !important;
  font-size: 14px !important;
  color: $white-color !important;
  font-weight: bold !important;
  background-color: $bg-transparent !important;
  border: $none !important;
}

.tour-text {
  font-size: 13px !important;
  color: $white-color !important;
  font-weight: bold !important;
  background-color: $bg-transparent !important;
  border: $none !important;
}

.cUAKPn {
  display: $display-flex !important;
  margin-top: 15px !important;
  align-items: $center !important;
  justify-content: $center !important;
  color: $white-color !important;
}

.bFlTwX {
  color: $white-color !important;
  margin-right: 15px !important;
  width: 16px !important;
  height: 12px !important;
  flex: 0 0 16px !important;
}

.gySfCW {
  color: $white-color !important;
  margin-left: 13px !important;
  width: 16px !important;
  height: 12px !important;
  flex: 0 0 16px !important;
}

.jYlHbd {
  color: $white-color !important;
  margin: 3px !important;
}

.progress-bar {
  background-color: #066ee7 !important;
}

.gFToeB {
  color: $white-color !important;
}

.img-comparison-slider {
  --divider-width: 2px !important;
  --divider-color: #ff0000 !important;
  --default-handle-opacity: 1 !important;
  --default-handle-color: #ff0000 !important;
  outline: none !important;
  --default-handle-width: 40px !important;
}

.modal-content {
  box-shadow: 0px 0px 15px #0000005b !important;

}

.signout {
  width: auto;
  line-height: 2.5px !important;
  background: #fff2f4 !important;
  border-radius: 4px !important;
  border: 0.2px #e82646 solid !important;
  text-align: $center !important;
  margin-right: 30px !important;
}

// ===================== //

@media only screen and (min-width: 320px) {
  .navbar {
    text-align: $center;
    margin-top: 6px;
  }

  .select-Catalog {
    width: 80% !important;
    margin-bottom: 10% !important;
  }

  .image-container {
    position: $position-absolute;
    width: 100%;
    top: 57.5% !important;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .faSquarePlusicon {
    font-size: 14px !important;
    padding: $padding-small;
  }
  .custom-back-btn{
    margin-left: 12% !important;
  }

  .bottom-panel {
    display: block !important;
  }

  .bookmark {
    display: block !important;
  }

  .bookmark-browser {
    display: $none !important;
  }

  .aiimage {
    object-fit: cover !important;
    max-height: 65vh !important;
  }

  .compare-container {
    position: $position-relative !important;
  }

  .image-slider-container {
    width: $full-width;
    height: 100% !important;
    display: inline-flex !important;
    place-content: $center !important;
    position: relative !important;
    margin-top: 0px !important;
  }

  .ai-image-section {
    align-content: center;
    background-color: #efefef !important;
  }

  .left-right-button {
    position: $position-absolute;
    top: 30px;
    left: 0px;
  }

  .image-card {
    height: $height-250px;
    width: $full-width;
    margin: 0px;
  }

  .select-room {
    background-image: url(../../public/images/select-room-300px.png) !important;
    background-repeat: $background-repeat-no;
    background-size: $background-size-full;
    height: 55%;
    width: $full-width;
    position: absolute !important;
    top: 20% !important;
    z-index: 999 !important;
  }

  .select-button {
    position: $position-absolute;
    bottom: 10%;
    left: 35%;
  }

  .signin-model {
    background-image: url(../../public/images/homepageanimation_700px.gif) !important;
    background-repeat: $background-repeat-no;
    background-size: $background-size-100-60;
  }

  .img-div1,
  .img-div2,
  .img-div3 {
    height: $height-100vh;
    width: $full-width;
    background-repeat: $background-repeat-no;
    background-size: $background-size-full;

    &.img-div1 {
      background-image: url(../../public/images/Introscreensimage300-1.png);
    }

    &.img-div2 {
      background-image: url(../../public/images/Introscreensimage300-2.png);
    }

    &.img-div3 {
      background-image: url(../../public/images/Introscreensimage300-3.png);
    }
  }

  .loading-logo {
    position: absolute;
    top: 170px !important;
    left: 135px !important;
  }
}

@media only screen and (max-width: 425px) {
  .product-img {
    padding-top: 40%;
  }
}

@media only screen and (max-width: 480px) {
  .image-container {
    width: 100%;
    transform: translate(-50%, -50%);
  }
  .custom-back-btn{
    margin-left: 12% !important;
  }
}

@media only screen and (min-width: 720px) {
  .dropdown-menu.show {
    display: inline-flex;
    padding: 0px;
  }

  .faSquarePlusicon {
    font-size: 14px !important;
  }
  
}

@media only screen and (max-width: 767px) {
  .modal-horizontal-mobile {
    display: block;
  }
  
}

@media only screen and (min-width: 768px) {
  .sigin-dialog {
    margin-top: 60% !important;
    background-color: $white-color !important;
  }
  .custom-back-btn{
    margin-left: 7% !important;
  }

  .bottom-panel {
    display: block !important;
  }

  .aiimage {
    object-fit: cover !important;
    max-height: 70vh !important;
  }

  .bookmark {
    display: block !important;
  }

  .bookmark-browser {
    display: $none !important;
  }

  .compare-container {
    position: $position-relative !important;
  }

  .image-slider-container {
    width: $full-width;
    height: 100% !important;
    display: inline-flex !important;
    place-content: $center !important;
    position: relative !important;
    margin-top: 5px !important;
  }

  .ai-image-section {
    align-content: center;
    background-color: #efefef !important;
  }

  .left-right-button {
    position: $position-absolute;
    top: 20px;
    left: $left-0px;
  }

  .image-card {
    height: $height-400px;
    width: $full-width;
  }

  .select-room {
    background-image: url(../../public/images/Select-Room-700px.png) !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    height: 60%;
    width: 100%;
    position: absolute !important;
    top: 20% !important;
    z-index: 999 !important;
  }

  .select-button {
    position: $position-absolute;
    bottom: 5%;
    left: 43%;
  }

  .signin-model {
    background-image: url(../../public/images/homepageanimation_700px.gif);
    background-repeat: $background-no-repeat;
    background-size: 100% 65% !important;
  }

  .img-div1,
  .img-div2,
  .img-div3 {
    height: $height-100vh;
    width: $full-width;
    background-repeat: $background-no-repeat;
    background-size: $background-size-full;

    &.img-div1 {
      background-image: url(../../public/images/Introscreens-image1.png);
    }

    &.img-div2 {
      background-image: url(../../public/images/Introscreens-image2.png);
    }

    &.img-div3 {
      background-image: url(../../public/images/Introscreens-image3.png);
    }
  }

  .space-ai-container {
    position: fixed;
    left: 50%;
    align-items: $center !important;
    transform: translateX(-50%);
    width: calc(100% - 0px);
    max-width: 100% !important;
  }

  .loading-logo {
    position: $position-absolute;
    top: $top-300px;
    left: $left-330px;
  }
}

@media only screen and (min-width: 769px) {
  .space-ai-container {
    width: calc(100% - 0px);
    align-items: $center !important;
  }
  .custom-back-btn{
    margin-left: 5% !important;
  }

  .compare-container {
    position: $position-relative !important;
  }

  .mobile-view {
    display: none !important;
  }

  .image-card {
    height: $height-400px;
    width: $full-width;
  }

  .product-logo {
    margin: $auto !important;
    display: $display-flex !important;
    justify-content: $center !important;
    align-items: $center !important;
  }

  .image-slider-container {
    width: $full-width;
    height: 100% !important;
    display: inline-flex !important;
    place-content: $center !important;
    position: relative !important;
    margin-top: 0px !important;
  }

  .left-right-button {
    position: $position-absolute;
    top: -50px !important;
    left: $left-0px;
  }

  .ai-image-section-browser-view {
    align-content: center;
    background-color: #efefef !important;
  }

  .aiimage {
    object-fit: cover !important;
    height: 100% !important;
  }

  .bookmark {
    display: $none !important;
  }

  .bookmark-browser {
    display: block !important;
  }

  .bottom-panel {
    display: $none !important;
  }

  .select-room {
    background-image: url(../../public/images/Introscreens_1536x695_4.jpg) !important;
    background-repeat: $background-no-repeat;
    background-size: $background-size-full;
    object-fit: contain !important;
    width: $full-width;
    height: $height-100vh;
    position: absolute !important;
    top: 0% !important;
    z-index: 999 !important;
  }

  .img-div1,
  .img-div2,
  .img-div3 {
    width: $full-width;
    background-repeat: $background-no-repeat;
    background-size: contain !important;
    object-fit: contain !important;

    &.img-div1 {
      background-image: url(../../public/images/Introscreens_1536x695_1.jpg);
    }

    &.img-div2 {
      background-image: url(../../public/images/Introscreens_1536x695_2.jpg);
    }

    &.img-div3 {
      background-image: url(../../public/images/Introscreens_1536x695_3.jpg);
    }
  }

  .text-div {
    height: 230px !important;
    width: $full-width;
    position: $position-absolute;
    bottom: $bottom--20px;
    left: $left-0px;
    background-color: $white-color;
    text-align: $center !important;
    border-top-left-radius: $border-radius-25px;
    border-top-right-radius: $border-radius-25px;
    padding-top: 30px !important;
  }

  .select-button {
    position: $position-absolute;
    bottom: 18%;
    left: 43%;
  }

  .slick-dots {
    position: $position-absolute !important;
    bottom: 20px !important;
    display: block !important;
  }

  .next-button {
    width: $width-139px !important;
    height: $height-33px !important;
    border-radius: $border-radius-6px !important;
    margin-top: 10px !important;
    background-color: $background-color-secondary !important;
    color: #ffffff !important;
    font-size: 13px !important;
  }

  .signin-model {
    // background-image: url(../../public/images/homepageanimation_1536.gif) !important;
    background-image: url(../../public/images/Home-banner-animation.gif) !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    object-fit: contain !important;
    height: 100vh !important;
  }

  .sigin-dialog {
    margin-top: 17% !important;
    background-color: $white-color !important;
  }

  .view-app-container {
    display: $display-flex !important;
    flex-direction: column;
    height: 100% !important;
    transition: height 2s !important;
    padding: 0px !important;
  }

  [data-rsbs-content] {
    overflow: $none !important;
  }

  .card .card-body {
    margin: 0px;
    padding: 10px;
  }

  .modal-backdrop {
    --bs-backdrop-zindex: $z-index-backdrop !important;
    --bs-backdrop-bg: transparent !important;
    --bs-backdrop-opacity: 1 !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    z-index: var(--bs-backdrop-zindex);
    width: 100vw !important;
    height: 100vh !important;
    background-color: var(--bs-backdrop-bg);
  }

  .loading-logo {
    position: $position-absolute !important;
  }
}

@media only screen and (min-width: 1068px) {
  .space-ai-container {
    position: fixed;
    left: 50%;
    align-items: $center !important;
    transform: translateX(-50%);
    width: calc(100% - 0px);
    max-width: 700px;
  }
  .custom-back-btn{
    margin-left: 4% !important;
  }
}

@media (max-width: 768px) {
  .web-view {
    display: none !important;
  }
}
