@import './Variables.scss';
.add-to-favorite svg {
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 1));
}

@media only screen and (min-width: 320px) {
    .bottom-panel {
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 40px);
        max-width: 700px;
    }

    .product-card {
        height: 70px !important;
        background: white;
        border-radius: 5px;
        border: 1px black solid !important;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
    }

    .product-name {
        margin-top: 3px;
        font-size: 14px;
        font-weight: bold;
    }

    .product-value {
        font-size: 14px;
        margin-top: 7px;
    }
}

@media only screen and (min-width: 568px) {
    .product-card {
        height: 85px !important;
        background: white;
        border-radius: 5px;
        border: 1px black solid !important;
        text-align: center !important;
        font-size: 18px;
        font-weight: 600;
    }

    .product-value {
        font-size: 20px;
        margin-top: 10px;
    }

    .product-name {
        font-size: 20px;
        margin-top: 2px;
        font-weight: bold;
    }
}

@media only screen and (min-width: 768px) {
    .bottom-panel {
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 40px);
        max-width: 100% !important;
    }
}

@media only screen and (min-width: 1068px) {
    .bottom-panel {
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 40px);
        max-width: 700px;
    }
}

@keyframes loading {
    to {
        background-position-x: -20%;
    }
}

.loading-skeleton-browser{
    width: auto !important;
    height: 75px !important;
}

.loading-skeleton {
    border-radius: 5%;
    margin-top: 12.2px;
    margin-bottom: 16px;
    width: 70px;
    height: 70px;
    background-color: $skeleton-bg;
    background-image: linear-gradient(
        100deg,
        transparent 40%,
        color-mix(in srgb, $skeleton-shine, transparent 50%) 50%,
        transparent 60%
    );
    background-size: 200% 100%;
    background-position-x: 120%;

    @media (prefers-reduced-motion: no-preference) {
        background-position-x: 180%;
        animation: 2s loading ease-in-out infinite;
    }
}

.loading-skeleton[data-skeleton-lines] {
    --l-h: 1lh;
    --l-gap: 1ex;
    min-height: calc(var(--skeleton-lines) * var(--l-h) + (var(--skeleton-lines) - 1) * var(--l-gap));
    mask: linear-gradient(#000 0 var(--l-h), transparent var(--l-h) calc(var(--l-h) + var(--l-gap))) 0 0/100% calc(var(--l-h) + var(--l-gap));
}
.product-image {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.image-wrapper {
    position: relative;
}

.product-add-to-favorite {
    position: absolute;
    top: 10px;
    left: 10px;
}

.product-image-margin {
    margin: 1.1rem !important;
}